import MBreadcrumbs from './MBreadcrumbs';
import MNewsDetail from './MNewsDetail';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageNewsDetail extends Component{
  renderBreadcrumbs(){
    if(!this.props.mNewsDetail ||
       !this.props.mNewsDetail.news) return null;

    const mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'News and Updates', slug: 'news'},
        {browserTitle: this.props.mNewsDetail.news.title, slug: '#'},
      ],
    };

    return (<MBreadcrumbs mBreadcrumbs={mBreadcrumbs} className="coupling coupling--full-centered"/>);
  }
  render(){
    return (
      <PageBase>
        <div className="coupling coupling--parent coupling--full main-content">
          {this.renderBreadcrumbs()}
          <div className="coupling coupling--parent coupling--full">
            <MNewsDetail/>
          </div>
        </div>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mNewsDetail: state.mNewsDetail,
  }),
)(PageNewsDetail));


PageNewsDetail.propTypes = {
  mNewsDetail: PropTypes.object,
};
