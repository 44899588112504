import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class EventItem extends React.Component{
  render(){
    if(!this.props.event) return null;

    const {
      event,
      disableDate,
      disableDescription,
      disableLocation,
    } = this.props;

    const {eventDate} = event;
    let itemDate = new Date(eventDate);

    if(!itemDate.getDate()){
      return null;
    }

    const color =
      event.program && event.program.Color ? event.program.Color : '#cccccc';

    let eventString = moment(itemDate).format('ddd,MMM,D');
    itemDate = moment(eventDate).format('dddd, MMMM D, YYYY | h:mm A');
    eventString = eventString.toString().split(',');

    return (
      <div className={`event-item ${this.props.className}`}>
        <div className={`event-item__calendar`}
             style={{
               backgroundColor: `${color}90`,
               borderLeft: `4px solid ${color}`,
             }}>
          <div className="event-item__month">
            <div className="event-item__weekday">{eventString[0]}.</div>
            <div className="event-item__month-abbr">{eventString[1]}.</div>
          </div>
          <div className="event-item__day">{eventString[2]}</div>
        </div>
        <div className="event-item__content">
          <SuperLink to={`/events/${event.slug}`} className="event-item__link">
            <div className="event-item__title">{event.title}</div>
          </SuperLink>
          {!disableDescription && (
            <div className="event-item__description">
              {event.content
               ? helpers.trimToWord(
                  event.content.Contents,
                  apiValues.STUB_DESCRIPTION_LENGTH,
                )
               : ''}
            </div>
          )}
          {!disableDate && (
            <div className="event-item__date">{itemDate.toString()}</div>
          )}
          {!disableLocation && (
            <div className="event-item__location">
              {event.locationDescription}
            </div>
          )}
        </div>
      </div>
    );
  }
}


EventItem.propTypes = {
  className: PropTypes.string,
  disableDate: PropTypes.bool,
  disableDescription: PropTypes.bool,
  disableLocation: PropTypes.bool,
  event: PropTypes.object,
};
