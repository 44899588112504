import * as helpers from '../helpers';
import * as mListCardsActions from '../actions/mListCardsActions';
import Card from './Card';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MListCards extends React.Component{
  componentDidMount(){
    //console.log(`MListCards.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMListCards(this.props.cmsId);
    }
    else{
      //console.log(`passed in `, this.props.mListCards);
    }
  }


  renderCard(card){
    if(typeof card == 'string') return (<Card className="m-listcards__list-card" cmsId={card.id}/>);
    else return (<Card className="m-listcards__list-card" card={card}/>);
  }
  renderCards(){
    if(!this.props.mListCards.cards) return null;

    return this.props.mListCards.cards.map(cardId => {
      let card = helpers.getById(this.props.card,cardId);
      if(!card) return null;
      let sizeClass = card.displayMode == 'wide' ? 'col-12 col-md-8 col-lg-6' : 'col-12 col-sm-6 col-md-4 col-lg-3';

      return (
        <div key={cardId} className={sizeClass}>
          {this.renderCard(card)}
        </div>
      );
    });
  }
  renderTitle(){
    if(!this.props.mListCards.title) return null;

    return (<div className="m-listcards__title">{this.props.mListCards.title}</div>);
  }
  render(){
    //console.log(`MListCards render`, this.state, this.props);
    if(!this.props.mListCards ||
       !this.props.mListCards.cards ||
       this.props.mListCards.cards.length < 1) return null;

    let bgBlockClass = this.props.mListCards.backgroundBlockColor && this.props.mListCards.backgroundBlockColor.length > 0 ? 'm-listcards--bg-block' : '';
    let bgBlockStyle = {backgroundColor: this.props.mListCards.backgroundBlockColor};

    return (
      <div className={`m-listcards ${bgBlockClass} ${this.props.className || ''}`}>
        <div className="m-listcards__bg-block" style={bgBlockStyle}></div>
        <div className="m-listcards__list-container container container--coupling">
          {this.renderTitle()}
          <div className="row">
            <div className="m-listcards__list">
              {this.renderCards()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    card: state.card,
    mListCards: ownProps.cmsId ? helpers.getById(state.mListCards, ownProps.cmsId) : undefined
  }),
  (dispatch) => ({
    actions: bindActionCreators(mListCardsActions, dispatch)
  })
)(MListCards));


MListCards.propTypes = {
  actions: PropTypes.object,
  card: PropTypes.array,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mListCards: PropTypes.object
};
