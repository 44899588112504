import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as actionTypes from '../constants/actionType';
import * as pageActions from './pageActions';


export const loadMEventDetailStart = () => {
  return {
    type: actionTypes.MEVENTDETAIL_LOAD_START,
  };
};
export const loadMEventDetailFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MEVENTDETAIL_LOAD_FAIL,
    error,
  };
};
export const loadMEventDetailSuccess = (data) => {
  return {
    type: actionTypes.MEVENTDETAIL_LOAD_SUCCESS,
    data,
  };
};

export const initPageEventDetails = slug => dispatch => {
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {Slug: slug})
      .then(result => {
        let page = apiActions.itemSelectCamelCaseKeys(result[0], apiValues.nref.event.fieldsUsed);
        page.tags = page.tags ? apiActions.arraySelectCamelCaseKeys(page.tags, apiValues.nref.tag.fieldsUsed) : undefined;
        page.contacts = page.contacts ? apiActions.arraySelectCamelCaseKeys(page.contacts, apiValues.nref.contact.fieldsUsed) : undefined;

        resolve(dispatch(pageActions.loadPageSuccess(page)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};

//
//export const initPageEventDetails = () => (dispatch) => {
//  dispatch(pageActions.loadPageStart());
//
//  return apiActions.loadEndpoint(apiValues.nref.pageEventDetails.endpoint)
//    .then(results => {
//      let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageEventDetails.fieldsUsed);
//      return page;
//    })
//    .then(results => {
//      return dispatch(pageActions.loadPageSuccess(results));
//    })
//};
