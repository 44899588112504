import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mNewsletterActions from '../actions/mNewsletterActions';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MNewsLetter extends React.Component{
  state = {
    formError: '',
    formSuccess: false,
  };


  constructor(props){
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
  }


  handleValidSubmit(event, values){
    this.setState({
      formError: '',
      formSuccess: false,
    });

    let body = {...values};
    console.log(`handleValidSubmit`, `${apiValues.CMS_PATH}/api/getinvolved`, helpers.encodeData(body));

    fetch(`${apiValues.CMS_PATH}/api/newsoptin`, {
      // fetch(`${apiValues.CMS_PATH}/api/error`, {
      method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: helpers.encodeData(body),
    }).then(result => {
      // console.log('received response');
      if(result.status == 200) this.handleValidSubmitSuccess();
      else this.handleValidSubmitFailure(result);
    }, result => this.handleValidSubmitFailure(result));
  }
  handleValidSubmitSuccess = () => {
    console.log(`handleValidSubmitSuccess`);
    this.setState({formSuccess: true});
  };
  handleValidSubmitFailure = (result) => {
    console.error(`handleValidSubmitFailure`, result);
    this.setState({
      formError: 'There was a problem signing you up. Please try again later.',
      formSuccess: false,
    });
  };


  renderForm(){
    if(this.state.formSuccess) return null;

    return (
      <div className="m-newsletter__form">
        <div className="m-newsletter__description">Sign up here to be informed by email about our latest news and events.</div>
        <AvForm onValidSubmit={this.handleValidSubmit}>
          <AvField name="email" label="Email Address *" type="email" required/>
          {this.renderFormError()}
          <button className="tg-button tg-button--pill tg-button--black">
            <span className="tg-button__label">Sign Up</span>
          </button>
        </AvForm>
      </div>
    );
  }
  renderFormError(){
    if(!this.state.formError ||
       this.state.formError.length < 1) return null;

    return (<p className="form-error">{this.state.formError}</p>);
  }
  renderSuccess(){
    if(!this.state.formSuccess) return null;

    return (
      <div className="m-newsletter__form">
        <div className="m-newsletter__big">Thank you for Signing Up!</div>
        <p>Learn more about our projects <SuperLink to={'/projects'}>here</SuperLink>.</p>
      </div>
    );
  }
  render(){
    return (
      <div className={`m-newsletter ${this.props.className}`}>
        <div className="container container--coupling">
          <div className="m-newsletter__title">SIGN UP FOR UPDATES</div>
          <div className="m-newsletter__content">
            {this.renderForm()}
            {this.renderSuccess()}
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mNewsletter: state.mNewsletter,
  }),
  (dispatch) => ({
    actions: bindActionCreators(mNewsletterActions, dispatch),
  }),
)(MNewsLetter));


MNewsLetter.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  mNewsletter: PropTypes.object,
};
