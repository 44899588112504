import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMGetInvolvedById} from "../reducers/index";
import * as cardActions from './cardActions';


//Action Creators
export const loadMGetInvolvedStart = (id) => {
  return {
    type: actionTypes.MGETINVOLVED_LOAD_START,
    id
  };
};
export const loadMGetInvolvedFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MGETINVOLVED_LOAD_FAIL,
    id,
    error
  };
};
export const loadMGetInvolvedSuccess = (data) => {
  return {
    type: actionTypes.MGETINVOLVED_LOAD_SUCCESS,
    data
  };
};

export const initMGetInvolved = id => dispatch => {
  dispatch(loadMGetInvolvedStart(id));

  apiActions.loadEndpoint(apiValues.nref.mGetInvolved.endpoint, id).then(result => {
    let mGetInvolved = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mGetInvolved.fieldsUsed);

    let cards = apiActions.arraySelectCamelCaseKeys(mGetInvolved.cards, apiValues.nref.card.fieldsUsed);
    cards.forEach(card => {
      dispatch(cardActions.loadCardSuccess(card))
    });
    mGetInvolved.cards = cards.map(card=>card.id);

    dispatch(loadMGetInvolvedSuccess(mGetInvolved));
  });
};


