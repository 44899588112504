import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageGetInvolvedFeedbackActions from '../actions/pageGetInvolvedFeedbackActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { withRouter } from 'react-router-dom';
import Select from './Select';


const iWantToOptions = [
  {
    id: 'my-story',
    title: 'Share My Story',
    copy: 'Tell us your story about getting around Los Angeles or how you connect with one of our programs.',
  },
  {
    id: 'innovative-idea',
    title: 'Share an Innovative Idea',
    copy: 'From creative community engagement to inventive street designs, we want to hear your ideas.',
  },
  {
    id: 'become-a-leader',
    title: 'Become a Leader',
    copy: 'Participate in leadership development training, identify the best ways to conduct engagement, advocate for street improvements, and help shape projects in your community.',
  },
  {
    id: 'ladot-your-event',
    title: 'Request LADOT at Your Event',
    copy: 'Our staff is happy to bring information and opportunities for involvement to your community event. Typical events include community fairs, Neighborhood Council meetings, town halls, and other similar public meetings and events. Note: LADOT branded materials will not always be available.',
  },
  {
    id: 'become-a-partner',
    title: 'Become a Partner Organization',
    copy: 'Participate in leadership development training, identify the best ways to conduct engagement, advocate for street improvements, and help shape projects in the communities where your organization works.',
  },
  {title: 'Other', copy: 'Share your questions, concerns, and ideas below.'},
];


class PageGetInvolvedFeedback extends React.Component{
  history;
  lastUrl = '';
  state = {
    email: false,
    iWantTo: 'Share My Story',
    formError: '',
  };
  timeout1;
  timeout2;


  constructor(props){
    super(props);

    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
  }
  componentDidMount(){
    this.history = createBrowserHistory();
    this.fetchContent();
  }
  componentDidUpdate(){
    if(window.location.href != this.lastUrl) this.fetchContent();
  }


  iWantTo_onChange = (value, event) => {
    this.timeout1 = setTimeout(() => {
      clearTimeout(this.timeout1);
      this.setState({iWantTo: value});
    }, 50);
  };
  sendTo_onChange = (value) => {
    this.timeout2 = setTimeout(() => {
      clearTimeout(this.timeout2);
      this.setState({sendTo: value});
    }, 50);
  };


  fetchContent(){
    this.props.actions.initPageGetInvolvedFeedback();
    this.lastUrl = window.location.href;
    const iWantToMatch = iWantToOptions.find(o => {
      return o.id == helpers.urlParams().iWantTo;
    });
    this.setState({iWantTo: iWantToMatch ? iWantToMatch.title : 'Share My Story'});
  }
  handleValidSubmit(event, values){
    if(!this.props.page.programs) return null;

    this.setState({...values, formError: ''});
    let selectedProgram = this.props.page.programs.find(program => program.id == this.state.sendTo);
    let body = {
      ...values,
      'sendTo': selectedProgram ? selectedProgram.id : null,
      'joinMailingList': values.joinMailingList.length > 0,
      'iWantTo': this.state.iWantTo,
    };
    // console.log(`handleValidSubmit`, `${apiValues.CMS_PATH}/api/getinvolved`, selectedProgram, helpers.encodeData(body));

    fetch(`${apiValues.CMS_PATH}/api/getinvolved`, {
      // fetch(`${apiValues.CMS_PATH}/api/error`, {
      method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: helpers.encodeData(body),
    }).then(result => {
      // console.log('received response');
      if(result.status == 200) this.handleValidSubmitSuccess();
      else this.handleValidSubmitFailure(result);
    }, result => this.handleValidSubmitFailure(result));
  }
  handleValidSubmitSuccess = () => {
    // this.history.push('/get-involved/thanks'); //todo: this wasn't redirecting? fix later
    window.location = '/get-involved/thanks';
  };
  handleValidSubmitFailure = (result) => {
    console.error(`handleValidSubmitFailure`, result);
    this.setState({formError: 'There was a problem submitting your feedback. Please try again later.'});
  };
  handleInvalidSubmit(event, errors, values){
    this.setState({
      ...values,
      error: true,
    });
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Get Involved', slug: '#'},
        {browserTitle: 'We Want To Hear From You!', slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderForm(){
    if(!this.props.page.programs) return null;

    const iWantToOptionItems = iWantToOptions.map(option => {
      return {title: option.title, value: option.title};
    });
    const q2OptionItems = [
      {disabled: true, title: 'Select a program (optional)', value: ''},
      ...this.props.page.programs.map(program => {
        return {title: program.title, value: program.id};
      }),
    ];
    const sendToClass = (!this.state.sendTo || this.state.sendTo == '') ? 'select--is-unset' : '';

    return (
      <div className="page-getinvolvedfeedback__form">
        <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
          <div className="select-container">
            <label htmlFor="getInvolvedSelect1">I Want To</label>
            <Select defaultValue={this.state.iWantTo}
                    id="getInvolvedSelect1"
                    onSelectedChange={this.iWantTo_onChange.bind(this)}
                    options={iWantToOptionItems}
                    value={this.state.iWantTo}/>
          </div>
          {this.renderOptionCopy()}
          <div className="select-container">
            <label htmlFor="getInvolvedSelect2">Send To</label>
            <Select className={sendToClass}
                    defaultValue={''}
                    id="getInvolvedSelect2"
                    onSelectedChange={this.sendTo_onChange.bind(this)}
                    options={q2OptionItems}
                    value={this.state.sendTo}/>
          </div>
          <AvField name="email" label="Email Address *" type="email" required/>
          <AvField name="nameFirst" label="Your First Name *" type="text" required/>
          <AvField name="nameLast" label="Your Last Name *" type="text" required/>
          <AvField name="zip" label="Your Zip Code *" type="number" required/>
          <AvField name="message" label="Message *" type="textarea" required/>
          <AvCheckboxGroup inline name="joinMailingList">
            <AvCheckbox label="Join mailing list (be informed by email about our latest news and events)." value="Join"/>
          </AvCheckboxGroup>
          <p>
            Your contact information is used to deliver information and updates.
            <br/><SuperLink to="/content/privacy-policy">Privacy Policy</SuperLink>
          </p>
          {this.renderFormError()}
          <button className="tg-button tg-button--pill tg-button--black">
            <span className="tg-button__label">Send Message</span>
          </button>
        </AvForm>
      </div>
    );
  }
  renderFormError(){
    if(!this.state.formError ||
       this.state.formError.length < 1) return null;

    return (<p className="form-error">{this.state.formError}</p>);
  }
  renderIntro(){
    return (
      <div className="page-getinvolvedfeedback__intro">
        <div className="page-getinvolvedfeedback__intro-title">We Want To Hear From You!</div>
        <div className="page-getinvolvedfeedback__intro-subtext">Use the form below to share your story or innovative idea, or become an LADOT partner organization or leader in your community.</div>
      </div>
    );
  }
  renderOptionCopy(){
    if(!this.state.iWantTo) return null;

    let selectedOption = iWantToOptions.find(option => (option.title == this.state.iWantTo));
    if(!selectedOption) return null;

    return (<div className="page-getinvolvedfeedback__i-want-to-copy">{selectedOption.copy}</div>);
  }
  render(){
    // console.log(`PageGetInvolvedFeedback.render()`, this.props);

    return (
      <PageBase className="page-getinvolvedfeedback">
        <Helmet>
          <title>{apiValues.buildTitle(`We Want To Hear From You!`)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <div className="coupling coupling--centered-thin">
          <div className="container container--coupling">
            {this.renderIntro()}
            {this.renderForm()}
          </div>
        </div>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageGetInvolvedFeedbackActions, dispatch),
  }),
)(PageGetInvolvedFeedback));


PageGetInvolvedFeedback.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  match: PropTypes.object,
  page: PropTypes.object,
};
