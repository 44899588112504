import * as actionTypes from '../constants/actionType';
import initialState from './initialState';


export default function mProjectListMapReducer(state = initialState.mProjectListMap, action){
  switch(action.type){
    case actionTypes.MPROJECTLISTMAP_LOAD_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.MPROJECTLISTMAP_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.MPROJECTLISTMAP_LOAD_SUCCESS:{
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined,
      };
    }
    case actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_START:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_SUCCESS:{
      return {
        ...state,
        projects: action.projects,
        isLoading: false,
        error: undefined,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SEARCH_START:
      return {
        ...state,
        isLoading: true,
        isSearchOpen: true,
        searchQuery: action.searchQuery,
      };
    case actionTypes.MPROJECTLISTMAP_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isSearchOpen: true,
      };
    case actionTypes.MPROJECTLISTMAP_SEARCH_SUCCESS:{
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined,
        isSearchOpen: true,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER:{
      return {
        ...state,
        filter: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTERINPUTS:{
      return {
        ...state,
        ...action.filterInputs,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAM:{
      return {
        ...state,
        filter_program: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMTEMP:{
      return {
        ...state,
        filter_program_temp: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTTYPE:{
      return {
        ...state,
        filter_projectType: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTTYPETEMP:{
      return {
        ...state,
        filter_projectType_temp: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUS:{
      return {
        ...state,
        filter_projectStatus: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSTEMP:{
      return {
        ...state,
        filter_projectStatus_temp: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECT:{
      return {
        ...state,
        programSelect: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECTTEMP:{
      return {
        ...state,
        programSelect_temp: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECT:{
      return {
        ...state,
        projectStatusSelect: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECTTEMP:{
      return {
        ...state,
        projectStatusSelect_temp: action.filter,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_LISTLENGTH:{
      return {
        ...state,
        listLength: action.listLength,
      };
    }
    case actionTypes.MPROJECTLISTMAP_SET_VIEWMAP:{
      return {
        ...state,
        viewMap: action.viewMap,
      };
    }
    case actionTypes.MPROJECTLISTMAP_TOGGLE_FILTERING:{
      return {
        ...state,
        isFilteringOpen: action.isFilteringOpen,
      };
    }
    case actionTypes.MPROJECTLISTMAP_TOGGLE_SEARCH:{
      return {
        ...state,
        isSearchOpen: action.isSearchOpen,
      };
    }

    default:
      return state;
  }
}
