import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getMediaItemById} from "../reducers/index";


//Action Creators
export const loadMediaItemStart = (id) => {
  return {
    type: actionTypes.MEDIAITEM_LOAD_START,
    id
  };
};
export const loadMediaItemFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MEDIAITEM_LOAD_FAIL,
    id,
    error
  };
};
export const loadMediaItemSuccess = (data) => {
  return {
    type: actionTypes.MEDIAITEM_LOAD_SUCCESS,
    data
  };
};


export const initMediaItem = id => dispatch => {
  dispatch(loadMediaItemStart(id));

  apiActions.loadEndpoint(apiValues.nref.mediaItem.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mediaItem.fieldsUsed);

    dispatch(loadMediaItemSuccess(normalized));
  });
};
