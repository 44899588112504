import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const loadMRelatedProjectsStart = () => {
  return {
    type: actionTypes.MRELATEDPROJECTS_LOAD_START
  };
};
export const loadMRelatedProjectsFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MRELATEDPROJECTS_LOAD_FAIL,
    error
  };
};
export const loadMRelatedProjectsSuccess = (data) => {
  return {
    type: actionTypes.MRELATEDPROJECTS_LOAD_SUCCESS,
    data
  };
};


export const initMRelatedProjects = params => dispatch => {
  dispatch(loadMRelatedProjectsStart());

  //get projects
  let projectsPromise;
  if (params.relatedProgram)
    projectsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {
      Program_in: params.relatedProgram.id
    });
  else if (params.relatedTag)
    projectsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {
      Tags_in: params.relatedTag.id
    });
  else if (params.program)
    projectsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {
      Program_in: params.program.id
    });
  else
    projectsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint);

  return projectsPromise.then(results => {
    results = results.sort((a, b) => new Date(a.LastSave) - new Date(b.LastSave));
    let mRelatedProjects = {projects: apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed)};
    dispatch(loadMRelatedProjectsSuccess(mRelatedProjects));

    return mRelatedProjects;
  });
};
