import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const loadMLatestEventsStart = () => {
  return {
    type: actionTypes.MLATESTEVENTS_LOAD_START
  };
};
export const loadMLatestEventsFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MLATESTEVENTS_LOAD_FAIL,
    error
  };
};
export const loadMLatestEventsSuccess = (data) => {
  return {
    type: actionTypes.MLATESTEVENTS_LOAD_SUCCESS,
    data
  };
};


export const initMLatestEvents = params => dispatch => {
  dispatch(loadMLatestEventsStart());

  //get projects
  let eventsPromise;
  if (params.relatedProgram)
    eventsPromise = apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {
      Program_in: params.relatedProgram.id
    });
  else if (params.relatedProject)
    eventsPromise = apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {
      RelatedProject_in: params.relatedProject.id
    });
  else if (params.relatedTag)
    eventsPromise = apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {
      Tags_in: params.relatedTag.id
    });
  else if (params.program)
    eventsPromise = apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {
      Program_in: params.program.id
    });
  else
    eventsPromise = apiActions.loadEndpoint(apiValues.nref.event.endpoint);

  return eventsPromise.then(results => {
    results = results.filter(item => {
      let eventDate = new Date(item.EventDate);

      if (!eventDate.getDate()) {
        return false;
      }
      const currentDate = new Date();
      return eventDate > currentDate;
    });
    results = results.sort((a, b) => new Date(a.EventDate) - new Date(b.EventDate));
    let mLatestEvents = {events: apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.event.fieldsUsed)};
    dispatch(loadMLatestEventsSuccess(mLatestEvents));

    return mLatestEvents;
  });

  //
  //
  //dispatch(loadMLatestEventsStart());
  //
  //let mLatestEvents = {};
  ////get projects
  //apiActions.loadEndpoint('events', null).then(results => {
  //  if (program) {
  //    results = results.filter(result => (result && result.Program) ? result.Program.id === program : false);
  //  }
  //  mLatestEvents.events = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.event.fieldsUsed);
  //
  //  dispatch(loadMLatestEventsSuccess(mLatestEvents));
  //});
}
