import MListNav from './MListNav';
import PropTypes from 'prop-types';
import React from 'react';


export default class Select extends React.Component{
  state = {isOpen: false};


  constructor(){
    super();
    this.selectRef = React.createRef();
    this.select_onChange = this.select_onChange.bind(this);
    this.selectCover_onClick = this.selectCover_onClick.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidMount(){
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  componentWillUnmount(){
    document.removeEventListener('mousedown', this.handleClickOutside);
  }


  setWrapperRef(node){
    this.wrapperRef = node;
  }
  handleClickOutside(event){
    if(this.wrapperRef && !this.wrapperRef.contains(event.target)){
      this.setState({isOpen: false});
    }
  }


  select_onChange = (e) => {
    console.log(`select_onChange`, this.selectRef, this.selectRef.current.value);
    this.props.onSelectedChange(this.selectRef.current.value, e);
  };
  selectCover_onClick = () => {
    this.setState({isOpen: !this.state.isOpen});
  };
  listOption_onClick = (option, e) => {
    this.setState({isOpen: false});
    this.selectRef.current.value = option.value;
    this.props.onSelectedChange(option.value, e);
  };


  renderLists(){
    return (
      <>
        {this.renderOptgroupLists()}
        {this.renderOptionList()}
      </>
    );
  }
  //let optgroupExample = [
  //  {
  //    key: 'a',
  //    title: 'myoptgroup',
  //    options: [
  //      {title: 'Hollywood', value: 'Hollywood'},
  //      {title: '2', value: '2'}
  //    ]
  //  },
  //  {
  //    key: 'b',
  //    title: 'myoptgroup2',
  //    options: [
  //      {title: 'Hollywood3', value: 'Hollywood3'},
  //      {title: '23', value: '23'}
  //    ]
  //  }
  //];
  renderOptgroups(){
    if(!this.props.optgroups) return null;

    let optgroups = [];
    this.props.optgroups.forEach((option, ii) => {
      let optgroup_options = option.options.map((optgroup_option, index) => {
        return (
          <option disabled={optgroup_option.disabled}
                  key={`${optgroup_option.title}${optgroup_option.value}-${ii}_${index}`}
                  value={optgroup_option.value}
                  selected={option.value == this.props.value}>{optgroup_option.title}</option>
        );
      });

      optgroups.push(
        <optgroup key={`${option.title}${option.value}-${ii}`}>
          {optgroup_options}
        </optgroup>
      );
    });

    return optgroups;
  }
  //let optionExample = [
  //  {title: 'Hollywood3', value: 'Hollywood3'},
  //  {title: '23', value: '23'}
  //];
  renderOptions(){
    if(!this.props.options) return null;

    return this.props.options.map((option, index) => {
      return (
        <option disabled={option.disabled}
                key={`${option.title}${option.value}_${index}`}
                value={option.value}
                selected={option.value == this.props.value}>{option.title}</option>
      );
    });
  }
  renderOptgroupLists = () => {
    if(!(this.props.optgroups && this.selectRef.current)) return null;

    let mListNavs = [];
    this.props.optgroups.forEach((option, index) => {
      mListNavs.push({
        key: `${option.key}-${option.title}-${index}`,
        title: option.title,
        navigationLinks: option.options.map(optgroup_option => {
          let isSelected = this.selectRef && this.selectRef.current.value == optgroup_option.value;
          let selectedClass = isSelected ? 'navigationlink--selected' : '';
          let disabledClass = option.disabled ? 'navigationlink--disabled' : '';

          return {
            key: optgroup_option.title + ' ' + optgroup_option.value,
            title: optgroup_option.title,
            url: isSelected ? '' : '#',
            onClick: (e) => {
              e.preventDefault();
              if(!option.disabled)
                this.listOption_onClick(optgroup_option, e);
            },
            className: `${selectedClass} ${disabledClass}`
          };
        })
      });
    });

    return mListNavs.map(mListNav => {
      return (
        <MListNav key={mListNav.key} mListNav={mListNav}/>
      );
    });
  };
  renderOptionList(){
    if(!(this.props.options && this.selectRef.current)) return null;

    let mListNav = {
      navigationLinks: this.props.options.map((option, index) => {
        let isSelected = this.selectRef && this.selectRef.current.value == option.value;
        let selectedClass = isSelected ? 'navigationlink--selected' : '';
        let disabledClass = option.disabled ? 'navigationlink--disabled' : '';


        return {
          key: `${option.title}_${option.value}_${index}`,
          title: option.title,
          url: isSelected ? '' : '#',
          onClick: (e) => {
            e.preventDefault();
            if(!option.disabled)
              this.listOption_onClick(option, e);
          },
          className: `${selectedClass} ${disabledClass}`
        };
      })
    };

    return (
      <MListNav mListNav={mListNav}/>
    );
  }
  renderSelect(){
    return (
      <select className="select__select"
              defaultValue={this.props.defaultValue}
              ref={this.selectRef}
              onChange={this.select_onChange}
              value={this.props.value}>
        {this.renderOptgroups()}
        {this.renderOptions()}
      </select>
    );
  }
  render(){
    //console.log(`Select.render()`, this.props);

    let isOpenClass = this.state.isOpen ? 'select--is-open' : '';

    return (
      <div className={`select ${this.props.className || ''} ${isOpenClass}`}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           ref={this.setWrapperRef}>
        <div className="select__cover" onClick={this.selectCover_onClick}></div>
        {this.renderSelect()}

        <div className="flyout">
          <div className="container">
            {this.renderLists()}
          </div>
        </div>
      </div>
    );
  }
}


Select.propTypes = {
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onSelectedChange: PropTypes.func,
  optgroups: PropTypes.array,
  options: PropTypes.array,
  value: PropTypes.string
};
