import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as pageActions from "./pageActions";
import * as helpers from "../helpers";


export const initPageHome = () => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return apiActions.loadEndpoint(apiValues.nref.pageHome.endpoint)
    .then(results => {
      let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageHome.fieldsUsed);
      page.currentProjects = apiActions.itemSelectCamelCaseKeys(page.currentProjects, apiValues.nref.mProjectsCurrent.fieldsUsed);
      page.getInvolved = apiActions.itemSelectCamelCaseKeys(page.getInvolved, apiValues.nref.mGetInvolved.fieldsUsed);
      page.heroCarousel = apiActions.itemSelectCamelCaseKeys(page.heroCarousel, apiValues.nref.mCarousel.fieldsUsed);
      return page;
    })
    .then(results => {
      return dispatch(pageActions.loadPageSuccess(results));
    })
};

export const searchStart = (query) => {
  return {
    type: actionTypes.PAGE_HOME_SEARCH_START,
    searchQuery: query
  };
};

export const searchSuccess = (data) => {
  return {
    type: actionTypes.PAGE_HOME_SEARCH_SUCCESS,
    data
  };
};

export const doSearch = (query) => dispatch => {
  dispatch(searchStart(query));

  if (query && query.length > 0) {
    const titlePromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Title_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));
    const neighborhoodsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Neighborhoods_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));

    return Promise.all([titlePromise, neighborhoodsPromise])
      .then(([titleResults, neighborhoodsResults]) => {
        const results = {suggestedProjects: helpers.mergeArraysWithoutDuplicatesBasedOnProperty(titleResults, neighborhoodsResults, "id")};
        dispatch(searchSuccess(results));
      });
  }
  else {
    dispatch(searchSuccess({
      suggestedProjects: undefined,
      viewProjectsNear: undefined
    }));
  }
};

export const toggleSearch = isSearchOpen => {
  return {
    type: actionTypes.PAGE_HOME_TOGGLE_SEARCH,
    isSearchOpen
  };
};