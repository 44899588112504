import * as apiValues from '../constants/apiValues';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MProgramBanner extends React.Component{
  render(){
    console.log(`MProgramBanner render`, this.props);
    if(!this.props.page ||
       !this.props.page.program ||
       !this.props.page.program.logo) return null;

    return (
      <div className={`m-programbanner ${this.props.className || ''}`}>
        <div className="m-programbanner__bar" style={{backgroundColor: this.props.page.program.color || this.props.page.program.Color}}></div>
        <div className="container container--coupling m-programbanner__container columns">
          <div className="m-programbanner__bg">
            <img src={apiValues.imgPath(this.props.page.program.logo.url)}
                 alt={this.props.page.program.title || this.props.page.program.Title}
                 className="m-programbanner__image"/>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    page: ownProps.page ? ownProps.page : state.page,
  }),
)(MProgramBanner));


MProgramBanner.propTypes = {
  className: PropTypes.string,
  page: PropTypes.object,
};
