import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as pageActions from "./pageActions";


export const initPageContent = (slug) => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.pageContent.endpoint, null, {Slug: slug})
      .then(results => {
        return apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageContent.fieldsUsed);
      })
      .then(results => {
        resolve(dispatch(pageActions.loadPageSuccess(results)));
      })
      .catch(error => {
        console.error(error)
        reject(error);
      });
  });
};
