import * as pageActions from './pageActions';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


export const initPageContentDetail = (slug) => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.pageContentDetail.endpoint, null, {Slug: slug})
      .then(results => {
        let page = results ? apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageContentDetail.fieldsUsed) : null;
        if(page){
          page.program = page.program ? apiActions.itemSelectCamelCaseKeys(page.program, apiValues.nref.program.fieldsUsed) : null;
          page.contacts = page.contacts ? apiActions.arraySelectCamelCaseKeys(page.contacts, apiValues.nref.contact.fieldsUsed) : null;
          page.tags = page.tags ? apiActions.arraySelectCamelCaseKeys(page.tags, apiValues.nref.tag.fieldsUsed) : null;
          // page.photosAndVideos = page.photosAndVideos ? apiActions.arraySelectCamelCaseKeys(page.photosAndVideos, apiValues.nref.mediaItem.fieldsUsed) : [];
        }

        resolve(dispatch(pageActions.loadPageSuccess(page)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
