import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const loadMNewsletterStart = (id) => {
  return {
    type: actionTypes.MNEWSLETTER_LOAD_START,
    id
  };
};
export const loadMNewsletterFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MNEWSLETTER_LOAD_FAIL,
    id,
    error
  };
};
export const loadMNewsletterSuccess = (data) => {
  return {
    type: actionTypes.MNEWSLETTER_LOAD_SUCCESS,
    data
  };
};

export const initMNewsletter = id => dispatch => {
  dispatch(loadMNewsletterStart(id));

  apiActions.loadEndpoint(apiValues.nref.mNewsletter.endpoint, id).then(result => {
    let mNewsletter = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mNewsletter.fieldsUsed);

    dispatch(loadMNewsletterSuccess(mNewsletter));
  });
};

export const postNewsletterStart = () => {
  return {
    type: actionTypes.MNEWSLETTER_POST_START
  };
};
export const postNewsletterFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MNEWSLETTER_POST_FAIL,
    error
  };
};
export const postNewsletterSuccess = (data) => {
  return {
    type: actionTypes.MNEWSLETTER_POST_SUCCESS,
    data
  };
};

export const loadPostEndpoint = email => dispatch => {
  dispatch(postNewsletterStart());

  apiActions.postToMailChimp(email).then(result => {
    dispatch(postNewsletterSuccess(result));
  });
}
