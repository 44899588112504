import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mProjectsCurrentActions from '../actions/mProjectsCurrentActions';
import * as projectTypeListActions from '../actions/projectTypeListActions';
import iconViewMap from '../img/icons/icon-view-map.svg';
import ProjectList from './ProjectList';
import ProjectTypeList from './ProjectTypeList';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MProjectsCurrent extends React.Component{
  componentDidMount(){
    //console.log(`MProjectsCurrent.componentDidMount()`, this.props);
    this.props.actions.mProjectsCurrent.init(this.props.cmsId);
    this.props.actions.projectTypeList.filterList([]);
  }


  loadMoreButton_onClick = () => {
    const start = this.props.mProjectsCurrent.listLength;
    const newLength = start + apiValues.PROJECTS_PER_PAGE;

    this.props.actions.mProjectsCurrent.setMProjectsCurrentListLength(this.props.mProjectsCurrent.id, newLength);
    this.props.actions.mProjectsCurrent.filterList(this.props.mProjectsCurrent.id, this.props.mProjectsCurrent.selectedProjectTypes || [], start, newLength, this.props.mProjectsCurrent.projects);
  };
  projectTypeList_onFilter = filter => {
    // console.log(`projectTypeList_onFilter`, filter);
    //this.props.actions.setMProjectsCurrentListLength(this.props.mProjectsCurrent.id, apiValues.PROJECTS_PER_PAGE);

    let selectedProjectTypes = [...this.props.mProjectsCurrent.selectedProjectTypes || []];
    if(selectedProjectTypes.indexOf(filter) >= 0){
      selectedProjectTypes.splice(selectedProjectTypes.indexOf(filter), 1);
    }
    else{
      selectedProjectTypes.push(filter);
    }
    this.props.actions.mProjectsCurrent.filterList(this.props.mProjectsCurrent.id, selectedProjectTypes, 0, apiValues.PROJECTS_PER_PAGE, []);
    this.props.actions.projectTypeList.filterList(selectedProjectTypes);
  };


  renderDescription(){
    if(!this.props.mProjectsCurrent.description) return null;

    return (
      <div className="m-projectscurrent__description">
        {this.props.mProjectsCurrent.description}
      </div>
    );
  }
  renderExploreButton(){
    return (
      <SuperLink to="/projects?viewMap=true" className="m-projectscurrent__explore tg-button tg-button--pill tg-button--orange">
        <img alt="Explore projects icon" className="tg-button__icon" src={iconViewMap}/>
        <span className="tg-button__label">Explore Projects on a Map</span>
      </SuperLink>
    );
  }
  renderLoadMoreButton(){
    if(!this.props.mProjectsCurrent.projects ||
       this.props.mProjectsCurrent.projects.length < this.props.mProjectsCurrent.listLength) return null;

    return (
      <div className="text-center">
        <button className="m-projectscurrent__load-more tg-button tg-button--pill tg-button--black" onClick={this.loadMoreButton_onClick}>
          <span className="tg-button__label">Load More</span>
        </button>
      </div>
    );
  }
  renderProjects(){
    // let projects = this.props.mProjectsCurrent.projects;//.slice(0, this.props.mProjectsCurrent.listLength);
    let projects = this.props.mProjectsCurrent.projects.map(projectId => this.props.project.find(project => project.id == projectId));

    if(projects.length > 0) return (<ProjectList projects={projects}/>);
    else return (<div className="no-results">Sorry, but nothing matched your search criteria. Please try again with some different keywords.</div>);
  }
  renderTitle(){
    if(!this.props.mProjectsCurrent.title) return null;

    return (
      <div className="m-projectscurrent__title">
        {this.props.mProjectsCurrent.title}
      </div>
    );
  }
  render(){
    if(!this.props.mProjectsCurrent ||
       !this.props.mProjectsCurrent.projects) return null;
    //console.log(`MProjectsCurrent render`, this.props);

    return (
      <div className={`m-projectscurrent ${this.props.className || ''}`}>
        <div className="container container--coupling">
          <div className="m-projectscurrent__intro-container">
            {this.renderTitle()}
            {this.renderDescription()}
          </div>
          <div className="m-projectscurrent__buttons row">
            <div className="col-md-1 col-lg-3 col-xl-4"></div>
            <div className="m-projectscurrent__buttons-container col-12 col-md-6 col-lg-5 col-xl-4 text-center">
              <ProjectTypeList navListId='5bff0e7b6d09534d2922e69c' onFilter={this.projectTypeList_onFilter}/>
            </div>
            <div className="col-12 col-md-5 col-lg-4 col-xl-4 text-right">
              {this.renderExploreButton()}
            </div>
          </div>
          {this.renderProjects()}
          {this.renderLoadMoreButton()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mProjectsCurrent: helpers.getById(state.mProjectsCurrent, ownProps.cmsId),
    project: state.project
  }),
  (dispatch) => ({
    actions: {
      mProjectsCurrent: bindActionCreators(mProjectsCurrentActions, dispatch),
      projectTypeList: bindActionCreators(projectTypeListActions, dispatch)
    }
  })
)(MProjectsCurrent));


MProjectsCurrent.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  mProjectsCurrent: PropTypes.object,
  project: PropTypes.array
};
