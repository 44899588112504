import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


//Action Creators
export const loadProjectTypeListStart = () => {
  return {type: actionTypes.PROJECTTYPELIST_LOAD_START};
};
export const loadProjectTypeListFail = (error) => {
  console.error(error);
  return {type: actionTypes.PROJECTTYPELIST_LOAD_FAIL, error};
};
export const loadProjectTypeListSuccess = (data) => {
  return {type: actionTypes.PROJECTTYPELIST_LOAD_SUCCESS, payload: data};
};
export const setProjectTypeListType = (selectedProjectTypes) => {
  return {
    type: actionTypes.PROJECTTYPELIST_SET,
    selectedProjectTypes,
  };
};


export const initProjectTypeList = (id, defaultValue) => dispatch => {
  dispatch(loadProjectTypeListStart());

  //console.log(`initProjectTypeList`, defaultValue)

  apiActions.loadEndpoint(apiValues.nref.mListNav.endpoint, id).then(result => {
    let n = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListNav.fieldsUsed);
    let types = n.projectTypes.map(projectType => {
      return apiActions.itemSelectCamelCaseKeys(projectType, apiValues.nref.projectType.fieldsUsed);
    });

    if(defaultValue) dispatch(setProjectTypeListType(defaultValue));

    dispatch(loadProjectTypeListSuccess(types));
  });
};
export const filterList = (selectedProjectTypes) => dispatch => {
  // console.log(selectedProjectTypes);
  dispatch(setProjectTypeListType(selectedProjectTypes));
};
