import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getCarouselSlideById} from "../reducers/index";


//Action Creators
export const loadCarouselSlideStart = (id) => {
  return {
    type: actionTypes.CAROUSELSLIDE_LOAD_START,
    id
  };
};
export const loadCarouselSlideFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.CAROUSELSLIDE_LOAD_FAIL,
    id,
    error
  };
};
export const loadCarouselSlideSuccess = (data) => {
  return {
    type: actionTypes.CAROUSELSLIDE_LOAD_SUCCESS,
    data
  };
};

export const initCarouselSlide = id => dispatch => {
  dispatch(loadCarouselSlideStart(id));

  apiActions.loadEndpoint(apiValues.nref.carouselSlide.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.carouselSlide.fieldsUsed);

    dispatch(loadCarouselSlideSuccess(normalized));
  });
};


