import PropTypes from 'prop-types';
import {Component} from 'react';
import {withRouter} from 'react-router-dom';


class ScrollToTop extends Component{
  componentDidUpdate(prevProps){
    if(this.props.location.pathname.localeCompare(prevProps.location.pathname) != 0)
      window.scrollTo(0, 0);
  }

  render(){
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);

ScrollToTop.propTypes = {
  children: PropTypes.object,
  location: PropTypes.object,
};
