import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mNewsDetailActions from '../actions/mNewsDetailActions';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MLatestNews from './MLatestNews';
import MListFollow from './MListFollow';
import MListShare from './MListShare';
import MListTag from './MListTag';
import MNewsletter from './MNewsletter';
import NewsDetailIntro from './NewsDetailIntro';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MNewsDetail extends React.Component{
  lastUrl = '';
  state = {
    currentPage: 1,
  };


  componentDidMount(){
    // console.log(`MNewsDetail.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initMNewsDetail(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderContent(){
    if(!this.props.mNewsDetail.news.description) return null;

    return (
      <div className="m-intro__contents">
        <MarkdownField markup={this.props.mNewsDetail.news.description}/>
      </div>
    );
  }
  renderTags(){
    if(!this.props.mNewsDetail.news.tags) return null;

    return (<MListTag mListTag={{tags: this.props.mNewsDetail.news.tags}} className="m-news-detail__tags"/>);
  }
  render(){
    // console.log(`MEvents render`, this.props);
    if(!this.props.mNewsDetail ||
       !this.props.mNewsDetail.news) return null;

    return (
      <>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.mNewsDetail.news.title)}</title>
        </Helmet>
        <div className="coupling coupling--full-centered">
          <NewsDetailIntro news={this.props.mNewsDetail.news}/>
        </div>
        <div className="columns">
          <div className="coupling coupling--parent coupling--left">
            <div className={`m-news-detail ${this.props.className || ''}`}>
              <div className="container container--coupling">
                {this.renderContent()}
              </div>
              {this.renderTags()}
            </div>
            <MListShare title="Share this page"/>
          </div>
          <div className="coupling coupling--parent coupling--right">
            <MLatestNews/>
            <MNewsletter/>
            <MListFollow/>
          </div>
        </div>
      </>
    );
  }
}


export default withRouter(connect(
  state => ({
    mNewsDetail: state.mNewsDetail,
  }),
  (dispatch) => ({
    actions: bindActionCreators(mNewsDetailActions, dispatch),
  }),
)(MNewsDetail));


MNewsDetail.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  mNewsDetail: PropTypes.object,
};
