import * as apiValues from '../constants/apiValues';
import * as pageOverallMapActions from '../actions/pageOverallMapActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MIntro from './MIntro';
import OverallMap from './OverallMap';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const mapTabs = [
  {
    name: 'Neighborhoods, Networks, and Zones',
    id: 'overall-neighborhoods_zones',
    layers: [
      'vz_hiinjnet',
      // "mobplan2035_ven",
      'mobplan2035_ten',
      'mobplan2035_ben',
      'mobplan2035_bpn',
      'mobplan2035_bln',
      'mobplan2035_nen',
      'mobplan2035_peds',
      'council_dist',
      'nbhood_council_dist',
    ],
  },
  {
    name: 'Memorial Map',
    id: 'overall-memorial_map',
    layers: [
      'memorial',
      'coll_ped_fatal',
      'coll_bik_fatal',
      'coll_car_fatal',
    ],
  },
  {
    name: 'Projects',
    id: 'overall-projects',
    layers: [],
  },
  {
    name: 'Project Features',
    id: 'overall-project_features',
    layers: [
      'greatst',
      'pst_parklet',
      'pst_plaza',
      'bikeway',
      'srts_top50',
    ],
  },
];


class PageOverallMap extends React.Component{
  componentDidMount(){
    this.props.actions.initPageOverallMap();
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Maps', slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderIntro(){
    const introData = {
      title: 'Maps',
      description: '',
    };

    return (<MIntro mIntro={introData} className="coupling coupling--centered-thin"/>);
  }
  render(){
    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(`Overall Map & Data`)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <OverallMap location={this.props.location}
                    mode={'overall'}
                    tabs={mapTabs}/>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageOverallMapActions, dispatch),
  }),
)(PageOverallMap));


PageOverallMap.propTypes = {
  actions: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object.isRequired,
  page: PropTypes.object,
};
