import objectAssign from 'object-assign';
import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function projectTypeListReducerReducer(state = initialState.projectTypeList, action) {
  switch (action.type) {
    case actionTypes.PROJECTTYPELIST_LOAD_START:
      return objectAssign({}, state, {isLoading: true});

    case actionTypes.PROJECTTYPELIST_LOAD_FAIL:
      return objectAssign({}, state, {
        error: action.error,
        isLoading: false
      });

    case actionTypes.PROJECTTYPELIST_LOAD_SUCCESS: {
      return objectAssign({}, state, {
        types: action.payload,
        isLoading: false
      });
    }

    case actionTypes.PROJECTTYPELIST_SET: {
      //let newState = {...state};
      //
      //if (!newState.selectedProjectTypes || newState.selectedProjectTypes.length < 1) {
      //  newState.selectedProjectTypes = [action.selectedProjectType];
      //}
      //else {
      //  let selectedProjectTypes = [...newState.selectedProjectTypes];
      //  newState.selectedProjectTypes = selectedProjectTypes;
      //
      //  let matchIndex = newState.selectedProjectTypes.indexOf(action.selectedProjectType);
      //  if (matchIndex >= 0) {
      //    selectedProjectTypes = selectedProjectTypes.splice(matchIndex, 1);
      //  }
      //  else {
      //    selectedProjectTypes.push(action.selectedProjectType);
      //  }
      //}

      return objectAssign({}, state, {
        selectedProjectTypes: action.selectedProjectTypes
      });
    }

    default:
      return state;
  }
}
