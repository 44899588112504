import * as apiValues from '../constants/apiValues';
import * as pageProjectsActions from '../actions/pageProjectsActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MIntro from './MIntro';
import MProjectListMap from './MProjectListMap';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageProjects extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageProjects.componentDidMount()`, this.props);
    this.props.actions.initPageProjects();
  }
  //todo: need to make this work; should be able to use Link but we need to parse url params and update the local state
  //componentDidMount() {
  //  console.log(`PageProjects.componentDidMount()`, this.props);
  //  this.fetchContent();
  //}
  //componentDidUpdate() {
  //  console.log("componentDidUpdate", this.props)
  //  if (this.props.location.search != this.lastUrl) this.fetchContent();
  //}
  //
  //
  //fetchContent() {
  //  console.log("fetching", this.lastUrl)
  //  this.lastUrl = this.props.location.search;
  //  this.props.actions.initPageProjects();
  //}


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Our Projects', slug: '/projects'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderIntro(){
    if(!this.props.page.intro) return null;

    return (<MIntro mIntro={this.props.page.intro} className="page-projects__intro coupling coupling--centered"/>);
  }
  renderProjectListMap(){
    if(!this.props.page.projectListMap) return null;

    return (
      <MProjectListMap history={this.props.history}/>
    );
  }
  render(){
    //console.log(`PageProjects.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        {this.renderIntro()}
        {this.renderProjectListMap()}
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageProjectsActions, dispatch),
  }),
)(PageProjects));


PageProjects.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
