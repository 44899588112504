import iconArrowRight from '../img/icon-arrow-right-black.png';
import PropTypes from 'prop-types';
import React from 'react';


export default class Pagination extends React.Component{
  render(){
    const {currentPage, totalPage, handlePagination} = this.props;
    let tempArray = [];

    if(totalPage >= 5){
      if(currentPage > 2 && currentPage < totalPage - 1){
        for(let i = currentPage - 2; i < currentPage + 3; i++){
          tempArray.push(i);
        }
      }
      else if(currentPage < 3){
        for(let i = 1; i < 6; i++){
          tempArray.push(i);
        }
      }
      else{
        for(let i = totalPage - 4; i < totalPage + 1; i++){
          tempArray.push(i);
        }
      }
    }
    else{
      for(let i = 1; i < totalPage + 1; i++){
        tempArray.push(i);
      }
    }

    return (
      <div className="pagination-container">
        <ul className="pagination">
          <li className={`page-item ${currentPage === 1 ? 'disabled-button' : ''}`}>
            <span className="page-link arrow-button" onClick={() => handlePagination(currentPage - 1)} aria-label="Previous">
              <img alt='Close' className="button__icon button__left" src={iconArrowRight}/>
            </span>
          </li>
          {tempArray.map(page => {
            page = Math.round(page);
            if(page === currentPage){
              return (
                <li className="page-item active" key={page}>
                  <span className="page-link" onClick={() => handlePagination(page)}>{page}</span>
                </li>
              );
            }
            return (
              <li className="page-item" key={page}>
                <span className="page-link" onClick={() => handlePagination(page)}>{page}</span>
              </li>
            );
          })}
          <li className={`page-item ${currentPage >= totalPage ? 'disabled-button' : ''}`}>
            <span className="page-link arrow-button" onClick={() => handlePagination(currentPage + 1)} aria-label="Next">
              <img alt='Close' className="button__icon" src={iconArrowRight}/>
            </span>
          </li>
        </ul>
      </div>
    );
  }
}


Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPage: PropTypes.number.isRequired,
  handlePagination: PropTypes.func,
};
