import * as actionTypes from '../constants/actionType';
import initialState from './initialState';


export default function mProgramBannerReducer(state = initialState.mProgramBanner, action) {
  switch (action.type) {
    case actionTypes.MPROGRAMBANNER_LOAD_START:
      return {
        ...state,
        id: action.id,
        isLoading: true
      }

    case actionTypes.MPROGRAMBANNER_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      }

    case actionTypes.MPROGRAMBANNER_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined
      }
    }

    default:
      return state;
  }
}
