import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as projectActions from './projectActions';


//Action Creators
export const loadFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MPROJECTLISTMAP_LOAD_FAIL,
    error
  };
};
export const loadStart = () => {
  return {
    type: actionTypes.MPROJECTLISTMAP_LOAD_START
  };
};
export const loadSuccess = (data) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_LOAD_SUCCESS,
    data
  };
};
export const loadProjectsFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_FAIL,
    error
  };
};
export const loadProjectsStart = () => {
  return {
    type: actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_START
  };
};
export const loadProjectsSuccess = (projects) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_PROJECTS_LOAD_SUCCESS,
    projects
  };
};
export const searchFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MPROJECTLISTMAP_SEARCH_FAIL,
    error
  };
};
export const searchStart = (query) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SEARCH_START,
    searchQuery: query
  };
};
export const searchSuccess = (data) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SEARCH_SUCCESS,
    data
  };
};
export const setFilters = (filters) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER,
    filters
  };
};
export const setFilterInputsActionCreator = (filterInputs) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTERINPUTS,
    filterInputs
  };
};
export const setListLength = (listLength) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_LISTLENGTH,
    listLength
  };
};
export const setFilterProgram = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAM,
    filter
  };
};
export const setFilterProgramTemp = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMTEMP,
    filter
  };
};
export const setFilterProjectType = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTTYPE,
    filter
  };
};
export const setFilterProjectTypeTemp = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTTYPETEMP,
    filter
  };
};
export const setFilterProjectStatus = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUS,
    filter
  };
};
export const setFilterProjectStatusTemp = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSTEMP,
    filter
  };
};
export const setFilterProgramSelect = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECT,
    filter
  };
};
export const setFilterProgramSelectTemp = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECTTEMP,
    filter
  };
};
export const setFilterProjectStatusSelect = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECT,
    filter
  };
};
export const setFilterProjectStatusSelectTemp = (filter) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECTTEMP,
    filter
  };
};
export const setViewMap = (viewMap) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_SET_VIEWMAP,
    viewMap
  };
};
export const toggleSearch = (isSearchOpen) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_TOGGLE_SEARCH,
    isSearchOpen
  };
};
export const toggleMobileFiltering = (isFilteringOpen) => {
  return {
    type: actionTypes.MPROJECTLISTMAP_TOGGLE_FILTERING,
    isFilteringOpen
  };
};


export const filterList = (filters) => dispatch => {
  dispatch(setListLength(apiValues.PROJECTS_PER_PAGE));
  dispatch(setFilters(filters));
  dispatch(loadProjectsStart());

  let titleParams = {_sort: 'LastSave:desc'};
  filters.Program_in ? titleParams.Program_in = filters.Program_in : undefined;
  filters.projectstatus_in ? titleParams.projectstatus_in = filters.projectstatus_in : undefined;
  filters.ProjectTypes_in && filters.ProjectTypes_in.length > 0 ? titleParams.ProjectTypes_in = filters.ProjectTypes_in : undefined;
  filters.Title_contains ? titleParams.Title_contains = filters.Title_contains : undefined;
  const titlePromise = apiActions.loadEndpointByFilters(apiValues.nref.project.endpoint, titleParams)
    .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));

  let neighborhoodsParams = {_sort: 'LastSave:desc'};
  filters.Program_in ? neighborhoodsParams.Program_in = filters.Program_in : undefined;
  filters.projectstatus_in ? neighborhoodsParams.projectstatus_in = filters.projectstatus_in : undefined;
  filters.ProjectTypes_in && filters.ProjectTypes_in.length > 0 ? neighborhoodsParams.ProjectTypes_in = filters.ProjectTypes_in : undefined;
  filters.Title_contains ? neighborhoodsParams.Neighborhoods_contains = filters.Title_contains : undefined;
  const neighborhoodsPromise = apiActions.loadEndpointByFilters(apiValues.nref.project.endpoint, neighborhoodsParams)
    .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));

  return Promise.all([titlePromise, neighborhoodsPromise])
    .then(([titleResults, neighborhoodsResults]) => {
      let results = helpers.mergeArraysWithoutDuplicatesBasedOnProperty(titleResults, neighborhoodsResults, 'id');
      // results = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed);

      // let projects = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed);
      results.forEach(project => {
        dispatch(projectActions.loadProjectSuccess(project));
      });

      dispatch(loadProjectsSuccess(results.map(project => project.id)));
    });
};
export const init = (filters) => dispatch => {
  // console.log(`mProjectListMapActions.init()`, filters);
  dispatch(loadStart());
  dispatch(setFilters(filters));

  let mProjectListMap = {};
  mProjectListMap.listLength = apiValues.PROJECTS_PER_PAGE;
  mProjectListMap.viewMap = filters.viewMap;

  //get projects
  return Promise.all([
    apiActions.loadEndpointByFilters(apiValues.nref.project.endpoint, {
      _sort: 'LastSave:desc',
      Program_in: filters.Program_in,
      projectstatus_in: filters.projectstatus_in,
      ProjectTypes_in: filters.ProjectTypes_in,
      Title_contains: filters.Title_contains
    }),
    apiActions.loadEndpoint(apiValues.nref.program.endpoint),
    apiActions.loadEndpoint(apiValues.nref.projectStatus.endpoint)
  ]).then(([projects, programs, projectstatuses]) => {
    let loadedProjects = apiActions.arraySelectCamelCaseKeys(projects, apiValues.nref.project.fieldsUsed);
    dispatch(projectActions.loadProjectManySuccess(loadedProjects));

    // mProjectListMap.projects = apiActions.arraySelectCamelCaseKeys(projects, apiValues.nref.project.fieldsUsed);
    mProjectListMap.projects = projects.map(project => project.id);
    mProjectListMap.programs = apiActions.arraySelectCamelCaseKeys(programs, apiValues.nref.program.fieldsUsed);
    mProjectListMap.projectStatuses = apiActions.arraySelectCamelCaseKeys(projectstatuses, apiValues.nref.projectStatus.fieldsUsed);

    dispatch(setFilterInputsActionCreator({
      filter_program: filters.Program_in,
      filter_program_temp: filters.Program_in,
      filter_projectStatus: filters.projectstatus_in,
      filter_projectStatus_temp: filters.projectstatus_in,
      filter_projectType: filters.ProjectTypes_in,
      filter_projectType_temp: filters.ProjectTypes_in,
      filter_title: filters.Title_contains,
      programSelect: filters.Program_in,
      projectStatusSelect: filters.projectstatus_in
    }));

    dispatch(loadSuccess(mProjectListMap));

    return mProjectListMap;
  }).catch(console.error);
};
export const doSearch = (query) => dispatch => {
  dispatch(searchStart(query));

  if(query && query.length > 0){
    const titlePromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Title_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));
    const neighborhoodsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Neighborhoods_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));

    return Promise.all([titlePromise, neighborhoodsPromise])
      .then(([titleResults, neighborhoodsResults]) => {
        const results = {suggestedProjects: helpers.mergeArraysWithoutDuplicatesBasedOnProperty(titleResults, neighborhoodsResults, 'id')};
        dispatch(searchSuccess(results));
      });
  }
  else{
    dispatch(searchSuccess({
      suggestedProjects: undefined,
      viewProjectsNear: undefined
    }));
  }
};
export const setFilterInputs = (filterInputs) => dispatch => {
  return dispatch(setFilterInputsActionCreator(filterInputs));
};
