import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from '../helpers';


export default function projectReducer(state = initialState.project, action){
  switch(action.type){
    case actionTypes.PROJECT_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });

    case actionTypes.PROJECT_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });

    case actionTypes.PROJECT_LOAD_SUCCESS:{
      return helpers.updateOrAdd(state, {
        ...action.data,
        isLoading: false,
        error: undefined
      });
    }

    case actionTypes.PROJECT_LOAD_MANY_SUCCESS:{
      return helpers.updateOrAddMany(state, action.data.map(data => ({
        ...data,
        isLoading: false,
        error: undefined
      })));
    }

    default:
      return state;
  }
}
