import * as apiValues from '../constants/apiValues';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MListFollow from './MListFollow';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class PageProjectsThanks extends React.Component{
  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Projects', slug: 'projects'},
        {browserTitle: 'Thank You!', slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  render(){
    return (
      <PageBase className="page-getinvolvedthanks">
        <Helmet>
          <title>{apiValues.buildTitle(`Thank You`)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <div className="page-getinvolvedthanks__content coupling coupling--centered-thin">
          <div className="container container--coupling">
            <div className="page-getinvolvedthanks__title">Thank you for your feedback.</div>
            <div className="page-getinvolvedthanks__body">
              <p>Learn more about our projects <SuperLink to="/projects">here</SuperLink>.</p>
            </div>
          </div>
          <MListFollow className="page-getinvolvedthanks__follow m-list-follow--title-line" title="Follow us on your favorite social media:"/>
        </div>
      </PageBase>
    );
  }
}


PageProjectsThanks.propTypes = {
  className: PropTypes.string,
};
