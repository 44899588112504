import * as helpers from '../helpers';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class SearchItem extends React.Component{
  render(){
    const typeStr = helpers.camelCaseToDashCase(this.props.type).replace('page-','');
    let newUrl = `/${typeStr}/${this.props.url}`;

    switch(this.props.type){
      case 'program':
        newUrl = `/programs/${this.props.url}`;
        break;
      case 'news':
        newUrl = `/news/${this.props.url}`;
        break;
      case 'event':
        newUrl = `/events/${this.props.url}`;
        break;
      case 'project':
        newUrl = `/projects/${this.props.url}`;
        break;
      default:
        break;
    }

    return (
      <div className={`search-item ${this.props.className}`}>
        <div className={`search-item__tag`}>{this.props.type}</div>
        <div className="search-item__content">
          <SuperLink to={newUrl} className="search-item__link">
            <div className="search-item__title">{this.props.title}</div>
          </SuperLink>
        </div>
      </div>
    );
  }
}


SearchItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
};
