import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as projectActions from '../actions/projectActions';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class ProjectStub extends React.Component{
  componentDidMount(){
    //console.log(`ProjectStub.componentDidMount()`, this.props);
    this.props.actions.initProject(this.props.cmsId);
  }


  projectUrl(){
    return `/projects/${this.props.project.slug}`;
  }


  renderDescription(){
    if(!this.props.project.whatDescription) return null;

    return (<div className="project-stub__description">{helpers.nToP(this.props.project.whatDescription)}</div>);
  }
  renderProgramBanner(){
    if(this.props.hideLabel ||
       !this.props.project.program) return null;

    return (<ProgramBanner className="project-stub__program-label" program={this.props.project.program}/>);
  }
  renderThumbnail(){
    if(!this.props.project.thumbnail) return null;

    if(this.props.project.slug) return (
      <SuperLink to={this.projectUrl()} className='project-stub__thumbnail'>
        <img alt={this.props.project.title} className="project-stub__thumbnail-img" src={apiValues.imgPath(this.props.project.thumbnail.url)}/>
      </SuperLink>
    );
    else return (
      <span className='project-stub__thumbnail'>
        <img alt={this.props.project.title} className="project-stub__thumbnail-img" src={apiValues.imgPath(this.props.project.thumbnail.url)}/>
      </span>
    );
  }
  renderTitle(){
    if(!this.props.project.title) return null;

    if(this.props.project.slug) return (<SuperLink to={this.projectUrl()} className='project-stub__title'>{this.props.project.title}</SuperLink>);
    else return (<span className='project-stub__title'>{this.props.project.title}</span>);
  }
  render(){
    // console.log(`ProjectStub render`, this.props);
    if(!this.props.project ||
       !this.props.project.cmsName) return null;

    return (
      <div className={`project-stub ${this.props.className || ''}`}>
        {this.renderThumbnail()}
        {this.renderProgramBanner()}
        {this.renderTitle()}
        {this.renderDescription()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    project: helpers.getById(state.project, ownProps.cmsId),
  }),
  (dispatch) => ({
    actions: bindActionCreators(projectActions, dispatch),
  }),
)(ProjectStub));


ProjectStub.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool,
  project: PropTypes.object,
};
