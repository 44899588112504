import * as apiActions from '../actions/apiActions';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageProgramsActions from '../actions/pageProgramsActions';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MContacts from './MContacts';
import MIntro from './MIntro';
import MLatestNews from './MLatestNews';
import MLatestRelatedEvents from './MLatestRelatedEvents';
import MListCards from './MListCards';
import MNewsletter from './MNewsletter';
import MProgramBanner from './MProgramBanner';
import MProgramMapAd from './MProgramMapAd';
import MRelatedProjects from './MRelatedProjects';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

class PagePrograms extends React.Component{
  lastUrl = '';


  componentDidMount(){
    //console.log(`PagePrograms.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPagePrograms(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: this.props.page.program.title, slug: `#`},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderContacts(){
    if(!this.props.page.program ||
       !this.props.page.program.contacts) return null;

    let contacts = apiActions.arraySelectCamelCaseKeys(
      this.props.page.program.contacts,
      apiValues.nref.contact.fieldsUsed,
    );

    return (<MContacts className="m-contacts--sidebar"
                       contacts={contacts}
                       title={`CONTACT ${this.props.page.program.title}`}/>);
  }
  renderContent(){
    if(!this.props.page.program.shortDescription ||
       !this.props.page.program.shortDescriptionSmall ||
       !this.props.page.program.longDescription) return null;

    return (
      <div className="m-intro__contents">
        <div className="m-intro__title">
          {helpers.nToP(this.props.page.program.shortDescription)}
        </div>
        <div className="m-intro__description">
          {helpers.nToP(this.props.page.program.shortDescriptionSmall)}
        </div>
        <div className="markdown-text">
          <MarkdownField markup={this.props.page.program.longDescription}/>
        </div>
      </div>
    );
  }
  renderIntro(){
    if(!this.props.page.program) return null;

    const introData = {
      title: this.props.page.program.title,
      description: this.props.page.program.shortDescription,
    };

    return (<MIntro mIntro={introData} className="coupling coupling--centered-thin"/>);
  }


   renderLatestRelatedEvents(){
    if(!this.props.page.program.events || this.props.page.program.events.length < 1) return null;

    const latestEvents = {
      title: `LATEST ${this.props.page.title.toUpperCase()} EVENTS AND MEETINGS`,
      linkText: `See All Events from ${this.props.page.title}`,
      events: this.props.page.program.events,
    };
    return <MLatestRelatedEvents latestEvents={latestEvents} program={this.props.page.program}/>;
  }
/*
  renderLatestRelatedEvents(){


    const events = this.props.mLatestEvents.events.sort((b, a) => {
      return moment(b.eventDate).valueOf() - moment(a.eventDate).valueOf();
    }).slice(0, PRODUCTS_PER_PAGE);

    return events.map(event => {
   
      return (
        <div key={event.id} className="col-12 events__listing-item">
          <EventItem event={event}
                     disableDate
                     disableLocation
                     disableDescription={!this.props.horizontal}/>
        </div>
      );
    });
  }
*/





  renderProgramMapAd(){
    if(!this.props.page.mProgramMapAd ||
       !this.props.page.program) return null;

    return (<MProgramMapAd mProgramMapAd={this.props.page.mProgramMapAd} program={this.props.page.program}/>);
  }
  //todo: Need style option to match with Get Involved version (green background like content landing News and Updates)
  //todo: Need view more url based on filtering option chosen
  //todo: Update event calendar styling throughout site to make sure it matches design (extra top
  //todo: Fix styles for news sidebar listing

  //todo: I'm not sure this works right... it is supplying projects, but I think MRelatedProjects is re-loading the projects
  renderRelatedProjects(){
    if(!this.props.page ||
       !this.props.page.program ||
       (this.props.page.program.project.length < 1 && this.props.page.program.featuredProjects.length < 1)) return null;

    let projects = [
      ...(this.props.page.program.featuredProjects || []),
      ...this.props.page.program.project.sort((b, a) => {
        return new Date(b.LastSave).getTime() - new Date(a.LastSave).getTime();
      }),
    ];

    return (<MRelatedProjects className="coupling--full-centered"
                              mRelatedProjects={{projects}}
                              programTitle={this.props.page.program.title}/>);
  }
  render(){
    //console.log(`PagePrograms.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id ||
       !this.props.page.program) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.program.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <MProgramBanner/>
        <div className="coupling coupling---full-centered">
          <div className="columns">
            <div className="programs-detail__content coupling--left">
              <div className="container container--coupling">
                {this.renderContent()}
              </div>
            </div>
            <div className="programs-detail__sidebar coupling--right">
              {this.renderProgramMapAd()}
              <MLatestNews program={this.props.page}/>
              {this.renderLatestRelatedEvents()}
              {this.renderContacts()}
              <MNewsletter/>
            </div>
          </div>
        </div>
        {this.renderRelatedProjects()}
        <MListCards cmsId={apiValues.PAGE_PROGRAMS_MLISTCARDS_ID}
                    className="m-listcards--take-an-action coupling--full-centered"/>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageProgramsActions, dispatch),
  }),
)(PagePrograms));


PagePrograms.propTypes = {
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
  page: PropTypes.object.isRequired,
};
