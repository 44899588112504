export const CARD_LOAD_FAIL = 'CARD_LOAD_FAIL';
export const CARD_LOAD_START = 'CARD_LOAD_START';
export const CARD_LOAD_SUCCESS = 'CARD_LOAD_SUCCESS';
export const CAROUSELSLIDE_LOAD_FAIL = 'CAROUSELSLIDE_LOAD_FAIL';
export const CAROUSELSLIDE_LOAD_START = 'CAROUSELSLIDE_LOAD_START';
export const CAROUSELSLIDE_LOAD_SUCCESS = 'CAROUSELSLIDE_LOAD_SUCCESS';
export const EVENTS_LOAD_ALL_FAIL = 'EVENTS_LOAD_ALL_FAIL';
export const EVENTS_LOAD_ALL_START = 'EVENTS_LOAD_ALL_START';
export const EVENTS_LOAD_ALL_SUCCESS = 'EVENTS_LOAD_ALL_SUCCESS';
export const GETINVOLVEDCARD_LOAD_FAIL = 'GETINVOLVEDCARD_LOAD_FAIL';
export const GETINVOLVEDCARD_LOAD_START = 'GETINVOLVEDCARD_LOAD_START';
export const GETINVOLVEDCARD_LOAD_SUCCESS = 'GETINVOLVEDCARD_LOAD_SUCCESS';
export const MAPPARENT_LOAD_FAIL = 'MAPPARENT_LOAD_FAIL';
export const MAPPARENT_LOAD_START = 'MAPPARENT_LOAD_START';
export const MAPPARENT_LOAD_SUCCESS = 'MAPPARENT_LOAD_SUCCESS';
export const MAPPROGRAM_FETCH_MEMORIALS = 'MAPPROGRAM_FETCH_MEMORIALS';
export const MAPPROGRAM_FETCH_PROJECTS = 'MAPPROGRAM_FETCH_PROJECTS';
export const MAPPROGRAM_SEARCH_FAIL = 'MAPPROGRAM_SEARCH_FAIL';
export const MAPPROGRAM_SEARCH_START = 'MAPPROGRAM_SEARCH_START';
export const MAPPROGRAM_SEARCH_SUCCESS = 'MAPPROGRAM_SEARCH_SUCCESS';
export const MAPPROGRAM_TOGGLE_SEARCH = 'MAPPROGRAM_TOGGLE_SEARCH';
export const MBRANDBAR_LOAD_FAIL = 'MBRANDBAR_LOAD_FAIL';
export const MBRANDBAR_LOAD_START = 'MBRANDBAR_LOAD_START';
export const MBRANDBAR_LOAD_SUCCESS = 'MBRANDBAR_LOAD_SUCCESS';
export const MBREADCRUMBS_LOAD_FAIL = 'MBREADCRUMBS_LOAD_FAIL';
export const MBREADCRUMBS_LOAD_START = 'MBREADCRUMBS_LOAD_START';
export const MBREADCRUMBS_LOAD_SUCCESS = 'MBREADCRUMBS_LOAD_SUCCESS';
export const MCAROUSEL_LOAD_FAIL = 'MCAROUSEL_LOAD_FAIL';
export const MCAROUSEL_LOAD_START = 'MCAROUSEL_LOAD_START';
export const MCAROUSEL_LOAD_SUCCESS = 'MCAROUSEL_LOAD_SUCCESS';
export const MEDIAITEM_LOAD_FAIL = 'MEDIAITEM_LOAD_FAIL';
export const MEDIAITEM_LOAD_START = 'MEDIAITEM_LOAD_START';
export const MEDIAITEM_LOAD_SUCCESS = 'MEDIAITEM_LOAD_SUCCESS';
export const MEDIAITEM_LOAD_MANY_SUCCESS = 'MEDIAITEM_LOAD_MANY_SUCCESS';
export const MEVENTDETAIL_LOAD_FAIL = 'MEVENTDETAIL_LOAD_FAIL';
export const MEVENTDETAIL_LOAD_START = 'MEVENTDETAIL_LOAD_START';
export const MEVENTDETAIL_LOAD_SUCCESS = 'MEVENTDETAIL_LOAD_SUCCESS';
export const MEVENTS_LOAD_FAIL = 'MEVENTS_LOAD_FAIL';
export const MEVENTS_LOAD_START = 'MEVENTS_LOAD_START';
export const MEVENTS_LOAD_SUCCESS = 'MEVENTS_LOAD_SUCCESS';
export const MFOOTER_LOAD_FAIL = 'MFOOTER_LOAD_FAIL';
export const MFOOTER_LOAD_START = 'MFOOTER_LOAD_START';
export const MFOOTER_LOAD_SUCCESS = 'MFOOTER_LOAD_SUCCESS';
export const MFOOTER_SEARCH_START = 'MFOOTER_SEARCH_START';
export const MFOOTER_SEARCH_SUCCESS = 'MFOOTER_SEARCH_SUCCESS';
export const MFOOTER_TOGGLE_SEARCH = 'MFOOTER_TOGGLE_SEARCH';
export const MGETINVOLVED_LOAD_FAIL = 'MGETINVOLVED_LOAD_FAIL';
export const MGETINVOLVED_LOAD_START = 'MGETINVOLVED_LOAD_START';
export const MGETINVOLVED_LOAD_SUCCESS = 'MGETINVOLVED_LOAD_SUCCESS';
export const MHEADER_LOAD_FAIL = 'MHEADER_LOAD_FAIL';
export const MHEADER_LOAD_START = 'MHEADER_LOAD_START';
export const MHEADER_LOAD_SUCCESS = 'MHEADER_LOAD_SUCCESS';
export const MHEADER_SET_EXPANDED_TAB = 'MHEADER_SET_EXPANDED_TAB';
export const MHEADER_TOGGLE = 'MHEADER_TOGGLE';
export const MHEADER_TOGGLE_SEARCH = 'MHEADER_TOGGLE_SEARCH';
export const MHEADERNAVIGATION_LOAD_FAIL = 'MHEADERNAVIGATION_LOAD_FAIL';
export const MHEADERNAVIGATION_LOAD_START = 'MHEADERNAVIGATION_LOAD_START';
export const MHEADERNAVIGATION_LOAD_SUCCESS = 'MHEADERNAVIGATION_LOAD_SUCCESS';
export const MHEADERPROJECT_LOAD_FAIL = 'MHEADERPROJECT_LOAD_FAIL';
export const MHEADERPROJECT_LOAD_START = 'MHEADERPROJECT_LOAD_START';
export const MHEADERPROJECT_LOAD_SUCCESS = 'MHEADERPROJECT_LOAD_SUCCESS';
export const MHEADERTAB_ADD = 'MHEADERTAB_ADD';
export const MHEADERTAB_TOGGLE = 'MHEADERTAB_TOGGLE';
export const MHEADERTAB_TOGGLE_ALL = 'MHEADERTAB_TOGGLE_ALL';
export const MINTRO_LOAD_FAIL = 'MINTRO_LOAD_FAIL';
export const MINTRO_LOAD_START = 'MINTRO_LOAD_START';
export const MINTRO_LOAD_SUCCESS = 'MINTRO_LOAD_SUCCESS';
export const MLATESTEVENTS_LOAD_FAIL = 'MLATESTEVENTS_LOAD_FAIL';
export const MLATESTEVENTS_LOAD_START = 'MLATESTEVENTS_LOAD_START';
export const MLATESTEVENTS_LOAD_SUCCESS = 'MLATESTEVENTS_LOAD_SUCCESS';
export const MLATESTNEWS_LOAD_FAIL = 'MLATESTNEWS_LOAD_FAIL';
export const MLATESTNEWS_LOAD_START = 'MLATESTNEWS_LOAD_START';
export const MLATESTNEWS_LOAD_SUCCESS = 'MLATESTNEWS_LOAD_SUCCESS';
export const MLISTCARDS_LOAD_FAIL = 'MLISTCARDS_LOAD_FAIL';
export const MLISTCARDS_LOAD_START = 'MLISTCARDS_LOAD_START';
export const MLISTCARDS_LOAD_SUCCESS = 'MLISTCARDS_LOAD_SUCCESS';
export const MLISTFOLLOW_LOAD_FAIL = 'MLISTFOLLOW_LOAD_FAIL';
export const MLISTFOLLOW_LOAD_START = 'MLISTFOLLOW_LOAD_START';
export const MLISTFOLLOW_LOAD_SUCCESS = 'MLISTFOLLOW_LOAD_SUCCESS';
export const MLISTMEDIA_LOAD_FAIL = 'MLISTMEDIA_LOAD_FAIL';
export const MLISTMEDIA_LOAD_START = 'MLISTMEDIA_LOAD_START';
export const MLISTMEDIA_LOAD_SUCCESS = 'MLISTMEDIA_LOAD_SUCCESS';
export const MLISTMEDIA_SET_SELECTEDITEM = 'MLISTMEDIA_SET_SELECTEDITEM';
export const MLISTMEDIA_TOGGLE_LIGHTBOX = 'MLISTMEDIA_TOGGLE_LIGHTBOX';
export const MLISTNAV_LOAD_FAIL = 'MLISTNAV_LOAD_FAIL';
export const MLISTNAV_LOAD_START = 'MLISTNAV_LOAD_START';
export const MLISTNAV_LOAD_SUCCESS = 'MLISTNAV_LOAD_SUCCESS';
export const MLISTSHARE_LOAD_FAIL = 'MLISTSHARE_LOAD_FAIL';
export const MLISTSHARE_LOAD_START = 'MLISTSHARE_LOAD_START';
export const MLISTSHARE_LOAD_SUCCESS = 'MLISTSHARE_LOAD_SUCCESS';
export const MLISTTAG_LOAD_FAIL = 'MLISTTAG_LOAD_FAIL';
export const MLISTTAG_LOAD_START = 'MLISTTAG_LOAD_START';
export const MLISTTAG_LOAD_SUCCESS = 'MLISTTAG_LOAD_SUCCESS';
export const MNEWSDETAIL_LOAD_FAIL = 'MNEWSDETAIL_LOAD_FAIL';
export const MNEWSDETAIL_LOAD_START = 'MNEWSDETAIL_LOAD_START';
export const MNEWSDETAIL_LOAD_SUCCESS = 'MNEWSDETAIL_LOAD_SUCCESS';
export const MNEWSLETTER_LOAD_FAIL = 'MNEWSLETTER_LOAD_FAIL';
export const MNEWSLETTER_LOAD_START = 'MNEWSLETTER_LOAD_START';
export const MNEWSLETTER_LOAD_SUCCESS = 'MNEWSLETTER_LOAD_SUCCESS';
export const MNEWSLETTER_POST_FAIL = 'MNEWSLETTER_POST_FAIL';
export const MNEWSLETTER_POST_START = 'MNEWSLETTER_POST_START';
export const MNEWSLETTER_POST_SUCCESS = 'MNEWSLETTER_POST_SUCCESS';
export const MPROGRAMBANNER_LOAD_FAIL = 'MPROGRAMBANNER_LOAD_FAIL';
export const MPROGRAMBANNER_LOAD_START = 'MPROGRAMBANNER_LOAD_START';
export const MPROGRAMBANNER_LOAD_SUCCESS = 'MPROGRAMBANNER_LOAD_SUCCESS';
export const MPROGRAMMAPAD_LOAD_FAIL = 'MPROGRAMMAPAD_LOAD_FAIL';
export const MPROGRAMMAPAD_LOAD_START = 'MPROGRAMMAPAD_LOAD_START';
export const MPROGRAMMAPAD_LOAD_SUCCESS = 'MPROGRAMMAPAD_LOAD_SUCCESS';
export const MPROJECTLISTMAP_LOAD_FAIL = 'MPROJECTLISTMAP_LOAD_FAIL';
export const MPROJECTLISTMAP_LOAD_START = 'MPROJECTLISTMAP_LOAD_START';
export const PAGE_PROJECTS_EXTERNAL_LOAD_FAIL = 'PAGE_PROJECTS_EXTERNAL_LOAD_FAIL';
export const PAGE_PROJECTS_EXTERNAL_LOAD_START = 'PAGE_PROJECTS_EXTERNAL_LOAD_START';
export const PAGE_PROJECTS_EXTERNAL_LOAD_SUCCESS = 'PAGE_PROJECTS_EXTERNAL_LOAD_SUCCESS';
export const MPROJECTLISTMAP_LOAD_SUCCESS = 'MPROJECTLISTMAP_LOAD_SUCCESS';
export const MPROJECTLISTMAP_PROJECTS_LOAD_FAIL = 'MPROJECTLISTMAP_PROJECTS_LOAD_FAIL';
export const MPROJECTLISTMAP_PROJECTS_LOAD_START = 'MPROJECTLISTMAP_PROJECTS_LOAD_START';
export const MPROJECTLISTMAP_PROJECTS_LOAD_SUCCESS = 'MPROJECTLISTMAP_PROJECTS_LOAD_SUCCESS';
export const MPROJECTLISTMAP_SEARCH_FAIL = 'MPROJECTLISTMAP_SEARCH_FAIL';
export const MPROJECTLISTMAP_SEARCH_START = 'MPROJECTLISTMAP_SEARCH_START';
export const MPROJECTLISTMAP_SEARCH_SUCCESS = 'MPROJECTLISTMAP_SEARCH_SUCCESS';
export const MPROJECTLISTMAP_SET_FILTER = 'MPROJECTLISTMAP_SET_FILTERS';
export const MPROJECTLISTMAP_SET_FILTER_PROGRAM = 'MPROJECTLISTMAP_SET_FILTER_PROGRAM';
export const MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECT = 'MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECT';
export const MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECTTEMP = 'MPROJECTLISTMAP_SET_FILTER_PROGRAMSELECTTEMP';
export const MPROJECTLISTMAP_SET_FILTER_PROGRAMTEMP = 'MPROJECTLISTMAP_SET_FILTER_PROGRAMTEMP';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUS = 'MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUS';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECT = 'MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECT';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECTTEMP = 'MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSSELECTTEMP';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSTEMP = 'MPROJECTLISTMAP_SET_FILTER_PROJECTSTATUSTEMP';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTTYPE = 'MPROJECTLISTMAP_SET_FILTER_PROJECTTYPE';
export const MPROJECTLISTMAP_SET_FILTER_PROJECTTYPETEMP = 'MPROJECTLISTMAP_SET_FILTER_PROJECTTYPETEMP';
export const MPROJECTLISTMAP_SET_FILTERINPUTS = 'MPROJECTLISTMAP_SET_FILTERINPUTS';
export const MPROJECTLISTMAP_SET_LISTLENGTH = 'MPROJECTLISTMAP_SET_LISTLENGTH';
export const MPROJECTLISTMAP_SET_VIEWMAP = 'MPROJECTLISTMAP_SET_VIEWMAP';
export const MPROJECTLISTMAP_TOGGLE_FILTERING = 'MPROJECTLISTMAP_TOGGLE_FILTERING';
export const MPROJECTLISTMAP_TOGGLE_SEARCH = 'MPROJECTLISTMAP_TOGGLE_SEARCH';
export const MPROJECTS_LOAD_FAIL = 'MPROJECTS_LOAD_FAIL';
export const MPROJECTS_LOAD_START = 'MPROJECTS_LOAD_START';
export const MPROJECTS_LOAD_SUCCESS = 'MPROJECTS_LOAD_SUCCESS';
export const MPROJECTS_SET_FILTER = 'MPROJECTS_SET_FILTER';
export const MPROJECTS_SET_LISTLENGTH = 'MPROJECTS_SET_LISTLENGTH';
export const MPROJECTSCURRENT_LOAD_FAIL = 'MPROJECTSCURRENT_LOAD_FAIL';
export const MPROJECTSCURRENT_LOAD_START = 'MPROJECTSCURRENT_LOAD_START';
export const MPROJECTSCURRENT_LOAD_SUCCESS = 'MPROJECTSCURRENT_LOAD_SUCCESS';
export const MPROJECTSCURRENT_PROJECTS_LOAD_FAIL = 'MPROJECTSCURRENT_PROJECTS_LOAD_FAIL';
export const MPROJECTSCURRENT_PROJECTS_LOAD_START = 'MPROJECTSCURRENT_PROJECTS_LOAD_START';
export const MPROJECTSCURRENT_PROJECTS_LOAD_SUCCESS = 'MPROJECTSCURRENT_PROJECTS_LOAD_SUCCESS';
export const MPROJECTSCURRENT_SET_FILTER = 'MPROJECTSCURRENT_SET_FILTER';
export const MPROJECTSCURRENT_SET_LISTLENGTH = 'MPROJECTSCURRENT_SET_LISTLENGTH';
export const MRELATEDPROJECTS_LOAD_FAIL = 'MRELATEDPROJECTS_LOAD_FAIL';
export const MRELATEDPROJECTS_LOAD_START = 'MRELATEDPROJECTS_LOAD_START';
export const MRELATEDPROJECTS_LOAD_SUCCESS = 'MRELATEDPROJECTS_LOAD_SUCCESS';
export const MRICHTEXT_LOAD_FAIL = 'MRICHTEXT_LOAD_FAIL';
export const MRICHTEXT_LOAD_START = 'MRICHTEXT_LOAD_START';
export const MRICHTEXT_LOAD_SUCCESS = 'MRICHTEXT_LOAD_SUCCESS';
export const NAVIGATIONLINK_LOAD_FAIL = 'NAVIGATIONLINK_LOAD_FAIL';
export const NAVIGATIONLINK_LOAD_START = 'NAVIGATIONLINK_LOAD_START';
export const NAVIGATIONLINK_LOAD_SUCCESS = 'NAVIGATIONLINK_LOAD_SUCCESS';
export const NEWS_LOAD_FAIL = 'NEWS_LOAD_FAIL';
export const NEWS_LOAD_START = 'NEWS_LOAD_START';
export const NEWS_LOAD_SUCCESS = 'NEWS_LOAD_SUCCESS';
export const PAGE_CONTENTLANDING_LOAD_FAIL = 'PAGE_CONTENTLANDING_LOAD_FAIL';
export const PAGE_CONTENTLANDING_LOAD_START = 'PAGE_CONTENTLANDING_LOAD_START';
export const PAGE_CONTENTLANDING_LOAD_SUCCESS = 'PAGE_CONTENTLANDING_LOAD_SUCCESS';
export const PAGE_EVENTDETAILS_LOAD_FAIL = 'PAGE_EVENTDETAILS_LOAD_FAIL';
export const PAGE_EVENTDETAILS_LOAD_START = 'PAGE_EVENTDETAILS_LOAD_START';
export const PAGE_EVENTDETAILS_LOAD_SUCCESS = 'PAGE_EVENTDETAILS_LOAD_SUCCESS';
export const PAGE_EVENTS_LOAD_FAIL = 'PAGE_EVENTS_LOAD_FAIL';
export const PAGE_EVENTS_LOAD_START = 'PAGE_EVENTS_LOAD_START';
export const PAGE_EVENTS_LOAD_SUCCESS = 'PAGE_EVENTS_LOAD_SUCCESS';
export const PAGE_GETINVOLVED_LOAD_FAIL = 'PAGE_GETINVOLVED_LOAD_FAIL';
export const PAGE_GETINVOLVED_LOAD_START = 'PAGE_GETINVOLVED_LOAD_START';
export const PAGE_GETINVOLVED_LOAD_SUCCESS = 'PAGE_GETINVOLVED_LOAD_SUCCESS';
export const PAGE_HOME_LOAD_FAIL = 'PAGE_HOME_LOAD_FAIL';
export const PAGE_HOME_LOAD_START = 'PAGE_HOME_LOAD_START';
export const PAGE_HOME_LOAD_SUCCESS = 'PAGE_HOME_LOAD_SUCCESS';
export const PAGE_HOME_SEARCH_START = 'PAGE_HOME_SEARCH_START';
export const PAGE_HOME_SEARCH_SUCCESS = 'PAGE_HOME_SEARCH_SUCCESS';
export const PAGE_HOME_TOGGLE_SEARCH = 'PAGE_HOME_TOGGLE_SEARCH';
export const PAGE_LOAD_FAIL = 'PAGE_LOAD_FAIL';
export const PAGE_LOAD_START = 'PAGE_LOAD_START';
export const PAGE_LOAD_SUCCESS = 'PAGE_LOAD_SUCCESS';
export const PAGE_NEWS_LOAD_FAIL = 'PAGE_NEWS_LOAD_FAIL';
export const PAGE_NEWS_LOAD_START = 'PAGE_NEWS_LOAD_START';
export const PAGE_NEWS_LOAD_SUCCESS = 'PAGE_NEWS_LOAD_SUCCESS';
export const PAGE_PROGRAMS_LOAD_FAIL = 'PAGE_PROGRAMS_LOAD_FAIL';
export const PAGE_PROGRAMS_LOAD_START = 'PAGE_PROGRAMS_LOAD_START';
export const PAGE_PROGRAMS_LOAD_SUCCESS = 'PAGE_PROGRAMS_LOAD_SUCCESS';
export const PAGE_PROJECTS_DETAIL_FEATURE_TOGGLE = 'PAGE_PROJECTS_DETAIL_FEATURE_TOGGLE';
export const PAGE_PROJECTS_DETAIL_LOAD_START = 'PAGE_PROJECTS_DETAIL_LOAD_START';
export const PAGE_PROJECTS_DETAIL_LOAD_SUCCESS = 'PAGE_PROJECTS_DETAIL_LOAD_SUCCESS';
export const PAGE_PROJECTS_LOAD_FAIL = 'PAGE_PROJECTS_LOAD_FAIL';
export const PAGE_PROJECTS_LOAD_START = 'PAGE_PROJECTS_LOAD_START';
export const PAGE_PROJECTS_LOAD_SUCCESS = 'PAGE_PROJECTS_LOAD_SUCCESS';
export const PAGECONTENT_LOAD_FAIL = 'PAGECONTENT_LOAD_FAIL';
export const PAGECONTENT_LOAD_START = 'PAGECONTENT_LOAD_START';
export const PAGECONTENT_LOAD_SUCCESS = 'PAGECONTENT_LOAD_SUCCESS';
export const PAGECONTENTLANDING_LOAD_FAIL = 'PAGECONTENTLANDING_LOAD_FAIL';
export const PAGECONTENTLANDING_LOAD_START = 'PAGECONTENTLANDING_LOAD_START';
export const PAGECONTENTLANDING_LOAD_SUCCESS = 'PAGECONTENTLANDING_LOAD_SUCCESS';
export const PROJECT_LOAD_FAIL = 'PROJECT_LOAD_FAIL';
export const PROJECT_LOAD_START = 'PROJECT_LOAD_START';
export const PROJECT_LOAD_SUCCESS = 'PROJECT_LOAD_SUCCESS';
export const PROJECT_LOAD_MANY_SUCCESS = 'PROJECT_LOAD_MANY_SUCCESS';
export const PROJECTTYPELIST_LOAD_FAIL = 'PROJECTTYPELIST_LOAD_FAIL';
export const PROJECTTYPELIST_LOAD_START = 'PROJECTTYPELIST_LOAD_START';
export const PROJECTTYPELIST_LOAD_SUCCESS = 'PROJECTTYPELIST_LOAD_SUCCESS';
export const PROJECTTYPELIST_SET = 'PROJECTTYPELIST_SET';
export const SEARCH_EVENTS_LOAD_FAIL = 'SEARCH_EVENTS_LOAD_FAIL';
export const SEARCH_EVENTS_LOAD_START = 'SEARCH_EVENTS_LOAD_START';
export const SEARCH_EVENTS_LOAD_SUCCESS = 'SEARCH_EVENTS_LOAD_SUCCESS';
export const SEARCH_NEWS_LOAD_FAIL = 'SEARCH_NEWS_LOAD_FAIL';
export const SEARCH_NEWS_LOAD_START = 'SEARCH_NEWS_LOAD_START';
export const SEARCH_NEWS_LOAD_SUCCESS = 'SEARCH_NEWS_LOAD_SUCCESS';
export const SEARCH_PAGECONTENTDETAILS_LOAD_FAIL = 'SEARCH_PAGECONTENTDETAILS_LOAD_FAIL';
export const SEARCH_PAGECONTENTDETAILS_LOAD_START = 'SEARCH_PAGECONTENTDETAILS_LOAD_START';
export const SEARCH_PAGECONTENTDETAILS_LOAD_SUCCESS = 'SEARCH_PAGECONTENTDETAILS_LOAD_SUCCESS';
export const SEARCH_PROGRAMS_LOAD_FAIL = 'SEARCH_PROGRAMS_LOAD_FAIL';
export const SEARCH_PROGRAMS_LOAD_START = 'SEARCH_PROGRAMS_LOAD_START';
export const SEARCH_PROGRAMS_LOAD_SUCCESS = 'SEARCH_PROGRAMS_LOAD_SUCCESS';
export const SEARCH_PROJECTS_LOAD_FAIL = 'SEARCH_PROJECTS_LOAD_FAIL';
export const SEARCH_PROJECTS_LOAD_START = 'SEARCH_PROJECTS_LOAD_START';
export const SEARCH_PROJECTS_LOAD_SUCCESS = 'SEARCH_PROJECTS_LOAD_SUCCESS';
export const SEARCH_SET_PROGRAMS = 'SEARCH_SET_PROGRAMS';
export const SEARCH_SET_QUERY = 'SEARCH_SET_QUERY';
export const SEARCH_SET_TAG_QUERY = 'SEARCH_SET_TAG_QUERY';
export const SEARCH_SET_TYPES = 'SEARCH_SET_TYPES';
export const TAG_LOAD_FAIL = 'TAG_LOAD_FAIL';
export const TAG_LOAD_START = 'TAG_LOAD_START';
export const TAG_LOAD_SUCCESS = 'TAG_LOAD_SUCCESS';
