import React from 'react';


export default class GoogleTranslate extends React.Component{
  constructor(props){
    super(props);
    if(document.getElementById('google_translate_element') == null || document.getElementById('google_translate_element').children.length == 0){
      this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
    }
  }
  componentDidMount(){
    if(document.getElementById('google_translate_element') == null || document.getElementById('google_translate_element').children.length == 0){
      var addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = this.googleTranslateElementInit;
    }
  }


  googleTranslateElementInit(){
    //alert("test2")
    /* eslint-disable no-new */
    new window.google.translate.TranslateElement({
      pageLanguage: 'en',
      layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
    }, 'google_translate_element');
  }


  render(){
    return (
      <div className="google-translate" id="google_translate_element"></div>
    );
  }
}
