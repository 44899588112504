import * as apiValues from '../constants/apiValues';
import * as carouselSlideActions from '../actions/carouselSlideActions';
import * as helpers from '../helpers';
import * as mHeaderActions from '../actions/mHeaderActions';
import * as pageHomeActions from '../actions/pageHomeActions';
import MListNav from './MListNav';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {SiteSearch} from './SiteSearch';
import {withRouter} from 'react-router-dom';


class CarouselSlide extends React.Component{
  searchSlideId = '5bfc1b04ddaa44007fd28f37';


  constructor(props){
    super(props);

    this.state = {
      searchQuery: '',
    };
  }
  componentDidMount(){
    //console.log(`CarouselSlide.componentDidMount()`, this.props);
    this.props.actions.carouselSlide.initCarouselSlide(this.props.cmsId);
  }


  carouselSearch__onClick = (e) => {
    e.preventDefault();
    this.props.actions.mHeader.menuToggle(true);
    this.props.actions.mHeader.toggleAllTabs(false);
    this.props.actions.mHeader.tabToggle('search', true);
    this.props.actions.mHeader.expandedTab({
      expandedTabBarVisible: false,
      //expandedTabBarOffset: -1,//e.target.offsetLeft,
      //expandedTabBarWidth: 1,// e.target.clientWidth,
      expandedTab:
        'search',
    });

    //setTimeout(()=>{
    //
    //},0);
    document.getElementById('search-main').focus();
    return null;


    //this.props.actions.menuToggle(typeof isOpen != "undefined" ? isOpen : !this.props.mHeader.isOpen);
    //this.props.actions.toggleAllTabs(false);
    //this.props.actions.tabToggle('search', true);
  };
  input_onChange = event => {
    //console.log(`onSearchInputChange`, event.target.value);
    let isSearchOpen = event && event.target.value.length > 0;
    this.props.actions.pageHome.toggleSearch(isSearchOpen);
    this.handleSearch(event.target.value);
    this.setState({
      searchQuery: event.target.value,
    });
  };
  search_onSubmit = () => {
    this.props.history.push(`/projects?Title_contains=${this.state.searchQuery}`);
  };
  searchClear_onClick = () => {
    setTimeout(() => {
      this.props.actions.pageHome.toggleSearch(false);
    });
  };
  siteSearch_onBlur = () => {
    this.props.actions.pageHome.toggleSearch(false);
  };
  siteSearch_onFocus = () => {
    if(helpers.isScreenSmallerThan(helpers.screenSizes.lgMin)){
      this.props.actions.mHeader.menuToggle(true);
      this.props.actions.mHeader.toggleAllTabs(false);
      this.props.actions.mHeader.tabToggle('search', true);
      setTimeout(() => {
        document.getElementById('search-main').focus();
      });
    }
    else this.props.actions.pageHome.toggleSearch(true);
  };


  handleSearch = query => {
    this.props.actions.pageHome.doSearch(query);
    //this.props.actions.mProjectListMap.doSearch(null, query);
  };


  renderCarouselSearch = () => {
    if(this.props.carouselSlide.id != this.searchSlideId) return null;
    return (
      <div className="carouselslide__search">
        <div className="carouselslide__search-description">Search Our Projects</div>
        <div className="carouselslide__search-container">
          {/* <SiteSearch className="site-search--carousel-slide"/> */}
          <SiteSearch className={`site-search--carousel-slide`}
                      isOpen={this.props.page.isSearchOpen}
                      onBlur={this.siteSearch_onBlur}
                      onClearClick={this.searchClear_onClick}
                      onFocus={this.siteSearch_onFocus}
                      onInputChange={this.input_onChange}
                      onSubmit={this.search_onSubmit}
                      inputPlaceholder="Try Figueroa, Venice, Avalon or a street near you.">
            <div className="row">
              {this.renderFeaturedProjects()}
              {this.renderSuggestedProjects()}
            </div>
          </SiteSearch>
          {/* <div className="carouselslide__search-cover" onClick={(e) => {
            this.carouselSearch__onClick(e);
          }}></div> */}
        </div>
      </div>
    );
  };
  renderDescription = () => {
    return (this.props.carouselSlide && this.props.carouselSlide.description) ? (
      <div className="carouselslide__description">
        {this.props.carouselSlide.description}
      </div>
    ) : null;
  };
  renderFeaturedProjects = () => {
    if(this.props.page.suggestedProjects) return null;

    return (
      <div className="col-12">
        <MListNav cmsId={apiValues.HEADER_OURPROJECTS_FEATURED_ID} className="m-listnav--page-search-results"/>
      </div>
    );
  };
  renderSuggestedProjects = () => {
    if(!this.props.page.suggestedProjects) return null;

    let mListNav = {
      title: 'Suggested Projects',
      navigationLinks: this.props.page.suggestedProjects.slice(0, 4).map(project => {
        return {
          key: project.id,
          title: project.title,
          url: `/projects/${project.slug ? project.slug : ''}`,
        };
      }),
      highlight: this.state.searchQuery,
    };

    if(this.props.page.suggestedProjects.length === 0) return (
      <div className="col-12">
        <div className="no-search-results">{`Sorry this search doesn't have any results.`}</div>
        {/* <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/> */}
        <div className="no-results-suggestion">
          Search Suggestions
          <ul>
            <li>
              Check spelling
            </li>
            <li>
              Try more general words
            </li>
            <li>
              Remove some search filters
            </li>
            <li>
              <a href="/get-involved" target="_blank">Contact us</a> {`if you still can't find what you are looking for`}
            </li>
          </ul>
        </div>
        {/* <SuperLink to="projects" className="m-projectlistmap__results-link">View all projects for &quot;{this.state.searchQuery}&quot;</SuperLink> */}
      </div>
    );
    else return (
      <div className="col-12">
        <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/>
        <SuperLink to="projects" className="m-projectlistmap__results-link">View all projects for &quot;{this.state.searchQuery}&quot;</SuperLink>
      </div>
    );
  };
  render(){
    // console.log(`CarouselSlide render`, this.props.page);
    if(!this.props.page ||
       !this.props.carouselSlide) return null;

    return (
      <div className="carouselslide">
        <div className="carouselslide__content">
          {this.renderDescription()}
          {this.renderCarouselSearch()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    carouselSlide: helpers.getById(state.carouselSlide, ownProps.cmsId),
    mHeader: state.mHeader,
    page: state.pageHome,
  }),
  (dispatch) => ({
    actions: {
      carouselSlide: bindActionCreators(carouselSlideActions, dispatch),
      mHeader: bindActionCreators(mHeaderActions, dispatch),
      pageHome: bindActionCreators(pageHomeActions, dispatch),
    },
  }),
)(CarouselSlide));


CarouselSlide.propTypes = {
  actions: PropTypes.object.isRequired,
  carouselSlide: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  history: PropTypes.object,
  page: PropTypes.object,
};
