import * as apiValues from '../constants/apiValues';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React, {Component} from 'react';
import Search from './Search';
import TagSearch from './TagSearch';
import {withRouter} from 'react-router-dom';


class PageSearch extends Component{
  componentDidMount(){
    // const query = QueryString.parse(this.props.location.search);
    // this.props.actions.setQuery(query.q);
    // this.props.actions.initSearch();
  }

  renderSearchContent(){
    const mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Search Results', slug: '#'},
      ],
    };

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle('Search Results')}</title>
        </Helmet>
        <div className="coupling coupling--parent coupling--full main-content">

          <MBreadcrumbs mBreadcrumbs={mBreadcrumbs} className="coupling coupling--full-centered"/>
          <div className="coupling coupling--parent coupling--full">
            <Search/>
          </div>

        </div>
      </PageBase>
    );
  }
  renderTagSearchContent(){
    const mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Tag Search Results', slug: '#'},
      ],
    };

    return (
      <PageBase>
        <div className="coupling coupling--parent coupling--full main-content">

          <MBreadcrumbs mBreadcrumbs={mBreadcrumbs} className="coupling coupling--full-centered"/>
          <div className="coupling coupling--parent coupling--full">
            <TagSearch/>
          </div>

        </div>
      </PageBase>
    );
  }
  render(){
    const query = QueryString.parse(this.props.location.search);

    return query.tag ? this.renderTagSearchContent() : this.renderSearchContent();
  }
}


export default withRouter(PageSearch);


PageSearch.propTypes = {
  location: PropTypes.object,
};
