import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getCardById} from "../reducers/index";


//Action Creators
export const loadCardStart = (id) => {
  return {
    type: actionTypes.CARD_LOAD_START,
    id
  };
};
export const loadCardFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.CARD_LOAD_FAIL,
    id,
    error
  };
};
export const loadCardSuccess = (data) => {
  return {
    type: actionTypes.CARD_LOAD_SUCCESS,
    data
  };
};


export const initCard = id => dispatch => {
  dispatch(loadCardStart(id));

  apiActions.loadEndpoint(apiValues.nref.card.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.card.fieldsUsed);

    dispatch(loadCardSuccess(normalized));
  });
};
