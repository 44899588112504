import moment from 'moment';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';


export default class NewsDetailIntro extends React.Component{
  renderDescription(){
    const {news} = this.props;
    const {timestamp} = news;
    let eventDate = new Date(timestamp);

    if(!eventDate.getDate()){
      return null;
    }
    eventDate = moment(eventDate).format('dddd, MMMM D, YYYY | HH:mm A');

    return (
      <div className="m-news-detail__intro__description">
        {eventDate.toString()}
      </div>
    );
  }
  renderProgramBanner(){
    const {news} = this.props;
    if(!news.program) return null;

    return news && news.program ? (
      <ProgramBanner className="event-detail-item__program-label" program={news.program}/>
    ) : null;
  }
  renderTitle(){
    const {news} = this.props;
    if(!news.title) return null;

    return (
      <div className="m-news-detail__intro__title">{news.title}</div>
    );
  }
  render(){
    if(!this.props.news) return null;

    return (
      <div className=" m-news-detail-intro">
        <div className="container container--coupling">
          {this.renderProgramBanner()}
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </div>
    );
  }
}


NewsDetailIntro.propTypes = {
  className: PropTypes.string,
  news: PropTypes.object,
};
