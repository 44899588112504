import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as pageActions from './pageActions';


export const initPageContentLanding = (slug) => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.pageContentLanding.endpoint, null, {Slug: slug})
      .then(results => {
        let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageContentLanding.fieldsUsed);
        page.cardList = page.cardList ? apiActions.itemSelectCamelCaseKeys(page.cardList, apiValues.nref.mListCards.fieldsUsed) : undefined;

        resolve(dispatch(pageActions.loadPageSuccess(page)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
