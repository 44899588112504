import * as pageActions from './pageActions';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


export const initPageOverallMap = () => dispatch => {
  dispatch(pageActions.loadPageStart());
  return new Promise((resolve, reject) => {
    apiActions
      .loadEndpoint(apiValues.nref.program.endpoint, null)
      .then(results => {
        let program = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.program.fieldsUsed);
        program.forEach(p => {
          p.project = p.project ? apiActions.arraySelectCamelCaseKeys(p.project, apiValues.nref.project.fieldsUsed).filter(project => {
            return !project.softDelete;
          }) : null;
        });
        // console.log(program);

        let page = {
          program: program,
        };

        return page;
      })
      .then(results => {
        resolve(dispatch(pageActions.loadPageSuccess(results)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
