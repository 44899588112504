import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getMListTagById} from "../reducers/index";


//Action Creators
export const loadMListTagStart = (id) => {
  return {
    type: actionTypes.MLISTTAG_LOAD_START,
    id
  };
};
export const loadMListTagFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTTAG_LOAD_FAIL,
    id,
    error
  };
};
export const loadMListTagSuccess = (data) => {
  return {
    type: actionTypes.MLISTTAG_LOAD_SUCCESS,
    data
  };
};

export const initMListTag = id => dispatch => {
  dispatch(loadMListTagStart(id));

  apiActions.loadEndpoint(apiValues.nref.mListTag.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListTag.fieldsUsed);

    dispatch(loadMListTagSuccess(normalized));
  });
};


