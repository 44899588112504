import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function pageHomeReducer(state = initialState.pageHome, action) {
  switch (action.type) {
    case actionTypes.PAGE_GETINVOLVED_LOAD_START:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.PAGE_GETINVOLVED_LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    case actionTypes.PAGE_GETINVOLVED_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };
    
    case actionTypes.PAGE_HOME_SEARCH_START:
      return {
        ...state,
        isSearchOpen: true
      };
    case actionTypes.PAGE_HOME_SEARCH_SUCCESS:
      return {
        ...state,
        ...action.data,
        isSearchOpen: true
      }
    case actionTypes.PAGE_HOME_TOGGLE_SEARCH: {
      return {
        ...state,
        isSearchOpen: action.isSearchOpen
      };
    }
    default: {
      return state;
    }
  }
}
