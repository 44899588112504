import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function pageGetInvolvedReducer(state = initialState.pageGetInvolved, action) {
  switch (action.type) {
    case actionTypes.PAGE_GETINVOLVED_LOAD_START:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.PAGE_GETINVOLVED_LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        isLoading: false
      };

    case actionTypes.PAGE_GETINVOLVED_LOAD_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false
      };

    default: {
      return state;
    }
  }
}
