import PropTypes from 'prop-types';
import React from 'react';


export default class ProgramBanner extends React.Component{
  state = {};


  render(){
    if(!this.props.program) return null;

    return (
      <div className={`program-banner ${this.props.className || ''}`} style={{backgroundColor: this.props.program.color || this.props.program.Color}}>
        <div className="program-banner__title">{this.props.program.title || this.props.program.Title}</div>
      </div>
    );
  }
}


ProgramBanner.propTypes = {
  className: PropTypes.string,
  program: PropTypes.object,
};
