import ProjectStub from './ProjectStub';
import PropTypes from 'prop-types';
import React from 'react';


export default class ProjectList extends React.Component{
  componentDidMount(){
    //console.log(`ProjectList.componentDidMount()`, this.props);
  }


  renderProjects(){
    return this.props.projects.map(project => (
      <div key={project.id} className="col-12 col-sm-6 col-md-4 col-lg-3">
        <ProjectStub cmsId={project.id}/>
      </div>
    ));
  }
  render(){
    //console.log(`ProjectList render`, this.props);
    if(!this.props.projects ||
       this.props.projects.length < 1) return null;

    return (
      <div className={`project-list ${this.props.className || ''}`}>
        <div className="row">
          {this.renderProjects()}
        </div>
      </div>
    );
  }
}


ProjectList.propTypes = {
  className: PropTypes.string,
  projects: PropTypes.array,
};
