import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';


//Action Creators
export const loadMHeaderStart = id => {
  return {type: actionTypes.MHEADER_LOAD_START, id};
};
export const loadMHeaderFail = (error) => {
  console.error(error);
  return {type: actionTypes.MHEADER_LOAD_FAIL, error};
};
export const loadMHeaderSuccess = (data) => {
  return {type: actionTypes.MHEADER_LOAD_SUCCESS, payload: data};
};

export const loadMHeaderProjectStart = () => {
  return {type: actionTypes.MHEADERPROJECT_LOAD_START};
};
export const loadMHeaderProjectFail = (error) => {
  console.error(error);
  return {type: actionTypes.MHEADERPROJECT_LOAD_FAIL, error};
};
export const loadMHeaderProjectSuccess = (data) => {
  return {type: actionTypes.MHEADERPROJECT_LOAD_SUCCESS, payload: data};
};

export const loadMHeaderNavigationStart = () => {
  return {type: actionTypes.MHEADERNAVIGATION_LOAD_START};
};
export const loadMHeaderNavigationFail = (error) => {
  console.error(error);
  return {type: actionTypes.MHEADERNAVIGATION_LOAD_FAIL, error};
};
export const loadMHeaderNavigationSuccess = (data) => {
  return {type: actionTypes.MHEADERNAVIGATION_LOAD_SUCCESS, payload: data};
};

export const loadMListNavStart = (id) => {
  return {
    type: actionTypes.MLISTNAV_LOAD_START,
    id,
  };
};
export const loadMListNavFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTNAV_LOAD_FAIL,
    id,
    error,
  };
};
export const loadMListNavSuccess = (data) => {
  return {
    type: actionTypes.MLISTNAV_LOAD_SUCCESS,
    data,
  };
};
export const menuToggle = (isOpen) => {
  return {
    type: actionTypes.MHEADER_TOGGLE,
    isOpen,
  };
};
export const toggleAllTabs = (isOpen) => {
  return {
    type: actionTypes.MHEADERTAB_TOGGLE_ALL,
    isOpen,
  };
};
export const tabToggle = (id, isOpen) => {
  return {
    type: actionTypes.MHEADERTAB_TOGGLE,
    id,
    isOpen,
  };
};
export const toggleSearch = (isSearchOpen) => {
  return {
    type: actionTypes.MHEADER_TOGGLE_SEARCH,
    isSearchOpen,
  };
};
export const expandedTab = (data) => {
  return {
    type: actionTypes.MHEADER_SET_EXPANDED_TAB,
    data,
  };
};


export const initMHeader = id => dispatch => {
  dispatch(loadMHeaderStart(id));

  apiActions.loadEndpoint(apiValues.nref.mHeader.endpoint, id).then(result => {
    let nmHeader = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mHeader.fieldsUsed);

    let promises = nmHeader.mListNavs.map(mListNav => {
      return apiActions.loadEndpoint(apiValues.nref.mListNav.endpoint, mListNav.id).then(result => {
        let nmListNav = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListNav.fieldsUsed);
        nmListNav.navigationLinks = apiActions.arraySelectCamelCaseKeys(nmListNav.navigationLinks, apiValues.nref.navigationLink.fieldsUsed);
        dispatch(loadMListNavSuccess(nmListNav));
        return nmListNav;
      });
    });

    Promise.all(promises).then(results => {
      nmHeader.mListNavs = results;
      dispatch(loadMHeaderSuccess(nmHeader));
    });
  });

  dispatch(loadMHeaderNavigationStart());
  apiActions.loadEndpoint(apiValues.nref.navigationLink.endpoint).then(result => {
    const navigations = apiActions.arraySelectCamelCaseKeys(result, apiValues.nref.navigationLink.fieldsUsed);
    dispatch(loadMHeaderNavigationSuccess({
      navigations,
    }));
  });
};

export const headerSearch = queryStr => {
  let projectPromise = helpers.multiFieldContainsQuery(
    queryStr,
    apiValues.nref.project.endpoint,
    ['Title', 'What Description', 'Why Description', 'Description', 'Location Description', 'Neighborhoods'],
    'id',
    {_sort: 'LastSave:desc', _limit: 4});
  let navigationLinkPromise = helpers.multiFieldContainsQuery(
    queryStr,
    apiValues.nref.navigationLink.endpoint,
    ['Title'],
    'id',
    {_sort: 'LastSave:desc', _limit: 4});

  return Promise.all([projectPromise, navigationLinkPromise])
    .then(([projects, navigationLinks]) => {
      return {
        projects: projects ? apiActions.arraySelectCamelCaseKeys(projects, apiValues.nref.project.fieldsUsed) : [],
        navigations: navigationLinks ? apiActions.arraySelectCamelCaseKeys(navigationLinks, apiValues.nref.navigationLink.fieldsUsed) : [],
      };
    });
};


