export default [
  {
    id: 'safety-111',
    name: 'Safety',
    children: [
      {
        name: 'Dangerous Left Turn'
      },
      {
        name: 'Poor Visibility'
      },
      {
        name: 'Speeding'
      },
      {
        name: 'Long Distance to Cross'
      },
      {
        name: 'Long Wait to Cross'
      },
      {
        name: 'Not Enough Time To Cross'
      },
      {
        name: 'Failure to Yield to Pedestrian in Crosswalk'
      },
      {
        name: 'Vehicles in Bike Lane'
      },
      {
        name: 'Red Light Running'
      },
      {
        name: 'Lack of Bike Facility'
      },
      {
        name: 'Protected Crossing Requested'
      },
      {
        name: 'Bike Network Gap'
      }
    ]
  },
  {
    id: 'signs-222',
    name: 'Signs',
    children: [
      {
        name: 'Stop Sign'
      },
      {
        name: 'Wayfinding/Guide'
      },
      {
        name: 'Warning & Regulatory'
      }
    ]
  },
  {
    id: 'transit-333',
    name: 'Transit',
    children: [
      {
        name: 'Vehicles Parked at Bus Stop'
      },
      {
        name: 'Lack of Bus Shelter or Bench'
      }
    ]
  },
  {
    id: 'maintenance-444',
    name: 'Maintenance',
    children: [
      {
        name: 'Damaged Sidewalk'
      },
      {
        name: 'Narrow Sidewalk'
      },
      {
        name: 'Missing Sidewalk'
      },
      {
        name: 'Potholes'
      },
      {
        name: 'Missing Curb Ramp'
      }
    ]
  },
  {
    id: 'enahncement-555',
    name: 'Street Enhancements',
    children: [
      {
        name: 'Poor Lighting'
      },
      {
        name: 'Lack of Street Trees'
      },
      {
        name: 'Lack of Bicycle Parking'
      }
    ]
  }
]