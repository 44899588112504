import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getMIntroById} from "../reducers/index";


//Action Creators
export const loadMListShareStart = (id) => {
  return {
    type: actionTypes.MLISTSHARE_LOAD_START,
    id
  };
};
export const loadMListShareFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTSHARE_LOAD_FAIL,
    id,
    error
  };
};
export const loadMListShareSuccess = (data) => {
  return {
    type: actionTypes.MLISTSHARE_LOAD_SUCCESS,
    data
  };
};

export const initMListShare = id => dispatch => {
  dispatch(loadMListShareStart(id));

  apiActions.loadEndpoint(apiValues.nref.mListShare.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListShare.fieldsUsed);

    dispatch(loadMListShareSuccess(normalized));
  });
};


