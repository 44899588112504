import 'rc-slider/assets/index.css';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mapProgramActions from '../actions/mapProgramActions';
import iconArrowRight from '../img/icon-arrow-right-black.png';
import iconClose from '../img/icons/icon-close.svg';
import iconKnob from '../img/icons/icon-knob.svg';
import loadingIcon from '../img/loading-icon-small.gif';
import MarkdownField from './MarkdownField';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';
import ShareMemorial from './ShareMemorial';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Range } from 'rc-slider';
import { withRouter } from 'react-router-dom';
import moment from 'moment';


let currentDomain = ''; //validate iframe domain origin with map/child.html & map/dcr_mapping_child.js

if(
  window.location.hostname == 'localhost' ||
  window.location.hostname == '127.0.0.1'
){
  var portNumber = location.port ? ':' + location.port : '';
  currentDomain =
    window.location.protocol + '//' + window.location.hostname + portNumber;
}
else{
  currentDomain = window.location.protocol + '//' + window.location.hostname; //TODO TEST THIS ON BUILT VANITY URL LOCATION
}

// let initMapPayload = {
//   testMsg: "Hello World!!!",
//   debug: true,
//   isCMS: false,  // flag for CMS or Public version
//   ui_ZoomLevel: "lab-zoom",  // defines HTML element showing current zoomlevel
//   map_html_class: "map-render-area", // this would be the "class" name of the div for example <div class="map-render-area"></div> whether in the CMS or in the PUB site
//   map_div_id: "map-primary-render-area", // since you can only have one "id" on a html page, you would only be able to use this once. Examples: <div id="map-primary"></div>, <div class="map-render-area"></div>, <div id="somethingelse" class="map-render-area"></div>
//   memorial_csv: "./test_memorial.csv"
// };

//TODO you may not need the project_id, pif if passed directly however if you do
//you can use this to temporarily store data for reference locally.
let dcrMappingData = {
  project_id: '',
  pif: '',
  params: [],
  functionName: '',
  selectedEditLayer: '',
  currentProgram: 'all',
  currentProgramStatus: 'all',
  startDate: '2010',
  endDate: '2021',
};
let layer_names = {};
let features_types_name = [];
let default_layers = [];


class MapProgram extends React.Component{
  haveSetProjectLayerBefore = false;


  constructor(props){
    super(props);

    let slug = props.location.search;
    let memorialPopUpName = null;
    if(slug && slug.includes('sharememorial')){
      slug = slug.split('-');
      memorialPopUpName = slug[1].replace('%20', ' ');
      slug = slug[0].replace('sharememorial=', '').replace('?', '');
    }
    else{
      slug = null;
    }
    this.state = {
      showMemorial: slug,
      collisions: 0,
      collisionDetail: {},
      feedbackAddress: '',
      currentLayers: [],
      // currentTab: props.tabs[0].id,
      currentTab: '',
      height: window.innerHeight,
      width: window.innerWidth,
      selectedObject: null,
      memorialPopUpName,
      memorialDateFilter: [2017, 2018],
      mapPluginReady: false,
      tabContentClose: false,
    };

    //this.componentDidMount = this.componentDidMount.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.sendMessageToChild = this.sendMessageToChild.bind(this);
    this.sendDirectlyToChild = this.sendDirectlyToChild.bind(this);
    this.recieveMessageFromChild = this.recieveMessageFromChild.bind(this);
    this.testBtn = this.testBtn.bind(this);
    this.zoomToOpt = this.zoomToOpt.bind(this);
    this.getMapLayersBtn = this.getMapLayersBtn.bind(this);
    this.mapmodeOpt = this.mapmodeOpt.bind(this);
    this.programOpt = this.programOpt.bind(this);
    this.projectOpt = this.projectOpt.bind(this);
    this.drawLayer = this.drawLayer.bind(this);
    this.setDateStart = this.setDateStart.bind(this);
    this.setDateEnd = this.setDateEnd.bind(this);
    this.statusOpt = this.statusOpt.bind(this);
    this.startEditingOpt = this.startEditingOpt.bind(this);
    this.stopEditingBtn = this.stopEditingBtn.bind(this);
    this.handleMessageFromChild = this.handleMessageFromChild.bind(this);
    this.updateFeatureCount = this.updateFeatureCount.bind(this);
    this.enableFeedbackBtn = this.enableFeedbackBtn.bind(this);
    this.displayFeedbackAddressPoint = this.displayFeedbackAddressPoint.bind(
      this,
    );
    this.toggleShareButtons = this.toggleShareButtons.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  componentDidMount(){
    this.bindEvents();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }


  filteringApply_onClick = () => {
    this.setState({filtersOpen: false});
  };
  filteringCancel_onClick = () => {
    this.setState({
      filtersOpen: false,
      currentLayers: this.state.previousLayers,
    });
  };
  filteringOpen_onClick = () => {
    let newState = {filtersOpen: !this.state.filtersOpen};
    if(!this.state.filtersOpen)
      newState.previousLayers = this.state.currentLayers;
    this.setState(newState);
  };
  mapClose_onClick = () => {
    this.setState({mapOpen: false});
  };
  mapOpen_onClick = () => {
    this.setState({mapOpen: !this.state.mapOpen});
  };


  /**
   * This function binds events from this javascript file to the browser
   */
  bindEvents(){
    // Assign handler to message event from the browser window.
    if(window.addEventListener){
      window.addEventListener('message', this.recieveMessageFromChild, false);
    }
    else if(window.attachEvent){
      // supports ie8
      window.attachEvent('onmessage', this.recieveMessageFromChild);
    }
  }
  changeValue = value => {
    this.setState({
      memorialDateFilter: value,
    });
    this.sendMessageToChild({
      functionName: 'setDateFilter',
      params: ['collisions', value[0], value[1]],
    });
  };
  drawLayer(event){
    //console.log("MapProgram -> drawLayer event: ", event);
    //console.log("MapProgram -> drawLayer event.target.value: ", event.target.value);
    //console.log("MapProgram -> drawLayer event.target.name: ", event.target.name);
    //console.log("MapProgram -> drawLayer event.target.getAttribute(map-layer): ", event.target.getAttribute("map-layer"));

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.getAttribute('map-layer'), event.target.value],
    });
  }
  displayFeedbackAddressPoint(data){
    //console.group("parent.html > parent.js > displayFeedbackAddressPoint");
    //console.log("data:", data);
    //console.log("MapProgram before setState this.state", this.state);
    this.setState({feedbackAddress: data.address});
    //console.log("MapProgram after setState this.state", this.state);
    //console.groupEnd();
  }
  enableFeedbackBtn(event){
    //console.log("MapProgram -> enableFeedbackBtn event: ", event);
    //TODO event.target.getAttribute('service-task')
    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [], // project_id is optional to test if point inside projecta area
    });
  }
  getMapLayersBtn(){
    this.sendMessageToChild({
      functionName: 'getMapLayer',
      params: ['srts_top50'],
    });
  }
  handleGoToProjectDetail = data => {
    const selectedProject = this.props.page.project.find(item => item.projectId == data.project_id);
    if(selectedProject){
      this.props.history.push(`/projects/${selectedProject.slug || ''}`);
    }
  };
  handleMemorialContent = () => {
    this.setState({
      selectedObject: null,
      showMemorial: null,
      memorialPopUpName: null,
    });
  };
  handleMessageFromChild(data){
    // DCR_Mapping test response
    // =============================

    //console.group("parent.html > parent.js > handleMessageFromChild");
    //console.log('data:', data);
    //console.log(typeof data);
    // console.log("--handlemsssage---", data);
    if(data && data.functionName){
      let memorialMapTab = this.props.tabs.find(tab => tab.id.indexOf('memorial_map') >= 0);
      switch(data.functionName){
        case 'mapPluginReady':
          this.mapLoaded();
          break;
        case 'projectPopupClicked':
          this.handleGoToProjectDetail(data);
          break;
        case 'updateFeatureCount':
          this.updateFeatureCount(data);

          break;

        case 'displayFeedbackAddressPoint':
          this.displayFeedbackAddressPoint(data);

          break;
        case 'getMapLayerGroup':{
          const {mode} = this.props;
          const layers = data.layers.layers;
          console.log(`handleMessageFromChild layers`, layers);
          const group_id = data.layers.id;
          let layer_data = {};
          if(group_id == `${mode}-projects`){
            if(this.props.page.program.slug == 'people-st'){
              layer_data['projects_parklet'] = false;
              layer_data['projects_plaza'] = false;
              layer_names['projects_parklet'] = 'Projects - Parklets';
              layer_names['projects_plaza'] = 'Projects - Plazas';

              // layer_data['people_streets-projects_parklets'] = false;
              // layer_data['people_streets-projectsprojects_plazas'] = false;
              // layer_names['people_streets-projectsprojects_parklets'] = 'Projects - Parklets';
              // layer_names['people_streets-projectsprojects_plazas'] = 'Projects - Plazas';
              // layer_data['projects'] = false;
              // layer_names['projects'] = 'Projects';
            }
            else{
              layer_data['projects'] = false;
              layer_names['projects'] = 'Projects';
            }
          }
          layers.forEach(layer => {
            this.sendMessageToChild({
              functionName: 'getMapLayer',
              params: [layer], // no arg
            });
            if(group_id == `${mode}-default`){
              default_layers.push(layer);
            }
            layer_data[layer] = default_layers.includes(layer);
            // if (group_id === `${mode}-memorial_map`){
            //   layer_data.memorial = true;
            // }
            // if (layer === 'coll_ped_fatal') {
            //   layer_data[layer] = false;
            // }
          });
          this.setState({
            ...this.state,
            layers: {
              ...this.state.layers,
              [group_id]: layer_data,
            },
          });
        }
          break;
        case 'getFeatureTypes':{
          if(data.types.length === 0){
            setTimeout(() => {
              this.sendMessageToChild({
                functionName: 'getFeatureTypes',
                params: ['point'],
              });
            }, 3000);
          }
          else{
            features_types_name = data.types;
            let layer_data = {};
            features_types_name.map(item => {
              if(item && item.value && item.count){
                layer_data[item.value] = false;
              }
            });
            this.setState({
              layers: {
                ...this.state.layers,
                'vision_zero-project_features': layer_data,
              },
            });
          }
        }
          break;
        case 'getMapLayer':{
          const layer = data.layer;
          layer_names[layer.id] = layer.name;
        }
          break;
        case 'selectedFeature':
          if(memorialMapTab){
            this.selectTab(memorialMapTab.id);
            setTimeout(() => {
              this.setState({
                selectedObject: data.values.caseid,
                memorialPopUpName: data.values.name,
              });
            });
          }
          break;
        case 'memorialPopupMore':
          console.log('----memorialPopupMore', data);
          break;
        case 'cmsFeaturePopupMore':
          console.log('----cmsFeaturePopupMore', data);
          break;

        case 'collisionPopupClicked':
          window.location = `mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${data.case_id})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`;
          break;

        default:
        //console.warn("Error with payload data", data);
      }
    }
    //console.groupEnd();
  }
  handleTabContentClose = () => {
    // this.setState({
    //   tabContentClose: true,
    // });
    this.selectTab('');
  };
  mapLoaded(){
    const {
      mode,
      tabs,
    } = this.props;
    this.sendMessageToChild({
      functionName: 'setMapMode',
      params: [mode],
    });
    this.sendMessageToChild({
      functionName: 'getMapLayerGroup',
      params: [`${mode}-default`],
    });
    // this.sendMessageToChild({
    //   functionName: "getMapLayers",
    //   params: []
    // });
    tabs.map(({id}) => {
      this.sendMessageToChild({
        functionName: 'getMapLayerGroup',
        params: [id],
      });
    });
    this.sendMessageToChild({
      functionName: 'zoomToLoc',
      params: ['city_la'],
    });
    this.sendMessageToChild({
      functionName: 'setDateFilter',
      params: ['collisions', this.state.memorialDateFilter[0], this.state.memorialDateFilter[1]],
    });
    this.sendMessageToChild({
      functionName: 'getFeatureTypes',
      params: ['point'],
    });
    setTimeout(() => {
      this.setState({
        mapPluginReady: true,
      }, () => {
        switch(this.props.page.program.slug){
          case'active-transportation':
            // this.sendMessageToChild({
            //   functionName: 'drawLayer',
            //   params: ['projects', true],
            // });
            this.sendMessageToChild({
              functionName: 'drawProjectsById',
              //params: [[]]  // empty array = draw All projects (i.e. no filter)
              params: [this.props.page.project.filter(item => item.projectId).map(item => item.projectId)],  // Roscoe Blvd, N Broadway
            });
            this.sendMessageToChild({
              functionName: 'drawLayer',
              //params: [[]]  // empty array = draw All projects (i.e. no filter)
              params: ['cms_project_polygons', true],  // Roscoe Blvd, N Broadway
            });
            break;

          case 'people-st':
            this.onToggleLayer('projects_parklet');
            setTimeout(() => {
              this.onToggleLayer('projects_plaza');
            });
            break;
        }
      });
    }, 5000);
  }
  mapmodeOpt(event){
    //console.log("MapProgram -> mapmodeOpt event: ", event);
    //console.log("MapProgram -> mapmodeOpt event.target.value: ", event.target.value);

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.value],
    });
  }
  onToggleLayer(layer_id){
    if(!this.state.layers || !this.state.layers[this.state.currentTab]){
      console.error('tab unavailable');
      return;
    }
    let toggle = !this.state.layers[this.state.currentTab][layer_id];
    this.setState({
      ...this.state,
      layers: {
        ...this.state.layers,
        [this.state.currentTab]: {
          ...this.state.layers[this.state.currentTab],
          [layer_id]: toggle,
        },
      },
    }, () => {
      console.log('onToggleLayer', layer_id, this.state.currentTab);
      if(layer_id === 'projects' || layer_id === 'projects_parklet' || layer_id == 'projects_plaza'){
        let projectIds;
        console.log(this.props.page.project, this.state.layers);

        switch(layer_id){
          case 'projects':
            projectIds = this.props.page.project.filter(item => item.projectId).map(item => item.projectId);
            break;

          case 'projects_parklet':
          case 'projects_plaza':
            if(this.state.layers['people_streets-projects'].projects_parklet || this.state.layers['people_streets-projects'].projects_plaza){
              toggle = true;
            }
            console.log(this.state.layers['people_streets-projects']);
            projectIds = this.props.page.project.filter(item => {
              return item.projectId && item.tags.filter(tag => {
                console.log(tag);
                return (this.state.layers['people_streets-projects'].projects_parklet && tag.title == 'parklet') ||
                       (this.state.layers['people_streets-projects'].projects_plaza && tag.title == 'plaza');
              }).length > 0;
            }).map(item => item.projectId);
            console.log(projectIds);
            break;
        }

        //todo: figure out how to make this not blink
        // if((this.state.layers['people_streets-projects'].projects_parklet && this.state.layers['people_streets-projects'].projects_plaza) ||
        //    (this.state.layers['people_streets-projects'].projects_parklet && layer_id == 'projects_plaza') ||
        //    (this.state.layers['people_streets-projects'].projects_plaza && layer_id == 'projects_parklet')){
        //   this.sendMessageToChild({
        //     functionName: 'drawProjectsById',
        //     //params: [[]]  // empty array = draw All projects (i.e. no filter)
        //     params: [projectIds],  // Roscoe Blvd, N Broadway
        //   });
        // } else
        if(toggle){
          this.sendMessageToChild({
            functionName: 'drawProjectsById',
            //params: [[]]  // empty array = draw All projects (i.e. no filter)
            params: [projectIds],  // Roscoe Blvd, N Broadway
          });
          this.sendMessageToChild({
            functionName: 'drawLayer',
            //params: [[]]  // empty array = draw All projects (i.e. no filter)
            params: ['cms_project_polygons', true],  // Roscoe Blvd, N Broadway
          });
        }
        else{
          this.sendMessageToChild({
            functionName: 'drawLayer',
            //params: [[]]  // empty array = draw All projects (i.e. no filter)
            params: ['cms_project_polygons', false],  // Roscoe Blvd, N Broadway
          });
        }
      }
      else{
        this.sendMessageToChild({
          functionName: 'drawLayer',
          params: [layer_id, toggle],
        });
      }
    });
  }
  onToggleFeatureLayer(layer_id){
    const toggle = !this.state.layers[this.state.currentTab][layer_id];
    this.setState({
      ...this.state,
      layers: {
        ...this.state.layers,
        [this.state.currentTab]: {
          ...this.state.layers[this.state.currentTab],
          [layer_id]: toggle,
        },
      },
    });
    this.sendMessageToChild({
      functionName: 'drawLayerFeature',
      params: ['cms_feature_points', layer_id, toggle],
    });
  }
  programOpt(event){
    //console.log("MapProgram -> programOpt event: ", event);
    //console.log("MapProgram -> programOpt event.target.value: ", event.target.value);

    dcrMappingData.currentProgram = event.target.value;

    //console.log("MapProgram -> programOpt dcrMappingData.currentProgram: ", dcrMappingData.currentProgram);

    this.sendMessageToChild({
      functionName: 'drawProjects',
      params: [
        dcrMappingData.currentProgram,
        dcrMappingData.currentProgramStatus,
      ],
    });
  }
  projectOpt(event){
    this.sendMessageToChild({
      functionName: 'zoomToProject',
      params: [event.target.value],
    });

    // this.sendMessageToChild({
    //   functionName: "drawFeaturesByProject",
    //   params: [event.target.value]
    // });

    this.sendMessageToChild({
      functionName: 'drawFeaturesByProject',
      params: [event.target.value, 'vision_zero'],
    });
  }
  /**
   * This function listens for an event from the browser message event and returns a JSON
   * @param {*} event
   */
  recieveMessageFromChild(event){
    //console.group("MapProgram parent.html > parent.js > recieveMessageFromChild");
    //console.log(event);
    if(event.origin === currentDomain){
      //WARNING this is used to verify the source of the message, aka whitelist
      // Retrieve data sent in postMessage

      //console.log("MapProgram event.origin", event.origin);
      //console.log("MapProgram currentDomain", currentDomain);
      //console.log("MapProgram event.data", event.data);

      if(event.data){
        // console.log("Message received from child:", JSON.parse(event.data));
        //console.groupEnd();

        let data = typeof event.data == 'object' ? event.data : JSON.parse(event.data);
        this.handleMessageFromChild(data);

        return data;
      }
      else{
        //console.warn("Check message payload on event.data", event.data);
      }
    }
    else{
      //console.warn("Error with validating origin: " + event.origin + " !== " + currentDomain);
    }
  }
  selectTab(tabName){
    this.setState({
      ...this.state,
      currentTab: tabName,
      showMemorial: null,
      tabContentClose: false,
    }, () => {
      if(tabName === 'vision_zero-project_features'){
        this.sendMessageToChild({
          functionName: 'drawLayerGroup',
          params: ['street_improvements-default'],
        });
      }
      if(this.props.page.program.slug == 'active-transportation'){
        if(!this.haveSetProjectLayerBefore)
          this.onToggleLayer('projects');
        this.haveSetProjectLayerBefore = true;
      }
    });
  }
  /**
   * This function sents a message from the parent to the child.
   *
   * It can be called by passing a JSON as an example
   *
   * sentMessageToChild({ //payload is being defined at the moment w/ DCR Maps Team.
        mapMode: programs-projects, // can also be program-projects, project-details, feedback, memorial
        program: [vision_zero, active_transporation, ...]
        projects: [data_collection_feedback, plannding_design, construction, ...] , // can also be data_collection_feedback, planning_design, construction, completed, post_project_evaluation, active
        ui_ZoomLevel: "lab-zoom", // defines HTML element showing current zoomlevel,
   * })
   *
   */
  sendMessageToChild(payload){
    //console.group("parent.html > parent.js > sendMessageToChild");
    //console.log(payload);
    if(payload){
      this.iframe.contentWindow.postMessage(
        JSON.stringify(payload),
        currentDomain,
      );
    }

    //console.log("MapProgram -> dcrMappingData: ", dcrMappingData);
    //console.groupEnd();
  }
  /**
   * // TODO: Talk to Roland about strictly defining the params as a JSON Object,
   * that way you can just use this function and pass parameters directly,
   * with a strictly defined JSON Object he would have easier time validating the parameters too
   *
   * Currently this will not work unless his parameters are properly defined.
   *
   * @param {*} event
   */
  sendDirectlyToChild(event){
    //console.group("parent.html > parent.js > sendDirectlyToChild");
    //console.log(event);

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [],
    });
    //console.groupEnd();
  }
  setDateEnd(event){
    dcrMappingData.endDate = event.target.value;

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: ['collisions', dcrMappingData.startDate, dcrMappingData.endDate],
    });
  }
  setDateStart(event){
    dcrMappingData.startDate = event.target.value;

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: ['collisions', dcrMappingData.startDate, dcrMappingData.endDate],
    });
  }
  startEditingOpt(event){
    //console.log("MapProgram -> startEditingOpt event: ", event);
    //console.log("MapProgram -> startEditingOpt event.target.value: ", event.target.value);

    //store selectedLayer for referenceing stopEditingBtn();
    dcrMappingData.selectedEditLayer = event.target.value;

    //console.log("MapProgram -> dcrMappingData: ", dcrMappingData);
    this.sendMessageToChild({
      functionName: 'startEditing',
      params: [event.target.value, {project_id: '0', pif: 'Test'}],
    });
  }
  statusOpt(event){
    //store currentProgramStatus
    dcrMappingData.currentProgramStatus = event.target.value;

    // this.sendMessageToChild({
    //   functionName: "drawProjects",
    //   params: [dcrMappingData.currentProgram, dcrMappingData.currentProgramStatus]
    // });
    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [
        dcrMappingData.currentProgram,
        dcrMappingData.currentProgramStatus,
      ],
    });
  }
  stopEditingBtn(){
    this.sendMessageToChild({
      functionName: 'stopEditing',
      params: [dcrMappingData.selectedEditLayer],
    });
  }
  testBtn(){
    //console.log("MapProgram -> testBtn this.state", this.state);
    //console.log("MapProgram -> testBtn this.props", this.props);
    //console.log("MapProgram -> testBtn this.actions", this.props.actions);

    this.sendMessageToChild({
      functionName: 'test',
      params: [], // no arg
    });
  }
  toggleShareButtons = () => {
    this.setState({
      shareButtons: !this.state.shareButtons,
    });
  };
  updateDimensions(){
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }
  /**
   * This function should recieve a JSON payload in the shape of features that will be rendered
   * on the page. This JSON object shape will need to be defined. At this moment we are using a
   * shallow state replacement on state.collisions
   *
   * Some samples are:
   *
   *  See Batch 2 LADOT-VisionZero-Maps-B-memorial
   *  memorial =  {
   *    livesLostWalking: 34,
   *    livesLostBiking: 12,
   *    livesLostDriving: 32,
   *  }
   *
   *  See Batch 1 Project-Details-B-Features
   *  feature = {
   *    pedestrianRefuge: 2,
   *    speedFeedbackSign: 4,
   *    crosswalkFlashBeacons: 2,
   *    leadignPedestrianIntevals: 14,
   *    protectedLeftTurn: 9,
   *    newTrafficSingal : 3
   *  }
   *

   *
   * @param {*} data
   */
  updateFeatureCount(data){
    //console.group("parent.html > parent.js > updateFeatureCount");
    //console.log("data:", data);

    //console.log("MapProgram before setState this.state", this.state);
    //let dcrmap = {...this.state.dcrmap, collisions : data.count};

    const collisionDetail = this.state.collisionDetail;
    collisionDetail[data.layer] = data.count;

    this.setState({
      collisions: data.count,
      collisionDetail,
    });

    //console.log("MapProgram after setState this.state", this.state);
    //console.log(this.state.collisions);
    //console.groupEnd();
  }
  zoomToOpt(event){
    //console.log("MapProgram -> zoomtoOpt event: ", event);
    //console.log("MapProgram -> zoomtoOpt event.target.value: ", event.target.value);

    //TODO: Since the params array doesn't need to be stored, you can reuse it but it must be cleared first.
    dcrMappingData.params.splice(0, dcrMappingData.params.length); //empties dcrMappingData.params array

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.value],
    });
  }


  renderCloseX = () => {
    return (
      <button aria-label="Clear"
              className="button-input__button tab-content-close-icon"
              type="button" onClick={this.handleTabContentClose}>
        <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
      </button>
    );
  };
  renderFilterControls(){
    if(!this.state.mapOpen) return null;

    return (
      <div className="map-program__tab-menu-buttons col">
        <button className="tg-button tg-button--pill tg-button--black flex-fill"
                onClick={this.filteringApply_onClick}>
          <span className="tg-button__label">Apply</span>
        </button>
        <button className="tg-button tg-button--pill tg-button--white-ghost flex-fill"
                onClick={this.filteringCancel_onClick}>
          <span className="tg-button__label">Cancel</span>
        </button>
      </div>
    );
  }
  renderIntroSection(){
    const buttonDisplayClass = this.state.mapOpen ? 'd-none' : '';

    return (
      <div className="map-program__intro coupling coupling--centered">
        <div className="container container--coupling">
          <div className="map-program__intro-title-container">
            <div>
              <ProgramBanner program={this.props.page.program}/>
            </div>
            <div className="map-program__intro-title">Maps</div>
          </div>
          <div className="row">
            <div className="col-12">
              <MarkdownField className="map-program__intro-description" markup={this.props.page.program.mapsIntroText}/>

              <button className={`map-program__view-maps-button tg-button tg-button--pill tg-button--black ${buttonDisplayClass}`}
                      onClick={this.mapOpen_onClick}>
                <span className="tg-button__label">View Maps</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderMapControls = () => {
    if(!this.state.mapOpen) return null;

    return (
      <>
        <button className="map-program__map-button map-program__map-button--filtering"
                onClick={this.filteringOpen_onClick}>
          Open Filtering
        </button>
        <button className="map-program__map-button map-program__map-button--close"
                onClick={this.mapClose_onClick}>
          Close
        </button>
      </>
    );
  };
  renderMapLoadingIconDesktop = () => {
    if(this.state.mapPluginReady) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon"/>);
  };
  renderMapLoadingIconMobile = () => {
    if(this.state.mapPluginReady || !this.state.mapOpen) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon-mobile"/>);
  };
  renderMapSection(){
    const displayClass = this.state.mapOpen ? 'map-program__map--is-open' : '';

    return (
      <div className={`map-program__map ${displayClass}`}>
        {this.renderTabs()}
        <div className="map-program__map-iframe-container">
          {this.renderMapLoadingIconDesktop()}
          {this.renderMapLoadingIconMobile()}
          <iframe
            className="map-program__map-iframe"
            src={`/maps/child.html?cms_path=${apiValues.CMS_PATH}`}
            ref={event => (this.iframe = event)}
          />
        </div>
        {this.renderMapControls()}
      </div>
    );
  }
  renderTabs(){
    if(!this.props.tabs ||
       !this.state.mapPluginReady) return null;

    let tabs = this.props.tabs.map(({id, name, type}) => {
      return (
        <div key={id}>
          {this.renderTab(id, name, type.icon)}
        </div>
      );
    });
    let openClass = this.state.filtersOpen ? 'map-program__tab-menu--filters-open' : '';
    let selectedClass = this.state.currentTab != '' ? 'map-program__tab-menu--is-open' : '';

    return (
      <div className={`map-program__tab-menu ${openClass} ${selectedClass}`}>
        <div className="map-program__tab-menu-tabs">{tabs}</div>
        {helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) ? null : this.renderTabContent()}
        {this.renderFilterControls()}
      </div>
    );
  }
  renderTab(id, name, icon){
    const isActive = this.state.currentTab === id;
    let className = 'map-program__tab' + (isActive ? ' active' : '');

    return (
      <>
        <div className={className} key={id} onClick={(e) => {
          e.preventDefault();
          this.selectTab(id);
          console.log(id);
        }}>
          <img alt={`${name} icon`} className="map-program__tab-icon" src={icon}/>
          <div className="map-program__tab-title">{name}</div>
        </div>
        {isActive && helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) ? this.renderTabContent() : null}
      </>
    );
  }
  renderTabChild(key){
    const layer = this.state.layers[this.state.currentTab][key];
    let layerTitle = layer_names[key];
    if(key === 'coll_bik_fatal'){
      layerTitle = `Biking`;
    }
    else if(key === 'coll_car_fatal'){
      layerTitle = `Driving`;
    }
    else if(key === 'coll_ped_fatal'){
      layerTitle = `Walking`;
    }
    else if(key === 'memorial'){
      layerTitle = 'Memorials';
    }
    if(key === 'coll_bik_fatal' || key === 'coll_car_fatal' || key === 'coll_ped_fatal' || key === 'memorial'){
      // const spaceArr = [];

      return (
        <div className="checkbox-item-container"
             key={key}>
          <input checked={layer}
                 id={`input-${key}`}
                 onChange={() => this.onToggleLayer(key)}
                 type="checkbox"/>
          <label htmlFor={`input-${key}`}>
            <span className="memorial-map-layer-title">
              <span className="memorial-map-number-count"><b>{`${this.state.collisionDetail[key] || ''}`}</b></span>
              <span> {layerTitle}</span>
            </span>
          </label>
        </div>
      );
    }
    if(!layerTitle) return null;

    return (
      <div className="checkbox-item-container"
           key={key}>
        <input checked={layer}
               id={`input-${key}`}
               onChange={() => this.onToggleLayer(key)}
               type="checkbox"/>
        <label htmlFor={`input-${key}`}>{layerTitle}</label>
      </div>
    );
  }
  renderTabContent(){
    if(this.state.tabContentClose === true) return null;

    if(this.state.showMemorial){
      const memorial = this.props.page.memorial.find(
        memorial => (memorial.caseId ? memorial.caseId.toString() : '') === this.state.showMemorial.toString() && memorial.title.toLowerCase() === this.state.memorialPopUpName.toLowerCase(),
      );
      if(memorial){
        //todo: why is this duplicated below??
        return (
          <div className="map-program__tab-content-container" style={{width: 'calc(100vw - 214px)'}}>
            {this.renderCloseX()}
            <div className="map-program__tab-content">
              <span onClick={this.handleMemorialContent} className="memorial-close-button">
                <img alt='Close' className="button__icon" src={iconArrowRight}/>
              </span>
              {memorial.title && (<div className="map-program__memorial-title">{`${memorial.title}, ${memorial.age || ''}`}</div>)}
              {memorial.eventDate && (<div className="map-program__memorial-event">{`Died on ${moment(memorial.eventDate).format('MMMM D, YYYY')}`}</div>)}
              {memorial.address && (<div className="map-program__memorial-address">{memorial.address}</div>)}
              {memorial.media && (<div className="map-program__memorial-content">
                <img alt={memorial.title}
                     className="map-program__memorial-media-imagery"
                     src={apiValues.imgPath(memorial.media.url)}/>
              </div>)}
              {memorial.description && (<MarkdownField className="map-program__memorial-description" markup={memorial.description}/>)}
              <a className='map-program__memorial-know' href={`mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${this.state.selectedObject.toString()})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`}>Did you know this person?</a>
              <ShareMemorial shareUrl={document.URL}
                             pageTitle={`${this.props.page.program.title} Memorial Map - ${memorial.title || ''}`}/>
            </div>
          </div>
        );
      }
    }

    if(
      !(
        this.state.currentTab &&
        this.state.layers &&
        this.state.layers[this.state.currentTab]
      )
    )
      return null;

    let layerOptions = Object.keys(
      this.state.layers[this.state.currentTab],
    ).map(key => {
      return (
        <div key={key}>
          {this.renderTabChild(key)}
        </div>
      );
    });

    if(this.state.currentTab === 'vision_zero-memorial_map'){
      if(this.state.selectedObject && this.props.page.memorial){
        const memorial = this.props.page.memorial.find(
          memorial => (memorial.caseId ? memorial.caseId.toString() : '') === this.state.selectedObject.toString() && memorial.title.toLowerCase() === this.state.memorialPopUpName.toLowerCase(),
        );
        if(memorial){
          return (
            <div className="map-program__tab-content-container">
              {this.renderCloseX()}
              <div
                className="map-program__tab-content"
              >
                <span onClick={this.handleMemorialContent} className="memorial-close-button">
                  <img alt='Close' className="button__icon" src={iconArrowRight}/>
                </span>
                {memorial.title && (<div className="map-program__memorial-title">{`${memorial.title}, ${memorial.age || ''}`}</div>)}
                {memorial.eventDate && (<div className="map-program__memorial-event">{`Died on ${moment(memorial.eventDate).format('MMMM D, YYYY')}`}</div>)}
                {memorial.address && (<div className="map-program__memorial-address">{memorial.address}</div>)}
                {memorial.media && (<div className="map-program__memorial-content">
                  <img alt={memorial.title}
                       className="map-program__memorial-media-imagery"
                       src={apiValues.imgPath(memorial.media.url)}/>
                </div>)}
                {memorial.description && (<MarkdownField className="map-program__memorial-description" markup={memorial.description}/>)}
                <a className='map-program__memorial-know' href={`mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${this.state.selectedObject.toString()})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`}>Did you know this person?</a>
                <ShareMemorial
                  shareUrl={`https://${window.location.hostname}/programs/${this.props.match.params.slug}/maps?sharememorial=${this.state.selectedObject.toString()}-${this.state.memorialPopUpName}`}
                  pageTitle={`${this.props.page.program.title} Memorial Map - ${memorial.title || ''}`}
                />
              </div>
            </div>
          );
        }
      }

      return (
        <div className="map-program__tab-content-container">
          {this.renderCloseX()}
          <div className="map-program__tab-content">
            <div className="map-program__tab-content-title">
              Memorials and Traffic Fatalities
            </div>
            <div className="map-program__memorial-slider">
              <Range
                min={2010}
                max={2021}
                marks={{
                  2010: 2010,
                  2011: 2011,
                  2012: 2012,
                  2013: 2013,
                  2014: 2014,
                  2015: 2015,
                  2016: 2016,
                  2017: 2017,
                  2018: 2018,
                  2019: 2019,
                  2020: 2020,
                  2021: 2021,
                }}
                onChange={this.changeValue}
                defaultValue={this.state.memorialDateFilter}
                tipFormatter={value => `${value}%`}
                trackStyle={[
                  {
                    backgroundColor: '#ff8563',
                  },
                ]}
                dotStyle={{
                  width: '2px',
                  backgroundColor: 'rgba(0, 0, 0, 0.54)',
                  border: 'none',
                }}
                activeDotStyle={{}}
                handleStyle={[
                  {
                    width: '29px',
                    height: '26px',
                    border: 'none',
                    borderRadius: 'unset',
                    backgroundColor: 'unset',
                    marginTop: '-10px',
                    marginLeft: '-16px',
                    backgroundImage: `url(${iconKnob})`,
                  },
                  {
                    width: '29px',
                    height: '26px',
                    border: 'none',
                    borderRadius: 'unset',
                    backgroundColor: 'unset',
                    marginTop: '-10px',
                    marginLeft: '-16px',
                    backgroundImage: `url(${iconKnob})`,
                  },
                ]}
              />
            </div>
            <div className="map-program__tab-content-description">Every dot on this map represents a person who passed away in a traffic crash—and a person whose family, friends, and community were deeply affected by their tragedy. The City of Los Angeles is committed to working with loved ones who wish to share their story. Email <a href="mailto:visionzero@lacity.org">visionzero@lacity.org</a> to share your loved one&apos;s story and a photo. Please include the location, date, and mode by which your loved one was traveling.</div>
            <div className="map-program__memorial-posts">LIVES LOST</div>
            {layerOptions}
          </div>
        </div>
      );
    }

    if(this.state.currentTab === 'vision_zero-project_features'){
      layerOptions = features_types_name.filter(item => item && item.value && item.count).map(item => {
        const layer = this.state.layers[this.state.currentTab][item.value];
        return (
          <div key={item.value}>
            <div className="checkbox-item-container"
                 key={item.value}>
              <input checked={layer}
                     id={`input-${item.value}`}
                     onChange={() => this.onToggleFeatureLayer(item.value)}
                     type="checkbox"/>
              <label htmlFor={`input-${item.value}`}>{`${item.value} ${item.count}`}</label>
            </div>
          </div>
        );
      });
    }

    layerOptions = layerOptions.filter(item => !!item);
    if(layerOptions.length === 0) return null;

    let mainTitle = `${this.props.page.program.title} Street Improvements`;
    let description;
    if(this.state.currentTab.includes('-projects')){
      switch(this.props.page.program.slug){
        case 'vision-zero':
          mainTitle = 'Vision Zero Projects';
          description = `LADOT has identified 63 Vision Zero street projects (more than 110 miles in total), a subset of LA&apos;s 470 mile High-Injury Network. When the Projects box is checked, click on a project to learn more about it. LADOT also identified 60 High-Injury intersections. Click the Priority Intersections box to view where these intersections are located.`;
          break;
        case 'safe-routes-to-school':
          mainTitle = 'Safe Routes To School Projects';
          description = 'LADOT is building, designing, and planning safety improvements at the Top 50 LAUSD schools with the highest need. Click on a school to learn about the project. Note that some projects are in the planning phase, while others have already been constructed.';
          break;
        case 'people-st':
          mainTitle = 'People St Projects';
          description = 'LADOT teams up with community partners across the city to install plazas, parklets, and more. Click on a project to learn more.';
          break;
        case 'active-transportation':
          mainTitle = 'Active Transportation Projects and Bike Infrastructure';
          description = 'Explore existing bike infrastructure and featured active transportation projects throughout the city. Click on a project to learn more.';
          break;
        case 'great-streets':
          mainTitle = 'Great Streets Projects';
          description = `Click <i>Projects</i> to learn about current projects the LADOT is working on with the Great Streets Initiative. Click <i>Great Streets Initiative</i> to see all &quot;Great Streets&quot; identified by Mayor Garcetti&apos;s <a href="http://lagreatstreets.org/" target="_blank">Great Streets Initiative.</a>`;
          break;
        case 'play-streets':
          mainTitle = 'Play Streets Events';
          description = 'In the Play Streets pilot program, residents apply to temporarily close a neighborhood block to car traffic. The street transforms into a place where neighbors of all ages are free to gather and play in their favorite ways. Click on a pilot project to learn more.';
          break;
        case 'safe-routes-for-seniors':
          mainTitle = 'Safe Routes for Seniors Project Areas';
          description = 'Through an engagement and community planning effort, Safe Routes for Seniors will develop neighborhood plans and route maps that prioritize street and sidewalk improvements along pathways to key destinations identified by senior residents within project areas. Click on a pilot area to learn more.';
          break;
        default:
          break;
      }
    }

    switch(this.state.currentTab){
      case 'active_transportation-neighborhoods_zones':
        mainTitle = 'Neighborhoods, Networks, and Zones';
        description = `The Mobility Plan 2035 is Los Angeles&apos;s road map for the future of our streets. Turn on layers to view and compare planned networks for safe and sustainable transportation throughout the city.`;
        break;
      case 'active_transportation-transit':
        mainTitle = 'Transit';
        break;
      case 'great_streets-transit':
        mainTitle = 'Transit';
        break;
      case 'great_streets-neighborhoods_zones':
        mainTitle = `Neighborhoods, Networks, and Zones`;
        break;
      case 'people_streets-neighborhoods_zones':
        mainTitle = 'Neighborhoods, Networks, and Zones';
        break;
      case 'safe_routes_for_seniors-neighborhoods_zones':
        mainTitle = `${this.props.page.program.title} Neighborhoods, Networks, and Zones`;
        break;
      case 'safe_routes_to_schools-neighborhoods_zones':
        mainTitle = `Neighborhoods, Networks, and Zones`;
        break;
      case 'vision_zero-neighborhoods_zones':
        mainTitle = 'Neighborhoods, Networks, and Zones';
        description = `Use these map layers to find Vision Zero projects and project features in your Council District or Neighborhood Council District, or view the High-Injury Network in full.`;
        break;
      case 'vision_zero-project_features':
        mainTitle = 'Project Features';
        description = `Below you&apos;ll find many of the individual safety treatments that comprise a comprehensive Vision Zero safety project. Usually, Vision Zero projects are made up of a suite of project features that deliver a holistic safety benefit to the community that uses the street. Sometimes we install features on a &quot;spot treatment&quot; basis. Explore project features by clicking features on and off. Features in yellow are proposed and red are installed. Check out our <a href='/content-landing/Vision-Zero-Safety-Toolkit'>Safety Toolkit</a> for a definition of many safety features.`;
        break;
    }

    return (
      <div className="map-program__tab-content-container">
        {this.renderCloseX()}
        <div className="map-program__tab-content">
          <div className="map-program__tab-content-title">{mainTitle}</div>
          <div className="map-program__tab-content-description" dangerouslySetInnerHTML={{__html: description}}/>
          {layerOptions}
        </div>
      </div>
    );
  }
  render(){
    //console.log(`MapProgram.render()`, this.props);
    if(!this.props.tabs ||
       !this.props.page ||
       !this.props.page.program) return null;

    return (
      <div className="map-program">
        {this.renderIntroSection()}
        {this.renderMapSection()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
    mapProgram: state.mapProgram,
  }),
  (dispatch) => ({
    actions: bindActionCreators(mapProgramActions, dispatch),
  }),
)(MapProgram));


MapProgram.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  mapProgram: PropTypes.object,
  match: PropTypes.object,
  mode: PropTypes.string.isRequired,
  page: PropTypes.object,
  tabs: PropTypes.array,
};
