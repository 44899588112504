import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mListShareActions from '../actions/mListShareActions';
import iconEmail from '../img/icons/icon-social-mail.svg';
import iconFacebook from '../img/icons/icon-social-facebook.svg';
import iconTwitter from '../img/icons/icon-social-twitter.svg';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MListShare extends React.Component{
  state = {};


  componentDidMount(){
    //console.log(`MIntro.componentDidMount()`, this.props);
    if(this.props.cmsId){
      this.props.actions.initMListShare(this.props.cmsId);
    }

    this.setState({
      shareUrl: this.props.shareUrl || document.URL,
      shareTitle: document.title.slice(0, document.title.indexOf(apiValues.BROWSER_TITLE_SUFFIX)),
    });
  }


  onFacebookShare = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${this.state.shareUrl}`,
      'Facebook-Popup',
      'height=350, width=600',
    );
  };
  onTwitterShare = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${this.state.shareUrl}&text=${this.state.shareTitle}`,
      'Twitter-Popup',
      'height=350, width=500',
    );
  };


  render(){
    const {className, mListShare} = this.props;
    const emailSubject = this.props.emailShareSubject || `I wanted you to see this site`;
    const emailBody = this.props.emailShareBody || `Check out ${this.state.shareTitle} ${this.state.shareUrl}`;

    return (
      <div className={`m-listshare ${className}`}>
        {mListShare ? mListShare.title : this.props.title || 'Share this page'}:
        <span className="m-listshare__link" onClick={this.onFacebookShare}>
          <img alt='Close' className="m-listshare__link-icon" src={iconFacebook}/>
        </span>
        <span className="m-listshare__link" onClick={this.onTwitterShare}>
          <img alt='Close' className="m-listshare__link-icon" src={iconTwitter}/>
        </span>
        <a className="m-listshare__link" href={`mailto:?subject=${emailSubject}&body=${emailBody}`}>
          <img alt='Close' className="m-listshare__link-icon" src={iconEmail}/>
        </a>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mListShare: helpers.getById(state.mListShare, ownProps.cmsId),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mListShareActions, dispatch),
  }),
)(MListShare));


MListShare.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  emailShareSubject: PropTypes.string,
  emailShareBody: PropTypes.string,
  mIntro: PropTypes.object,
  mListShare: PropTypes.object,
  shareUrl: PropTypes.string,
  title: PropTypes.string,
};
