import NotFoundPage from './components/NotFoundPage';
import PageContent from './components/PageContent';
import PageContentDetail from './components/PageContentDetail';
import PageContentLanding from './components/PageContentLanding';
import PageEventDetails from './components/PageEventDetails';
import PageEvents from './components/PageEvents';
import PageGetInvolved from './components/PageGetInvolved';
import PageGetInvolvedApply from './components/PageGetInvolvedApply';
import PageGetInvolvedFeedback from './components/PageGetInvolvedFeedback';
import PageGetInvolvedThanks from './components/PageGetInvolvedThanks';
import PageGoogleForm from './components/PageGoogleForm';
import PageHome from './components/PageHome';
import PageNews from './components/PageNews';
import PageNewsDetail from './components/PageNewsDetail';
import PageOverallMap from './components/PageOverallMap';
import PagePrograms from './components/PagePrograms';
import PageProgramsMap from './components/PageProgramsMap';
import PageProjectDetail from './components/PageProjectDetail';
import PageProjects from './components/PageProjects';
import PageProjectsThanks from './components/PageProjectsThanks';
import PageSearch from './components/PageSearch';
import PopUp from './components/PopUp';
import PropTypes from 'prop-types';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import Page500 from './components/Page500';
import shareImage from './img/share.png';
import PageProjectsExternal from './components/PageProjectsExternal';

// const staticReload = () => window.location.reload();

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level. When making updates to
// this file you may need to reload the page or restart
// `npm start` service to see changes.

class App extends React.Component{
  // constructor(props){
  //   super(props);
  //   this.googleTranslateElementInit = this.googleTranslateElementInit.bind(this);
  // }
  //
  //
  // componentDidMount(){
  //   var addScript = document.createElement('script');
  //   addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = this.googleTranslateElementInit;
  // }
  //
  //
  // googleTranslateElementInit(){
  //   //alert("test2")
  //   /* eslint-disable no-new */
  //   new window.google.translate.TranslateElement({
  //     pageLanguage: 'en',
  //     layout: window.google.translate.TranslateElement.FloatPosition.TOP_LEFT,
  //   }, 'google_translate_element');
  // }


  render(){
    //The NavLinks & Switch can be created via data from the CMS using a for loop
    console.log(process);

    //images to use somewhere in the site (other images in img folder are dropped)
    shareImage;

    return (
      <>
        <PopUp/>

        <Switch>
          <Route path="/" component={PageHome} exact/>
          <Route path="/content/:slug" component={PageContent}/>
          <Route path="/content-detail/:slug" component={PageContentDetail}/>
          <Route path="/content-landing/:slug" component={PageContentLanding}/>
          <Route path="/events/:slug" component={PageEventDetails}/>
          <Route path="/events" component={PageEvents} exact/>
          <Route path="/get-involved/" component={PageGetInvolved} exact/>
          <Route path="/get-involved/apply/:slug" component={PageGetInvolvedApply}/>
          <Route path="/get-involved/feedback" component={PageGetInvolvedFeedback}/>
          <Route path="/get-involved/thanks" component={PageGetInvolvedThanks}/>
          <Route path="/request-bike-rack" component={PageGoogleForm} exact/>
          <Route path="/news" component={PageNews} exact/>
          <Route path="/news/:slug" component={PageNewsDetail}/>
          <Route path="/overall-map/maps" component={PageOverallMap}/>
          <Route path="/programs/:slug/maps" component={PageProgramsMap}/>
          <Route path="/programs/:slug" component={PagePrograms}/>
          <Route path="/projects" component={PageProjects} exact/>
          <Route path="/projects-external" component={PageProjectsExternal} exact/>
          <Route path="/projects/thanks" component={PageProjectsThanks} exact/>
          <Route path="/projects/:slug" component={PageProjectDetail}/>
          <Route path="/search" component={PageSearch} exact/>
          <Route path="/500" component={Page500} exact/>
          <Route component={NotFoundPage}/>

          {/*<Route path="/maps/child.html" onEnter={staticReload}/>*/}
        </Switch>
      </>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
