import fetch from 'cross-fetch';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';


const loadedEndpoints = {};


export const arraySelectCamelCaseKeys = (items, keys) => {
  return items.map(item => itemSelectCamelCaseKeys(item, keys));
};
export const buildStrapiParams = (filters) => {
  let paramsStr = '';

  Object.keys(filters).forEach(filterKey => {
    let filter = filters[filterKey];
    if(Array.isArray(filter))
      filter.forEach(filterItem => {
        paramsStr += `${paramsStr.length > 0 ? '&' : ''}${filterKey}=${filterItem}`;
      });
    else if(!isNaN(filter))
      paramsStr += `${paramsStr.length > 0 ? '&' : ''}${filterKey}=${filter}`;
    else if(typeof filter != 'undefined' && filter.length > 0)
      paramsStr += `${paramsStr.length > 0 ? '&' : ''}${filterKey}=${filter}`;
  });

  return paramsStr;
};
export const itemSelectCamelCaseKeys = (item, keys) => {
  let nObj = {};

  keys.forEach(key => {
    let nObjKey = key.indexOf(' ') >= 0 ?
                  helpers.toCamelCase(key) : //makes regular keys camelCase
                  helpers.lowercaseFirstLetter(key);//makes relation keys camelCase
    nObj[nObjKey] = item[key];
  });

  return helpers.cleanObj(nObj);
};
export const loadEndpoint = (endpoint, id, data) => {
  //todo: consider using _limit=-1 on all calls that do not already have _limit defined, to return all results when there are more than 100.
  data = {'SoftDelete_ne': true, '_limit': 0, ...data};

  let url = `${apiValues.CMS_PATH}/${endpoint}`;
  let extra = id ? `/${id}` : ``;
  extra += data ? (((url + extra).indexOf('?') >= 0 ? `&` : '?') + `${helpers.encodeData(data)}`) : '';


  //If this has been loaded before, return the result
  if(loadedEndpoints[url + extra]){
    console.log(`${url + extra} was loaded before so we return the result`);
    return new Promise((resolve, reject) => {
      resolve(loadedEndpoints[url + extra]);
    });
  }


  // return fetch(`${apiValues.CMS_PATH}/api/error`)//tests 400
  // return fetch(`${apiValues.CMS_PATH}/pagehomes/5d094b71ddda20a2149a4e4b`)//tests 404
  // return fetch(`${apiValues.CMS_PATH}/pagehomes/5d094b71ddda20a2149a4e4bb`)//tests 500
  return fetch(url + extra)
    .then(response => {
      if(response.ok) return response;
      else{
        window.location = '/500';

        //todo: make this work so we can see the history that caused the redirect to /500; requires implementing catch() on every page & testing, because their actions will fail and throw errors.
        // let history = createBrowserHistory();
        // console.log(history);
        // history.replace('/500');
      }
    })
    .then(helpers.parseJSON)
    // .then(original => {
    //   let results = original;
    //
    //   //auto-array/item case
    //   //todo: we can't apply to child items because the naming isn't conclusive; need to establish naming in nref? Alternatively: just camel case all item keys instead of using nref.keys
    //   if(apiValues.nref[endpoint]){
    //     if(Array.isArray(original)) results = arraySelectCamelCaseKeys(original, apiValues.nref[endpoint].fieldsUsed);
    //     else results = itemSelectCamelCaseKeys(original, apiValues.nref[endpoint]);
    //   }
    //
    //   //Ensure removal of items that are soft deleted
    //   if(Array.isArray(original)){
    //     results = results.filter(result => {
    //       console.log(result.SoftDelete, result.SoftDelete != true);
    //       return result.SoftDelete != true;
    //     });
    //   }
    //   else if(results.SoftDelete) results = null;
    //
    //   console.error(`loadEndpoint`, endpoint, id, data, url, extra, original, results, Array.isArray(results));
    //   return results;
    // })
    .then(results => loadedEndpoints[url + extra] = results)
    .catch(err => console.log('---error---', err.message));
};
export const loadEndpointByFilters = (endpoint, filters) => {
  return loadEndpoint(`${endpoint}?${buildStrapiParams(filters)}`);
};
export const loadNormalizedContent = (typeName, id) => {
  let typeEndpoint = apiValues.nref[typeName].endpoint;

  return loadEndpoint(typeEndpoint, id)
    .then(result => {
      if(apiValues.nref[typeName]){
        let nresult = itemSelectCamelCaseKeys(result, apiValues.nref[typeName].fieldsUsed);

        return nresult;
      }
      else return result;
    });
};
export const loadProjectsByTitle = (id, title) => {
  return loadEndpoint(apiValues.nref.project.endpoint, id, {Title_contains: title})
    .then(results => {
      return {
        suggestedProjects: results ? arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : [],
        viewProjectsNear: results ? arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []
      };
    });
};
export const postToMailChimp = email => {
  return fetch(`https://us17.api.mailchimp.com/3.0/lists/${apiValues.MAILCHIMP_LISTID}/members/`, {
    method: 'post',
    headers: {
      Accept: 'application/json',
      Authorization: `Basic ${Buffer.from(`apikey:${apiValues.MAILCHIMP_API_KEY}`).toString('base64')}`
    },
    json: true,
    body: JSON.stringify({
      email_address: email,
      status: 'subscribed'
    })
  })
    .then((response) => {
      //do something awesome that makes the world a better place
      return response;
    })
    .catch(err => console.log('---error---', err.message));
};
