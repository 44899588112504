import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import iconChevronDown from '../img/icon-chevron-black-down.png';
import iconXBlack from '../img/icon-x-black.png';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import React, { Component } from 'react';


export default class ListFilter extends Component{
  state = {
    startDate: null,
    endDate: null,
  };


  handleChangeStart = event => {
    const {handleStartDate} = this.props;

    handleStartDate(event);
    this.setState({
      startDate: event,
    });
  };
  handleChangeEnd = event => {
    const {handleEndDate} = this.props;

    handleEndDate(event);
    this.setState({
      endDate: event,
    });
  };
  handleClearAll = () => {
    this.props.handleClearFilter();
    this.setState({
      startDate: null,
      endDate: null,
    });
  };


  renderCategories = () => {
    if(!this.props.categories) return null;

    const {categories, handleCategoryFilter, selectedCategory} = this.props;

    return (
      <div className="list-filter-container">
        <div className="list-filter-title">Event Category</div>
        <div className="list-filter-items">
          {categories.map(item => (
            <div className="checkbox-item-container" key={item.id}>
              <input checked={selectedCategory.includes(item.id) ? true : false}
                     id={`input-${item.id}`}
                     onClick={() => handleCategoryFilter(item.id, 'category')}
                     type="checkbox"/>
              <label htmlFor={`input-${item.id}`}>{item.title}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  renderPrograms = () => {
    if(!this.props.programs) return null;

    const {programs, handleCategoryFilter, selectedProgram} = this.props;

    return (
      <div className="list-filter-container">
        <div className="list-filter-title">Programs</div>
        <div className="list-filter-items">
          {programs.map(item => (
            <div className="checkbox-item-container" key={item.id}>
              <input checked={selectedProgram.includes(item.id) ? true : false}
                     id={`input-${item.id}`}
                     onClick={() => handleCategoryFilter(item.id, 'program')}
                     type="checkbox"/>
              <label htmlFor={`input-${item.id}`}>{item.title}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  renderTypes = () => {
    if(!this.props.types) return null;

    const {types, handleCategoryFilter, selectedType} = this.props;

    return (
      <div className="list-filter-container">
        <div className="list-filter-title">Type</div>
        <div className="list-filter-items">
          {types.map(item => (
            <div className="checkbox-item-container" key={item.id}>
              <input checked={selectedType.includes(item.id) ? true : false}
                     id={`input-${item.id}`}
                     onClick={() => handleCategoryFilter(item.id, 'type')}
                     type="checkbox"/>
              <label htmlFor={`input-${item.id}`}>{item.title}</label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  renderListModal = () => {
    const {title, modalOpen, handleModalHide, disableDate} = this.props;

    return (
      <Modal isOpen={modalOpen}
             className="list-modal"
             overlayClassName="list-modal-overlay"
             onRequestClose={handleModalHide}>
        <span onClick={handleModalHide} className="modal-close-button">
          <img alt='Close' className="button__icon" src={iconXBlack}/>
        </span>
        <div className="list-filters-modal">
          <div className="list-filters-title">
            {title}
            <span className="list-filter-clear-link" onClick={this.handleClearAll}>
              Clear All
            </span>
          </div>
          {!disableDate && (
            <div className="list-filters-date-container">
              <DatePicker className="list-filter-date-picker"
                          selected={this.state.startDate}
                          onChange={this.handleChangeStart}/>

              <DatePicker className="list-filter-date-picker"
                          selected={this.state.endDate}
                          startDate={this.state.startDate}
                          onChange={this.handleChangeEnd}/>
            </div>
          )}
          {this.renderCategories()}
          {this.renderPrograms()}
        </div>
        <div className="filter-modal-footer">
          <span className="filter-modal-footer-button" onClick={handleModalHide}>
            Cancel
          </span>
        </div>
      </Modal>
    );
  };
  render(){
    const {title, disableDate} = this.props;
    return (
      <>
        <div className="container container--coupling">
          <div className="list-filters">
            <div className="list-filters-title">
              {title}
              <span className="list-filter-clear-link" onClick={this.handleClearAll}>Clear All</span>
            </div>
            {!disableDate && (
              <div className="list-filters-date-container">
                <div className="lister-filter-date-wrapper">
                  <div>From</div>
                  <DatePicker className="list-filter-date-picker"
                              selected={this.state.startDate}
                              onChange={this.handleChangeStart}/>
                  <img alt='ArrowDown' className="date-button__icon" src={iconChevronDown}/>
                </div>

                <div className="lister-filter-date-wrapper">
                  <div>To</div>
                  <DatePicker className="list-filter-date-picker"
                              selected={this.state.endDate}
                              startDate={this.state.startDate}
                              onChange={this.handleChangeEnd}/>
                  <img alt='ArrowDown' className="date-button__icon" src={iconChevronDown}/>
                </div>
              </div>
            )}
            {this.renderCategories()}
            {this.renderTypes()}
            {this.renderPrograms()}
          </div>
          {this.renderListModal()}
        </div>
      </>
    );
  }
}


ListFilter.propTypes = {
  categories: PropTypes.array,
  disableDate: PropTypes.bool,
  handleCategoryFilter: PropTypes.func,
  handleClearFilter: PropTypes.func,
  handleEndDate: PropTypes.func,
  handleModalHide: PropTypes.func,
  handleStartDate: PropTypes.func,
  list: PropTypes.array,
  modalOpen: PropTypes.bool,
  programs: PropTypes.array,
  selectedCategory: PropTypes.array,
  selectedProgram: PropTypes.array,
  selectedType: PropTypes.array,
  title: PropTypes.string,
  types: PropTypes.array,
};
