import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';

//Action Creators
export const setShowFeatures = (showFeatures) => {
  return {
    type: actionTypes.PAGE_PROJECTS_DETAIL_FEATURE_TOGGLE,
    showFeatures,
  };
};

export const loadPageProjectDetailStart = () => {
  return {
    type: actionTypes.PAGE_PROJECTS_DETAIL_LOAD_START,
  };
};

export const loadPageProjectDetailSuccess = (result) => {
  return {
    type: actionTypes.PAGE_PROJECTS_DETAIL_LOAD_SUCCESS,
    result,
  };
};

export const initPageProjectDetail = (slug) => (dispatch) => {
  dispatch(loadPageProjectDetailStart());
  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Slug: slug})
      .then(results => {
        let project = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.project.fieldsUsed);
        project.projectFeatures = project.projectFeatures ? apiActions.arraySelectCamelCaseKeys(project.projectFeatures, apiValues.nref.projectFeature.fieldsUsed) : [];
        project.projectFeatures.forEach((projectFeature) => {
          projectFeature.projectFeatureLocations = apiActions.arraySelectCamelCaseKeys(
            projectFeature.projectFeatureLocations, apiValues.nref.projectFeatureLocation.fieldsUsed,
          );
        });
        project.projectPartners = project.projectPartners ? apiActions.arraySelectCamelCaseKeys(project.projectPartners, apiValues.nref.projectPartner.fieldsUsed) : [];
        project.program = project.program ? apiActions.itemSelectCamelCaseKeys(project.program, apiValues.nref.program.fieldsUsed) : undefined;
        project.tags = project.tags ? apiActions.arraySelectCamelCaseKeys(project.tags, apiValues.nref.tag.fieldsUsed) : [];
        let page = {
          ...project,
          project: project,
        };

        return page;
      })
      .then(page => {
        return Promise.all([
          apiActions.loadEndpoint(apiValues.nref.event.endpoint, null, {
            RelatedProject_in: page.project.id,
          }),
          apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {
            RelatedProject_in: page.project.id,
          }),
        ]).then(([events, news]) => {
          page.latestEvents = events;
          page.latestNews = news;

          return page;
        });
      })
      .then(results => {
        resolve(dispatch(loadPageProjectDetailSuccess(results)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
