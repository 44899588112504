import * as actionTypes from '../constants/actionType';


//Action Creators
export const addToggle = (id) => {
  return {
    type: actionTypes.MHEADERTAB_ADD,
    id
  };
};
export const tabToggle = (id, isOpen) => {
  return {
    type: actionTypes.MHEADERTAB_TOGGLE,
    id,
    isOpen
  };
};


export const initMHeaderTab = (id, isOpen = false) => dispatch => {
  dispatch(addToggle(id, isOpen));
};


