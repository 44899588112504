import * as apiValues from '../constants/apiValues';
import * as cardActions from '../actions/cardActions';
import * as helpers from '../helpers';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
//import TextTruncate from 'react-text-truncate';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class Card extends React.Component{
  componentDidMount(){
    //console.log(`Card.componentDidMount()`, this.props);

    if(this.props.cmsId) this.props.actions.initCard(this.props.cmsId);
    //else console.log(`passed in `, this.props.mListNav);
  }


  getUrl = () => {
    if(this.props.card.linkUrl && this.props.card.linkUrl.length > 0) return this.props.card.linkUrl;
    else if(this.props.card.linkMedia && this.props.card.linkMedia.url.length > 0) return `${apiValues.CMS_PATH}${this.props.card.linkMedia.url}`;
  };


  renderDescription = () => {
    if(!this.props.card.description) return null;

    // const lines = this.props.isGetInvolved ? (this.getUrl() ? 2 : 4) : (this.getUrl() ? 2 : 5);
    return (
      <div className="list-card__description">
        {/*<TextTruncate line={lines}*/}
        {/*              element="span"*/}
        {/*              truncateText="…"*/}
        {/*              text={this.props.card.description}/>*/}
        {this.props.card.description}
      </div>
    );
  };
  renderGo = () => {
    if(!this.getUrl()) return null;

    return (
      <div className="navigationlink m-listnav__navigationlink navigationlink--blue-outline navigationlink--card">
        <div className="navigationlink__link">
          <div className="navigationlink__title">Go</div>
        </div>
      </div>
    );
  };
  renderImage = () => {
    if(!this.props.card.background) return null;

    let style = {backgroundImage: `url(${apiValues.imgPath(this.props.card.background.url)}`};
    return (<div className="list-card__background" style={style}></div>);
  };
  renderOpensInNewTab = () => {
    if(!this.props.card.opensInNewTab) return null;

    return (<div className="list-card__opens-tab"></div>);
  };
  renderTitle = () => {
    if(!this.props.card.title) return null;

    return (
      <div className="list-card__title">
        {helpers.getHighlightedText(this.props.card.title, this.props.highlight)}
      </div>
    );
  };
  renderByDisplayMode = () => {
    if(this.props.card.displayMode == 'wide') return (
      <>
        <div className="list-card__text">
          {this.renderOpensInNewTab()}
          {this.renderTitle()}
        </div>
        {this.renderImage()}
      </>
    );
    else return (
      <>
        {this.renderImage()}
        <div className="list-card__text">
          {this.renderOpensInNewTab()}
          {this.renderTitle()}
          {this.renderDescription()}
          {this.renderGo()}
        </div>
      </>
    );
  };
  render(){
    //console.log(`Card render`, this.props);
    if(!this.props.card ||
       (!this.props.card.description && !this.props.card.title && !this.props.card.background)) return null;

    let sizeClass = this.props.card.displayMode == 'wide' ? 'list-card--wide' : '';
    let openTarget = this.props.card.opensInNewTab ? `_blank` : '';

    if(this.getUrl())
      return (
        <div className={`list-card ${sizeClass} ${this.props.className || ''}`}>
          <SuperLink to={this.getUrl()} className='list-card__link' target={openTarget}>
            {this.renderByDisplayMode()}
          </SuperLink>
        </div>
      );
    else
      return (
        <div className={`list-card ${sizeClass} ${this.props.className || ''}`}>
          <span className='list-card__link'>
            {this.renderByDisplayMode()}
          </span>
        </div>
      );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {card: helpers.getById(state.card, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: bindActionCreators(cardActions, dispatch),
  }),
)(Card));


Card.propTypes = {
  actions: PropTypes.object,
  card: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  highlight: PropTypes.string,
  isGetInvolved: PropTypes.bool,
};
