import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class MBreadcrumbs extends React.Component{
  renderBreadcrumbs(){
    let crumbElements = [];
    let pageChain = this.props.mBreadcrumbs.pageChain;
    pageChain.forEach((crumb, crumb_i) => {
      let isLast = crumb_i == pageChain.length - 1;
      let isFirst = crumb_i == 0;
      let slashBefore = !isFirst ? ' / ' : '';
      let className = `m-breadcrumbs__crumb ${isLast ? 'm-breadcrumbs__crumb--inactive' : ''}`;

      if(isLast)
        crumbElements.push(
          <span key={`${crumb.browserTitle}-${crumb.slug}`}>
            {slashBefore}
            <span className={className}>{crumb.browserTitle}</span>
          </span>,
        );
      else
        crumbElements.push(
          <span key={`${crumb.browserTitle}-${crumb.slug}`}>
            {slashBefore}
            <SuperLink to={`/${crumb.slug}`} className={className}>{crumb.browserTitle}</SuperLink>
          </span>,
        );
    });

    return crumbElements;
  }
  render(){
    //console.log(`MBreadcrumbs render`, this.props);
    if(!this.props.mBreadcrumbs ||
       !this.props.mBreadcrumbs.pageChain) return null;

    return (
      <div className={`m-breadcrumbs ${this.props.className || ''}`}>
        <div className="container container--coupling">
          <div className="m-breadcrumbs__crumbs">
            {this.renderBreadcrumbs()}
          </div>
        </div>
      </div>
    );
  }
}


MBreadcrumbs.propTypes = {
  className: PropTypes.string,
  mBreadcrumbs: PropTypes.object,
};
