import * as helpers from '../helpers';
import React, {Component} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';


export default class PopUp extends Component{
  constructor(props){
    super(props);

    this.state = {
      modal: false,
    };
  }
  componentDidMount(){
    if(helpers.GetIEVersion() > 0){
      this.setState({
        modal: true,
      });
    }
  }


  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  };


  render(){
    return (
      <Modal isOpen={this.state.modal} toggle={this.toggle}>
        <ModalHeader toggle={this.toggle}>Modal title</ModalHeader>
        <ModalBody>
          This website works best on the latest versions of Chrome, Firefox and Safari.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.toggle}>OK</Button>
        </ModalFooter>
      </Modal>
    );
  }
}
