import * as actionTypes from '../constants/actionType';
import initialState from './initialState';


export default function mBreadcrumbsReducer(state = initialState.mBreadcrumbs, action) {
  switch (action.type) {
    case actionTypes.MBREADCRUMBS_LOAD_START:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.MBREADCRUMBS_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case actionTypes.MBREADCRUMBS_LOAD_SUCCESS: {
      return {
        ...state,
        pageChain: action.pageChain,
        isLoading: false,
        error: undefined
      };
    }

    default:
      return state;
  }
}
