import * as helpers from '../helpers';
import * as mListTagActions from '../actions/mListTagActions';
import PropTypes from 'prop-types';
import React from 'react';
import Tag from './Tag';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MListTag extends React.Component{
  componentDidMount(){
    //console.log(`MListTag.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMListTag(this.props.cmsId);
    }
    else{
      //console.log(`passed in `, this.props.mListTag);
    }
  }


  renderTags(){
    return (
      <div className="m-listtag__list">
        {this.props.mListTag.tags.map(tag => {
          return (
            <Tag className={`m-listtag__tag ${tag.className}`}
                 key={tag.id}
                 tag={tag}
                 highlight={this.props.mListTag.highlight}
                 onClick={tag.onClick}/>
          );
        })}
      </div>
    );
  }
  renderTitle(){
    return <div className="m-listtag__title">Tags:</div>;
  }
  render(){
    //console.log(`MListTag render`, this.state, this.props);
    if(!this.props.mListTag ||
       !this.props.mListTag.tags ||
       this.props.mListTag.tags.length < 1) return null;

    return (
      <div className={`m-listtag ${this.props.className}`}>
        {this.renderTitle()}
        {this.renderTags()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {mListTag: helpers.getById(state.mListTag, ownProps.cmsId)} : {}
  ),
  dispatch => ({
    actions: bindActionCreators(mListTagActions, dispatch),
  }),
)(MListTag));


MListTag.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mListTag: PropTypes.object,
};
