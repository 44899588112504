import * as mLatestNewsActions from '../actions/mLatestNewsActions';
import moment from 'moment';
import NewsItem from './NewsItem';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const PRODUCTS_PER_PAGE = 3;


class MLatestNews extends React.Component{
  state = {
    currentPage: 1,
  };


  componentDidMount(){
    //console.log(`MNews.componentDidMount()`, this.props);
    this.props.actions.initMLatestNews({
      program: this.props.program,
      relatedProgram: this.props.relatedProgram,
      relatedProject: this.props.relatedProject,
      relatedTag: this.props.relatedTag,
    }).then(results => {
      this.props.onLoad ? this.props.onLoad(results) : null;
    });
  }


  renderLink(){
    let url = '/news';
    if(this.props.program) url = `/news?program=${this.props.program.id}`;
    if(this.props.relatedProject && this.props.relatedProject.program) url = `/news?program=${this.props.relatedProject.program._id}`;

    if(this.props.horizontal) return (
      <center>
        <SuperLink to={url} className="m-latest-news__view-all tg-button tg-button--pill tg-button--black">
          <span className="tg-button__label">View All News</span>
        </SuperLink>
      </center>
    );
    else return (
      <div className="navigationlink navigationlink--blue-outline">
        <SuperLink to={url} className="navigationlink__link">
          <div className="navigationlink__title">View All News</div>
        </SuperLink>
      </div>
    );
  }
  renderNews(){
    if(!this.props.mLatestNews.news) return null;

    const news = this.props.mLatestNews.news.sort((a, b) => {
      return moment(b.newsDate).valueOf() - moment(a.newsDate).valueOf();
    }).slice(0, PRODUCTS_PER_PAGE);

    return news.map(event => {
      return (
        <div key={event.id}
             className="col-12 m-latest-news__listing-item-col">
          <NewsItem className="m-latest-news__news-item"
                    disableDate
                    disableDescription={!this.props.horizontal}
                    disableLocation
                    event={event}/>
        </div>
      );
    });
  }
  renderTitle(){
    let titleStr = 'LATEST NEWS';
    if(this.props.title)
      titleStr = this.props.title;
    else if(this.props.program && (this.props.program.Title || this.props.program.title))
      titleStr = `LATEST ${this.props.program.Title || this.props.program.title} NEWS`;

    return (<div className="m-latest-news__title">{titleStr}</div>);
  }
  render(){
    // console.log(`MNews render`, this.props);
    if(!this.props.mLatestNews ||
       !this.props.mLatestNews.news ||
       this.props.mLatestNews.news.length < 1) return null;

    return (
      <div className={`m-latest-news ${this.props.className || ''}`}>
        <div className="container container--coupling">
          {this.renderTitle()}
          <div className="m-latest-news__listing">
            <div className="row">{this.renderNews()}</div>
          </div>
          {this.renderLink()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  state => ({
    mLatestNews: state.mLatestNews,
  }),
  dispatch => ({
    actions: bindActionCreators(mLatestNewsActions, dispatch),
  }),
)(MLatestNews));


MLatestNews.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  mLatestNews: PropTypes.object,
  onLoad: PropTypes.func,
  program: PropTypes.object,
  relatedProgram: PropTypes.object,
  relatedProject: PropTypes.object,
  relatedTag: PropTypes.object,
  title: PropTypes.string,
};
