import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMListNavById} from "../reducers/index";


//Action Creators
export const loadMListNavStart = (id) => {
  return {
    type: actionTypes.MLISTNAV_LOAD_START,
    id,
  };
};
export const loadMListNavFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTNAV_LOAD_FAIL,
    id,
    error,
  };
};
export const loadMListNavSuccess = (data) => {
  return {
    type: actionTypes.MLISTNAV_LOAD_SUCCESS,
    data,
  };
};

export const initMListNav = id => dispatch => {
  dispatch(loadMListNavStart(id));

  return apiActions.loadEndpoint(apiValues.nref.mListNav.endpoint, id).then(result => {
    let mListNav = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListNav.fieldsUsed);
    mListNav.navigationLinks = apiActions.arraySelectCamelCaseKeys(mListNav.navigationLinks, apiValues.nref.navigationLink.fieldsUsed);
    //console.log(`mListNav`, mListNav);
    dispatch(loadMListNavSuccess(mListNav));
    return (mListNav);
  });
};


