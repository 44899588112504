import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function pageProjectDetailsReducer(state = initialState.pageProjectDetails, action) {

  switch (action.type) {
    case actionTypes.PAGE_PROJECTS_DETAIL_FEATURE_TOGGLE:
      return {
        ...state,
        showFeatures: action.showFeatures
      };
    case actionTypes.PAGE_PROJECTS_DETAIL_LOAD_START:
      return {
        isLoading: true
      };
    case actionTypes.PAGE_PROJECTS_DETAIL_LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.result
      }
    default:
      return state;
  }
}
