import * as actionTypes from '../constants/actionType';


//Action Creators
export const loadPageStart = () => {
  return {type: actionTypes.PAGE_LOAD_START};
};
export const loadPageFail = (error) => {
  console.error(error);
  return {type: actionTypes.PAGE_LOAD_FAIL, error};
};
export const loadPageSuccess = (data) => {
  return {type: actionTypes.PAGE_LOAD_SUCCESS, payload: data};
};
