import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageProjectDetailActions from '../actions/pageProjectDetailActions';
import feedBackMaps from '../constants/mockFeedbackMap';
import Helmet from 'react-helmet';
import iconAbout from '../img/icons/about.svg';
import iconAddFeedback from '../img/icons/icon-add-feedback.svg';
import iconAlert from '../img/icons/Alert.svg';
import iconBack from '../img/icons/back-arrow.svg';
import iconCheck from '../img/icons/icon-check-white.svg';
import iconClose from '../img/icons/icon-close.svg';
import iconContactus from '../img/icons/contactus.svg';
import iconCrossPoint from '../img/icons/Cross-Point.svg';
import iconFeatures from '../img/icons/features.svg';
import iconFeedback from '../img/icons/icon-feedback-map.svg';
import iconNews from '../img/icons/news.svg';
import iconPhotos from '../img/icons/photosandvideos.svg';
import iconViewMap from '../img/icons/icon-view-map.svg';
import iconPinInstalled from '../img/icons/pin-installed.svg';
import iconPinPlanned from '../img/icons/pin-planned.svg';
import loadingIcon from '../img/loading-icon-small.gif';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MLatestEvents from './MLatestEvents';
import MLatestNews from './MLatestNews';
import MListMedia from './MListMedia';
import MListTag from './MListTag';
import MProgramBanner from './MProgramBanner';
import PageBase from './PageBase';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import { AvCheckbox, AvCheckboxGroup, AvField, AvForm } from 'availity-reactstrap-validation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import MListShare from './MListShare';
import Select from './Select';


let currentDomain = '';  //validate iframe domain origin with map/child.html & map/dcr_mapping_child.js
if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1'){
  var portNumber = location.port ? ':' + location.port : '';
  currentDomain = window.location.protocol + '//' + window.location.hostname + portNumber;
}
else{
  currentDomain = window.location.protocol + '//' + window.location.hostname; //TODO TEST THIS ON BUILT VANITY URL LOCATION
}
let layer_names = {};
let default_layers = [];


class PageProjectDetail extends React.Component{
  history;
  lastUrl = '';


  constructor(props){
    super(props);

    this.state = {
      cmsFeatureLines: [],
      cmsFeaturePoints: [],
      collisions: 0,
      currentLayers: [],
      currentSubTab: '',
      currentTab: '',
      feedbackAddress: '',
      feedbackLayer: [],
      feedbackStep: 0,
      feedbackSubmitted: false,
      feedBackSuccess: false,
      feedBackWarning: false,
      contactUsFormError: '',
      height: window.innerHeight,
      mapOpen: false,
      mapPluginReady: false,
      selectedFeedbackIssue: {},
      tabContentClose: false,
      width: window.innerWidth,
    };
  }
  componentDidMount(){
    this.bindEvents();
    this.history = createBrowserHistory();
    window.addEventListener('resize', this.updateDimensions);
    //console.log(`PageProjectDetail.componentDidMount()`, this.props);

    this.mapPluginReady = false;
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }
  UNSAFE_componentWillMount(){
    this.fetchContent();
  }
  UNSAFE_componentWillReceiveProps(newProps){
    if(this.props.page.projectId !== newProps.page.projectId && newProps.page.projectId && newProps.page.projectId !== '' && this.mapPluginReady === true)
      if(this.state.currentTab === 'features' || this.state.currentTab === '') this.featureOpt();
      else this.projectOpt();
  }


  addFeedback_onClick = () => {
    this.setState({
      mapOpen: true,
      currentSubTab: 'addFeedback',
      feedbackStep: 0,
      feedbackSubmitted: false,
      waitingToSendAddFeedback: true,
    });
    this.sendMessageToChild({
      functionName: 'addFeedback',
      params: [this.props.page.projectId],
    });
  };
  backButton_onClick = (event) => {
    this.selectTab('');
    event.stopPropagation();
  };
  feedbackFeatureToggle_onClick = () => {
    this.props.actions.setShowFeatures(!this.props.page.showFeatures);
  };
  feedbackIssue_onChange = (value, type) => {
    console.log(`feedbackIssue_onChange`, value, type);
    const {selectedFeedbackIssue} = this.state;
    // selectedFeedbackIssue[type] = value.target.value;
    selectedFeedbackIssue[type] = value;
    this.setState({
      selectedFeedbackIssue,
    });
  };
  mapClose_onClick = () => {
    this.setState({mapOpen: false});
  };
  mapOpen_onClick = () => {
    this.setState({mapOpen: !this.state.mapOpen});
  };


  /**
   * This function binds events from this javascript file to the browser
   */
  bindEvents = () => {
    // Assign handler to message event from the browser window.
    if(window.addEventListener){
      window.addEventListener('message', this.recieveMessageFromChild, false);
    }
    else if(window.attachEvent){ // supports ie8
      window.attachEvent('onmessage', this.recieveMessageFromChild);
    }
  };
  cancelAddFeedback = () => {
    this.setState({
      feedBackWarning: false,
      // currentSubTab: '',
      feedbackStep: 0,
      selectedFeedbackIssue: {},
    });
    this.sendMessageToChild({
      functionName: 'addFeedback',
      params: [this.props.page.projectId],
    });
  };
  canRenderDocuments = () => {
    return this.props.page.projectDocuments && this.props.page.projectDocuments.length > 0;
  };
  canRenderPartners = () => {
    return this.props.page.projectPartners && this.props.page.projectPartners.length > 0;
  };
  closeAddFeedback = () => {
    this.setState({
      feedBackWarning: false,
      currentSubTab: '',
      feedbackStep: 0,
      feedbackSubmitted: false,
      selectedFeedbackIssue: {},
      mapOpen: false,
    });
  };
  displayFeedbackAddressPoint = data => {
    if(!data.isInside){
      this.setState({
        feedbackAddress: data.address,
        feedBackWarning: true,
      });
    }
    else{
      this.setState(
        {
          currentSubTab: 'addFeedback',
          feedbackStep: 1,
          feedbackAddress: data.address,
        },
      );
    }
  };
  /**
   * This function should recieve a JSON payload in the shape of features that will be rendered
   * on the page. This JSON object shape will need to be defined. At this moment we are using a
   * shallow state replacement on state.collisions
   *
   * Some samples are:
   *
   *  See Batch 2 LADOT-VisionZero-Maps-B-memorial
   *  memorial =  {
   *    livesLostWalking: 34,
   *    livesLostBiking: 12,
   *    livesLostDriving: 32,
   *  }
   *
   *  See Batch 1 Project-Details-B-Features
   *  feature = {
   *    pedestrianRefuge: 2,
   *    speedFeedbackSign: 4,
   *    crosswalkFlashBeacons: 2,
   *    leadignPedestrianIntevals: 14,
   *    protectedLeftTurn: 9,
   *    newTrafficSingal : 3
   *  }
   *

   *
   * @param {*} data
   */
  drawFeaturesByProject = data => {
    console.log(`drawFeaturesByProject()`, data);
    if(data.layer === 'feedback'){
      const {feedbackLayer} = this.state;
      feedbackLayer.push({
        name: data.field.replace('_', ' '),
        id: data.field,
        children: data.values.map(item => {
          return {
            name: item.value,
            count: item.count,
          };
        }).filter(item => item.name),
      });
      this.setState({
        feedbackLayer,
      });
    }
    else if(data.layer === 'cms_feature_points'){
      const {values} = data;
      this.setState({
        cmsFeaturePoints: values,
      });
    }
    else if(data.layer === 'cms_feature_lines'){
      const {values} = data;
      this.setState({
        cmsFeatureLines: values,
      });
    }
  };
  drawLayer = event => {
    //console.log("MapProgram -> drawLayer event: ", event);
    //console.log("MapProgram -> drawLayer event.target.value: ", event.target.value);
    //console.log("MapProgram -> drawLayer event.target.name: ", event.target.name);
    //console.log("MapProgram -> drawLayer event.target.getAttribute(map-layer): ", event.target.getAttribute("map-layer"));

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.getAttribute('map-layer'), event.target.value],
    });
  };
  featureOpt = () => {
    if(!this.props.page.projectId) return;
    this.sendMessageToChild({
      functionName: 'zoomToProject',
      params: [this.props.page.projectId],
    });
    this.sendMessageToChild({
      functionName: 'drawFeaturesByProject',
      params: [this.props.page.projectId, 'vision_zero'],
    });
  };
  feedbackSubmit = () => {
    if(!this.isFeedbackValid()) return;
    const {projectId} = this.props.page;
    const params = {};
    params['project_id'] = projectId;
    params[this.state.selectedFeedbackIssue.main] = this.state.selectedFeedbackIssue.secondary;
    if(this.state.selectedFeedbackIssue.commentText && this.state.selectedFeedbackIssue.commentText !== ''){
      params['Comments'] = this.state.selectedFeedbackIssue.commentText;
    }

    this.sendMessageToChild({
      functionName: 'feedbackSubmit',
      params: [params],
    });
  };
  feedbackOpt = () => {
    if(!this.props.page.projectId) return;
    this.sendMessageToChild({
      functionName: 'zoomToProject',
      params: [this.props.page.projectId],
    });
    this.sendMessageToChild({
      functionName: 'drawFeaturesByProject',
      params: [this.props.page.projectId, 'feedback'],
    });
  };
  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageProjectDetail(this.props.match.params.slug.trim()).then(null, () => helpers.handle404(this.props.history));
  }
  handleInvalidSubmit = () => {

  };
  handleMessageFromChild = data => {
    // DCR_Mapping test response
    // =============================

    //console.group("parent.html > parent.js > handleMessageFromChild", data);
    //console.log('data:', data);
    //console.log(typeof data);
    if(data && data.functionName){
      switch(data.functionName){
        case 'mapPluginReady':
          this.mapLoaded();
          break;
        case 'updateFeatureCount':
          this.updateFeatureCount(data);

          break;
        case 'selectedFeature':
          this.updateFeatureTab(data);
          break;
        case 'displayFeedbackAddressPoint':
          this.displayFeedbackAddressPoint(data);

          break;
        case 'feedbackSubmit':
          this.toggleFeedbackSuccess();
          break;
        case 'drawFeaturesByProject':
          this.drawFeaturesByProject(data);
          break;
        case 'getMapLayerGroup':{
          const mode = 'feedback';
          const layers = data.layers.layers;
          const group_id = data.layers.id;
          let layer_data = {};
          layers.forEach((layer) => {
            this.sendMessageToChild({
              functionName: 'getMapLayer',
              params: [layer],  // no arg
            });
            if(group_id == `${mode}-default`){
              default_layers.push(layer);
            }
            layer_data[layer] = default_layers.includes(layer);
          });
          this.setState(
            {
              ...this.state,
              layers: {
                ...this.state.layers,
                [group_id]: layer_data,
              },
            },
          );
        }
          break;
        case 'getMapLayer':{
          const layer = data.layer;
          layer_names[layer.id] = layer.name;
        }
          break;
        case 'test':
          //console.log('testData from Child:', data);

          break;
        default:
        //console.warn("Error with payload data", data);
      }
    }
    //console.groupEnd();
  };
  handleValidSubmit = (event, values) => {
    this.setState({contactUsFormError: ''});
    let body = {
      // to_email: this.props.page.feedbackEmail || 'visionzero.engagement@lacity.org',
      ...values,
      projectId: this.props.page.id,
      responseRequested: values.responseRequested.length > 0,
    };
    // console.log(`handleValidSubmit`, `${apiValues.CMS_PATH}/api/visitorfeedback`, body, helpers.encodeData(body));

    fetch(`${apiValues.CMS_PATH}/api/visitorfeedback`, {
      // fetch(`${apiValues.CMS_PATH}/api/error`, {
      method: 'post',
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      body: helpers.encodeData(body),
    }).then(result => {
      // console.log('received response');
      if(result.status == 200) this.handleValidSubmitSuccess();
      else this.handleValidSubmitFailure(result);
    }, result => this.handleValidSubmitFailure(result));
  };
  handleValidSubmitSuccess = () => {
    // console.log(`handleValidSubmitSuccess`);
    // this.history.push('/get-involved/thanks'); //todo: this wasn't redirecting? fix later
    window.location = '/projects/thanks';
  };
  handleValidSubmitFailure = (result) => {
    console.error(`handleValidSubmitFailure`, result);
    this.setState({contactUsFormError: 'There was a problem submitting your feedback. Please try again later.'});
  };
  isFeedbackMode = () => {
    return this.props.page.projectstatus.Title == 'Data Collection & Feedback';
  };
  isFeedbackValid = () => {
    return this.state.selectedFeedbackIssue.secondary && this.state.selectedFeedbackIssue.secondary != '';
  };
  mapLoaded = () => {
    this.mapPluginReady = true;
    if(this.state.currentTab === 'features' || this.state.currentTab === ''){
      this.featureOpt();
    }
    else{
      this.projectOpt();
    }
    setTimeout(() => this.setState({
      mapPluginReady: true,
    }), 5000);


    if(this.state.waitingToSendAddFeedback){
      this.sendMessageToChild({
        functionName: 'addFeedback',
        params: [this.props.page.projectId],
      });
      this.setState({
        waitingToSendAddFeedback: false,
      });
    }
  };
  projectOpt = () => {
    if(!this.props.page.projectId) return;
    this.sendMessageToChild({
      functionName: 'zoomToProject',
      params: [this.props.page.projectId],
    });
    this.sendMessageToChild({
      functionName: 'drawProjectsById',
      //params: [[]]  // empty array = draw All projects (i.e. no filter)
      params: [[this.props.page.projectId]],  // Roscoe Blvd, N Broadway
    });
  };
  /**
   * This function listens for an event from the browser message event and returns a JSON
   * @param {*} event
   */
  recieveMessageFromChild = event => {
    //console.group("MapProgram parent.html > parent.js > recieveMessageFromChild");
    //console.log(event);

    if(event.origin === currentDomain){ //WARNING this is used to verify the source of the message, aka whitelist
      // Retrieve data sent in postMessage

      //console.log("MapProgram event.origin", event.origin);
      //console.log("MapProgram currentDomain", currentDomain);
      //console.log("MapProgram event.data", event.data);
      if(event.data){
        //console.log("Message received from child:", JSON.parse(event.data));
        //console.groupEnd();
        let data;
        if(typeof event.data === 'string'){
          data = JSON.parse(event.data);
        }
        else{
          data = event.data;
        }
        this.handleMessageFromChild(data);

        return data;
      }
      else{
        //console.warn("Check message payload on event.data", event.data);
      }
    }
    else{
      //console.warn("Error with validating origin: " + event.origin + " !== " + currentDomain);
    }
  };
  restartFeedback = () => {
    this.toggleFeedbackSuccess();
    this.setState({
      currentSubTab: '',
      feedbackStep: 0,
      feedbackSubmitted: true,
      selectedFeedbackIssue: {},
    });
    this.mapClose_onClick();
  };
  selectTab = tabName => {
    console.log(`selectTab`, tabName, this.state.currentTab);

    if(this.state.currentTab !== 'feedback' && tabName === 'feedback') this.feedbackOpt();
    else if(this.state.currentTab !== 'about' && tabName === 'about') this.projectOpt();
    else if(this.state.currentTab !== 'features' && tabName === 'features') this.featureOpt();

    if(this.state.currentTab == 'features'){
      if(tabName == 'features') return;

      if(tabName == '') this.setState({currentTab: tabName});
      else{
        console.log(`selectTab 2`, tabName);
        this.setState({currentSubTab: tabName});
        this.sendMessageToChild({
          functionName: 'highlightFeaturesByType',
          params: ['cms_feature_points', tabName],
        });
      }
    }
    else this.setState({currentTab: tabName});

    // setTimeout(() => {
    //   if(this.state.currentTab != 'feedback') this.closeAddFeedback();
    // });
  };
  /**
   * This function sents a message from the parent to the child.
   *
   * It can be called by passing a JSON as an example
   *
   * sentMessageToChild({ //payload is being defined at the moment w/ DCR Maps Team.
        mapMode: programs-projects, // can also be program-projects, project-details, feedback, memorial
        program: [vision_zero, active_transporation, ...]
        projects: [data_collection_feedback, plannding_design, construction, ...] , // can also be data_collection_feedback, planning_design, construction, completed, post_project_evaluation, active
        ui_ZoomLevel: "lab-zoom", // defines HTML element showing current zoomlevel,
   * })
   *
   */
  sendMessageToChild = payload => {
    //console.group("parent.html > parent.js > sendMessageToChild");
    //console.log(payload);
    if(payload && this.iframe){
      this.iframe.contentWindow.postMessage(JSON.stringify(payload), currentDomain);
    }

    //console.log("MapProgram -> dcrMappingData: ", dcrMappingData);
    //console.groupEnd();
  };
  toggleFeedbackSuccess = () => {
    this.setState({
      feedBackSuccess: !this.state.feedBackSuccess,
    });
  };
  updateDimensions = () => {
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  };
  updateFeatureCount = data => {
    //console.log(`updateFeatureCount()`, data);
    this.setState({collisions: data.count});
  };
  updateFeatureTab = data => {
    console.log(`updateFeatureTab()`, data);
    const {values} = data;

    switch(data.layer){
      case  'cms_feature_points':
        this.setState(
          {
            currentTab: 'features',
            currentSubTab: values.feature_type,
          },
        );
        this.sendMessageToChild({
          functionName: 'highlightFeaturesByType',
          params: ['cms_feature_points', values.feature_type],
        });
        break;

      case 'cms_feature_lines':
        this.setState(
          {
            currentTab: 'features',
            currentSubTab: values.feature_type,
          },
        );
        this.sendMessageToChild({
          functionName: 'highlightFeaturesByType',
          params: ['cms_feature_lines', values.feature_type],
        });
        break;
    }

    // drawLayerFeature("cms_feature_lines", "reset");
  };


  renderBackButton = (showBackButton, isBlack) => {
    if(!showBackButton) return;

    const blackClass = isBlack ? 'page-project-details__tab-back--black' : '';

    return (<img alt="Back"
                 className={`page-project-details__tab-back ${blackClass}`}
                 src={iconBack}/>);
  };
  renderBreadcrumbs = () => {
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Our Projects', slug: 'projects'},
        {browserTitle: this.props.page.title, slug: '#'},
      ],
    };
    //console.log(mBreadcrumbs);

    return (
      <MBreadcrumbs className="coupling coupling--full-centered m-breadcrumbs--project-details"
                    mBreadcrumbs={mBreadcrumbs}/>
    );
  };
  renderCloseX = (cancelsFeedback) => {
    const onClick = cancelsFeedback ? this.cancelAddFeedback : () => {
      this.selectTab('');
    };

    return (
      <button aria-label="Clear"
              className="button-input__button page-project-details__close-icon"
              type="button" onClick={onClick}>
        <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
      </button>
    );
  };
  renderContactUsFormError(){
    if(!this.state.contactUsFormError ||
       this.state.contactUsFormError.length < 1) return null;

    return (<p className="form-error">{this.state.contactUsFormError}</p>);
  }
  renderCouncilDistrictNames = () => {
    if(!this.props.page.councilDistrictNames) return null;

    const pluralStr = this.props.page.councilDistrictNames.split('](').length > 1 ? 's' : '';

    return (
      <div className="page-project-details__line-info">
        <span className="page-project-details__info-label">Council District{pluralStr}: </span>
        <MarkdownField className="d-inline-block page-project-details__info-description" markup={this.props.page.councilDistrictNames}/>
      </div>
    );
  };
  renderProjectStatus = () => {
    if(!this.props.page.projectstatus) return null;

    return (
      <div className="page-project-details__line-info">
        <span className="page-project-details__info-label">Current Phase: </span>
        {this.props.page.projectstatus.Title}
      </div>
    );
  };
  renderDescription = () => {
    if(!this.props.page.description || this.props.page.description.length < 1) return null;

    return (<MarkdownField className="description" markup={this.props.page.description}/>);
  };
  renderDocuments = () => {
    if(!this.canRenderDocuments()) return null;

    return (
      <div className="page-project-details__info">
        <div className="page-project-details__info-title">DOWNLOADS</div>
        <MarkdownField className="superlink" markup={this.props.page.projectDocuments}/>
      </div>
    );
  };
  renderFeatureInfoMobile = () => {
    if(this.state.currentTab != 'features' || !helpers.isScreenSmallerThan(helpers.screenSizes.lgMin)) return null;

    let containerStyles = {};
    const items = this.props.page.projectFeatures.filter((item) => {
      //console.log(`item.cmsName= ${item.cmsName}, this.state.currentSubTab= ${this.state.currentSubTab}`);
      return (item.cmsName == this.state.currentSubTab);
    });
    if(items.length < 1) return null;

    const projectFeature = items[0];
    const imgStyles = {backgroundImage: `url(${apiValues.IMG_PATH}${projectFeature.image && projectFeature.image.url ? projectFeature.image.url : ''})`};
    const locations = projectFeature.projectFeatureLocations.map((projectFeatureLocation) => (
      <div key={projectFeatureLocation.id} className="page-project-details__feature-location__item">
        {projectFeatureLocation.title}
      </div>
    ));

    return (
      <div className="page-project-details__tab-content-container" style={containerStyles}>
        <div className="page-project-details__tab-content" key={this.state.currentTab}>
          <div className="page-project-details__tab-content--inner-scroll">
            <div className="page-project-details__feature-sub-tab" onClick={this.backButton_onClick}>
              {this.renderBackButton(true, true)}
              {projectFeature.image ? (
                <div className="page-project-details__feature-sub-tab-img" style={imgStyles}>
                  <div className="d-3-2"></div>
                </div>
              ) : null}
              <MarkdownField markup={projectFeature.description}/>
              {locations.length > 0 ? (
                <div className="page-project-details__feature-location">
                  <div className="page-project-details__feature-location__title">LOCATIONS</div>
                  {locations}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderFeatureList = () => {
    console.log(`renderFeatureList()`, this.state.feedbackLayer);
    // if(!this.props.page.showFeatures) return;
    const {feedbackLayer} = this.state;
    let showClass = this.props.page.showFeatures ? 'page-project-details__feedback-tab-features--is-visible' : '';

    return (
      <div className={`page-project-details__feedback-tab-features ${showClass}`}>
        {
          feedbackLayer.map(category => {
            return (
              <div key={category.id} className="page-project-details__feedback-tab-feature">
                <div className="page-project-details__feedback-tab-feature-title">
                  <span>{category.name}</span>
                </div>
                {category.children.map(child => {
                  return (
                    <div key={child.name} className="page-project-details__feedback-tab-feature-item">
                      <div className="page-project-details__feedback-tab-feature-count">
                        <span>{child.count}</span>
                      </div>
                      <div className="page-project-details__feedback-tab-feature-name">
                        <span>{child.name}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            );
          })
        }
      </div>
    );
  };
  renderFeatureToggle = () => {
    let toggleText = this.props.page.showFeatures ? '< Hide' : 'Show';
    let endArrow = this.props.page.showFeatures ? '' : '>';

    return (<div className="page-project-details__feature-toggle"
                 onClick={this.feedbackFeatureToggle_onClick}><span>{toggleText} Identified Issues {endArrow}</span></div>);
  };
  renderFeedbackBar = () => {
    //console.log(this.state.currentTab, this.state.currentSubTab, this.state.feedbackStep);
    if(this.state.currentTab != 'feedback') return null;
    // if(!this.state.currentTab == 'feedback' || this.state.currentSubTab != 'addFeedback') return null;

    if(this.state.currentSubTab != 'addFeedback'){
      return (
        <div className="page-project-details__feedback-bar">
          <div className="page-project-details__feedback-bar-description">
            <div className="page-project-details__feedback-bar-description-title">Want to add a feedback?</div>
          </div>
          <div className="page-project-details__feedback-arrow"></div>
          <button className="page-project-details__feedback-button-add"
                  onClick={this.addFeedback_onClick}>
            <img alt="Add" src={iconAddFeedback}/>
          </button>
        </div>
      );
    }

    switch(this.state.feedbackStep){
      default:{
        return (
          <div className="page-project-details__feedback-bar">
            <div className="page-project-details__feedback-bar-description">
              <div className="page-project-details__feedback-bar-description-title">Drop the add pin on the map</div>
              <div className="page-project-details__feedback-bar-description-subtitle">You can pan and zoom in to find where on the map the issue is</div>
            </div>
            <button className="page-project-details__feedback-button-cancel tg-button tg-button--pill tg-button--yellow"
                    onClick={this.closeAddFeedback}>
              <span className="tg-button__label">Cancel</span>
            </button>
          </div>
        );
      }

      case 1:{
        return null;
      }
    }
  };
  renderFeedbackFollowup = (isMobile) => {
    if(this.state.currentSubTab != 'addFeedback' || this.state.feedbackStep == 0) return null;

    let mobileClass = isMobile ? 'page-project-details__feedback-followup-mobile' : '';

    return (
      <div className={`page-project-details__feedback-tab ${mobileClass}`}>
        <div className="page-project-details__feedback-tab-intro">
          Your feedback is located on
        </div>
        <p className="page-project-details__feedback-tab-description">
          {this.state.feedbackAddress}
        </p>
        <p className="page-project-details__feedback-tab-description">
          Select the issue from the drop-down or select other. You can add comments as well
        </p>
        {this.renderFeedbackIssues()}
        <button className="page-project-details__feedback-send tg-button tg-button--pill tg-button--black"
                onClick={this.feedbackSubmit}
                disabled={!this.isFeedbackValid()}>
          <span className="tg-button__label">Send</span>
        </button>
        <button className="tg-button tg-button--pill tg-button--white-ghost" onClick={this.cancelAddFeedback}>
          <span className="tg-button__label">Cancel</span>
        </button>
        {this.renderFeedbackSuccessModal()}
      </div>
    );
  };
  renderFeedbackIssues = () => {
    const {selectedFeedbackIssue} = this.state;
    const {main, secondary, comment, commentText} = selectedFeedbackIssue;

    const mainOptions = [
      {
        title: 'Select issue',
        value: '',
      },
      {
        title: 'Safety',
        value: 'Safety',
      },
      {
        title: 'Signs',
        value: 'Signs',
      },
      {
        title: 'Transit',
        value: 'Transit',
      },
      {
        title: 'Maintenance',
        value: 'Maintenance',
      },
      {
        title: 'Street Enhancements',
        value: 'Street_Enhancements',
      },
    ];

    const safetyOptions = [{title: 'Select Safety', value: ''}].concat(feedBackMaps[0].children.map(item => {
      return {
        title: item.name,
        value: item.name.replace(/ /g, '-'),
      };
    }));
    const signsOptions = [{title: 'Select Signs', value: ''}].concat(feedBackMaps[1].children.map(item => {
      return {
        title: item.name,
        value: item.name.replace(/ /g, '-'),
      };
    }));
    const transitOptions = [{title: 'Select Transit', value: ''}].concat(feedBackMaps[2].children.map(item => {
      return {
        title: item.name,
        value: item.name.replace(/ /g, '-'),
      };
    }));
    const maintenanceOptions = [{title: 'Select Maintenance', value: ''}].concat(feedBackMaps[3].children.map(item => {
      return {
        title: item.name,
        value: item.name.replace(/ /g, '-'),
      };
    }));
    const enhancementOptions = [{
      title: 'Select Street Enhancement',
      value: '',
    }].concat(feedBackMaps[4].children.map(item => {
      return {
        title: item.name,
        value: item.name.replace(/ /g, '-'),
      };
    }));

    let secondaryOptions = [];
    if(main && main !== ''){
      if(main === 'Safety'){
        secondaryOptions = safetyOptions;
      }
      else if(main === 'Signs'){
        secondaryOptions = signsOptions;
      }
      else if(main === 'Transit'){
        secondaryOptions = transitOptions;
      }
      else if(main === 'Maintenance'){
        secondaryOptions = maintenanceOptions;
      }
      else{
        secondaryOptions = enhancementOptions;
      }
    }

    return (
      <div className="page-project-details__feedback-step-select-container">
        <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
          <div className="select-container">
            <label htmlFor="feedbackSelect1">What is the issue at this location?</label>
            <Select defaultValue={main}
                    id="feedbackSelect1"
                    onSelectedChange={event => this.feedbackIssue_onChange(event, 'main')}
                    options={mainOptions}
                    value={main}/>
          </div>
          {main && (
            <div className="select-container">
              <label htmlFor="feedbackSelect2">Select details</label>
              <Select defaultValue={secondary}
                      id="feedbackSelect2"
                      onSelectedChange={event => this.feedbackIssue_onChange(event, 'secondary')}
                      options={secondaryOptions}
                      value={secondary}/>
            </div>
          )}
        </AvForm>
        {!comment && (
          <span className="page-project-details__feedback-tab-add-comment"
                onClick={() => this.setState({
                  selectedFeedbackIssue: {
                    ...this.state.selectedFeedbackIssue,
                    comment: true,
                  },
                })}>Add Comment</span>
        )}
        {comment && (
          <textarea className="page-project-details__feedback-tab-add-comment-textarea"
                    value={commentText}
                    onChange={(e) => this.setState({
                      selectedFeedbackIssue: {
                        ...this.state.selectedFeedbackIssue,
                        commentText: e.target.value,
                      },
                    })}/>
        )}
      </div>
    );
  };
  renderFeedbackMobileBar = () => {
    if(this.state.currentSubTab != 'addFeedback' || this.state.feedbackStep != 0) return null;

    return (
      <div className="page-project-details__mobile-feedback">
        <div className="page-project-details__mobile-feedback-description">Press and Hold on the location where you want to report an issue</div>
        <div className="page-project-details__mobile-feedback-cancel" onClick={this.closeAddFeedback}>
          <span className="tg-button tg-button--pill tg-button--yellow">
            <span className="tg-button__label">Cancel</span>
          </span>
        </div>
      </div>
    );
  };
  renderFeedbackMobileClose = () => {
    if(this.isFeedbackMode() && (this.state.feedbackStep != 0 || !this.state.feedbackSubmitted)) return null;

    return (
      <button className="page-project-details__map-button page-project-details__map-button--close"
              onClick={this.mapClose_onClick}>Close</button>
    );
  };
  renderFeedbackSuccessModal = () => {
    return (
      <Modal isOpen={this.state.feedBackSuccess}
             toggle={this.toggleFeedbackSuccess}
             className="tg-modal tg-modal--thanks"
             contentClassName="tg-modal__content">
        <ModalHeader className="tg-modal__header">
          <center>
            <img alt="Checkmark" className="tg-modal__img-check" src={iconCheck}/>
            <div className="tg-modal__header-text">Thank you for letting us know about this issue.</div>
          </center>
        </ModalHeader>
        <ModalFooter className="tg-modal__footer">
          <button className="tg-button tg-button--pill tg-button--black" onClick={this.restartFeedback}>
            <span className="tg-button__label">Close Map</span>
          </button>
        </ModalFooter>
      </Modal>
    );
  };
  renderIntroSection = () => {
    return (
      <div className="page-project-details__intro coupling coupling--centered">
        <div className="container container--coupling">
          <div className="page-project-details__intro-title-container">
            <div>
              <ProgramBanner program={this.props.page.program}/>
            </div>
            <div className="page-project-details__intro-title">
              {this.props.page.title}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              {this.renderWhatDescription()}
            </div>
            <div className="col-md-6">
              {this.renderWhyDescription()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  renderLegend = () => {
    if(this.state.currentTab !== 'features') return false;

    //note: this must be span so we can use last-of-type to eliminate the border from the last tab item (tabs use div)
    return (
      <span className="page-project-details__legend">
        <div className="page-project-details__legend-item">
          <img src={iconPinInstalled} alt="Installed Icon" className="page-project-details__legend-icon"/>
          <div className="page-project-details__legend-title">Installed</div>
        </div>
        <div className="page-project-details__legend-item">
          <img src={iconPinPlanned} alt="Planned Icon" className="page-project-details__legend-icon"/>
          <div className="page-project-details__legend-title">Planned</div>
        </div>
      </span>
    );
  };
  renderLocationDescription = () => {
    if(!this.props.page.locationDescription || this.props.page.locationDescription.length < 1) return null;

    return (
      <div className="page-project-details__line-info">
        <span className="page-project-details__info-label">Location: </span>
        {this.props.page.locationDescription}
      </div>
    );
  };
  renderMapLoadingIconDesktop = () => {
    if(this.state.mapPluginReady) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon"/>);
  };
  renderMapLoadingIconMobile = () => {
    if(this.state.mapPluginReady || !this.state.mapOpen) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon-mobile"/>);
  };
  renderMapSection = () => {
    const displayClass = this.state.mapOpen ? 'page-project-details__map--is-open' : '';

    return (
      <div className={`page-project-details__map ${displayClass}`}>
        {this.renderTabs()}
        <div className="page-project-details__map-iframe-container">
          {this.renderFeedbackBar()}
          {this.renderFeedbackMobileClose()}
          {this.renderFeedbackFollowup(true)}
          {this.renderMapLoadingIconDesktop()}
          {this.renderMapLoadingIconMobile()}
          <iframe className="page-project-details__map-iframe"
                  src={`/maps/child.html?hidePopup=${!this.isFeedbackMode()}&cms_path=${apiValues.CMS_PATH}&centroid_level=300000`}
            // src={`/maps/child.html?cms_path=https://ladot-dev-cms.getsomeglue.com&centroid_level=1000`}
                  ref={(event) => this.iframe = event}/>
          {this.renderFeedbackMobileBar()}
          {this.renderFeatureInfoMobile()}
        </div>
      </div>
    );
  };
  renderNeighborhoodCouncilName = () => {
    if(!this.props.page.neighborhoodCouncilName) return null;

    return (
      <div className="page-project-details__line-info">
        <span className="page-project-details__info-label">Neighborhood Council: </span>
        <span className="page-project-details__info-description">{this.props.page.neighborhoodCouncilName}</span>
      </div>
    );
  };
  renderPartners = () => {
    if(!this.canRenderPartners()) return null;

    return (
      <div className="page-project-details__info">
        <div className="page-project-details__info-title">PARTNERS</div>
        {this.props.page.projectPartners.map(({title, linkUrl, id}) => {
          return (
            <div className="page-project-details__link-item" key={id}>
              <SuperLink to={linkUrl}>{title}</SuperLink>
            </div>
          );
        })}
      </div>
    );
  };
  renderTabContent = () => {
    let tabContent = null;
    if(!this.state.currentTab) return null;
    if(this.state.currentTab === 'features' && this.state.currentSubTab === '') return null;
    let containerStyles = {};

    if(this.state.currentTab == 'features' && this.state.currentSubTab){
      const items = this.props.page.projectFeatures.filter((item) => {
        //console.log(`item.cmsName= ${item.cmsName}, this.state.currentSubTab= ${this.state.currentSubTab}`);
        return (item.cmsName == this.state.currentSubTab);
      });
      if(items){ //todo: filter that returns 0 items still returns an array, so i think this would always evaluate to true and can be removed?
        const projectFeature = items[0];
        //console.log('---features', projectFeature);
        if(projectFeature){
          // console.log(projectFeature.projectFeatureLocations.length);
          const imgStyles = {
            backgroundImage: `url(${apiValues.IMG_PATH}${projectFeature.image && projectFeature.image.url ? projectFeature.image.url : ''})`,
          };
          const locations = projectFeature.projectFeatureLocations.map((projectFeatureLocation) => (
            <div key={projectFeatureLocation.id} className="page-project-details__feature-location__item">
              {projectFeatureLocation.title}
            </div>
          ));
          tabContent = (
            <div className="page-project-details__feature-sub-tab">
              {projectFeature.image &&
               <div className="page-project-details__feature-sub-tab-img" style={imgStyles}>
                 <div className="d-3-2"></div>
               </div>}
              <MarkdownField markup={projectFeature.description}/>
              {projectFeature.projectFeatureLocations.length > 0 ? (
                <div className="page-project-details__feature-location">
                  <div className="page-project-details__feature-location__title">LOCATIONS</div>
                  {locations}
                </div>
              ) : null}
            </div>
          );
        }
      }
    }
    if(this.state.currentTab == 'feedback'){
      if(this.state.currentSubTab === 'addFeedback'){
        if(this.state.feedbackStep === 0){
          containerStyles = {
            padding: 0,
            width: 0,
            overflow: 'visible',
          };
          tabContent = (
            <div className="page-project-details__feedback-tab">
              <div className="page-project-details__feedback-tab-intro"/>
              <p className="page-project-details__feedback-tab-description"/>
              <div className="page-project-details__feature-toggle"/>
            </div>
          );
        }
        else{
          tabContent = this.renderFeedbackFollowup();
        }
      }
      else{
        containerStyles = {
          overflow: 'visible',
        };
        tabContent = (
          <div className="page-project-details__feedback-tab">
            <div className="page-project-details__feedback-tab-intro">
              Tell Us About This Area
            </div>
            <p className="page-project-details__feedback-tab-description">
              People who live, work and play in the neighborhood know it best.
              Help us plan the best improvements for this corridor by identifying issues you encounter.
            </p>
            {this.renderFeatureToggle()}
            {this.renderFeatureList()}
            {this.renderWantToAddFeedbackMobile()}
          </div>
        );
      }
    }
    if(this.state.currentTab == 'about'){
      tabContent = (
        <div className="page-project-details__tab-about">
          <MProgramBanner className="m-programbanner--project-details" page={this.props.page}/>
          {this.renderLocationDescription()}
          {this.renderProjectStatus()}
          {this.renderDescription()}
          {this.renderTags()}
          {this.renderNeighborhoodCouncilName()}
          {this.renderCouncilDistrictNames()}
          {this.canRenderDocuments() || this.canRenderPartners() ? (<hr className="hr--separator hr--separator-project-details"/>) : null}
          {this.renderDocuments()}
          {this.renderPartners()}
          <MListShare title="Share this project"
                      emailShareBody={`LADOT Livable Streets%0D%0A%0D%0A${this.props.page.project.whatDescription}%0D%0A%0D%0A${document.URL}`}
                      emailShareSubject={`LADOT Livable Streets: ${this.props.page.project.title}`}/>
        </div>
      );
    }
    if(this.state.currentTab == 'news-events'){
      tabContent = (
        <div className="page-project-details__news-events-tab">
          <MLatestNews relatedProject={this.props.page.project} title="NEWS"/>
          <MLatestEvents relatedProject={this.props.page.project} title="UPCOMING MEETING & EVENTS"/>
        </div>
      );
    }
    if(this.state.currentTab == 'photos-videos'){
      const mListMedia = {
        mediaItems: this.props.page.photosAndVideos,
        id: this.props.page.id,
      };
      tabContent = (
        <div className="news-events-tab">
          <MListMedia colClass='col-12 col-sm-12 col-md-6' mListMedia={mListMedia}/>
        </div>
      );
    }
    if(this.state.currentTab == 'contact-us'){
      tabContent = (
        <div className="page-project-details__contact-us-tab">
          <div className="page-project-details__contact-us-title">Share Your Feedback</div>
          <AvForm onValidSubmit={this.handleValidSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
            <AvField name="nameFirst" label="First Name" type="text" required/>
            <AvField name="nameLast" label="Last Name" type="text" required/>
            <AvField name="email" label="Email Address" type="email" required/>
            <AvField name="comments" label="Your Comments" type="textarea" required/>
            <AvCheckboxGroup inline name="responseRequested">
              <AvCheckbox label="I'd like an LADOT official to respond to me" value="Join"/>
            </AvCheckboxGroup>
            {this.renderContactUsFormError()}
            <button className="page-project-details__contact-submit tg-button tg-button--pill tg-button--black">
              <span className="tg-button__label">Send</span>
            </button>
          </AvForm>
          <div className="page-project-details__contact-information-title">Contact Information</div>
          <MarkdownField className="page-project-details__contact-information-description" markup={this.props.page.contactDescription}/>
        </div>
      );
    }

    return (
      <div className="page-project-details__tab-content-container" style={containerStyles}>
        {this.renderCloseX()}
        <div className="page-project-details__tab-content" key={this.state.currentTab}>
          <div className="page-project-details__tab-content--inner-scroll">
            {tabContent}
          </div>
        </div>
      </div>
    );
  };
  renderTab = (id, name, isParentTab) => {
    //console.log(id);
    const isActive = this.state.currentTab === id;
    const isActiveClass = isActive ? 'active' : '';
    const isSubActive = this.state.currentSubTab === id;
    const isSubActiveClass = isSubActive ? ' sub-active' : '';
    const isShown = this.state.currentTab.length < 1 || this.state.currentTab == id || !isParentTab;
    const isShownClass = isShown ? '' : 'page-project-details__tab--hide-mobile';
    const isSubClass = !isActive && this.state.currentTab == 'features' ? 'page-project-details__tab--subtab' : '';
    let iconSrc;
    // let secondIconSrc = null;
    let showBackDesktopClass = '';

    switch(name){
      case 'Feedback Map':
        iconSrc = iconFeedback;
        break;
      case 'Features':
        iconSrc = iconFeatures;
        showBackDesktopClass = isActive ? 'page-project-details__tab--show-back-desktop' : '';
        break;
      case 'About':
        iconSrc = iconAbout;
        break;
      case 'News and Events':
        if((!this.props.page.latestEvents || this.props.page.latestEvents.length < 1) && (!this.props.page.latestNews || this.props.page.latestNews.length < 1)) return null;
        iconSrc = iconNews;
        break;
      case 'Photos and Videos':
        if(!this.props.page.photosAndVideos || this.props.page.photosAndVideos.length < 1) return null;
        iconSrc = iconPhotos;
        break;
      case 'Contact Us':
        iconSrc = iconContactus;
        break;
    }

    let className = `page-project-details__tab ${isSubClass} ${isActiveClass} ${isSubActiveClass} ${isShownClass} ${showBackDesktopClass}`;

    return (
      <>
        <div className={className}
             key={id}
             onClick={() => {
               if(isActive) this.backButton_onClick();
               // else if(!isActive && this.state.currentTab.length > 1) this.setState({currentSubTab: ''});
               else if(isSubActive) this.setState({currentSubTab: ''});
               else this.selectTab(id);
             }}>
          {this.renderBackButton(isActive)}
          <div className="page-project-details__tab-title-container">
            {iconSrc && <img alt="Tab icon" className="page-project-details__tab-icon" src={iconSrc}/>}
            <span className="page-project-details__tab-title">{name}</span>
          </div>
        </div>
        {helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) &&
         ((this.state.currentTab.length < 1 && isParentTab) || //nothing selected and this is a main tab
          ((isActive || isSubActive) && !isParentTab) || //subtab that is selected
          (isActive && isParentTab && this.state.currentSubTab.length < 1) //main tab that is selected without child selected
         ) ? this.renderTabContent() : null}
      </>
    );
  };
  renderTabs = () => {
    if(!this.props.page.projectstatus) return null;

    let tabs = null;
    if(this.isFeedbackMode()){
      const confTabs = [
        {
          name: 'About',
          id: 'about',
        },
        {
          name: 'Feedback Map',
          id: 'feedback',
        },
        {
          name: 'News and Events',
          id: 'news-events',
        },
        {
          name: 'Photos and Videos',
          id: 'photos-videos',
        },
        {
          name: 'Contact Us',
          id: 'contact-us',
        },
      ];
      tabs = confTabs.map(({id, name}) => {
        return this.renderTab(id, name, true);
      });
    }
    else if(this.state.currentTab != 'features'){
      const confTabs = [
        {
          name: 'Features',
          id: 'features',
        },
        {
          name: 'About',
          id: 'about',
        },
        {
          name: 'News and Events',
          id: 'news-events',
        },
        {
          name: 'Photos and Videos',
          id: 'photos-videos',
        },
        {
          name: 'Contact Us',
          id: 'contact-us',
        },
      ];
      tabs = confTabs.map(({id, name}) => {
        return this.renderTab(id, name, true);
      });
    }
    else{
      const confTabs = [
        {
          name: 'Features',
          id: 'features',
          isParent: true,
        },
      ];
      this.state.cmsFeaturePoints.forEach((feature) => {
        confTabs.push(
          {
            name: `${feature.count} ${feature.value}`,
            id: feature.value,
          },
        );
      });
      this.state.cmsFeatureLines.forEach((feature) => {
        confTabs.push(
          {
            name: `${feature.count} ${feature.value}`,
            id: feature.value,
          },
        );
      });
      tabs = confTabs.map(({id, name, isParent}) => {
        return this.renderTab(id, name, isParent);
      });
    }
    let filtersClass = this.state.filtersOpen ? 'page-project-details__tab-menu--filters-open' : '';
    let mapReadyClass = this.state.mapPluginReady ? 'page-project-details__tab-menu--map-ready' : '';
    let selectedClass = '';
    if(this.state.currentTab != '' &&
       (this.state.currentSubTab != 'addFeedback' || this.state.feedbackStep == 1) &&
       !(this.state.currentTab == 'features' && this.state.currentSubTab == ''))
      selectedClass = 'page-project-details__tab-menu--is-open';

    return (
      <div className={`page-project-details__tab-menu ${filtersClass} ${selectedClass} ${mapReadyClass}`}>
        <div className="page-project-details__tab-menu-tabs">
          {this.renderViewMapButton()}
          {tabs}
          {this.renderLegend()}
        </div>
        {helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) ? null : this.renderTabContent()}
      </div>
    );
  };
  renderTags = () => {
    if(this.props.page.tags.length < 1) return null;

    let tags = this.props.page.tags.map((tag) => {
      return {
        id: tag.id,
        title: tag.title,
      };
    });
    return (
      <MListTag mListTag={{tags: tags}} className="page-project-details__tags"/>
    );
  };
  renderViewMapButton = () => {
    if(this.isFeedbackMode()) return;

    return (
      <button aria-label="View Map"
              className="page-project-details__view-map"
              type="submit"
              onClick={this.mapOpen_onClick}>
        <img alt="View Map" className="page-project-details__map-icon" src={iconViewMap}/>
        <span>View Map</span>
      </button>
    );
  };
  renderWantToAddFeedbackMobile = () => {
    return (
      <div className="page-project-details__feedback-bar-mobile">
        Want to add a feedback?
        <button className="page-project-details__feedback-button-add"
                onClick={this.addFeedback_onClick}>
          <img alt="Add Feedback button" src={iconAddFeedback}/>
        </button>
      </div>
    );
  };
  renderWarningModal = () => {
    return (
      <Modal isOpen={this.state.feedBackWarning}
             toggle={this.cancelAddFeedback}
             className="tg-modal tg-modal--warning"
             contentClassName="tg-modal__content">
        <ModalHeader className="tg-modal__header">
          <img alt="Alert" className="tg-modal__img-alert" src={iconAlert}/>
          {this.renderCloseX()}
          <div className="tg-modal__header-text">You are trying to leave a feedback outside of this project boundries</div>
        </ModalHeader>
        <ModalBody className="tg-modal__body">
          <div className="tg-modal__body-text">Leave feedback within the project boundaries. Or go to <a className="project-detail-warning-link" href="http://myladot.lacity.org" rel="noopener noreferrer" target="_blank">Myladot</a> to create a service request.</div>
        </ModalBody>
      </Modal>
    );
  };
  renderWhatDescription = () => {
    return (
      <>
        <p className="page-project-details__intro__subtitle">
          WHAT
        </p>
        <MarkdownField className="page-project-details__intro-description" markup={this.props.page.whatDescription}/>
      </>
    );
  };
  renderWhyDescription = () => {
    return (
      <>
        <p className="page-project-details__intro__subtitle">
          WHY
        </p>
        <MarkdownField className="page-project-details__intro-description" markup={this.props.page.whyDescription}/>
      </>
    );
  };
  render(){
    //console.log(`render pageProjectDetail`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        <PageBase>
          {this.renderBreadcrumbs()}
          <div className="page-project-details">
            {this.renderIntroSection()}
            {this.renderMapSection()}
          </div>
          {this.renderWarningModal()}
        </PageBase>
      </>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.pageProjectDetails,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageProjectDetailActions, dispatch),
  }),
)(PageProjectDetail));


PageProjectDetail.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object,
  latestEvents: PropTypes.array,
  latestNews: PropTypes.array,
  match: PropTypes.object.isRequired,
  page: PropTypes.object,
};
