import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageEventDetailsActions from '../actions/pageEventDetailsActions';
import AddToCalendar from 'react-add-to-calendar';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MContacts from './MContacts';
import MLatestEvents from './MLatestEvents';
import MListFollow from './MListFollow';
import MListShare from './MListShare';
import MListTag from './MListTag';
import MNewsletter from './MNewsletter';
import moment from 'moment';
import PageBase from './PageBase';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageEventDetails extends React.Component{
  lastUrl = '';


  componentDidMount(){
    //console.log(`PageEventDetails.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageEventDetails(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Events and Meetings', slug: 'events'},
        {browserTitle: this.props.page.title, slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderCalendarButton(){
    let eventDate = new Date(this.props.page.eventDate);
    if(!eventDate.getDate()) return null;

    return (
      <div className="m-event-detail-intro__calendar">
        <AddToCalendar event={{
          title: this.props.page.title || '',
          location: this.props.page.locationDescription || '',
          startTime: eventDate,
        }}/>
      </div>
    );
  }
  renderContactInfo(){
    if(!this.props.page.contacts || this.props.page.contacts.length < 1) return null;

    return (
      <div className="m-event-detail__contact contact-info">
        <MContacts contacts={this.props.page.contacts} title="Contact Info"/>
      </div>
    );
  }
  renderContent(){
    if(!this.props.page.content) return null;

    return (
      <div className="container container--coupling">
        <div className="m-intro__contents">
          <MarkdownField markup={this.props.page.content}/>
        </div>
      </div>
    );
  }
  renderDescription(){
    let eventDate = new Date(this.props.page.eventDate);
    if(!eventDate.getDate()) return null;
    eventDate = moment(eventDate).format('dddd, MMMM D, YYYY | h:mm A');

    return (<div className="m-event-detail__intro__description">{eventDate.toString()}</div>);
  }
  renderEventDetails(){
    //console.log(`MEvents render`, this.props);

    return (
      <div className="columns">
        <div className="coupling coupling--parent coupling--left">
          <div className={`m-event-detail coupling coupling--full`}>
            {this.renderContent()}
            {this.renderContactInfo()}
            {this.renderTags()}
          </div>
          <MListShare title="Share this page"/>
        </div>
        <div className="coupling coupling--parent coupling--right">
          <MLatestEvents/>
          <MNewsletter/>
          <MListFollow/>
        </div>
      </div>
    );
  }
  renderIntro(){
    return (
      <div className={`m-event-detail-intro coupling coupling--full-centered`}>
        <div className="container container--coupling">
          {this.renderProgramBanner()}
          {this.renderTitle()}
          {this.renderDescription()}
          {this.renderCalendarButton()}
        </div>
      </div>
    );
  }
  renderProgramBanner(){
    if(!this.props.page.program) return null;

    return (
      <ProgramBanner className="event-detail-item__program-label" program={this.props.page.program}/>
    );
  }
  renderTags(){
    if(!this.props.page.tags) return null;

    return (
      <MListTag mListTag={{tags: this.props.page.tags}} className="m-event-detail__tags"/>
    );
  }
  renderTitle(){
    if(!this.props.page.title) return null;

    return (<div className="m-event-detail__intro__title">{this.props.page.title}</div>);
  }
  render(){
    //console.log(`PageEventDetails.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        {this.renderIntro()}
        {this.renderEventDetails()}
      </PageBase>
    );
  }
}

export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageEventDetailsActions, dispatch),
  }),
)(PageEventDetails));


PageEventDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
