import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as pageActions from "./pageActions";


export const initPageGetInvolved = () => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return apiActions.loadEndpoint(apiValues.nref.pageGetInvolved.endpoint)
    .then(results => {
      let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageGetInvolved.fieldsUsed);
      page.contact = apiActions.itemSelectCamelCaseKeys(page.contact, apiValues.nref.contact.fieldsUsed);
      page.cardListA = apiActions.itemSelectCamelCaseKeys(page.cardListA, apiValues.nref.mListCards.fieldsUsed);
      page.cardListB = apiActions.itemSelectCamelCaseKeys(page.cardListB, apiValues.nref.mListCards.fieldsUsed);
      page.cardListC = apiActions.itemSelectCamelCaseKeys(page.cardListC, apiValues.nref.mListCards.fieldsUsed);
      return page;
    })
    .then(results => {
      return dispatch(pageActions.loadPageSuccess(results));
    })
};
