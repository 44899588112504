import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mFooterActions from '../actions/mFooterActions';
import MListNav from './MListNav';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {SiteSearch} from './SiteSearch';
import {withRouter} from 'react-router-dom';


class MFooter extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      searchQuery: '',
    };
  }
  componentDidMount(){
    //console.log(`MFooter.componentDidMount()`, this.props);
    this.props.actions.initMFooter(this.props.cmsId);
  }


  input_onChange = event => {
    //console.log(`onSearchInputChange`, event.target.value);
    let isSearchOpen = event && event.target.value.length > 0;
    this.props.actions.toggleSearch(isSearchOpen, this.props.cmsId);
    this.handleSearch(event.target.value);
    this.setState({
      searchQuery: event.target.value,
    });
  };
  on_Submit = () => {
    this.props.history.push('/projects');
  };
  searchClear_onClick = () => {
    setTimeout(() => {
      this.props.actions.toggleSearch(false, this.props.cmsId);
    });
  };
  siteSearch_onBlur = () => {
    this.props.actions.toggleSearch(false, this.props.cmsId);
  };
  siteSearch_onFocus = () => {
    this.props.actions.toggleSearch(true, this.props.cmsId);
  };


  handleSearch = query => {
    this.props.actions.doSearch(query, this.props.cmsId);
    //this.props.actions.mProjectListMap.doSearch(null, query);
  };


  renderContactDescription(){
    if(!this.props.mFooter.contactText) return null;

    return (
      <div className="m-footer__contact-description">
        {helpers.nToP(this.props.mFooter.contactText)}
      </div>
    );
  }
  renderFeaturedProjects(){
    if(this.props.mFooter.suggestedProjects) return null;

    return (
      <div className="col-12">
        <MListNav cmsId={apiValues.HEADER_OURPROJECTS_FEATURED_ID} className="m-listnav--page-search-results"/>
      </div>
    );
  }
  renderMobileContactLinks(){
    if(!this.props.mFooter.mobileContactLinks || !this.props.mFooter.mobileContactLinks.id) return null;

    return (
      <div className="col-12 d-md-none">
        <hr className="d-md-none hr--white-light"/>
        <MListNav className="m-listnav--footer" key={this.props.mFooter.mobileContactLinks.id} cmsId={this.props.mFooter.mobileContactLinks.id}/>
      </div>
    );
  }
  renderMobileLinks(){
    if(!this.props.mFooter.mobileLinks || !this.props.mFooter.mobileLinks.id) return null;

    return (
      <div className="col-12 d-md-none">
        <MListNav className="m-listnav--footer" key={this.props.mFooter.mobileLinks.id} cmsId={this.props.mFooter.mobileLinks.id}/>
      </div>
    );
  }
  renderSiteSearch(){
    if(!this.props.mFooter.socialList || !this.props.mFooter.socialList.id) return null;

    return (<div className="col-12 col-md-5">
        {/* <SiteSearch className="m-footer__site-search"/> */}
        <SiteSearch className={`m-footer__site-search`}
                    isOpen={this.props.mFooter.isSearchOpen}
                    onBlur={this.siteSearch_onBlur}
                    onClearClick={this.searchClear_onClick}
                    onFocus={this.siteSearch_onFocus}
                    onInputChange={this.input_onChange}
                    onSubmit={this.on_Submit}
                    inputPlaceholder="Search our projects">
          <div className="row">
            {this.renderFeaturedProjects()}
            {this.renderSuggestedProjects()}
          </div>
        </SiteSearch>
        <hr className="hr--white-light d-none d-md-block"/>
        <MListNav className="m-listnav--footer m-listnav--footer-social m-listnav--horizontal" key={this.props.mFooter.socialList.id} cmsId={this.props.mFooter.socialList.id}/>
      </div>
    );
  }
  renderSubmenuA(){
    if(!this.props.mFooter.submenuA || !this.props.mFooter.submenuA.id) return null;

    return (<div className="col-12 col-md-2 d-none d-md-block">
        <MListNav className="m-listnav--footer" key={this.props.mFooter.submenuA.id} cmsId={this.props.mFooter.submenuA.id}/>
      </div>
    );
  }
  renderSubmenuB(){
    if(!this.props.mFooter.submenuB || !this.props.mFooter.submenuB.id) return null;

    return (<div className="col-12 col-md-2 d-none d-md-block">
        <MListNav className="m-listnav--footer" key={this.props.mFooter.submenuB.id} cmsId={this.props.mFooter.submenuB.id}/>
      </div>
    );
  }
  renderSubmenuC(){
    if(!this.props.mFooter.submenuC || !this.props.mFooter.submenuC.id) return null;

    return (
      <div className="col-12 col-md-3 d-none d-md-block">
        <MListNav className="m-listnav--footer" key={this.props.mFooter.submenuC.id} cmsId={this.props.mFooter.submenuC.id}/>
        {this.renderContactDescription()}
      </div>
    );
  }
  renderSuggestedProjects(){
    if(!this.props.mFooter.suggestedProjects) return null;

    let mListNav = {
      title: 'Suggested Projects',
      navigationLinks: this.props.mFooter.suggestedProjects.slice(0, 4).map(project => {
        return {
          key: project.id,
          title: project.title,
          url: `/projects/${project.slug ? project.slug : ''}`,
        };
      }),
      highlight: this.state.searchQuery,
    };

    return (
      <div className="col-12">
        <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/>
        <SuperLink to={`${this.state.searchQuery && this.state.searchQuery !== '' ? '/projects?Title_contains=' + this.state.searchQuery : '/projects'}`} className="m-projectlistmap__results-link">View all projects for &quot;{this.state.searchQuery}&quot;</SuperLink>
      </div>
    );
  }
  render(){
    //console.log(`MFooter.render()`, this.props);

    if(!this.props.mFooter) return (
      <div className={`m-footer ${this.props.className || ''}`}>
        <div className="is-loading">loading...</div>
      </div>
    );
    else return (
      <div className={`m-footer ${this.props.className || ''}`}>
        <div className="container-fluid container--coupling">
          <div className="row m-footer__row-top">
            {this.renderSubmenuA()}
            {this.renderSubmenuB()}
            {this.renderSubmenuC()}
            {this.renderMobileLinks()}
            {this.renderMobileContactLinks()}
            {this.renderSiteSearch()}
          </div>
          <hr className="hr--white-light"/>
          <div className="row">
            <div className="col-12 col-lg-6">
              <span className="m-footer__bot-left-text">{this.props.mFooter.botLeftText}</span>
            </div>
            <div className="col-12 col-lg-6 m-footer__legal-text-col">
              <span className="m-footer__legal-text">{this.props.mFooter.legalText}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mFooter: helpers.getById(state.mFooter, ownProps.cmsId),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mFooterActions, dispatch),
  }),
)(MFooter));


MFooter.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  history: PropTypes.object,
  mFooter: PropTypes.object,
};
