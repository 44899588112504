import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mHeaderActions from '../actions/mHeaderActions';
import * as navigationLinkActions from '../actions/navigationLinkActions';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class NavigationLink extends React.Component{
  componentDidMount(){
    //console.log(`NavigationLink.componentDidMount()`, this.props);

    if(this.props.navigationLink && this.props.navigationLink.title){
      //console.log(`passed in `, this.props.navigationLink);
    }
    else if(this.props.cmsId){
      this.props.actions.navigationLink.initNavigationLink(this.props.cmsId);
    }
  }


  renderDescription(){
    if(!this.props.navigationLink.description) return null;

    return (
      <div className="navigationlink__description">{helpers.nToP(this.props.navigationLink.description)}</div>
    );
  }
  renderImage(){
    if(!this.props.navigationLink.imageOptional) return null;

    return (
      <img alt={this.props.navigationLink.title} className=""
           src={apiValues.imgPath(this.props.navigationLink.imageOptional.url)}/>
    );
  }
  renderTitle(){
    if(!this.props.navigationLink.title) return null;

    return (
      <div className="navigationlink__title">
        {helpers.getHighlightedText(this.props.navigationLink.title, this.props.highlight)}
      </div>
    );
  }
  render(){
    //console.log(`render NavigationLink`, this.props);
    if(!this.props.navigationLink ||
       (!this.props.navigationLink.description && !this.props.navigationLink.imageOptional && !this.props.navigationLink.title)) return null;

    let baseClass = `navigationlink ${this.props.className || ''}`;
    let typeClass = this.props.navigationLink.type ? ` navigationlink--${this.props.navigationLink.type}` : '';
    let isSelectedClass = this.props.navigationLink.isSelected ? ` navigationlink--is-selected` : '';
    let className = `${baseClass} ${typeClass} ${isSelectedClass}`;
    let linkUrl = this.props.navigationLink.document ? `${apiValues.CMS_PATH}${this.props.navigationLink.document.url}` : this.props.navigationLink.url ? this.props.navigationLink.url : undefined;

    if(linkUrl)
      return (
        <div className={className}>
          <SuperLink to={linkUrl} className='navigationlink__link' onClick={this.props.onClick}>
            {this.renderImage()}
            {this.renderTitle()}
          </SuperLink>
          {this.renderDescription()}
        </div>
      );
    else
      return (
        <span className={className} onClick={this.props.onClick}>
          {this.renderImage()}
          {this.renderTitle()}
          {this.renderDescription()}
        </span>
      );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {navigationLink: helpers.getById(state.navigationLink, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: {
      navigationLink: bindActionCreators(navigationLinkActions, dispatch),
      mHeaderActions: bindActionCreators(mHeaderActions, dispatch),
    },
  }),
)(NavigationLink));


NavigationLink.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  highlight: PropTypes.string,
  navigationLink: PropTypes.object,
  onClick: PropTypes.func,
};
