import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mHeaderTabActions from '../actions/mHeaderTabActions';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MHeaderTab extends React.Component{
  state = {
    ariaExpanded: false,
  };


  componentDidMount(){
    //console.log(`MHeaderTab.componentDidMount()`, this.props);
    this.props.actions.initMHeaderTab(this.props.id);
  }


  onMouseEnter = (e) => {
    this.setState({ariaExpanded: true});
    this.props.onMouseEnter ? this.props.onMouseEnter(e) : null;
  };
  onMouseLeave = (e) => {
    this.setState({ariaExpanded: false});
    this.props.onMouseLeave ? this.props.onMouseLeave(e) : null;
  };
  onTabClick = (e) => {
    if(this.props.onTabClick) this.props.onTabClick(e);
    else{
      this.props.actions.tabToggle(this.props.id, !this.props.mHeaderTab.isOpen);
      this.setState({ariaExpanded: !this.props.mHeaderTab.isOpen});
    }
  };
  onToggleClick = () => {
    this.props.actions.tabToggle(this.props.id, !this.props.mHeaderTab.isOpen);
    this.setState({ariaExpanded: !this.props.mHeaderTab.isOpen});
  };


  render(){
    //console.log(`MHeaderTab.render()`, this.props);
    if(!this.props.mHeaderTab) return null;

    let tabStyle = {backgroundImage: this.props.bgPath ? `url(${apiValues.CMS_PATH}${this.props.bgPath})` : ''};
    let openClass = this.props.mHeaderTab.isOpen ? 'is-open' : '';
    let activeClass = this.props.url == window.location.pathname.slice(1) ? 'is-active' : '';
    let tabLink = this.props.url ? (
      <SuperLink to={this.props.url} className="m-header__tab-link">{this.props.title}</SuperLink>) : (
                    <span className="m-header__tab-link">{this.props.title}</span>);
    let subheadTitle = this.props.url ? (
      <SuperLink to={this.props.url} className="m-header__subhead-title">{this.props.title}</SuperLink>) : (
                         <span className="m-header__subhead-title">{this.props.title}</span>);

    const ariaExpanded = typeof this.props.ariaExpanded != 'undefined' ? this.props.ariaExpanded : this.state.ariaExpanded;
    const tabIndex = ariaExpanded ? '0' : '-1';

    return (
      <div className={`m-header__tab ${this.props.className || ''} ${openClass} ${activeClass}`}
           onMouseEnter={this.onMouseEnter}
           onMouseLeave={this.onMouseLeave}
           aria-expanded={`${ariaExpanded}`}
           aria-haspopup="true"
           tabIndex={tabIndex}>
        {tabLink}
        {this.props.title ? (<button aria-expanded={this.props.ariaExpanded || this.state.ariaExpanded}
                                     className="m-header__tab-toggle"
                                     onClick={this.onToggleClick}>
          Toggle {this.props.title} submenu
        </button>) : null}
        <button className="m-header__tab-link m-header__tab-link--mobile"
                onClick={this.onTabClick}>{this.props.title}</button>
        <div className="m-header__tab-menu">
          <div className="m-header__tab-menu-content" style={tabStyle}>
            <div className="m-header__subhead">
              <button className="m-header__subhead-back" onClick={this.onTabClick}>Back</button>
              {subheadTitle}
            </div>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mHeaderTab: helpers.getById(state.mHeaderTab, ownProps.id),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mHeaderTabActions, dispatch),
  }),
)(MHeaderTab));


MHeaderTab.propTypes = {
  actions: PropTypes.object.isRequired,
  ariaExpanded: PropTypes.bool,
  bgPath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  mHeaderTab: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  onTabClick: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
};
