import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";

export default function mHeaderTabReducer(state = initialState.mHeaderTab, action) {
  switch (action.type) {
    case actionTypes.MHEADERTAB_ADD: {
      return helpers.updateOrAdd(state, {
        id: action.id,
        isOpen: action.isOpen
      });
    }

    case actionTypes.MHEADERTAB_TOGGLE: {
      return helpers.updateOrAdd(state, {
        id: action.id,
        isOpen: action.isOpen
      });
    }

    case actionTypes.MHEADERTAB_TOGGLE_ALL: {
      let newState = JSON.parse(JSON.stringify(state));

      newState.forEach(toggle => {
        toggle.isOpen = false;
      });

      return newState;
    }


    default:
      return state;
  }
}
