import EventItem from './EventItem';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import moment from 'moment';

const PRODUCTS_PER_PAGE = 3;

var today = new Date();

today = moment(today).valueOf();

//var events3 ;


export default class MLatestRelatedEvents extends React.Component{
  state = {
    currentPage: 1,
  };


  renderEvents(){
    if(!this.props.latestEvents.events) return null;


    const events = this.props.latestEvents.events.sort((a, b) =>
    
    
    
    {
      return moment(b.eventDate).valueOf() - moment(a.eventDate).valueOf();
    }
    
    
    
    )

    var x = 0;

    for (var i = 0; i < events.length; i++) {
      if (moment(events[i].eventDate).valueOf() > today) {
        
        x = x+1;
      }    
      } 
    
      if ( x < 3) {
        x = 3;
      }

   
    
    return events.slice(x-3, x).reverse().map((event, index) => 
    {
      return (
        <div key={event.id} className="col-12 events__listing-item">
          <EventItem event={event} 
          disableDate 
          disableLocation 
          className={index === PRODUCTS_PER_PAGE - 1 ? 'remove--border' : ''}/>
        </div>
      );
    });
  }






  renderLink(){
    const eventsLink = this.props.program && this.props.program.id ? `/events?program=${this.props.program.id}` : '/events';

    return (
      <div className="navigationlink navigationlink--blue-outline">
        <SuperLink to={eventsLink} className="navigationlink__link">
          <div className="navigationlink__title">{this.props.latestEvents.linkText || 'View All Events'}</div>
        </SuperLink>
      </div>
    );
  }
  renderTitle(){
    if(this.props.latestEvents.title) return (<div className="m-latest-events__title">{this.props.latestEvents.title}</div>);
    else return (<div className="m-latest-events__title">LATEST EVENTS</div>);
  }
  render(){
    if(!this.props.latestEvents ||
       !this.props.latestEvents.events ||
       this.props.latestEvents.events.length < 1) return null;

    return (
      <div className={`m-latest-events ${this.props.className || ''}`}>
        <div className="container container--coupling">
          {this.renderTitle()}
          <div className="events__listing">
            <div className="row">
              {this.renderEvents()}
            </div>
          </div>
          {this.renderLink()}
        </div>
      </div>
    );
  }
}


MLatestRelatedEvents.propTypes = {
  className: PropTypes.string,
  latestEvents: PropTypes.object,
  program: PropTypes.object,
};
