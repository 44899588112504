import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getProjectById} from "../reducers/index";


//Action Creators
export const loadProjectStart = (id) => {
  return {
    type: actionTypes.PROJECT_LOAD_START,
    id,
  };
};
export const loadProjectFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.PROJECT_LOAD_FAIL,
    id,
    error,
  };
};
export const loadProjectSuccess = (data) => {
  return {
    type: actionTypes.PROJECT_LOAD_SUCCESS,
    data,
  };
};
export const loadProjectManySuccess = (data) => {
  return {
    type: actionTypes.PROJECT_LOAD_MANY_SUCCESS,
    data,
  };
};


export const initProject = id => dispatch => {
  dispatch(loadProjectStart(id));

  apiActions.loadEndpoint(apiValues.nref.project.endpoint, id).then(result => {
    let project = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.project.fieldsUsed);
    project.program = project.program ? apiActions.itemSelectCamelCaseKeys(project.program, apiValues.nref.program.fieldsUsed) : undefined;
    //console.log(result, project);
    dispatch(loadProjectSuccess(project));
  });
};
