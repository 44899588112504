import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function mHeaderReducer(state = initialState.mHeader, action) {
  switch (action.type) {
    case actionTypes.MHEADER_LOAD_START:
      return {
        ...state,
        isLoading: true,
        projects: [],
        navigations: []
      };

    case actionTypes.MHEADER_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case actionTypes.MHEADER_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined
      };
    }

    case actionTypes.MHEADERPROJECT_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined
      };
    }

    case actionTypes.MHEADERNAVIGATION_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        error: undefined
      };
    }

    case actionTypes.MHEADER_TOGGLE: {
      return {
        ...state,
        isOpen: action.isOpen
      };
    }

    case actionTypes.MHEADER_TOGGLE_SEARCH: {
      return {
        ...state,
        isSearchOpen: action.isSearchOpen
      };
    }

    case actionTypes.MHEADER_SET_EXPANDED_TAB: {
      return {
        ...state,
        ...action.data
      };
    }

    default:
      return state;
  }
}
