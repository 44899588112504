import * as actionTypes from '../constants/actionType';
import initialState from './initialState';


export default function mEventsReducer(state = initialState.mEvents, action){
  switch(action.type){
    case actionTypes.MEVENTS_LOAD_START:
      return {
        ...state,
        id: action.id,
        isLoading: true,
      };

    case actionTypes.MEVENTS_LOAD_FAIL:
      return {
        ...state,
        id: action.id,
        isLoading: false,
        error: action.error,
      };

    case actionTypes.MEVENTS_LOAD_SUCCESS:{
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined,
      };
    }

    default:
      return state;
  }
}
