import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as projectActions from './projectActions';
//import {getMProjectsCurrentById} from "../reducers/index";


//Action Creators
export const loadMProjectsCurrentStart = (id) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_LOAD_START,
    id
  };
};
export const loadMProjectsCurrentFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MPROJECTSCURRENT_LOAD_FAIL,
    id,
    error
  };
};
export const loadMProjectsCurrentSuccess = (data) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_LOAD_SUCCESS,
    data
  };
};
export const loadMProjectsCurrentProjectsStart = (id) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_START,
    id
  };
};
export const loadMProjectsCurrentProjectsFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_FAIL,
    id,
    error
  };
};
export const loadMProjectsCurrentProjectsSuccess = (id, projects) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_SUCCESS,
    id,
    projects
  };
};
export const setMProjectsCurrentListLength = (id, listLength) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_SET_LISTLENGTH,
    id,
    listLength
  };
};
export const setMProjectsCurrentProjectTypes = (id, selectedProjectTypes) => {
  return {
    type: actionTypes.MPROJECTSCURRENT_SET_FILTER,
    id,
    selectedProjectTypes
  };
};


export const init = (id) => dispatch => {
  dispatch(loadMProjectsCurrentStart(id));

  apiActions.loadEndpoint(apiValues.nref.mProjectsCurrent.endpoint, id).then(result => {
    let mProjectsCurrent = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mProjectsCurrent.fieldsUsed);
    mProjectsCurrent.listLength = apiValues.PROJECTS_PER_PAGE;

    //get projects
    apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {
      _sort: 'LastSave:desc',
      _limit: apiValues.PROJECTS_PER_PAGE
    }).then(results => {
      let projects = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed);
      projects.forEach(project => {
        dispatch(projectActions.loadProjectSuccess(project));
      });
      mProjectsCurrent.projects = projects.map(project => project.id);

      dispatch(loadMProjectsCurrentSuccess(mProjectsCurrent));
    });
  });
};
export const filterList = (id, selectedProjectTypes, start, end, existingItems) => dispatch => {
  // console.log(`filterList`, selectedProjectTypes);

  dispatch(setMProjectsCurrentListLength(id, end));
  dispatch(setMProjectsCurrentProjectTypes(id, selectedProjectTypes));
  dispatch(loadMProjectsCurrentProjectsStart(id));

  apiActions.loadEndpointByFilters(apiValues.nref.project.endpoint, {
      ProjectTypes_in: selectedProjectTypes,
      _limit: apiValues.PROJECTS_PER_PAGE,
      _start: start,
      _sort: 'LastSave:desc'
    })
    .then(results => {
      let projects = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed);
      projects.forEach(project => {
        dispatch(projectActions.loadProjectSuccess(project));
      });

      dispatch(loadMProjectsCurrentProjectsSuccess(id, [
        ...existingItems,
        ...projects.map(project => project.id)
      ]));
    });
};
