import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMIntroById} from "../reducers/index";

//Action Creators
export const loadMProgramMapAdStart = id => {
  return {
    type: actionTypes.MPROGRAMMAPAD_LOAD_START,
    id,
  };
};
export const loadMProgramMapAdFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MPROGRAMMAPAD_LOAD_FAIL,
    id,
    error,
  };
};
export const loadMProgramMapAdSuccess = data => {
  return {
    type: actionTypes.MPROGRAMMAPAD_LOAD_SUCCESS,
    data,
  };
};

export const initMProgramMapAd = id => dispatch => {
  dispatch(loadMProgramMapAdStart(id));

  apiActions.loadEndpoint(apiValues.nref.mProgramMapAd.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mProgramMapAd.fieldsUsed);
    normalized.program = normalized.program ? apiActions.itemSelectCamelCaseKeys(normalized.program, apiValues.nref.program.fieldsUsed) : undefined;

    dispatch(loadMProgramMapAdSuccess(normalized));
  });
};
