import * as apiValues from '../constants/apiValues';
import * as pageEventsActions from '../actions/pageEventsActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MEvents from './MEvents';
import MIntro from './MIntro';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageEvents extends React.Component{
  componentDidMount(){
    //console.log(`PageEvents.componentDidMount()`, this.props);
    this.props.actions.initPageEvents();
  }
  shouldComponentUpdate(){
    return true;
  }
  UNSAFE_componentWillReceiveProps(nextProps){
    this.props = nextProps;
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: '/'},
        {browserTitle: 'Events and Meetings', slug: '/events'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderIntro(){
    if(!this.props.page.intro) return null;

    return (<MIntro mIntro={this.props.page.intro} className="coupling coupling--centered"/>);
  }
  render(){
    //console.log(`PageEvents.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        <div className="coupling coupling--parent coupling--full main-content">
          {this.renderBreadcrumbs()}
          <div className="coupling coupling--parent coupling--full">
            {this.renderIntro()}
            <MEvents className="coupling coupling--full"/>
          </div>
        </div>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageEventsActions, dispatch),
  }),
)(PageEvents));


PageEvents.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
