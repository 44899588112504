import * as apiValues from '../constants/apiValues';
import * as pageGetInvolvedActions from '../actions/pageGetInvolvedActions';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MContacts from './MContacts';
import MIntro from './MIntro';
import MLatestEvents from './MLatestEvents';
import MListCards from './MListCards';
import MNewsletter from './MNewsletter';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageGetInvolved extends React.Component{
  componentDidMount(){
    //console.log(`PageGetInvolved.componentDidMount()`, this.props);
    this.props.actions.initPageGetInvolved();
  }

  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: '/'},
        {browserTitle: 'Get Involved', slug: '/get-involved/'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderBody(){
    if(!this.props.page.body) return null;

    return (
      <div className="coupling coupling--centered-thin">
        <div className="container container--coupling">
          <div className="markdown-text">
            <MarkdownField markup={this.props.page.body}/>
          </div>
        </div>
      </div>
    );
  }
  renderContactBlock(){
    return (
      <div className="page-getinvolved__contact coupling coupling--centered-thin">
        <div className="container container--coupling">
          <div className="row">
            <div className="page-getinvolved__contact-col">
              {this.renderContacts()}
            </div>
            <div className="page-getinvolved__contact-col">
              <MNewsletter/>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderContacts(){
    if(!this.props.page.contact) return null;

    return (
      <MContacts contacts={[this.props.page.contact]}
                 title="Contact Us"
                 className="page-getinvolved__m-contacts coupling coupling--full"/>
    );
  }
  renderCardListA(){
    if(!this.props.page.cardListA) return null;

    return (<MListCards cmsId={this.props.page.cardListA.id} className="coupling coupling--full-centered"/>);
  }
  renderCardListB(){
    if(!this.props.page.cardListB) return null;

    return (<MListCards cmsId={this.props.page.cardListB.id} className="coupling coupling--full-centered"/>);
  }
  renderCardListC(){
    if(!this.props.page.cardListC) return null;

    return (<MListCards cmsId={this.props.page.cardListC.id} className="coupling coupling--full-centered"/>);
  }
  renderEvents(){
    return (
      <div className="coupling coupling--full-centered">
        <MLatestEvents className="m-latest-events--green m-latest-events--horizontal"
                       horizontal
                       relatedProgram={this.props.page.eventsRelatedProgram}
                       relatedProject={this.props.page.eventsRelatedProject}
                       relatedTag={this.props.page.eventsRelatedTag}
                       title="Join Us at Community Events and Public Meetings"/>
      </div>
    );
  }
  renderIntro(){
    if(!this.props.page.title && !this.props.page.introSubtitle) return null;

    let mIntro = {title: this.props.page.title, description: this.props.page.introSubtitle};

    return (<MIntro mIntro={mIntro} className="coupling coupling--centered-thin"/>);
  }
  render(){
    //console.log(`PageGetInvolved.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        {this.renderIntro()}
        {this.renderBody()}
        {this.renderContactBlock()}
        {this.renderCardListA()}
        {this.renderCardListB()}
        {this.renderEvents()}
        {this.renderCardListC()}
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageGetInvolvedActions, dispatch),
  }),
)(PageGetInvolved));


PageGetInvolved.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
