import * as apiValues from '../constants/apiValues';
import * as pageNewsActions from '../actions/pageNewsActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MIntro from './MIntro';
import News from './News';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageNews extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageNews.componentDidMount()`, this.props);
    this.props.actions.initPageNews();
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'News and Updates', slug: '/news'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderIntro(){
    if(!this.props.page.intro) return null;

    return (<MIntro mIntro={this.props.page.intro} className="page-news__intro coupling coupling--centered"/>);
  }
  render(){
    //console.log(`PageNews.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        {this.renderIntro()}
        <News/>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageNewsActions, dispatch),
  }),
)(PageNews));


PageNews.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
