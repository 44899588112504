import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as carouselSlideActions from './carouselSlideActions';
//import {getMCarouselById} from "../reducers/index";


//Action Creators
export const loadMCarouselStart = (id) => {
  return {
    type: actionTypes.MCAROUSEL_LOAD_START,
    id
  };
};
export const loadMCarouselFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MCAROUSEL_LOAD_FAIL,
    id,
    error
  };
};
export const loadMCarouselSuccess = (data) => {
  return {
    type: actionTypes.MCAROUSEL_LOAD_SUCCESS,
    data
  };
};

export const initMCarousel = id => dispatch => {
  dispatch(loadMCarouselStart(id));

  apiActions.loadEndpoint(apiValues.nref.mCarousel.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mCarousel.fieldsUsed);

    let carouselSlides = apiActions.arraySelectCamelCaseKeys(normalized.carouselSlides, apiValues.nref.carouselSlide.fieldsUsed);
    carouselSlides.forEach(carouselSlide => {
      dispatch(carouselSlideActions.loadCarouselSlideSuccess(carouselSlide))
    });
    normalized.carouselSlides = carouselSlides.map(carouselSlide=>carouselSlide.id);

    dispatch(loadMCarouselSuccess(normalized));
  });
};


