import * as apiValues from '../constants/apiValues';
import MBrandBar from './MBrandBar';
import MFooter from './MFooter';
import MHeader from './MHeader';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Helmet from 'react-helmet';


class PageBase extends React.Component{
  render(){
    // console.log(`PageBase.render()`, this.props);

    const hamburgerOpenClass = this.props.mHeader && this.props.mHeader.isOpen ? 'page--hamburger-is-open' : '';
    const contentTitle = document.title.slice(0, document.title.indexOf(apiValues.BROWSER_TITLE_SUFFIX));

    return (
      <div className={`page ${this.props.className || ''} ${hamburgerOpenClass}`}>
        <Helmet>
          <meta property="og:site_name" content={apiValues.OG_SITE_NAME}/>
          <meta property="og:url" content={`${window.location.protocol}//${window.location.host}${window.location.pathname}`}/>
          <meta property="og:title" content={contentTitle}/>
          {this.props.mHeader && this.props.mHeader.imageLogo && (<meta property="og:image" content={this.props.mHeader.imageLogo.url}/>)}
          <meta name="twitter:card" content="summary"/>
          <meta name="twitter:image:alt" content={apiValues.OG_SITE_NAME}/>
        </Helmet>
        <div className="page__sticky">
          <MBrandBar cmsId={apiValues.MBRANDBAR_ID} className="coupling coupling--full-max"/>
          <MHeader cmsId={apiValues.MHEADER_ID} className="coupling coupling--full-max"/>
          <div className="page__sticky-body">
            {this.props.children}
          </div>
          <MFooter cmsId={apiValues.MFOOTER_ID} className="coupling coupling--full-centered"/>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mHeader: state.mHeader,
  }),
)(PageBase));


PageBase.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  mHeader: PropTypes.object,
};
