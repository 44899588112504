import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMProjectsById} from "../reducers/index";


//Action Creators
export const loadMLatestNewsStart = () => {
  return {
    type: actionTypes.MLATESTNEWS_LOAD_START,
  };
};
export const loadMLatestNewsFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MLATESTNEWS_LOAD_FAIL,
    error,
  };
};
export const loadMLatestNewsSuccess = (data) => {
  return {
    type: actionTypes.MLATESTNEWS_LOAD_SUCCESS,
    data,
  };
};

export const initMLatestNews = params => dispatch => {
  dispatch(loadMLatestNewsStart());


  //if has params, try
  //if no results, try next & repeat


  let promiseOptions = [];
  if(params.relatedProject)
    promiseOptions.push(apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {
      RelatedProject_in: params.relatedProject.id,
      '_sort': 'NewsDate:DESC',
    }));
  if(params.relatedProgram)
    promiseOptions.push(apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {
      Program_in: params.relatedProgram.id,
      '_sort': 'NewsDate:DESC',
    }));
  if(params.relatedTag)
    promiseOptions.push(apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {
      Tags_in: params.relatedTag.id,
      '_sort': 'NewsDate:DESC',
    }));
  if(params.program && params.program.id)
    promiseOptions.push(apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {
      Program_in: params.program.id,
      '_sort': 'NewsDate:DESC',
    }));
  promiseOptions.push(apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {'_sort': 'NewsDate:DESC'}));


  return tryNextSource(promiseOptions).then(results => {
    let mLatestNews = {news: results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.news.fieldsUsed) : []};
    dispatch(loadMLatestNewsSuccess(mLatestNews));
    return mLatestNews;
  });
};

const tryNextSource = (promiseOptions) => {
  return new Promise((resolve) => {
    if(promiseOptions.length < 1) resolve([]);

    promiseOptions[0].then((results) => {
      if(results.length < 1) resolve(tryNextSource(promiseOptions.slice(1)));
      else resolve(results);
    });
  });
};
