import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


// Action Creators
export const setQuery = query => dispatch => {
  dispatch({
    type: actionTypes.SEARCH_SET_QUERY,
    data: query,
  });
};
export const setTagQueryActionCreator = (tag) => dispatch => {
  dispatch({
    type: actionTypes.SEARCH_SET_TAG_QUERY,
    data: tag,
  });
};
export const setProgram = program => dispatch => {
  dispatch({
    type: actionTypes.SEARCH_SET_PROGRAMS,
    data: program,
  });
};
export const setTypes = types => dispatch => {
  dispatch({
    type: actionTypes.SEARCH_SET_TYPES,
    data: types,
  });
};


export const setTagQuery = tag => dispatch => {
  apiActions.loadEndpoint(apiValues.nref.tag.endpoint, null, {id: tag})
    .then(results => {
      let tags = apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.tag.fieldsUsed);

      dispatch(setTagQueryActionCreator(tags[0]));
      console.log(`tag, tags[0].title`, tag, tags[0].title);
      // dispatch(setQuery(tags[0].title));
      dispatch(initSearchWithTags(tag));
    })
    .catch(err => {
      console.error(err);
    });
};


//News
export const loadNewsStart = () => {
  return {
    type: actionTypes.SEARCH_NEWS_LOAD_START,
  };
};
export const loadNewsFail = error => {
  console.error(error);
  return {
    type: actionTypes.SEARCH_NEWS_LOAD_FAIL,
    error,
  };
};
export const loadNewsSuccess = data => {
  return {
    type: actionTypes.SEARCH_NEWS_LOAD_SUCCESS,
    data,
  };
};
export const loadNews = () => dispatch => {
  dispatch(loadNewsStart());

  //get news
  apiActions
    .loadEndpointByFilters(apiValues.nref.news.endpoint, {'_sort': 'Date:DESC'})
    .then(results => {
      dispatch(loadNewsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.news.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadNewsFail(err));
    });
};
export const loadNewsWithTag = selectedTag => dispatch => {
  dispatch(loadNewsStart());

  //get news
  apiActions.loadEndpointByFilters(apiValues.nref.news.endpoint, {Tags_in: selectedTag, '_sort': 'NewsDate:DESC'})
    .then(results => {
      dispatch(loadNewsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.news.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadNewsFail(err));
    });
};


//Events
export const loadEventsStart = () => {
  return {
    type: actionTypes.SEARCH_EVENTS_LOAD_START,
  };
};
export const loadEventsFail = error => {
  console.error(error);
  return {
    type: actionTypes.SEARCH_EVENTS_LOAD_FAIL,
    error,
  };
};
export const loadEventsSuccess = data => {
  return {
    type: actionTypes.SEARCH_EVENTS_LOAD_SUCCESS,
    data,
  };
};
export const loadEvents = () => dispatch => {
  dispatch(loadEventsStart());

  //get events
  apiActions.loadEndpoint(apiValues.nref.event.endpoint, null)
    .then(results => {
      dispatch(loadEventsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.event.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadEventsFail(err));
    });
};
export const loadEventsWithTag = selectedTag => dispatch => {
  dispatch(loadEventsStart());

  //get events
  apiActions.loadEndpointByFilters(apiValues.nref.event.endpoint, {Tags_in: selectedTag})
    .then(results => {
      dispatch(loadEventsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.event.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadEventsFail(err));
    });
};


//Projects
export const loadProjectsStart = () => {
  return {
    type: actionTypes.SEARCH_PROJECTS_LOAD_START,
  };
};
export const loadProjectsFail = error => {
  console.error(error);
  return {
    type: actionTypes.SEARCH_PROJECTS_LOAD_FAIL,
    error,
  };
};
export const loadProjectsSuccess = data => {
  return {
    type: actionTypes.SEARCH_PROJECTS_LOAD_SUCCESS,
    data,
  };
};
export const loadProjects = () => dispatch => {
  dispatch(loadProjectsStart());

  //get projects
  apiActions.loadEndpoint(apiValues.nref.project.endpoint, null)
    .then(results => {
      dispatch(loadProjectsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadProjectsFail(err));
    });
};
export const loadProjectsWithTag = selectedTag => dispatch => {
  dispatch(loadProjectsStart());

  //get projects
  apiActions.loadEndpointByFilters(apiValues.nref.project.endpoint, {Tags_in: selectedTag})
    .then(results => {
      dispatch(loadProjectsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadProjectsFail(err));
    });
};


//Programs
export const loadProgramsStart = () => {
  return {
    type: actionTypes.SEARCH_PROGRAMS_LOAD_START,
  };
};
export const loadProgramsFail = error => {
  console.error(error);
  return {
    type: actionTypes.SEARCH_PROGRAMS_LOAD_FAIL,
    error,
  };
};
export const loadProgramsSuccess = data => {
  return {
    type: actionTypes.SEARCH_PROGRAMS_LOAD_SUCCESS,
    data,
  };
};
export const loadPrograms = () => dispatch => {
  dispatch(loadProgramsStart());

  //get programs
  apiActions.loadEndpoint(apiValues.nref.program.endpoint, null)
    .then(results => {
      dispatch(loadProgramsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.program.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadProgramsFail(err));
    });
};
export const loadProgramsWithTag = selectedTag => dispatch => {
  dispatch(loadProgramsStart());

  //get programs
  apiActions.loadEndpointByFilters(apiValues.nref.program.endpoint, {Tags_in: selectedTag})
    .then(results => {
      dispatch(loadProgramsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.program.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadProgramsFail(err));
    });
};


//PageContentDetails
export const loadPageContentDetailsStart = () => {
  return {
    type: actionTypes.SEARCH_PAGECONTENTDETAILS_LOAD_START,
  };
};
export const loadPageContentDetailsFail = error => {
  console.error(error);
  return {
    type: actionTypes.SEARCH_PAGECONTENTDETAILS_LOAD_FAIL,
    error,
  };
};
export const loadPageContentDetailsSuccess = data => {
  return {
    type: actionTypes.SEARCH_PAGECONTENTDETAILS_LOAD_SUCCESS,
    data,
  };
};
export const loadPageContentDetails = () => dispatch => {
  dispatch(loadPageContentDetailsStart());

  //get pageContentDetails
  apiActions.loadEndpoint(apiValues.nref.project.endpoint, null)
    .then(results => {
      dispatch(loadPageContentDetailsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadPageContentDetailsFail(err));
    });
};
export const loadPageContentDetailsWithTag = selectedTag => dispatch => {
  dispatch(loadPageContentDetailsStart());

  //get pageContentDetails
  apiActions.loadEndpointByFilters(apiValues.nref.pageContentDetail.endpoint, {Tags_in: selectedTag})
    .then(results => {
      dispatch(loadPageContentDetailsSuccess(apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.pageContentDetail.fieldsUsed)));
    })
    .catch(err => {
      dispatch(loadPageContentDetailsFail(err));
    });
};


//General
export const initSearch = () => dispatch => {
  dispatch(loadNews());
  dispatch(loadEvents());
  dispatch(loadPrograms());
  dispatch(loadProjects());
};
export const initSearchWithTags = selectedTag => dispatch => {
  console.log(selectedTag);
  dispatch(loadNewsWithTag(selectedTag));
  dispatch(loadEventsWithTag(selectedTag));
  dispatch(loadProgramsWithTag(selectedTag));
  dispatch(loadProjectsWithTag(selectedTag));
  dispatch(loadPageContentDetailsWithTag(selectedTag));
};
//todo: redo this function so it is more generic / simple
export const filterResult = state => {
  state.filterQuery = state.filterQuery || '';
  const query = state.filterQuery.toLowerCase();
  console.log(`query is`, state, query, types);
  const types = state.filterType;
  const filterProgram = state.filterProgram;

  const projects = state.projects;
  const news = state.news;
  const programs = state.programs;
  const events = state.events;
  const pageContentDetails = state.pageContentDetails;

  let projectResult = [];
  let newsResult = [];
  let eventResult = [];
  let programResult = [];
  let pageContentDetailResult = [];
  let total = 0;


  // Types selected
  if(types.length > 0){
    // Filter projects
    if(types.includes('project')){
      if(filterProgram.length > 0){
        projectResult = projects.filter(project => {
          if(!project.program){
            return false;
          }
          return filterProgram.includes(project.program.id);
        });
        if(query !== ''){
          projectResult = projectResult.filter(project => {
            if(project.title && project.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              project.description &&
              project.description.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }
        total = projectResult.length;
      }else{
        if(query !== ''){
          projectResult = projects.filter(project => {
            if(project.title && project.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              project.description &&
              project.description.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }else{
          projectResult = projects;
        }
        total = projectResult.length;
      }
    }

    // Filter programs
    if(types.includes('program')){
      if(filterProgram.length > 0){
        programResult = programs.filter(program =>
          filterProgram.includes(program.id),
        );
        if(query !== ''){
          programResult = programResult.filter(program => {
            if(program.title && program.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              program.shortDescription &&
              program.shortDescription.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }
        total += programResult.length;
      }else{
        if(query !== ''){
          programResult = programs.filter(program => {
            if(program.title && program.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              program.shortDescription &&
              program.shortDescription.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }else{
          programResult = programs;
        }
        total += programResult.length;
      }
    }

    // Filter events
    if(types.includes('event')){
      if(filterProgram.length > 0){
        eventResult = events.filter(event =>
          filterProgram.includes(event.program.id),
        );
        if(query !== ''){
          eventResult = eventResult.filter(event => {
            if(event.title.toLowerCase().includes(query)) return true;

            if(event.content && event.content.Contents){
              return event.content.Contents.toLowerCase().includes(query);
            }
            return false;
          });
        }
        total += eventResult.length;
      }else{
        if(query !== ''){
          eventResult = events.filter(event => {
            if(event.title.toLowerCase().includes(query)) return true;

            if(event.content && event.content.Contents){
              return event.content.Contents.toLowerCase().includes(query);
            }
            return false;
          });
        }else{
          eventResult = events;
        }
        total += eventResult.length;
      }
    }

    // Filter news
    if(types.includes('news')){
      if(filterProgram.length > 0){
        newsResult = news.filter(item =>
          filterProgram.includes(item.program.id),
        );
        if(query !== ''){
          newsResult = newsResult.filter(item => {
            if(item.title && item.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              item.description &&
              item.description.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }
        total += newsResult.length;
      }else{
        if(query !== ''){
          newsResult = news.filter(item => {
            if(item.title && item.title.toLowerCase().includes(query)){
              return true;
            }
            if(
              item.description &&
              item.description.toLowerCase().includes(query)
            ){
              return true;
            }
            return false;
          });
        }else{
          newsResult = news;
        }
        total += newsResult.length;
      }
    }

    // Filter pageContentDetails
    if(types.includes('other')){
      if(filterProgram.length > 0){
        pageContentDetailResult = pageContentDetails.filter(item => filterProgram.includes(item.program.id));
        if(query !== '')
          pageContentDetailResult = pageContentDetails.filter(item =>
            (item.title && item.title.toLowerCase().includes(query)) ||
            (item.description && item.description.toLowerCase().includes(query)),
          );
        total += pageContentDetailResult.length;
      }else{
        if(query !== '')
          pageContentDetailResult = pageContentDetails.filter(item =>
            (item.title && item.title.toLowerCase().includes(query)) ||
            (item.description && item.description.toLowerCase().includes(query)),
          );
        else pageContentDetailResult = pageContentDetails;

        total += pageContentDetailResult.length;
      }
    }
  }else{
    // Filter projects
    if(filterProgram.length > 0){
      projectResult = projects.filter(project =>
        filterProgram.includes(project.program.id),
      );
      if(query !== ''){
        projectResult = projectResult.filter(project => {
          if(project.title && project.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            project.description &&
            project.description.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }
      total = projectResult.length;
    }else{
      if(query !== ''){
        projectResult = projects.filter(project => {
          if(project.title && project.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            project.description &&
            project.description.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }else{
        projectResult = projects;
      }
      total = projectResult.length;
    }

    // Filter programs
    if(filterProgram.length > 0){
      programResult = programs.filter(program =>
        filterProgram.includes(program.id),
      );
      if(query !== ''){
        programResult = programResult.filter(program => {
          if(program.title && program.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            program.shortDescription &&
            program.shortDescription.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }
      total += programResult.length;
    }else{
      if(query !== ''){
        programResult = programs.filter(program => {
          if(program.title && program.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            program.shortDescription &&
            program.shortDescription.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }else{
        programResult = programs;
      }
      total += programResult.length;
    }

    // Filter events
    if(filterProgram.length > 0){
      eventResult = events.filter(event =>
        filterProgram.includes(event.program.id),
      );
      if(query !== ''){
        eventResult = eventResult.filter(event => {
          if(event.title.toLowerCase().includes(query)) return true;

          if(event.content && event.content.Contents){
            return event.content.Contents.toLowerCase().includes(query);
          }
          return false;
        });
      }
      total += eventResult.length;
    }else{
      if(query !== ''){
        eventResult = events.filter(event => {
          if(event.title.toLowerCase().includes(query)) return true;

          if(event.content && event.content.Contents){
            return event.content.Contents.toLowerCase().includes(query);
          }
          return false;
        });
      }else{
        eventResult = events;
      }
      total += eventResult.length;
    }

    // Filter news
    if(filterProgram.length > 0){
      newsResult = news.filter(item =>
        filterProgram.includes(item.program.id),
      );
      console.log(news.map(p=>p.program.id),newsResult);
      if(query !== ''){
        newsResult = newsResult.filter(item => {
          if(item.title && item.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            item.description &&
            item.description.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }
      total += newsResult.length;
    }else{
      if(query !== ''){
        newsResult = news.filter(item => {
          if(item.title && item.title.toLowerCase().includes(query)){
            return true;
          }
          if(
            item.description &&
            item.description.toLowerCase().includes(query)
          ){
            return true;
          }
          return false;
        });
      }else{
        newsResult = news;
      }
      total += newsResult.length;
    }

    // Filter pageContentDetails
    console.log(filterProgram, query, pageContentDetails, pageContentDetailResult);
    if(filterProgram.length > 0){
      pageContentDetailResult = pageContentDetails.filter(item => filterProgram.includes(item.program.id));
      if(query !== '')
        pageContentDetailResult = pageContentDetails.filter(item =>
          (item.title && item.title.toLowerCase().includes(query)) ||
          (item.description && item.description.toLowerCase().includes(query)),
        );
      total += pageContentDetailResult.length;
    }else{
      if(query !== '')
        pageContentDetailResult = pageContentDetails.filter(item =>
          (item.title && item.title.toLowerCase().includes(query)) ||
          (item.description && item.description.toLowerCase().includes(query)),
        );
      else pageContentDetailResult = pageContentDetails;
      total += pageContentDetailResult.length;
    }
  }

  programResult = programResult.map(program => {
    program.type = 'program';
    return program;
  });

  projectResult = projectResult.map(project => {
    project.type = 'project';
    return project;
  });

  newsResult = newsResult.map(item => {
    item.type = 'news';
    return item;
  });

  eventResult = eventResult.map(event => {
    event.type = 'event';
    return event;
  });

  pageContentDetailResult = pageContentDetailResult.map(pageContentDetail => {
    pageContentDetail.type = 'pageContentDetail';
    return pageContentDetail;
  });

  console.log({
    programResult,
    projectResult,
    newsResult,
    eventResult,
    total,
    pageContentDetailResult,
  });

  return {
    programResult,
    projectResult,
    newsResult,
    eventResult,
    total,
    pageContentDetailResult,
  };
};
