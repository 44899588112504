import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from '../helpers';
import update from 'immutability-helper';


export default function mListMediaReducer(state = initialState.mListMedia, action){
  switch(action.type){
    case actionTypes.MLISTMEDIA_LOAD_START:
      return update(state, state[action.id] ? {
        [action.id]: {
          isLoading: {$set: true},
        },
      } : {
        [action.id]: {$set: {isLoading: true}},
      });
    case actionTypes.MLISTMEDIA_LOAD_FAIL:
      return update(state, {
        [action.id]: {
          isLoading: {$set: false},
          error: {$set: action.error},
        },
      });
    case actionTypes.MLISTMEDIA_LOAD_SUCCESS:{
      return update(state,  {
        [action.data.id]: {$set: action.data},
      });
    }

    case actionTypes.MLISTMEDIA_TOGGLE_LIGHTBOX:{
      return update(state, {
        [action.id]: {
          showLightbox: {$set: action.showLightbox}
        },
      });
    }

    case actionTypes.MLISTMEDIA_SET_SELECTEDITEM:{
      return update(state, {
        [action.id]: {
          selectedItem: {$set: action.selectedItem}
        },
      });
    }

    default:
      return state;
  }
}
