import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as pageActions from './pageActions';


export const initPageProgramsMaps = (slug) => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.program.endpoint, null, {Slug: slug})
      .then(results => {
        let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.program.fieldsUsed);
        page.contacts = page.contacts ? apiActions.arraySelectCamelCaseKeys(page.contacts, apiValues.nref.contact.fieldsUsed) : null;
        page.project = page.project ? apiActions.arraySelectCamelCaseKeys(page.project, apiValues.nref.project.fieldsUsed).filter(project => {
          return !project.softDelete;
        }) : null;

        page.program = {
          ...page,
        };

        return Promise.all([apiActions.loadEndpoint(apiValues.nref.memorial.endpoint, null),
                            apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {
                              Program_in: page.id,
                            })])
          .then(([memorials, projects]) => {
            page.memorial = apiActions.arraySelectCamelCaseKeys(
              memorials,
              apiValues.nref.memorial.fieldsUsed,
            );
            projects = projects ? apiActions.arraySelectCamelCaseKeys(projects, apiValues.nref.project.fieldsUsed).filter(project => {
              return !project.softDelete;
            }) : null;
            page.project = projects.map(project => {
              project.tags = apiActions.arraySelectCamelCaseKeys(project.tags, apiValues.nref.tag.fieldsUsed);
              return project;
            });

            return page;
          });
      })
      .then(results => {
        dispatch(pageActions.loadPageSuccess(results));
        resolve(results);
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
