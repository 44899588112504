import * as helpers from '../helpers';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class ContactItem extends React.Component{
  renderAddress = () => {
    if(!this.props.address) return null;

    return (
      <div className="contact-item__address">
        {this.props.address.split('\n').map((item, key) => {
          return <span key={key}>{item}<br/></span>;
        })}
      </div>
    );
  };
  renderDescription = () => {
    if(!this.props.description) return null;

    return (
      <div className="contact-item__description">{helpers.nToP(this.props.description)}</div>
    );
  };
  renderEmail = () => {
    if(!this.props.email) return null;

    return (
      <SuperLink to={`mailto:${this.props.email}`} className="contact-item__email">{this.props.email}</SuperLink>
    );
  };
  renderFeedbackFormLink = () => {
    if(!this.props.feedbackFormLink) return null;

    return (
      <p className="contact-item__feedback">
        Or use our <SuperLink to={this.props.feedbackFormLink}>Feedback Form</SuperLink>
      </p>
    );
  };
  renderName = () => {
    if(!this.props.name) return null;

    return (
      <div className="contact-item__name">{this.props.name}</div>
    );
  };
  renderPhoneNumber = () => {
    if(!this.props.phoneNumber) return null;

    return (
      <div className="contact-item__phone">{this.props.phoneNumber}</div>
    );
  };
  render(){
    if(!this.props.address &&
       !this.props.description &&
       !this.props.email &&
       !this.props.feedbackFormLink &&
       !this.props.name &&
       !this.props.phoneNumber) return null;

    return (
      <div className={`contact-item ${this.props.className || ''}`}>
        {this.renderName(this.props)}
        {this.renderDescription(this.props)}
        {this.renderPhoneNumber(this.props)}
        {this.renderEmail(this.props)}
        {this.renderAddress(this.props)}
        {this.renderFeedbackFormLink(this.props)}
      </div>
    );
  }
}


ContactItem.propTypes = {
  address: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  email: PropTypes.string,
  feedbackFormLink: PropTypes.string,
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
};
