import CarouselSlide from '../components/CarouselSlide';
import MBrandBar from '../components/MBrandBar';
import MCarousel from '../components/MCarousel';
import MFooter from '../components/MFooter';
import MGetInvolved from '../components/MGetInvolved';
import MHeader from '../components/MHeader';
import MIntro from '../components/MIntro';
import MLatestNews from '../components/MLatestNews';
import MListCards from '../components/MListCards';
import MListNav from '../components/MListNav';
import MProgramMapAd from '../components/MProgramMapAd';
import MProjectsCurrent from '../components/MProjectsCurrent';
import MapParent from '../components/MapParent';
import PageContent from '../components/PageContent';
import PageContentLanding from '../components/PageContentLanding';
import PageEventDetails from '../components/PageEventDetails';
import PageEvents from '../components/PageEvents';
import PageGetInvolved from '../components/PageGetInvolved';
import PageHome from '../components/PageHome';
import PageProjects from '../components/PageProjects';
import Tag from '../components/Tag';
import PageNews from '../components/PageNews';


//export const CMS_PATH = "https://ladot-staging-cms.getsomeglue.com";
// export const CMS_PATH = 'https://ladot-dev-cms.getsomeglue.com';
// export const CMS_PATH = 'http://192.168.43.215:1337'; //chris venus e4
//export const CMS_PATH = 'http://192.168.1.101:1337'; //chris 3D7D

export const CMS_PATH = 'https://ladotlivablestreets-cms.org'; //ladotlivablestreets-cms.org

export const IMG_PATH = CMS_PATH;


export const MAILCHIMP_LISTID = '46eca3db3b';
export const MAILCHIMP_API_KEY = '94397d000225b69fdcb8a6a878bd83f1-us17';
export const PROJECTS_PER_PAGE = 12;
export const STUB_DESCRIPTION_LENGTH = 150;

export const BROWSER_TITLE_PREFIX = '';
export const BROWSER_TITLE_SUFFIX = ' | LADOT Livable Streets';
export const OG_SITE_NAME = 'LADOT Livable Streets';

export const MBRANDBAR_ID = '5c4737d7a4dcf5002c5647ca';
export const MHEADER_ID = '5bfeffa76d09534d2922e695';
export const MFOOTER_ID = '5bfc1c3eddaa44007fd28f45';
export const HEADER_WHOWEARE_ID = '5bff06c16d09534d2922e696';
export const HEADER_WHOWEARE_LIST_ID = '5c547420e5bdca0029e6eac8';
export const HEADER_WHATWEDO_ID = '5bff06e16d09534d2922e697';
export const HEADER_WHATWEDO_SAFETYPROGRAM_ID = '5bff07bd6d09534d2922e698';
export const HEADER_WHATWEDO_STREETSPROGRAM_ID = '5c547642e5bdca0029e6eace';
export const HEADER_OURPROJECTS_ID = '5bff0e4c6d09534d2922e69a';
export const HEADER_OURPROJECTS_FEATURED_ID = '5c4532dda4dcf5002c5647bf';
export const HEADER_OURPROJECTS_TYPES_ID = '5bff0e7b6d09534d2922e69c';
export const HEADER_GETINVOLVED_ID = '5c548246e5bdca0029e6eadf';
export const HEADER_GETINVOLVED_TAKEACTION_ID = '5c547136e5bdca0029e6eab8';
export const HEADER_GETINVOLVED_FROMYOU_ID = '5c547168e5bdca0029e6eab9';
export const HEADER_MAPSANDDATA_ID = '5c546ef6e5bdca0029e6eab1';
export const HEADER_MAPSANDDATA_LIST_ID = '5c546f2de5bdca0029e6eab2';
export const HEADER_SEARCH_ID = '5c5db88ae5bdca0029e6eaef';
export const HEADER_SEARCHLINKS_ID = '5c5db87be5bdca0029e6eaee';
export const PAGE_CONTENTDETAIL_RELATEDPROJECTS_ID = '5c9e330f44fc4d63e92edf6c';
export const PAGE_PROGRAMS_MLISTCARDS_ID = '5c9e330f44fc4d63e92edf6c';
export const PROGRAM_ACTIVETRANSPORTATION_ID = '5c79a4d0c8927a6ab1f6da2c';

export const FACEBOOK_URL = 'https://www.facebook.com/ladotofficial';
export const INSTAGRAM_URL = 'https://www.instagram.com/ladotofficial/';
export const TWITTER_URL = 'https://twitter.com/LADOTofficial';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC90xEQsc5WdUuFg-I8j-cuQ';


//Normalization reference (keys we want, keys strapi has)
//export const nref = {
//  camelCaseTypeName: { - the name is js friendly
//    component<react component> - undefined or the react component
//    endpoint<string> - the endpoint string for accessing the data from strapi
//    isModule<bool> - is this a module content type?
//    fieldsUsed<string>[] - the strapi names for the fields we'll use in our code. This is used for building the component data in a clean object with camelCased properties
//  },
//  ...
//};
export const nref = {
  card: {
    component: undefined,
    endpoint: 'cards',
    isModule: false,
    fieldsUsed: [
      'Background',
      'CMS Name',
      'Description',
      'Display Mode',
      'id',
      'Link URL',
      'LinkMedia',
      'MGetInvolved',
      'Opens In New Tab',
      'SoftDelete',
      'Title',
    ],
  },
  carouselSlide: {
    component: CarouselSlide,
    endpoint: 'carouselSlides',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'mCarousels',
      'SoftDelete',
    ],
  },
  category: {
    component: undefined,
    endpoint: 'categories',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  council: {
    component: undefined,
    endpoint: 'councils',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'District Link URL', //todo: make url capitalization match
      'District Title',
      'id',
      'Location Link Url', //todo: make url capitalization match
      'Location Title',
      'SoftDelete',
    ],
  },
  contact: {
    component: undefined,
    endpoint: 'contacts',
    isModule: false,
    fieldsUsed: [
      'Address',
      'Description',
      'Email',
      'Events',
      'Feedback Form Link',
      'id',
      'Name',
      'Phone Number',
      'Programs',
      'SoftDelete',
    ],
  },
  event: {
    component: undefined,
    endpoint: 'events',
    isModule: false,
    fieldsUsed: [
      'Category',
      'CMS Name',
      'ContactInfo',
      'Contacts',
      'Content',
      'EventDate',
      'id',
      'Location Description',
      'Program',
      'Slug',
      'SoftDelete',
      'Tags',
      'Timestamp',
      'Title',
    ],
  },
  getInvolvedCard: {
    component: undefined,
    endpoint: 'getInvolvedCards',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'Image',
      'mGetInvolveds',
      'navigationlink',
      'SoftDelete',
      'Title',
    ],
  },
  mapParent: {
    component: MapParent,
    endpoint: 'projects',
    isModule: false,
    fieldsUsed: [
      'Center ArcGIS ID',
      'CMS Name',
      'Contact Description',
      'Council',
      'Description',
      'id',
      'Location Description',
      'Phase',
      'PhotosAndVideos',
      'Program',
      'ProjectDocuments',
      'ProjectFeatures',
      'ProjectPartners',
      'ProjectTypes',
      'Slug',
      'SoftDelete',
      'Status',
      'Tags',
      'Thumbnail',
      'Title',
      'What Description',
      'Why Description',
    ],
  },
  mBrandBar: {
    component: MBrandBar,
    endpoint: 'mBrandBars',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'id',
      'Logo Alt',
      'Logo',
      'SoftDelete',
    ],
  },
  mCarousel: {
    component: MCarousel,
    endpoint: 'mCarousels',
    isModule: true,
    fieldsUsed: [
      'BackgroundImage',
      'BackgroundVideo',
      'CarouselSlides',
      'CMS Name',
      'id',
      'SoftDelete',
    ],
  },
  mediaItem: {
    component: undefined,
    endpoint: 'mediaitems',
    isModule: false,
    fieldsUsed: [
      'Alt',
      'Caption',
      'id',
      'MediaEmbed',
      'MediaImage',
      'MediaVideo',
      'SoftDelete',
    ],
  },
  memorial: {
    component: undefined,
    endpoint: 'memorials',
    isModule: false,
    fieldsUsed: [
      'Address',
      'Age',
      'CaseId',
      'CMS Name',
      'Description',
      'EventDate',
      'Media',
      'Point_X',
      'Point_Y',
      'SoftDelete',
      'Title',
    ],
  },
  mFooter: {
    component: MFooter,
    endpoint: 'mFooters',
    isModule: true,
    fieldsUsed: [
      'Bot Left Text',
      'CMS Name',
      'Contact Text',
      'id',
      'Legal Text',
      'MobileContactLinks',
      'MobileLinks',
      'SocialList',
      'SoftDelete',
      'SubmenuA',
      'SubmenuB',
      'SubmenuC',
    ],
  },
  mGetInvolved: {
    component: MGetInvolved,
    endpoint: 'mGetInvolveds',
    isModule: true,
    fieldsUsed: [
      'Button Label',
      'Cards',
      'CMS Name',
      'Description',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  mHeader: {
    component: MHeader,
    endpoint: 'mHeaders',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'id',
      'ImageBackground',
      'ImageLogo',
      'mListNavs',
      'Public Name',
      'SoftDelete',
      'Text Optional',
      'Title Optional',
      'Title',
    ],
  },
  mIntro: {
    component: MIntro,
    endpoint: 'mIntros',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'Display Mode',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  mLatestNews: {
    component: MLatestNews,
    endpoint: 'mLatestNews',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'Program',
      'SoftDelete',
      'Title',
    ],
  },
  mListCards: {
    component: MListCards,
    endpoint: 'mListCards',
    isModule: true,
    fieldsUsed: [
      'BackgroundBlockColor',
      'Cards',
      'CMS Name',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  mListNav: {
    component: MListNav,
    endpoint: 'mListNavs',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'Description Optional',
      'Description',
      'Display Mode',
      'Display Position',
      'id',
      'ImageBackground',
      'ImageOptional',
      'mHeaders',
      'NavigationLinks',
      'ProjectTypes',
      'Public Name',
      'SoftDelete',
      'Title Short',
      'Title',
      'Url',
    ],
  },
  mProgramMapAd: {
    component: MProgramMapAd,
    endpoint: 'mProgramMapAds',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'Program',
      'SoftDelete',
      'Title',
    ],
  },
  mProjectsCurrent: {
    component: MProjectsCurrent,
    endpoint: 'mProjectsCurrents',
    isModule: true,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  navigationLink: {
    component: undefined,
    endpoint: 'navigationLinks',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'Document',
      'id',
      'ImageOptional',
      'Public Name',
      'SoftDelete',
      'Title',
      'Type',
      'Url',
    ],
  },
  news: {
    component: undefined,
    endpoint: 'news',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Content',
      'Description',
      'id',
      'NewsDate',
      'Program',
      'Slug',
      'SoftDelete',
      'Tags',
      'Timestamp',
      'Title',
    ],
  },
  pageContent: {
    component: PageContent,
    endpoint: 'pagecontents',
    isModule: false,
    fieldsUsed: [
      'Browser Title',
      'CMS Name',
      'Content',
      'id',
      'Slug',
      'SoftDelete',
    ],
  },
  pageContentDetail: {
    component: undefined,
    endpoint: 'pagecontentdetails',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Contacts',
      'id',
      'Long Description',
      'PhotosAndVideos',
      'Program',
      'Projects',
      'Short Description Small',
      'Short Description',
      'Slug',
      'SoftDelete',
      'Tags',
      'Title',
    ],
  },
  pageContentLanding: {
    component: PageContentLanding,
    endpoint: 'pagecontentlandings',
    isModule: false,
    fieldsUsed: [
      'Body',
      'BrandBar',
      'Browser Title',
      'CardList',
      'CMS Name',
      'Footer',
      'Header',
      'id',
      'NewsRelatedProgram',
      'NewsRelatedProject',
      'SoftDelete',
      'Subtitle',
      'Title',
    ],
  },
  pageEvents: {
    component: PageEvents,
    endpoint: 'pageevents',
    isModule: false,
    fieldsUsed: [
      'CMSName',
      'Description',
      'EventsListing',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  pageEventDetails: {
    component: PageEventDetails,
    endpoint: 'pageeventdetails',
    isModule: false,
    fieldsUsed: [
      'CMSName',
      'EventDetailIntro',
      'EventDetails',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  pageGetInvolved: {
    component: PageGetInvolved,
    endpoint: 'pagegetinvolveds',
    isModule: false,
    fieldsUsed: [
      'Body',
      'CardListA',
      'CardListB',
      'CardListC',
      'CMS Name',
      'Contact',
      'EventsRelatedProgram',
      'EventsRelatedProject',
      'EventsRelatedTag',
      'id',
      'Intro Subtitle',
      'SoftDelete',
      'Title',
    ],
  },
  pageHome: {
    component: PageHome,
    endpoint: 'pagehomes',
    isModule: false,
    fieldsUsed: [
      'CMSName',
      'CurrentProjects',
      'GetInvolved',
      'HeroCarousel',
      'id',
      'SoftDelete',
      'SoftDelete',
    ],
  },
  pageNews: {
    component: PageNews,
    endpoint: 'pagenews',
    isModule: false,
    fieldsUsed: [
      'CMSName',
      'id',
      'Description',
      'SoftDelete',
      'Title',
    ],
  },
  pageProjects: {
    component: PageProjects,
    endpoint: 'pageprojects',
    isModule: false,
    fieldsUsed: [
      'CMSName',
      'id',
      'Description',
      'SoftDelete',
      'Title',
    ],
  },
  program: {
    component: undefined,
    endpoint: 'programs',
    isModule: false,
    fieldsUsed: [
      'ApplicationSteps',
      'CMS Name',
      'Color',
      'Contacts',
      'Events',
      'FeaturedProjects',
      'Get Involved Application Address',
      'Get Involved Email',
      'GetInvolvedContent',
      'GetInvolvedOnlineApplicationUrl',
      'GetInvolvedSubhead',
      'id',
      'Logo',
      'Long Description',
      'Maps Intro Text',
      'MProgramMapAd',
      'PaperForms',
      'Project',
      'RelatedTag',
      'Short Description Small',
      'Short Description',
      'Slug',
      'SoftDelete',
      'Title',
    ],
  },
  programApplicationStep: {
    component: undefined,
    endpoint: 'programapplicationsteps',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  projectFeatureLocation: {
    component: undefined,
    endpoint: 'projectFeatureLocations',
    isModule: false,
    fieldsUsed: [
      'id',
      'CMS Name',
      'ArcGIS ID',
      'ProjectFeatures',
      'Title',
    ],
  },
  projectFeature: {
    component: undefined,
    endpoint: 'projectFeatures',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'id',
      'Image',
      'ProjectFeatureLocations',
      'Projects',
      'SoftDelete',
      'Title',
    ],
  },
  projectPartner: {
    component: undefined,
    endpoint: 'projectPartners',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'id',
      'Link URL',
      'Projects',
      'SoftDelete',
      'Title',
    ],
  },
  project: {
    component: undefined,
    endpoint: 'projects',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Contact Description',
      'Council',
      'CouncilDistrictNames',
      'Description',
      'FeaturedProgram',
      'FeedbackEmail',
      'id',
      'LastSave',
      'Location Description',
      'NeighborhoodCouncilName',
      'PhotosAndVideos',
      'Program',
      'Project Id',
      'ProjectDocuments',
      'ProjectFeatures',
      'ProjectPartners',
      'projectstatus',
      'ProjectTypes',
      'Slug',
      'SoftDelete',
      'Status',
      'Tags',
      'Thumbnail',
      'Title',
      'What Description',
      'Why Description',
    ],
  },
  projectStatus: {
    component: undefined,
    endpoint: 'projectStatuses',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'id',
      'SoftDelete',
      'Title',
    ],
  },
  projectType: {
    component: undefined,
    endpoint: 'projectTypes',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Display Order',
      'Filter Value',
      'Icon',
      'IconHover',
      'id',
      'mListNavs',
      'Projects',
      'Public Name',
      'SoftDelete',
      'Text Optional',
      'Title',
      'Url',
    ],
  },
  tag: {
    component: Tag,
    endpoint: 'tags',
    isModule: false,
    fieldsUsed: [
      'CMS Name',
      'Description',
      'Events',
      'id',
      'News',
      'SoftDelete',
      'Title',
    ],
  },
};
export const nref_keys = Object.keys(nref);
export const nref_moduleNames = nref_keys.filter(key => {
  return nref[key].isModule;
});

export const imgPath = src => {
  return `${IMG_PATH}${src}`;
};
export const buildTitle = title => {
  return title && title.length > 0 ? `${BROWSER_TITLE_PREFIX}${title}${BROWSER_TITLE_SUFFIX}` : OG_SITE_NAME;
};
