import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as helpers from "../helpers";

//Action Creators
export const loadMFooterStart = () => {
  return {type: actionTypes.MFOOTER_LOAD_START};
};
export const loadMFooterFail = (error) => {
  console.error(error);
  return {type: actionTypes.MFOOTER_LOAD_FAIL, error};
};
export const loadMFooterSuccess = (data) => {
  return {type: actionTypes.MFOOTER_LOAD_SUCCESS, payload: data};
};


export const initMFooter = id => dispatch => {
  dispatch(loadMFooterStart(id));

  apiActions.loadEndpoint(apiValues.nref.mFooter.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mFooter.fieldsUsed);

    dispatch(loadMFooterSuccess(normalized));
  });
};

export const searchStart = (query, id) => {
  return {
    type: actionTypes.MFOOTER_SEARCH_START,
    payload: {
      searchQuery: query,
      id,
    }
  };
};

export const searchSuccess = (data) => {
  return {
    type: actionTypes.MFOOTER_SEARCH_SUCCESS,
    payload: data
  };
};

export const doSearch = (query, id) => dispatch => {
  dispatch(searchStart(query, id));

  if (query && query.length > 0) {
    const titlePromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Title_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));
    const neighborhoodsPromise = apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Neighborhoods_contains: query, _limit: 5})
      .then(results => (results ? apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.project.fieldsUsed) : []));

    return Promise.all([titlePromise, neighborhoodsPromise])
      .then(([titleResults, neighborhoodsResults]) => {
        const results = {suggestedProjects: helpers.mergeArraysWithoutDuplicatesBasedOnProperty(titleResults, neighborhoodsResults, "id")};
        results.id = id;
        dispatch(searchSuccess(results));
      });
  }
  else {
    dispatch(searchSuccess({
      id,
      suggestedProjects: undefined,
      viewProjectsNear: undefined
    }));
  }
};

export const toggleSearch = (isSearchOpen, id) => {
  return {
    type: actionTypes.MFOOTER_TOGGLE_SEARCH,
    payload: {
      id,
      isSearchOpen
    }
  };
};
