import ButtonInput from './ButtonInput';
import PropTypes from 'prop-types';
import React from 'react';


export class SiteSearch extends React.Component{
  state = {};


  componentDidMount(){
    window.addEventListener('click', this.document_onClick);
    window.addEventListener('ontouchstart', this.document_onClick);
    window.addEventListener('touchstart', this.document_onClick);
  }
  componentWillUnmount(){
    window.removeEventListener('click', this.document_onClick);
    window.removeEventListener('ontouchstart', this.document_onClick);
    window.removeEventListener('touchstart', this.document_onClick);
  }


  document_onClick = (e) => {
    // console.log(`document_onClick`);
    this.props.onBlur ? this.props.onBlur(e) : null;
  };
  form_onSubmit = (e) => {
    if(this.props.onSubmit) this.props.onSubmit(e);
    e.preventDefault();
  };
  siteSearch_onClick = (e) => {
    // console.log(`siteSearch_onClick`);
    this.siteSearch_onFocus(e);
    if(this.props.onFocus) this.props.onFocus(e);
    e.stopPropagation();
  };
  siteSearch_onFocus = (e) => {
    var currentTarget = e.currentTarget;

    setTimeout(() => {
      if(currentTarget.contains(document.activeElement)){
        // console.log('component officially focused');
        if(this.props.onFocus) this.props.onFocus(e);
      }
    }, 0);
  };


  render(){
    //console.log(`SiteSearch render`, this.state, this.props);

    let openClass = this.props.isOpen ? 'is-open' : '';

    return (
      <div className={`site-search ${this.props.className || ''} ${openClass}`}
           onClick={this.siteSearch_onClick}
           onFocus={this.siteSearch_onFocus}>
        <form onSubmit={this.form_onSubmit} className='site-search__form'>

          <ButtonInput onClearClick={this.props.onClearClick}
                       onInputChange={this.props.onInputChange}
                       inputPlaceholder={this.props.inputPlaceholder}
                       defaultValue={this.props.defaultValue}
                       required={this.props.required}
                       isOpen={this.props.isOpen}/>

          <div className="site-search__button">
            <button type="submit" className="site-search__submit-pill tg-button tg-button--pill tg-button--blue">
              <span className="tg-button__label">Search</span>
            </button>
          </div>

        </form>
        <div className="site-search__results">
          <div className="site-search__results-container">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}


SiteSearch.propTypes = {
  children: PropTypes.object,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  inputPlaceholder: PropTypes.string,
  isOpen: PropTypes.bool,
  onBlur: PropTypes.func,
  onClearClick: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  required: PropTypes.bool,
};
