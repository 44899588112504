import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function mapProgramReducer(state = initialState.mapProgram, action) {
  switch (action.type) {
    case actionTypes.MAPPROGRAM_FETCH_MEMORIALS:
      return {
        ...state,
        memorials: action.memorials
      };
    case actionTypes.MAPPROGRAM_FETCH_PROJECTS:
      return {
        ...state,
        projects: action.projects
      };
    case actionTypes.MAPPROGRAM_SEARCH_START:
      return {
        ...state,
        isLoading: true,
        isSearchOpen: true,
        searchQuery: action.searchQuery
      };
    case actionTypes.MAPPROGRAM_SEARCH_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error,
        isSearchOpen: true
      };
    case actionTypes.MAPPROGRAM_SEARCH_SUCCESS: {
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined,
        isSearchOpen: true
      };
    }
    case actionTypes.MAPPROGRAM_TOGGLE_SEARCH: {
      return {
        ...state,
        isSearchOpen: action.isSearchOpen
      };
    }

    default:
      return state;
  }
}
