import * as mEventsActions from '../actions/mEventsActions';
import EventItem from './EventItem';
import iconSearch from '../img/icon-search.png';
import iconXBlack from '../img/icon-x-black.png';
import ListFilter from './ListFilter';
import Pagination from './Pagination';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const EVENTS_PER_PAGE = 100;
const getFilteredEvents = (events,
                           value,
                           categories,
                           programs,
                           startDate,
                           endDate) => {
  let newEvents = events.filter(event => {
    if(!event.title.toLowerCase().includes(value.toLowerCase())){
      return false;
    }
    if(programs.length > 0){
      if(!event.program){
        return false;
      }
      if(!programs.includes(event.program.id)){
        return false;
      }
    }
    if(categories.length > 0){
      if(!event.category){
        return false;
      }
      if(!categories.includes(event.category.id)){
        return false;
      }
    }
    if(startDate){
      if(!event.eventDate){
        return false;
      }
      const eventDate = new Date(event.eventDate);
      if(!eventDate.getDate()){
        return false;
      }
      if(eventDate < startDate){
     

       return false;
      }
      


    }
    if(endDate){
      if(!event.eventDate){
        return false;
      }
      const eventDate = new Date(event.eventDate);
      if(!eventDate.getDate()){
        return false;
      }
      if(eventDate > endDate){
        
        return false;
      }
    }
      return true;
  });
  return newEvents || [];
};

let currentDateSet = new Date();

class MEvents extends React.Component{
  state = {
    currentPage: 1,
    value: '',
    selectedProgram: [],
    selectedCategory: [],
//    startDate: null,

    startDate: currentDateSet,
    endDate: null,
    modalOpen: false,
  };


  constructor(props){
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClearInput = this.handleClearInput.bind(this);
  }
  componentDidMount(){
    //console.log(`MEvents.componentDidMount()`, this.props);

    //if (this.props.cmsId) {
    this.props.actions.initMEvents();
    //}
    //else {
    //  //console.log(`passed in `, this.props.mListCards);
    //}


    const query = QueryString.parse(this.props.location.search);
    if(query && query.program){
      this.setState({
        selectedProgram: [query.program],
      });
    }
  }


  handleCategoryFilter = (value, type) => {
    if(type === 'category'){
      const {selectedCategory} = this.state;
      if(selectedCategory.includes(value)){
        selectedCategory.splice(selectedCategory.indexOf(value), 1);
        this.setState({
          selectedCategory,
        });
      }
      else{
        selectedCategory.push(value);
        this.setState({
          selectedCategory,
        });
      }
    }
    else if(type === 'program'){
      const {selectedProgram} = this.state;

      if(selectedProgram.includes(value)){
        selectedProgram.splice(selectedProgram.indexOf(value), 1);
        this.setState({
          selectedProgram,
        });
      }
      else{
        selectedProgram.push(value);
        this.setState({
          selectedProgram,
        });
      }
    }
  };
  handleClearFilter = () => {
    this.setState({
      selectedCategory: [],
      selectedProgram: [],
      startDate: null,
      endDate: null,
    });
  };
  handleClearInput = () => {
    this.setState({
      value: '',
    });
  };
  handleEndDate = endDate => {
    this.setState({
      endDate,
    });
  };
  handleInputChange = evt => {
    this.setState({
      value: evt.target.value,
    });
  };
  handleModalShow = () => {
    this.setState({
      modalOpen: true,
    });
  };
  handleModalHide = () => {
    this.setState({
      modalOpen: false,
    });
  };
  handlePagination = page => {
    this.setState({
      currentPage: page,
    });
  };
  handleStartDate = startDate => {
    this.setState({
      startDate,
    });
  };


  renderEvents = () => {
    const {
      currentPage,
      value,
      selectedCategory,
      selectedProgram,
      startDate,
      endDate,
    } = this.state;
    const {mEvents} = this.props;
    //console.log("---mevents---", mEvents);
    //console.log("---state---", this.state);

    if(!mEvents.events) return null;
    const newEvents = getFilteredEvents(
      mEvents.events,
      value,
      selectedCategory,
      selectedProgram,
      startDate,
      endDate,
    );

    return newEvents
      .slice((currentPage - 1) * EVENTS_PER_PAGE, EVENTS_PER_PAGE * currentPage)
      .map((event, index) => {
        return (
          <div key={event.id} className="col-12">
            <EventItem event={event}
                       className={index === EVENTS_PER_PAGE - 1 ? 'remove--border' : ''}/>
          </div>
        );
      });
  };
  renderResultStatus = () => {
    const {mEvents} = this.props;
    const {
      currentPage,
      value,
      selectedCategory,
      selectedProgram,
      startDate,
      endDate,
    } = this.state;

    if(!mEvents.events) return null;
    const newEvents = getFilteredEvents(
      mEvents.events,
      value,
      selectedCategory,
      selectedProgram,
      startDate,
      endDate,
    );
    const startFrom = (currentPage - 1) * EVENTS_PER_PAGE + 1;
    const lastTo =
      currentPage * EVENTS_PER_PAGE > newEvents.length
      ? newEvents.length
      : currentPage * EVENTS_PER_PAGE;

    return (
      <div className="m-events__results">
        {`Showing ${startFrom}-${lastTo} of ${newEvents.length}`}
        <span className="m-events__filter-button" onClick={this.handleModalShow}>
          Filter Events
        </span>
      </div>
    );
  };
  render(){
    //console.log(`MEvents render`, this.props);
    if(!this.props.mEvents ||
       !this.props.mEvents.events) return null;

    const {
      value,
      selectedCategory,
      selectedProgram,
      startDate,
      endDate,
      modalOpen,
    } = this.state;
    const {mEvents} = this.props;
    const totalPage = mEvents && mEvents.events && mEvents.events.length > 0 ? Math.ceil(
      getFilteredEvents(
        mEvents.events,
        value,
        selectedCategory,
        selectedProgram,
        startDate,
        endDate,
      ).length / EVENTS_PER_PAGE,
    ) : 0;

    const isOpen = value && value.length !== '' ? true : false;

    return (
      <div className="columns">
        <div className="coupling coupling--parent coupling--left">
          <div className={`m-events ${this.props.className || ''}`}>
            <div className="container container--coupling">
              <div className={`button-input ${isOpen ? 'search-input-open' : ''}`}>
                <button aria-label="Search"
                        className="button-input__button"
                        type="submit">
                  <img alt="Search" className="button-input__button-icon" src={iconSearch}/>
                </button>

                <input aria-label="Search Input"
                       className={`button-input__input input ${isOpen ? 'search-input-open' : ''}`}
                       type="text"
                       name="query"
                       value={value}
                       placeholder="Search events and meetings by phrase or keyword"
                       required
                       onChange={this.handleInputChange}/>

                {isOpen && <button aria-label="Clear"
                                   className="button-input__button button-input__button--clear"
                                   type="button"
                                   onClick={this.handleClearInput}>
                  <img alt="Clear" className="button-input__button-icon" src={iconXBlack}/>
                </button>}
              </div>
              {totalPage !== 0 && this.renderResultStatus()}
              {totalPage === 0 && (
                <div className="no-search-results">
                  <div>
                    {`Sorry this search doesn't have any results.`}
                  </div>
                  <div className="no-results-suggestion">
                    Search Suggestions
                    <ul>
                      <li>Check spelling</li>
                      <li>Try more general words</li>
                      <li>Remove some search filters</li>
                      <li>
                        <a href="/get-involved" target="_blank">Contact us</a> {`if you still can't find what you are looking for`}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="m-events__listing">
                <div className="row">{this.renderEvents()}</div>
              </div>
              <Pagination
                totalPage={totalPage}
                currentPage={this.state.currentPage}
                handlePagination={this.handlePagination}
              />
            </div>
          </div>
        </div>
        <div className="coupling coupling--parent coupling--right">
          <ListFilter programs={mEvents.programs || []}
                      selectedProgram={selectedProgram}
                      categories={mEvents.categories || []}
                      selectedCategory={selectedCategory}
                      title="Filter Events"
                      handleCategoryFilter={this.handleCategoryFilter}
                      handleStartDate={this.handleStartDate}
                      handleEndDate={this.handleEndDate}
                      handleClearFilter={this.handleClearFilter}
                      modalOpen={modalOpen}
                      handleModalHide={this.handleModalHide}/>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mEvents: state.mEvents,
  }),
  dispatch => ({
    actions: bindActionCreators(mEventsActions, dispatch),
  }),
)(MEvents));


MEvents.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  location: PropTypes.object,
  mEvents: PropTypes.object,
};
