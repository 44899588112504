import React, {Component} from 'react';
import PageBase from './PageBase';
import MBreadcrumbs from './MBreadcrumbs';


export default class PageGoogleForm extends Component{
  render(){
    const mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Bike Rack Request', slug: '#'},
      ],
    };

    return (
      <PageBase>
        <div className="coupling coupling--parent coupling--full main-content">
          <MBreadcrumbs mBreadcrumbs={mBreadcrumbs}
                        className="coupling coupling--full-centered"/>
          <div className="coupling coupling--parent coupling--full page-google-from">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdbdsoGKNEScCfVL-f980wzz3xPIKqknQieTOF3LFAVXbIGOA/viewform?embedded=true"
                    width="760"
                    height="1900"
                    frameBorder="0"
                    marginWidth="0"
                    marginHeight="0">Loading
            </iframe>
          </div>
        </div>
      </PageBase>
    );
  }
}
