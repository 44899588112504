import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const loadMRichTextStart = (id) => {
  return {
    type: actionTypes.MRICHTEXT_LOAD_START,
    id
  };
};
export const loadMRichTextFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MRICHTEXT_LOAD_FAIL,
    id,
    error
  };
};
export const loadMRichTextSuccess = (data) => {
  return {
    type: actionTypes.MRICHTEXT_LOAD_SUCCESS,
    data
  };
};


export const initMRichText = id => dispatch => {
  //console.log(`initMRichText`, id);
  dispatch(loadMRichTextStart(id));

  apiActions.loadEndpoint(apiValues.nref.mRichText.endpoint, id).then(result => {
    console.log(`loaded mRichText`, id, result);
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mRichText.fieldsUsed);
    dispatch(loadMRichTextSuccess(normalized));
  });
};


