import * as helpers from '../helpers';
import * as mListMediaActions from '../actions/mListMediaActions';
import Lightbox from './Lightbox';
import MediaItem from './MediaItem';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MListMedia extends React.Component{
  componentDidMount(){
    console.log(`MListMedia.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMListMediaWithEndpoint(this.props.cmsId);
    }
    else if(this.props.mListMedia && this.props.needsInit){
      console.log(`passed in `, this.props.mListMedia);
      this.props.actions.initMListMedia(this.props.mListMedia);
    }
  }


  lightboxClose_onClick = () => {
    console.log(`MListMedia.mediaItem_onClick`, this.props.mListMedia.id);
    this.props.actions.toggleLightbox(this.props.mListMedia.id, false);
  };
  mediaItem_onClick = (mediaItem, i) => {
    console.log(`MListMedia.mediaItem_onClick`, this.props.mListMedia.id);
    this.props.actions.toggleLightbox(this.props.mListMedia.id, true);
    this.props.actions.setSelectedItem(this.props.mListMedia.id, i);
  };


  renderMediaItems(){
    if(!this.props.mListMedia.mediaItems) return null;

    return this.props.mListMedia.mediaItems.map((mediaItem, i) => {
      let sizeClass = this.props.colClass || 'col-12 col-sm-6 col-md-4';
      return (
        <div key={mediaItem.id ? mediaItem.id : mediaItem.key} className={`${sizeClass} media-column-item`}>
          <MediaItem className="m-listmedia__mediaitem"
                     cmsId={mediaItem.id ? mediaItem.id : undefined}
                     mediaItem={mediaItem.id ? undefined : mediaItem}
                     onClick={() => this.mediaItem_onClick(mediaItem, i)}/>
        </div>
      );
    });
  }
  renderTitle(){
    if(!this.props.mListMedia.title) return null;
    return (<div className="m-listmedia__title">{this.props.mListMedia.title}</div>);
  }
  render(){
    console.log(`MListMedia render`, this.state, this.props);
    if(!this.props.mListMedia ||
       !this.props.mListMedia.mediaItems ||
       this.props.mListMedia.mediaItems.length < 1) return null;

    return (
      <div className={`m-listmedia ${this.props.className || ''}`}>
        <div className="m-listmedia__container container container--coupling">
          <div className="row">
            {this.renderTitle()}
          </div>
          <div className="row">
            {this.renderMediaItems()}
          </div>
        </div>
        <Lightbox isOpen={this.props.mListMedia.showLightbox}
                  mediaItems={this.props.mListMedia.mediaItems}
                  onCloseClick={this.lightboxClose_onClick}
                  selectedItem={this.props.mListMedia.selectedItem}/>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => {
    const referenceId = ownProps.cmsId || ownProps.mListMedia.id || undefined;
    return state.mListMedia[referenceId] ? {
      mListMedia: state.mListMedia[referenceId],
      needsInit:false
    } : {
      mListMedia: ownProps.mListMedia,
      needsInit:true
    };
  },
  (dispatch) => ({
    actions: bindActionCreators(mListMediaActions, dispatch),
  }),
)(MListMedia));


MListMedia.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mediaItem: PropTypes.object,
  mListMedia: PropTypes.object,
  needsInit:PropTypes.bool,
  colClass: PropTypes.string,
};
