import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";

export default function mFooterReducer(state = initialState.mFooter, action) {
  switch (action.type) {
    case actionTypes.MFOOTER_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });

    case actionTypes.MFOOTER_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });

    case actionTypes.MFOOTER_LOAD_SUCCESS: {
      return helpers.updateOrAdd(state, {
        ...action.payload,
        isLoading: false,
        error: undefined
      });
    }
    case actionTypes.MFOOTER_SEARCH_START:
      return helpers.updateOrAdd(state, {
        isSearchOpen: true
      });
    case actionTypes.MFOOTER_SEARCH_SUCCESS:
      return helpers.updateOrAdd(state, {
        ...action.payload,
        isSearchOpen: true,
      });
    case actionTypes.MFOOTER_TOGGLE_SEARCH: {
      return helpers.updateOrAdd(state, {
        ...action.payload,
      });
    }
    default:
      return state;
  }
}
