import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getMProjectsById} from "../reducers/index";


//Action Creators
export const loadMNewsDetailStart = () => {
  return {
    type: actionTypes.MNEWSDETAIL_LOAD_START
  };
};
export const loadMNewsDetailFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MNEWSDETAIL_LOAD_FAIL,
    error
  };
};
export const loadMNewsDetailSuccess = (data) => {
  return {
    type: actionTypes.MNEWSDETAIL_LOAD_SUCCESS,
    data
  };
};

export const initMNewsDetail = slug => dispatch => {
  dispatch(loadMNewsDetailStart());
  let mNewsDetail = {};
  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {Slug: slug})
      .then(result => {
        mNewsDetail.news = apiActions.itemSelectCamelCaseKeys(result[0], apiValues.nref.news.fieldsUsed);
        mNewsDetail.news.tags = apiActions.arraySelectCamelCaseKeys(mNewsDetail.news.tags, apiValues.nref.tag.fieldsUsed);
        resolve(dispatch(loadMNewsDetailSuccess(mNewsDetail)));
      })
      .catch(error => {
        console.error(error)
        reject(error);
      });
  });
};


