import * as apiValues from '../constants/apiValues';
import * as PageProjectsExternalActions from '../actions/pageProjectsExternalActions';
import * as PageProjectsActions from '../actions/pageProjectsActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MIntro from './MIntro';
import MProjectListMap from './MProjectListMap';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MapParent from './MapParent';


class PageProjectsExternal extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageProjectsExternal.componentDidMount()`, this.props);
    this.props.actions.pageProjectsExternal.init();
    this.props.actions.pageProjects.initPageProjects();
  }


  render(){
    //console.log(`PageProjectsExternal.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <React.Fragment>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        <MapParent className='m-projectlistmap--external'
                   history={this.props.history}
                   isPageProjectsExternal={true}/>
      </React.Fragment>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: {
      pageProjectsExternal: bindActionCreators(PageProjectsExternalActions, dispatch),
      pageProjects: bindActionCreators(PageProjectsActions, dispatch),
    },
  }),
)(PageProjectsExternal));


PageProjectsExternal.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
