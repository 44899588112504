import 'react-responsive-carousel/lib/styles/carousel.min.css';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mCarouselActions from '../actions/mCarouselActions';
import CarouselSlide from './CarouselSlide';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { Carousel } from 'react-responsive-carousel';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MCarousel extends React.Component{
  state = {
    isDev: window.location.host !== 'ladotlivablestreets.org'
  };

  componentDidMount(){
    //console.log(`MCarousel.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMCarousel(this.props.cmsId);
    }
    else{
      //console.log(`passed in `, this.props.mListMedia);
    }
  }


  renderBackground(){
    if(this.props.mCarousel.backgroundVideo && !this.state.isDev){
      return (
        <div className="m-carousel__background-video-container">
          <video className="m-carousel__background-video"
                 src={apiValues.imgPath(this.props.mCarousel.backgroundVideo.url)}
                 type="*"
                 autoPlay loop muted playsInline></video>
          <div className="m-carousel__background-img" style={{backgroundImage: `url(${apiValues.imgPath(this.props.mCarousel.backgroundImage.url)})`}}/>
        </div>
      );
    }
    else if(this.props.mCarousel.backgroundImage){
      return (
        <div className="m-carousel__background-img" style={{backgroundImage: `url(${apiValues.imgPath(this.props.mCarousel.backgroundImage.url)})`}}/>
      );
    }
  }
  renderCarousel(){
    if(!this.props.mCarousel.carouselSlides) return null;

    return (
      <div className="m-carousel__carousel-container">
        <Carousel showStatus={false} showThumbs={false} infiniteLoop={true} selectedItem={this.props.selectedItem}>
          {this.props.mCarousel.carouselSlides.map(carouselSlideId => {
            let carouselSlide = helpers.getById(this.props.carouselSlide, carouselSlideId);

            return carouselSlide ? (
              <CarouselSlide className="m-carousel__carouselslide" key={carouselSlide.id} cmsId={carouselSlide.id}>
                <img alt="" src=""/>
              </CarouselSlide>
            ) : null;
          })}
        </Carousel>
      </div>
    );
  }
  render(){
    // console.log(`MCarousel render`, this.props);
    if(!this.props.mCarousel ||
       !this.props.mCarousel.carouselSlides ||
       this.props.mCarousel.carouselSlides.length < 1) return null;

    return (
      <div className={`m-carousel ${this.props.className || ''}`}>
        <div className="container container--coupling">
          <div className="row">
            {this.renderBackground()}
            {this.renderCarousel()}
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    carouselSlide: state.carouselSlide,
    mCarousel: ownProps.cmsId ? helpers.getById(state.mCarousel, ownProps.cmsId) : undefined
  }),
  (dispatch) => ({
    actions: bindActionCreators(mCarouselActions, dispatch)
  })
)(MCarousel));


MCarousel.propTypes = {
  actions: PropTypes.object,
  carouselSlide: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mCarousel: PropTypes.object,
  selectedItem: PropTypes.object
};
