import * as apiValues from '../constants/apiValues';
import * as projectTypeListActions from '../actions/projectTypeListActions';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class ProjectTypeList extends React.Component{
  filter = '';


  componentDidMount(){
    //console.log(`ProjectTypeList.componentDidMount()`, this.props);

    let defaultValue = this.props.defaultValue ? this.props.defaultValue.split(',') : null;
    this.props.actions.initProjectTypeList(this.props.navListId, defaultValue);
  }


  projectType_onClick = filter => {
    // console.log(`projectType_onClick`, filter);
    this.props.onFilter(filter);
  };


  renderProjectTypes(){
    return this.props.projectTypeList.types.map(type => {
      let activeClass = this.props.projectTypeList.selectedProjectTypes && (this.props.projectTypeList.selectedProjectTypes.length < 1 || this.props.projectTypeList.selectedProjectTypes.indexOf(type.id) >= 0) ? 'project-type-list__project-type--is-active' : '';
      let imgSrc = apiValues.imgPath(type.icon.url);

      if(this.props.onFilter) return (
        <span key={type.id}
              className={`project-type-list__project-type ${activeClass}`}
              onClick={() => {
                this.projectType_onClick(type.id);
              }}>
            <img alt={type.title} className="project-type-list__project-type-icon" src={imgSrc}/>
            <div className="project-type-list__project-type-title">{type.title}</div>
          </span>
      );
      else return (
        <a href={type.url}
           key={type.id}
           className={`project-type-list__project-type ${activeClass}`}>
          <img alt={type.title} className="project-type-list__project-type-icon" src={imgSrc}/>
          <div className="project-type-list__project-type-title">{type.title}</div>
        </a>
      );
    });
  }
  render(){
    //console.log(`ProjectTypeList.render()`, this, this.props.projectTypeList.types);
    if(!this.props.projectTypeList || !this.props.projectTypeList.types) return null;

    return (
      <div className={`project-type-list ${this.props.className || ''}`}>
        {this.renderProjectTypes()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    projectTypeList: state.projectTypeList
  }),
  (dispatch) => ({
    actions: bindActionCreators(projectTypeListActions, dispatch)
  })
)(ProjectTypeList));


ProjectTypeList.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  navListId: PropTypes.string.isRequired,
  onFilter: PropTypes.func,
  projectTypeList: PropTypes.object.isRequired,
  defaultValue: PropTypes.string
};
