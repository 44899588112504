import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const loadMapParentStart = (id) => {
  return {
    type: actionTypes.MAPPARENT_LOAD_START,
    id
  };
};
export const loadMapParentFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MAPPARENT_LOAD_FAIL,
    id,
    error
  };
};
export const loadMapParentSuccess = (data) => {
  return {
    type: actionTypes.MAPPARENT_LOAD_SUCCESS,
    data
  };
};


export const initMapParent = id => dispatch => {
  console.log('initMapParentMap id: ', id);
  dispatch(loadMapParentStart(id));

  apiActions.loadEndpoint(apiValues.nref.projects.endpoint, id).then(result => {
    //let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mapParent.fieldsUsed);
    console.log("Load MapProjects", result);
    dispatch(loadMapParentSuccess(result));
  });
};
