import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as cardActions from './cardActions';
//import {getMListCardsById} from "../reducers/index";


//Action Creators
export const loadMListCardsStart = (id) => {
  return {
    type: actionTypes.MLISTCARDS_LOAD_START,
    id
  };
};
export const loadMListCardsFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTCARDS_LOAD_FAIL,
    id,
    error
  };
};
export const loadMListCardsSuccess = (data) => {
  return {
    type: actionTypes.MLISTCARDS_LOAD_SUCCESS,
    data
  };
};

export const initMListCards = id => dispatch => {
  dispatch(loadMListCardsStart(id));

  apiActions.loadEndpoint(apiValues.nref.mListCards.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListCards.fieldsUsed);

    let cards = apiActions.arraySelectCamelCaseKeys(normalized.cards, apiValues.nref.card.fieldsUsed);
    cards.forEach(card => {
      dispatch(cardActions.loadCardSuccess(card))
    });
    normalized.cards = cards.map(card=>card.id);

    dispatch(loadMListCardsSuccess(normalized));
  });
};


