import React from 'react';
import * as apiActions from './actions/apiActions';


export const screenSizes = {
  xsMin: 0,
  xsMax: 575,
  smMin: 576,
  smMax: 767,
  mdMin: 768,
  mdMax: 991,
  lgMin: 992,
  lgMax: 1199,
  xlMin: 1200
};


export const camelize = (str) => {
  return str.replace(/\b\w/g, chr => chr.toUpperCase()).replace('-', ' ');
};
export const camelCaseToDashCase = string =>{
  return string.replace(/[\w]([A-Z])/g, function(m) {
    return m[0] + "-" + m[1];
  }).toLowerCase();
}
export const camelCaseToSnakeCase = string =>{
  return string.replace(/[\w]([A-Z])/g, function(m) {
    return m[0] + "_" + m[1];
  }).toLowerCase();
}
export const checkStatus = response => {
  if(response.ok) return response;
  else throw new Error(response.error);
};
export const cleanObj = myObj => {
  Object.keys(myObj).forEach(key => {
    !myObj[key] && delete myObj[key];
  });

  return myObj;
};
export const debounceEvent = (callback, time = 250, interval) =>
  (...args) =>
    clearTimeout(interval, interval = setTimeout(() => callback(...args), time));
export const deepClone = obj => {
  return JSON.parse(JSON.stringify(obj));
};
export const encodeData = data => {
  return Object.keys(data)
    .map(function(key){
      return [key, data[key]].map(encodeURIComponent).join('=');
    })
    .join('&');
};
export const getById = (state, id) => state.find(item => item.id === id);
export const getHighlightedText = (text, highlight) => {
  if(typeof highlight == 'undefined') return text;

  // Split on highlight term and include term into parts, ignore case
  let parts = text.split(new RegExp(`(${highlight})`, 'gi'));
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
            ? {fontWeight: 'bold'}
            : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};
export const GetIEVersion = () => {
  var sAgent = window.navigator.userAgent;
  var Idx = sAgent.indexOf('MSIE');

  // If IE, return version number.
  if(Idx > 0)
    return parseInt(sAgent.substring(Idx + 5, sAgent.indexOf('.', Idx)));

  // If IE 11 then look for Updated user agent string.
  else if(navigator.userAgent.match(/Trident\/7\./))
    return 11;

  else
    return 0; //It is not IE
};
export const intersection = (array1, array2) => {
  return array1.filter(element => array2.includes(element));
};
export const isScreenLargerThan = (size) => {
  if(typeof size == 'string' && screenSizes[size]) return window.innerWidth > screenSizes[size];
  else if(!isNaN(size)) return window.innerWidth > size;
  else return null;
};
export const isScreenSmallerThan = (size) => {
  if(typeof size == 'string' && screenSizes[size]) return window.innerWidth < screenSizes[size];
  else if(!isNaN(size)) return window.innerWidth < size;
  else return null;
};
export const lowercaseFirstLetter = string => {
  return string.charAt(0).toLowerCase() + string.slice(1);
};
export const mergeArraysWithoutDuplicatesBasedOnProperty = (a1, a2, propertyName) => {
  var ids = new Set(a1.map(d => d[propertyName]));
  return [...a1, ...a2.filter(d => !ids.has(d[propertyName]))];
};
export const mergeArrayofArraysWithoutDuplicatesBasedOnProperty = (arrays, propertyName) => {
  if(!arrays || !Array.isArray(arrays) || arrays.length < 1) return undefined;
  let newArray = [...arrays[0]];
  arrays.slice(1).forEach(otherArray => {
    mergeArraysWithoutDuplicatesBasedOnProperty(newArray, otherArray, propertyName);
  });

  return newArray;
};
export const multiFieldContainsQuery = (queryStr, endpoint, fieldNames, matchingField, generalFilters) => {
  //if querystring is empty or unset then we it's a call for the main list
  if(typeof queryStr == 'undefined' || queryStr.length < 1)
    return apiActions.loadEndpoint(endpoint, null, generalFilters);
  else
    return Promise.all(fieldNames.map(fieldName => {
      let filters = {...generalFilters};
      filters[`${fieldName}_contains`] = queryStr;

      return apiActions.loadEndpoint(endpoint, null, filters);
    })).then(results => {
      return mergeArrayofArraysWithoutDuplicatesBasedOnProperty(results, matchingField).slice(0, generalFilters._limit);
    });
};
export const nLinks = text => {
  return text.split('\n').map(item => {
    return item;
  });
};
export const nToP = text => {
  return text.split('\n').map((item, i) => {
    return <p key={i}>{item}</p>;
  });
};
export const parseJSON = data => {
  return data.json();
};
export const toCamelCase = str => {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (ltr, idx) =>
      idx === 0 ? ltr.toLowerCase() : ltr.toUpperCase()
    )
    .replace(/\s+/g, '');
};
/***
 * replaceArrayItemsWithAnotherArrayBasedOnProperty
 * Pass in 2 arrays of objects and the name of a property to match
 *
 * @param a1 - primary array of objects
 * @param a2 - updating array of objects
 * @param propertyName - string name of property to match
 */
export const replaceArrayItemsWithAnotherArrayBasedOnProperty = (a1, a2, propertyName) => {
  return a1.map(
    (a) => {
      return this[a[propertyName]] || a;
    },
    a2.reduce((r, a) => {
      r[a[propertyName]] = a;
      return r;
    }, Object.create(null))
  );
};
export const trimToWord = (str, maxLength) => {
  if(!str) return str;
  var trimmedString = str.substr(0, maxLength);
  trimmedString = trimmedString.substr(
    0,
    Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
  );
  return trimmedString;
};
export const updateOrAddMany = (arr, items) => {
  items.forEach(item => arr = updateOrAdd(arr, item));
  return arr;
};
export const updateOrAdd = (arr, item) => {
  let replaced = false;
  let newArr = arr.map(arr_item => {
    let match = arr_item.id == item.id;
    replaced = match ? true : replaced;
    return match ? {...arr_item, ...item} : arr_item;
  });
  return replaced ? newArr : [...arr, item];
};
export const urlParams = () => {
  return location.search
    .slice(1)
    .split('&')
    .map(p => p.split('='))
    .reduce((obj, pair) => {
      const [key, value] = pair.map(decodeURIComponent);
      return {...obj, [key]: value};
    }, {});
};
export const handle404 = (history) => {
  history.replace('/404');
};
