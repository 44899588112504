import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";

export default function mProjectsCurrentReducer(state = initialState.mProjectsCurrent, action) {
  switch (action.type) {
    case actionTypes.MPROJECTSCURRENT_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });
    case actionTypes.MPROJECTSCURRENT_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });
    case actionTypes.MPROJECTSCURRENT_LOAD_SUCCESS: {
      return helpers.updateOrAdd(state, {
        ...action.data,
        isLoading: false,
        error: undefined
      });
    }

    case actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });
    case actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });
    case actionTypes.MPROJECTSCURRENT_PROJECTS_LOAD_SUCCESS: {
      return helpers.updateOrAdd(state, {
        id: action.id,
        projects: action.projects,
        isLoading: false,
        error: undefined
      });
    }

    case actionTypes.MPROJECTSCURRENT_SET_FILTER: {
      return helpers.updateOrAdd(state, {
        id: action.id,
        selectedProjectTypes: action.selectedProjectTypes
      });

    }
    case actionTypes.MPROJECTSCURRENT_SET_LISTLENGTH: {
      return helpers.updateOrAdd(state, {
        id: action.id,
        listLength: action.listLength
      });
    }

    default:
      return state;
  }
}
