import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageProgramsMapActions from '../actions/pageProgramsMapActions';
import Helmet from 'react-helmet';
import MapProgram from './MapProgram';
import MBreadcrumbs from './MBreadcrumbs';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {programsMapConf} from '../constants/programsMapValues';
import {withRouter} from 'react-router-dom';


class PageProgramsMap extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageProgramsMap.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageProgramsMaps(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    if(!this.props.page.program) return null;

    const slug = this.props.match && this.props.match.params && this.props.match.params.slug;
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: this.props.page.program.title, slug: `programs/${slug}`},
        {browserTitle: 'Maps', slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered m-breadcrumbs--project-details" mBreadcrumbs={mBreadcrumbs}/>);
  }
  render(){
    //console.log(`render PageProgramsMap`, this.props);
    if(!this.props.page ||
       !this.props.page.id ||
       !this.props.page.program) return null;

    const slug = this.props.match && this.props.match.params && this.props.match.params.slug;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(`${this.props.page.program.title} Map`)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <MapProgram mode={programsMapConf[slug]['mode']}
                    tabs={programsMapConf[slug]['tabs']}
                    history={this.props.history}
                    location={this.props.location}
                    match={this.props.match}/>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageProgramsMapActions, dispatch),
  }),
)(PageProgramsMap));


PageProgramsMap.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};
