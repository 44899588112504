import * as helpers from '../helpers';
import * as SearchActions from '../actions/SearchActions';
import iconXBlack from '../img/icon-x-black.png';
import ListFilter from './ListFilter';
import Pagination from './Pagination';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import SearchItem from './SearchItem';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const ITEMS_PER_PAGE = 10;
const filterTypes = [
  {id: 'project', title: 'Project'},
  {id: 'news', title: 'News'},
  {id: 'event', title: 'Event'},
  {id: 'document', title: 'Document'},
  {id: 'program', title: 'Program'},
  {id: 'map', title: 'Map'},
  {id: 'other', title: 'Other'},
];


class Search extends React.Component{
  state = {
    currentPage: 1,
    value: '',
    selectedProgram: [],
    selectedType: [],
    modalOpen: false,
  };
  lastUrl = '';


  constructor(props){
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClearInput = this.handleClearInput.bind(this);
  }
  componentDidMount(){
    this.fetchContent();
  }
  componentDidUpdate(){
    if(window.location.href != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = window.location.href;
    const query = QueryString.parse(this.props.location.search);
    this.props.actions.setQuery(query.q);
    this.setState({
      value: query.q,
    }, () => console.log(`Search.componentDidMount()`, query, this.state));

    this.props.actions.initSearch();
  }


  handleCategoryFilter = (value, type) => {
    console.log(value,type);
    if(type === 'type'){
      const {selectedType} = this.state;
      if(selectedType.includes(value)){
        selectedType.splice(selectedType.indexOf(value), 1);
        this.setState({
          selectedType,
        });
        this.props.actions.setTypes(selectedType);
      }
      else{
        selectedType.push(value);
        this.setState({
          selectedType,
        });
        this.props.actions.setTypes(selectedType);
      }
    }
    else if(type === 'program'){
      const {selectedProgram} = this.state;

      if(selectedProgram.includes(value)){
        selectedProgram.splice(selectedProgram.indexOf(value), 1);
        this.setState({
          selectedProgram,
        });
        this.props.actions.setProgram(selectedProgram);
      }
      else{
        selectedProgram.push(value);
        this.setState({
          selectedProgram,
        });
        this.props.actions.setProgram(selectedProgram);
      }
    }
  };
  handleClearFilter = () => {
    this.setState({
      selectedCategory: [],
      selectedProgram: [],
      startDate: null,
      endDate: null,
    });
  };
  handleClearInput = () => {
    this.setState({
      value: '',
    });

    this.props.actions.setQuery('');
  };
  handleInputChange = evt => {
    this.setState({
      value: evt.target.value,
    });

    this.props.actions.setQuery(evt.target.value);
  };
  handleModalHide = () => {
    this.setState({
      modalOpen: false,
    });
  };
  handleModalShow = () => {
    this.setState({
      modalOpen: true,
    });
  };
  handlePagination = page => {
    this.setState({
      currentPage: page,
    });
  };


  renderItems = () => {
    const {currentPage} = this.state;
    const {searchResult} = this.props;
    const {
      total,
      newsResult,
      eventResult,
      programResult,
      projectResult,
    } = searchResult;

    if(total === 0) return null;

    const newResult = newsResult
      .concat(eventResult)
      .concat(programResult)
      .concat(projectResult);

    return newResult
      .slice((currentPage - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE * currentPage)
      .map((item, index) => {
        return (
          <div key={item.id} className="col-12">
            <SearchItem title={helpers.getHighlightedText(item.title, this.state.value)}
                        url={item.slug || ''}
                        type={item.type}
                        className={index === ITEMS_PER_PAGE - 1 ? 'remove--border' : ''}/>
          </div>
        );
      });
  };
  renderResultStatus = () => {
    const {searchResult} = this.props;
    const {total, filterQuery} = searchResult;
    const {currentPage} = this.state;
    const startFrom = (currentPage - 1) * ITEMS_PER_PAGE + 1;
    const lastTo = currentPage * ITEMS_PER_PAGE > total ? total : currentPage * ITEMS_PER_PAGE;

    return (
      <div className="search__results">
        {`Results ${startFrom}-${lastTo} of ${total}`}
        {filterQuery !== '' && (
          <span>
            {' '}
            for <span style={{fontWeight: 600}}>{filterQuery}</span>
          </span>
        )}
      </div>
    );
  };
  render(){
    //console.log(`MEvents render`, this.props);


    const {value, selectedType, selectedProgram, modalOpen} = this.state;
    const {searchResult} = this.props;
    const totalPage = searchResult.total / ITEMS_PER_PAGE;
    const isOpen = value && value.length !== '' ? true : false;

    return (
      <div className="columns">
        <div className="coupling coupling--parent coupling--left">
          <div className={`search-page ${this.props.className || ''}`}>
            <div className="container container--coupling">
              <div className={`button-input ${isOpen ? 'search-input-open' : ''}`}>
                {/* <button className="button-input__button"
                  type="submit">
                  <img className="button-input__button-icon" src={iconSearch} alt="Search" />
                </button> */}

                <input aria-label="Search Input"
                       className={`button-input__input input ${isOpen ? 'search-input-open' : ''}`}
                       type="text"
                       name="query"
                       value={value}
                       placeholder="Search"
                       required
                       onChange={this.handleInputChange}/>

                {isOpen && <button aria-label="Clear"
                                   className="button-input__button button-input__button--clear"
                                   type="button"
                                   onClick={this.handleClearInput}>
                  <img alt="Clear" className="button-input__button-icon" src={iconXBlack}/>
                </button>}
              </div>
              {searchResult.total !== 0 && this.renderResultStatus()}
              {searchResult.total === 0 && (
                <div className="no-search-results">
                  <div>
                    {`Sorry this search doesn't have any results.`}
                  </div>
                  <div className="no-results-suggestion">
                    Search Suggestions
                    <ul>
                      <li>Check spelling</li>
                      <li>Try more general words</li>
                      <li>Remove some search filters</li>
                      <li>
                        <a href="/get-involved" target="_blank">Contact us</a> {`if you still can't find what you are looking for`}
                      </li>
                    </ul>
                  </div>
                </div>
              )}
              <div className="news__listing">
                <div className="row">{this.renderItems()}</div>
              </div>
              <Pagination totalPage={totalPage}
                          currentPage={this.state.currentPage}
                          handlePagination={this.handlePagination}/>
            </div>
          </div>
        </div>
        <div className="coupling coupling--parent coupling--right">
          <ListFilter programs={searchResult.programs || []}
                      types={filterTypes}
                      selectedProgram={selectedProgram}
                      selectedType={selectedType}
                      title="Filter Results"
                      handleCategoryFilter={this.handleCategoryFilter}
                      disableDate={true}
                      handleClearFilter={this.handleClearFilter}
                      modalOpen={modalOpen}
                      handleModalHide={this.handleModalHide}/>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  state => ({
    searchResult: state.searchResult,
  }),
  dispatch => ({
    actions: bindActionCreators(SearchActions, dispatch),
  }),
)(Search));


Search.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  searchResult: PropTypes.object,
};
