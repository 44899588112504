import * as pageActions from "./pageActions";
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


export const initPageGetInvolvedFeedback = () => (dispatch) => {
  dispatch(pageActions.loadPageStart());
  return apiActions.loadEndpoint(apiValues.nref.program.endpoint)
    .then(results => {
      const page = {
        programs: apiActions.arraySelectCamelCaseKeys(results, apiValues.nref.program.fieldsUsed)
      }

      return page;
    })
    .then(results => {
      return dispatch(pageActions.loadPageSuccess(results));
    });
};
