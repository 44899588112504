import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mBrandBarActions from '../actions/mBrandBarActions';
import GoogleTranslate from './GoogleTranslate';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MBrandBar extends React.Component{
  componentDidMount(){
    //console.log(`MBrandBar.componentDidMount()`, this.props);
    this.props.actions.initMBrandBar(this.props.cmsId);
  }


  renderLogo(){
    if(!this.props.mBrandBar.logo) return null;

    return (
      <span className="m-brandbar__logo">
          <img alt="City Logo" src={`${apiValues.CMS_PATH}${this.props.mBrandBar.logo.url}`}/>
        </span>
    );
  }
  render(){
    //console.log(`MBrandBar render`, this.props);
    if(!this.props.mBrandBar) return null;

    return (
      <div className={`m-brandbar ${this.props.className || ''}`}>
        <div className="container container--coupling">
          <div className="row">
            {this.renderLogo()}

            <div className="m-brandbar__buttons">
              <span className="m-brandbar__language">
                <span className="m-brandbar__label-desktop">Language:&nbsp;</span>
                <GoogleTranslate/>
              </span>
              <SuperLink to="https://myla311.lacity.org/" className="m-brandbar__services">
                <span className="tg-button tg-button--pill-brandbar">
                  <span className="tg-button__label">311</span>
                </span>
                <span className="m-brandbar__label-desktop">City Services</span>
              </SuperLink>
              <SuperLink to="https://www.lacity.org/your-government/government-information/city-directory" className="m-brandbar__directory">
                <span className="tg-button tg-button--pill-brandbar">
                  <span className="tg-button__label">LA</span>
                </span>
                <span className="m-brandbar__label-desktop">City Directory</span>
              </SuperLink>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mBrandBar: helpers.getById(state.mBrandBar, ownProps.cmsId),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mBrandBarActions, dispatch),
  }),
)(MBrandBar));


MBrandBar.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  mBrandBar: PropTypes.object,
};
