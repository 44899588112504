import iconClose from '../img/icons/icon-close.svg';
import iconSearch from '../img/icons/icon-search.svg';
import PropTypes from 'prop-types';
import React from 'react';


export default class ButtonInput extends React.Component{
  state = {};


  constructor(){
    super();
    this.queryInputRef = React.createRef();
  }


  onClearClick = () => {
    this.props.onClearClick ? this.props.onClearClick() : null;
    if(this.queryInputRef.current)
      this.queryInputRef.current.value = '';
  };
  onInputChange = (e) => {
    this.props.onInputChange ? this.props.onInputChange(e) : null;
  };


  handleKeyDown = event => {
    if(this.props.onSubmit){
      if(event.key === 'Enter'){
        let clearResults = this.props.onSubmit();
        if(clearResults) this.onClearClick();
      }
    }
  };


  renderX = () => {
    if(!this.props.showX && !this.props.isOpen) return null;

    return (
      <button aria-label="Clear" className="button-input__button button-input__button--clear" type="button" onClick={this.onClearClick.bind(this)}>
        <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
      </button>
    );
  };
  render(){
    //console.log(`ButtonInput.render()`, this.props);

    let inputPlaceholder = this.props.inputPlaceholder || 'Search';

    return (
      <div className="button-input">
        <button aria-label="Search" className="button-input__button button-input__button--search" type="submit">
          <img alt='Search' className="button-input__button-icon" src={iconSearch}/>
        </button>

        <input aria-label="Search Input"
               className={`button-input__input input ${this.props.isOpen ? 'search-input-open' : ''}`}
               defaultValue={this.props.defaultValue}
               id={this.props.inputID}
               type="text"
               name="query"
               placeholder={inputPlaceholder}
               required={this.props.required}
               onChange={this.onInputChange.bind(this)}
               onKeyDown={this.handleKeyDown}
               ref={this.queryInputRef}/>

        {this.renderX()}
      </div>
    );
  }
}


ButtonInput.propTypes = {
  defaultValue: PropTypes.any,
  inputID: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  isOpen: PropTypes.bool,
  onClearClick: PropTypes.func,
  onInputChange: PropTypes.func,
  onSubmit: PropTypes.func,
  required: PropTypes.bool,
  showX: PropTypes.bool
};
