import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as pageActions from './pageActions';


export const initPageEvents = () => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return apiActions.loadEndpoint(apiValues.nref.pageEvents.endpoint)
    .then(results => {
      let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageEvents.fieldsUsed);
      page.intro = {
        title: page.title,
        description: page.description
      };
      // page.eventsListing = apiActions.itemSelectCamelCaseKeys(page.eventsListing, apiValues.nref.mEvents.fieldsUsed);

      return page;
    })
    .then(results => {
      return dispatch(pageActions.loadPageSuccess(results));
    });
};
