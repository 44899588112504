import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
import * as pageActions from "./pageActions";


export const initPageProjects = () => (dispatch) => {
  dispatch(pageActions.loadPageStart());

  return apiActions.loadEndpoint(apiValues.nref.pageProjects.endpoint)
    .then(results => {
      let page = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.pageProjects.fieldsUsed);
      page.intro = {
        title: page.title,
        description: page.description
      };
      page.projectListMap = {};
      return page;
    })
    .then(results => {
      return dispatch(pageActions.loadPageSuccess(results));
    })
};
