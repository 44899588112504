import * as mRelatedProjectsActions from '../actions/mRelatedProjectsActions';
import ProjectStub from './ProjectStub';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MRelatedProjects extends React.Component{
  componentDidMount(){
    console.log(`MHeader.componentDidMount()`, this.props.mRelatedProjects);
    if(this.props.mRelatedProjects){
      console.log('Supplied');
    }
    else{
      this.props.actions.initMRelatedProjects({
        program: this.props.program,
        relatedProgram: this.props.relatedProgram,
        relatedTag: this.props.relatedTag,
      });
    }
  }


  getProjectsList = () => {
    if(!this.props.mRelatedProjects.projects && !this.props.featuredProjects) return [];

    return [
      ...(this.props.featuredProjects ? this.props.featuredProjects : []),
      ...(this.props.mRelatedProjects.projects ? this.props.mRelatedProjects.projects : []),
    ].filter(project => !project.softDelete).slice(0, 4);
  };


  renderDescription(){
    if(!this.props.description) return null;

    return (
      <div className="m-relatedprojects__description">{this.props.description}</div>
    );
  }
  renderLoadMoreButton(){
    let label = `See All Projects`;
    let url = `/projects`;

    if(this.props.program){
      label = `See All ${this.props.program.title} Projects`;
      url = `/projects?Program_in=${this.props.program.id}`;
    }

    if(this.props.filter && this.props.filter.program){
      label = `See All ${this.props.filter.program.title} Projects`;
      url = `/projects?Program_in=${this.props.filter.program.id}`;
    }

    return (
      <div className="text-center">
        <SuperLink to={url} className="m-relatedprojects__load-more tg-button tg-button--pill tg-button--black">
          <span className="tg-button__label">{label}</span>
        </SuperLink>
      </div>
    );
  }
  renderProjects(){
    const projects = this.getProjectsList();
    if(projects.length < 1) return null;

    return (
      <div className="m-relatedprojects__listing">
        <div className="row">
          {projects.map(project => {
            return (
              <ProjectStub key={project.id} cmsId={project.id} hideLabel={true} className="col-12 col-sm-6 col-md-4 col-lg-3"/>
            );
          })}
        </div>
        {this.renderLoadMoreButton()}
      </div>
    );
  }
  render(){
    // console.log(`MRelatedProjects.render()`, this.props);
    if(!this.props.mRelatedProjects ||
       this.getProjectsList().length < 1) return null;

    return (
      <div className={`m-relatedprojects ${this.props.className}`}>
        <div className="container container--coupling">
          <div className="m-relatedprojects__intro-container">
            <div className="m-relatedprojects__title">Latest {this.props.programTitle} Projects</div>
            {this.renderDescription()}
          </div>
          {this.renderProjects()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    mRelatedProjects: ownProps.mRelatedProjects ? ownProps.mRelatedProjects : state.mRelatedProjects,
  }),
  dispatch => ({
    actions: bindActionCreators(mRelatedProjectsActions, dispatch),
  }),
)(MRelatedProjects));


MRelatedProjects.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  description: PropTypes.string,
  featuredProjects: PropTypes.array,
  filter: PropTypes.object,
  mRelatedProjects: PropTypes.object,
  program: PropTypes.object,
  programTitle: PropTypes.string,
  relatedProgram: PropTypes.object,
  relatedProject: PropTypes.object,
  relatedTag: PropTypes.object,
  title: PropTypes.string,
};
