import * as mProgramMapAdActions from '../actions/mProgramMapAdActions';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MProgramMapAd extends React.Component{
  componentDidMount(){
    if(this.props.cmsId){
      // this.props.actions.initMProgramMapAd(this.props.cmsId);
    }
    else{
      // Additional setup
      //console.log(this.props);
    }
  }


  renderDescription(){
    if(!this.props.mProgramMapAd.Description) return null;

    return (
      <div className="m-programmapad__description">
        {this.props.mProgramMapAd.Description}
      </div>
    );
  }
  renderTitle(){
    if(!this.props.mProgramMapAd.Title) return null;

    return (
      <div className="m-programmapad__title">
        {this.props.mProgramMapAd.Title}
      </div>
    );
  }
  render(){
    // console.log(`MProgramMapAd.render()`, this.props);
    if(!this.props.mProgramMapAd ||
       !this.props.program ||
       (!this.props.mProgramMapAd.Description && !this.props.mProgramMapAd.Title)) return null;

    return (
      <SuperLink className={`m-programmapad ${this.props.className || ''}`}
                 to={`/programs/${this.props.program.slug}/maps`}>
        <div className="container container--coupling">
          <div className="m-programmapad__container">
            <div className="m-programmapad__sizer"></div>
            <div className="m-programmapad__content">
              {this.renderTitle()}
              {this.renderDescription()}
            </div>
          </div>
        </div>
      </SuperLink>
    );
  }
}


export default withRouter(connect(
  dispatch => ({
    actions: bindActionCreators(mProgramMapAdActions, dispatch),
  }),
)(MProgramMapAd));


MProgramMapAd.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mProgramMapAd: PropTypes.object,
  program: PropTypes.object,
};
