import * as apiValues from '../constants/apiValues';
import iconFacebook from '../img/icons/icon-social-facebook.svg';
import iconInstagram from '../img/icons/icon-social-instagram.svg';
import iconTwitter from '../img/icons/icon-social-twitter.svg';
import iconYoutube from '../img/icons/icon-share-youtube.svg';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class MListFollow extends React.Component{
  title;


  componentDidMount(){
    this.title = this.props.title ? this.props.title : 'FOLLOW US';
  }


  render(){
    return (
      <div className={`m-list-follow ${this.props.className}`}>
        <div className="container container--coupling">
          <span className="m-list-follow__title">{this.title}</span>
          <SuperLink to={apiValues.FACEBOOK_URL} className="m-list-follow__link">
            <img alt='Follow us on Facebook' className="button__icon" src={iconFacebook}/>
          </SuperLink>
          <SuperLink to={apiValues.TWITTER_URL} className="m-list-follow__link">
            <img alt='Follow us on Twitter' className="button__icon" src={iconTwitter}/>
          </SuperLink>
          <SuperLink to={apiValues.YOUTUBE_URL} className="m-list-follow__link">
            <img alt='Follow us on YouTube' className="button__icon" src={iconYoutube}/>
          </SuperLink>
          <SuperLink to={apiValues.INSTAGRAM_URL} className="m-list-follow__link">
            <img alt='Follow us on Instagram' className="button__icon" src={iconInstagram}/>
          </SuperLink>
        </div>
      </div>
    );
  }
}


MListFollow.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};
