import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getTagById} from "../reducers/index";


//Action Creators
export const loadTagStart = (id) => {
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_START,
    id
  };
};
export const loadTagFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_FAIL,
    id,
    error
  };
};
export const loadTagSuccess = (data) => {
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_SUCCESS,
    data
  };
};


export const initTag = id => dispatch => {
  dispatch(loadTagStart(id));

  apiActions.loadEndpoint(apiValues.nref.tag.endpoint, id).then(result => {
    let n = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.tag.fieldsUsed);

    dispatch(loadTagSuccess(n));
  });
};
