import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';


export default class NewsItem extends React.Component{
  renderEventContent = (event, disableDescription) => {
    if(!event.content || disableDescription) return null;

    return (
      <div className="news-item__description">
        {helpers.trimToWord(
          event.content.Contents,
          apiValues.STUB_DESCRIPTION_LENGTH,
        )}
      </div>
    );
  };
  render(){
    if(!this.props.event) return null;

    const eventDate = moment(this.props.event.newsDate);
    if(!eventDate.isValid()) return null;
    const eventString = eventDate.format('MMM D, YYYY');
    const color = this.props.event.program && this.props.event.program.Color ? this.props.event.program.Color : '#DD9405';

    return (
      <div className={`news-item ${this.props.className}`}>
        <div className={`news-item__calendar`} style={{color: color}}>{eventString}</div>
        <div className="news-item__content">
          <SuperLink to={`/news/${this.props.event.slug}`} className="news-item__link">
            <div className="news-item__title">{this.props.event.title}</div>
          </SuperLink>
          {this.renderEventContent(this.props.event, this.props.disableDescription)}
          {!this.props.disableLocation && (
            <div className="news-item__location">{this.props.event.description}</div>
          )}
        </div>
      </div>
    );
  }
}


NewsItem.propTypes = {
  event: PropTypes.object,
  className: PropTypes.string,
  disableDescription: PropTypes.bool,
  disableLocation: PropTypes.bool,
};
