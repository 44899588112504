import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mediaItemActions from '../actions/mediaItemActions';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class MediaItem extends React.Component{
  componentDidMount(){
    //console.log(`MediaItem.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMediaItem(this.props.cmsId);
    }
    else{
      //console.log(`passed in `, this.props.mListNav);
    }
  }


  mediaItem_onClick = () => {
    if(this.props.onClick){
      event.preventDefault();
      this.props.onClick(this.props.mediaItem);
    }
  };


  renderCaption(){
    if(!this.props.mediaItem.description) return null;

    return (
      <div className="mediaitem__description">{helpers.nToP(this.props.mediaItem.caption)}</div>
    );
  }
  renderPreviewImage(){
    if(!this.props.mediaItem.mediaImage) return null;

    let imgPath = apiValues.imgPath(this.props.mediaItem.mediaImage.url);

    return (
      <div className="mediaitem__media-img d-3-2" style={{backgroundImage: `url(${imgPath})`}}></div>
    );
  }
  render(){
    //console.log(`MediaItem render`, this.props);
    if(!this.props.mediaItem ||
       !this.props.mediaItem.mediaImage) return null;

    return (
      <div className={`mediaitem ${this.props.className || ''}`}
           onClick={this.mediaItem_onClick}>
        {this.renderPreviewImage()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    // ownProps.cmsId ? {mediaItem: helpers.getById(state.mediaItem, ownProps.cmsId)} : {}
    ownProps.cmsId ? {mediaItem: state.mediaItem[ownProps.cmsId]} : {}
  ),
  (dispatch) => ({
    actions: bindActionCreators(mediaItemActions, dispatch),
  }),
)(MediaItem));


MediaItem.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mediaItem: PropTypes.object,
  onClick: PropTypes.func,
};
