import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mHeaderActions from '../actions/mHeaderActions';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class SuperLink extends React.Component{
  link_onClick = (e) => {
    this.props.actions.mHeaderActions.menuToggle(false);
    this.props.actions.mHeaderActions.toggleAllTabs(false);
    this.props.actions.mHeaderActions.expandedTab({
      expandedTabBarVisible: false,
      expandedTabBarWidth: 0,
      expandedTab: '',
    });
    this.props.onClick ? this.props.onClick(e) : null;
  };
  superLink_onClick = (e) => {
    this.link_onClick(e);
    if(this.props.onClick) this.props.onClick(e);

    setTimeout(() => {
      if(!this.props.to) return null;

      const to = this.props.to.replace(/(?:\[cms]|\[CMS])/g, apiValues.CMS_PATH); //lets user use [cms], replaced by the environment's cms-app path at runtime
      const isAbsolute = to.indexOf('://') >= 0;
      const isMailto = to.indexOf('mailto:') >= 0;
      if(isMailto) window.location = to;
      else if(isAbsolute || (this.props.target && this.props.target.length > 0)) window.open(to, this.props.target);
      else this.props.history.push(to);
    }, 1);
  };


  render(){
    // console.log(`SuperLink.render()`, this.props);

    return (
      <span className={`superlink ${this.props.className}`}
            onClick={(e) => {
              this.superLink_onClick(e);
            }}>{this.props.children}</span>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {navigationLink: helpers.getById(state.navigationLink, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: {
      mHeaderActions: bindActionCreators(mHeaderActions, dispatch),
    },
  }),
)(SuperLink));


SuperLink.propTypes = {
  actions: PropTypes.object,
  children: PropTypes.any,
  className: PropTypes.string,
  history: PropTypes.object,
  onClick: PropTypes.func,
  target: PropTypes.string,
  to: PropTypes.string,
};
