import * as helpers from '../helpers';
import * as tagActions from '../actions/tagActions';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class Tag extends React.Component{
  componentDidMount(){
    //console.log(`Tag.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initTag(this.props.cmsId);
    }
    else{
      //console.log(`passed in `, this.props.tag);
    }
  }


  render(){
    if(!this.props.tag) return null;

    return (
      <div className={`tag ${this.props.className}`}>
        <SuperLink to={`/search?tag=${this.props.tag.id}`} className="tag__link">
          {this.props.tag.title}
        </SuperLink>
      </div>
    );
  }
}

export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {tag: helpers.getById(state.tag, ownProps.cmsId)} : {}
  ),
  dispatch => ({
    actions: bindActionCreators(tagActions, dispatch),
  }),
)(Tag));


Tag.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  tag: PropTypes.object,
};
