import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getNavigationLinkById} from "../reducers/index";


//Action Creators
export const loadNavigationLinkStart = (id) => {
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_START,
    id
  };
};
export const loadNavigationLinkFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_FAIL,
    id,
    error
  };
};
export const loadNavigationLinkSuccess = (data) => {
  return {
    type: actionTypes.NAVIGATIONLINK_LOAD_SUCCESS,
    data
  };
};


export const initNavigationLink = id => dispatch => {
  console.error("loading a navigationlink for some reason");
  dispatch(loadNavigationLinkStart(id));

  apiActions.loadEndpoint(apiValues.nref.navigationLink.endpoint, id).then(result => {
    let n = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.navigationLink.fieldsUsed);

    dispatch(loadNavigationLinkSuccess(n));
  });
};
