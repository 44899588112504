export default {
  card: [],
  carouselSlide: [],
  getInvolvedCard: [],
  mapParent: [],
  mapProgram: {
    memorials: [],
    projects: [],
  },
  mBrandBar: [],
  mBreadcrumbs: {},
  mCarousel: [],
  mediaItem: {},
  mEventDetail: {},
  mEvents: {},
  mFooter: [],
  mGetInvolved: [],
  mHeader: {},
  mHeaderTab: [],
  mIntro: [],
  mLatestEvents: {},
  mLatestNews: {},
  mListCards: [],
  mListFollow: [],
  mListMedia: {},
  mListNav: [],
  mListShare: [],
  mListTag: [],
  mNewsDetail: {},
  mNewsletter: {},
  mProgramBanner: {},
  mProgramMapAd: [],
  mProjectListMap: {},
  mProjects: [],
  mProjectsCurrent: [],
  mRelatedProjects: {},
  mRichText: [],
  navigationLink: [],
  news: {},
  page: {},
  pageContentLanding: {},
  pageEventDetails: {},
  pageEvents: {},
  pageGetInvolved: {},
  pageHome: {},
  pageProjectDetails: {},
  pageProjects: {},
  project: [],
  projectTypeList: {},
  searchResult: {
    eventResult: [],
    events: [],
    filterProgram: [],
    filterQuery: '',
    filterTag: null,
    filterType: [],
    news: [],
    newsResult: [],
    programResult: [],
    programs: [],
    projectResult: [],
    projects: [],
    pageContentDetailResult: [],
    pageContentDetails: [],
    total: 0,
  },
  tag: [],
};
