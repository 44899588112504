import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mProjectListMapActions from '../actions/mProjectListMapActions';
import * as projectTypeListActions from '../actions/projectTypeListActions';
import iconViewGrid from '../img/icons/icon-view-grid.svg';
import iconViewMap from '../img/icons/icon-view-map.svg';
import MapParent from './MapParent';
import MListNav from './MListNav';
import ProjectList from './ProjectList';
import ProjectTypeList from './ProjectTypeList';
import PropTypes from 'prop-types';
import React from 'react';
import Select from './Select';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createBrowserHistory } from 'history';
import { SiteSearch } from './SiteSearch';
import { withRouter } from 'react-router-dom';


class MProjectListMap extends React.Component{
  history;


  constructor(props){
    super(props);

    this.state = {
      mapNearByProjects: []
    };
    this.filteringApply_onClick = this.filteringApply_onClick.bind(this);
    this.filteringCancel_onClick = this.filteringCancel_onClick.bind(this);
    this.filteringClear_onClick = this.filteringClear_onClick.bind(this);
    this.filteringClose_onClick = this.filteringClose_onClick.bind(this);
    this.filterProjectsButton_onClick = this.filterProjectsButton_onClick.bind(this);
    this.loadMoreButton_onClick = this.loadMoreButton_onClick.bind(this);
    this.loadMoreButton_onClick = this.loadMoreButton_onClick.bind(this);
    this.programsSelect_onChange = this.programsSelect_onChange.bind(this);
    this.projectStatus_onChange = this.projectStatus_onChange.bind(this);
    this.projectTypeList_onFilter = this.projectTypeList_onFilter.bind(this);
    this.search_onBlur = this.search_onBlur.bind(this);
    this.search_onClearClick = this.search_onClearClick.bind(this);
    this.search_onFocus = this.search_onFocus.bind(this);
    this.search_onInputChange = this.search_onInputChange.bind(this);
    this.search_onSubmit = this.search_onSubmit.bind(this);
    this.viewToggle_listView_onClick = this.viewToggle_listView_onClick.bind(this);
    this.viewToggle_mapView_onClick = this.viewToggle_mapView_onClick.bind(this);
  }
  componentDidMount(){
    //console.log(`MProjectListMap.componentDidMount()`, this.props);
    this.history = createBrowserHistory();

    let urlParams = helpers.urlParams();
    let filters = helpers.cleanObj({
      Program_in: urlParams.Program_in,
      projectstatus_in: urlParams.projectstatus_in,
      viewMap: urlParams.viewMap,
      ProjectTypes_in: urlParams.ProjectTypes_in ? urlParams.ProjectTypes_in.split(',') : null,
      Title_contains: urlParams.Title_contains
    });

    const searchKeyword = urlParams.q || '';

    this.props.actions.mProjectListMap.init(filters);
    this.props.actions.projectTypeList.filterList(urlParams.ProjectTypes_in ? urlParams.ProjectTypes_in.split(',') : []);
    if(searchKeyword){
      this.handleSearch(searchKeyword);
    }
    //this.props.actions.mProjectListMap.setViewMap(urlParams.viewMap == 'true');
  }


  filteringApply_onClick = () => {
    this.props.actions.mProjectListMap.toggleMobileFiltering(false);

    this.props.actions.mProjectListMap.setFilterInputs({
      filter_program: this.props.mProjectListMap.filter_program_temp,
      filter_projectStatus: this.props.mProjectListMap.filter_projectStatus_temp,
      filter_projectType: this.props.mProjectListMap.filter_projectType_temp,
      programSelect: this.props.mProjectListMap.programSelect_temp,
      projectStatusSelect: this.props.mProjectListMap.projectStatusSelect_temp
    });
    this.filterList({
      filter_program: this.props.mProjectListMap.filter_program_temp,
      filter_projectStatus: this.props.mProjectListMap.filter_projectStatus_temp,
      filter_projectType: this.props.mProjectListMap.filter_projectType_temp
    });
  };
  filteringCancel_onClick = () => {
    this.props.actions.mProjectListMap.setFilterInputs({
      filter_program: this.props.mProjectListMap.filter_program || '',
      filter_projectStatus: this.props.mProjectListMap.filter_projectStatus || '',
      filter_projectType: this.props.mProjectListMap.filter_projectType || '',
      programSelect: this.props.mProjectListMap.filter_program || '',
      projectStatusSelect: this.props.mProjectListMap.filter_projectStatus || ''
    });
    this.props.actions.mProjectListMap.toggleMobileFiltering(false);
    this.props.actions.projectTypeList.filterList(this.props.mProjectListMap.filter_projectType);
  };
  filteringClear_onClick = () => {
    this.props.actions.mProjectListMap.setFilterInputs({
      filter_program_temp: '',
      filter_projectStatus_temp: '',
      filter_projectType_temp: '',
      programSelect: '',
      projectStatusSelect: ''
    });
    this.props.actions.projectTypeList.filterList();
  };
  filteringClose_onClick = () => {
    this.filteringCancel_onClick();
  };
  filterProjectsButton_onClick = () => {
    //todo: should this be setting ProgramSelectTemp & ProjectStatusTemp?
    this.props.actions.mProjectListMap.setFilterInputs({
      filter_program_temp: this.props.mProjectListMap.filter_program,
      filter_projectStatus_temp: this.props.mProjectListMap.filter_projectStatus,
      filter_projectType_temp: this.props.mProjectListMap.filter_projectType,
      programSelect: this.props.mProjectListMap.programSelect,
      projectStatusSelect: this.props.mProjectListMap.projectStatusSelect
    });
    this.props.actions.mProjectListMap.toggleMobileFiltering(true);
  };
  loadMoreButton_onClick = () => {
    //console.log(`loadMoreButton_onClick`, this.props.mProjectListMap.listLength);
    this.props.actions.mProjectListMap.setListLength(this.props.mProjectListMap.listLength + apiValues.PROJECTS_PER_PAGE);
  };
  programsSelect_onChange = (value) => {
    //console.log(`programsSelect_onChange`, this.props, value);
    if(this.props.mProjectListMap.isFilteringOpen)
      this.props.actions.mProjectListMap.setFilterInputs({
        filter_program_temp: value,
        programSelect: value
      });
    else{
      this.props.actions.mProjectListMap.setFilterInputs({
        filter_program: value,
        programSelect: value
      });
      this.filterList({filter_program: value});
    }
  };
  projectStatus_onChange = (value) => {
    //console.log(`projectStatus_onChange`, this.props.mProjectListMap.isFilteringOpen, value);
    if(this.props.mProjectListMap.isFilteringOpen){
      this.props.actions.mProjectListMap.setFilterInputs({
        filter_projectStatus_temp: value,
        projectStatusSelect: value
      });
    }
    else{
      this.props.actions.mProjectListMap.setFilterInputs({
        filter_projectStatus: value,
        projectStatusSelect: value
      });
      this.filterList({filter_projectStatus: value});
    }
  };
  projectTypeList_onFilter = filter => {
    //console.log(`projectTypeList_onFilter`, this.props.projectTypeList.selectedProjectTypes, filter);

    let selectedProjectTypes = [...this.props.projectTypeList.selectedProjectTypes || []];
    if(selectedProjectTypes.indexOf(filter) >= 0)
      selectedProjectTypes.splice(selectedProjectTypes.indexOf(filter), 1);
    else
      selectedProjectTypes.push(filter);

    if(this.props.mProjectListMap.isFilteringOpen){
      this.props.actions.mProjectListMap.setFilterInputs({filter_projectType_temp: selectedProjectTypes});
      this.props.actions.projectTypeList.filterList(selectedProjectTypes);
    }
    else{
      this.props.actions.mProjectListMap.setFilterInputs({filter_projectType: selectedProjectTypes});
      this.filterList({filter_projectType: selectedProjectTypes});
    }
  };
  search_onBlur = () => {
    this.props.actions.mProjectListMap.toggleSearch(false);
    // this.filterList({filter_title: ''});
  };
  search_onClearClick = () => {
    this.filterList({filter_title: ''});
    setTimeout(() => {
      this.props.actions.mProjectListMap.toggleSearch(false);
    });
  };
  search_onFocus = () => {
    this.props.actions.mProjectListMap.toggleSearch(true);
    // this.filterList({filter_title: ''});
  };
  search_onInputChange = event => {
    let isSearchOpen = event && event.target.value.length > 0;
    this.props.actions.mProjectListMap.toggleSearch(isSearchOpen);
    this.handleSearch(event.target.value);
  };
  search_onSubmit = event => {
    this.props.actions.mProjectListMap.toggleSearch(false);
    this.filterList({filter_title: event.target.query.value});
  };
  viewToggle_listView_onClick = () => {
    this.props.actions.mProjectListMap.setViewMap(false);
    this.filterList({viewMap: false});
  };
  viewToggle_mapView_onClick = () => {
    this.props.actions.mProjectListMap.setViewMap(true);
    this.filterList({viewMap: true});
  };


  filterList = (opts) => {
    let filters = {
      ProjectTypes_in: typeof opts.filter_projectType != 'undefined' ? opts.filter_projectType : this.props.mProjectListMap.filter_projectType,
      projectstatus_in: typeof opts.filter_projectStatus != 'undefined' ? opts.filter_projectStatus : this.props.mProjectListMap.filter_projectStatus,
      Program_in: typeof opts.filter_program != 'undefined' ? opts.filter_program : this.props.mProjectListMap.filter_program,
      Title_contains: typeof opts.filter_title != 'undefined' ? opts.filter_title : this.props.mProjectListMap.filter_title,
      viewMap: typeof opts.viewMap != 'undefined' ? opts.viewMap : this.props.mProjectListMap.viewMap
    };
    this.props.actions.mProjectListMap.filterList(filters);
    this.props.actions.projectTypeList.filterList(typeof opts.filter_projectType != 'undefined' ? opts.filter_projectType : this.props.mProjectListMap.filter_projectType);

    this.history.push({
      pathname: '/projects',
      search: helpers.encodeData(helpers.cleanObj(filters))
    });
  };
  handleMapNearByProjects = values => {
    this.setState({
      mapNearByProjects: values
    });
  };
  handleSearch = query => {
    this.props.actions.mProjectListMap.doSearch(query);
  };


  renderFeaturedProjects(){
    if(this.props.mProjectListMap.suggestedProjects) return null;

    return (
      <div className="col-12">
        <MListNav cmsId={apiValues.HEADER_OURPROJECTS_FEATURED_ID} className="m-listnav--page-search-results"/>
      </div>
    );
  }
  renderLoadMoreButton(){
    if(this.props.mProjectListMap.projects.length < this.props.mProjectListMap.listLength) return null;

    return (
      <div className="text-center">
        <button className="m-projectlistmap__load-more tg-button tg-button--pill tg-button--black" onClick={this.loadMoreButton_onClick}>
          <span className="tg-button__label">Load More</span>
        </button>
      </div>
    );
  }
  renderMap(){
    let viewClass = this.props.mProjectListMap.viewMap ? 'm-projectlistmap__map--show-map' : '';

    return (
      <div className={`m-projectlistmap__map ${viewClass}`}>
        <MapParent cmsId={this.props.cmsId}
                   handleMapNearByProjects={this.handleMapNearByProjects}
                   history={this.props.history}/>
      </div>
    );
  }
  renderProjects(){
    let viewClass = this.props.mProjectListMap.viewMap ? 'd-none' : '';
    // let projects = this.props.mProjectListMap.projects.slice(0, this.props.mProjectListMap.listLength);
    let projects = this.props.mProjectListMap.projects
      .slice(0, this.props.mProjectListMap.listLength)
      .map(projectId => this.props.project.find(project => project.id == projectId))
      .filter(project => !!project);

    return (
      <div className={`m-projectlistmap__listing ${viewClass}`}>
        <div className="coupling coupling--full-centered">
          <div className="container container--coupling">
            {projects.length > 0 ?
             (<ProjectList projects={projects}/>) :
             (<div>
               <div className="no-results">
                 Sorry, but nothing matched your search criteria. Please try again with some different keywords.
               </div>
               <div className="no-results-suggestion">
                 Search Suggestions
                 <ul>
                   <li>
                     Check spelling
                   </li>
                   <li>
                     Try more general words
                   </li>
                   <li>
                     Remove some search filters
                   </li>
                   <li>
                     <a href="/get-involved" target="_blank">Contact us</a> {`if you still can't find what you are looking for`}
                   </li>
                 </ul>
               </div>
             </div>)}
          </div>
          {this.renderLoadMoreButton()}
        </div>
      </div>
    );
  }
  renderSearch(){
    let urlParams = helpers.urlParams();

    return (
      <div className="m-projectlistmap__search">
        <div className="coupling coupling--full-centered">
          <div className="container container--coupling">
            <div className="m-projectlistmap__search">
              <SiteSearch className={`site-search--mprojectlistmap`}
                          isOpen={this.props.mProjectListMap.isSearchOpen}
                          onBlur={this.search_onBlur}
                          onClearClick={this.search_onClearClick}
                          onFocus={this.search_onFocus}
                          onInputChange={this.search_onInputChange}
                          onSubmit={this.search_onSubmit}
                          inputPlaceholder="Search our projects"
                          defaultValue={urlParams.Title_contains}
                          requred={false}>
                <div className="row">
                  {this.renderFeaturedProjects()}
                  {this.renderSuggestedProjects()}
                  {this.renderViewProjectsNear()}
                </div>
              </SiteSearch>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderSuggestedProjects(){
    if(!this.props.mProjectListMap.suggestedProjects) return null;

    let mListNav = {
      title: 'Suggested Projects',
      navigationLinks: this.props.mProjectListMap.suggestedProjects.slice(0, 4).map(project => {
        return {
          key: project.id,
          title: project.title,
          url: `/projects/${project.slug ? project.slug : ''}`
        };
      }),
      highlight: this.props.mProjectListMap.searchQuery
    };

    if(this.props.mProjectListMap.suggestedProjects.length === 0){
      return (
        <div className="col-12">
          <div className="no-search-results">{`Sorry this search doesn't have any results.`}</div>
          <div className="no-results-suggestion">
            Search Suggestions
            <ul>
              <li>
                Check spelling
              </li>
              <li>
                Try more general words
              </li>
              <li>
                Remove some search filters
              </li>
              <li>
                <a href="/get-involved" target="_blank">Contact us</a> {`if you still can't find what you are looking for`}
              </li>
            </ul>
          </div>
          {/* <SuperLink to="projects" className="m-projectlistmap__results-link">View all projects for &quot;{this.state.searchQuery}&quot;</SuperLink> */}
        </div>
      );
    }

    return (
      <div className="col-12">
        <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/>
      </div>
    );
  }
  renderToolbar(){
    let mapViewClass = this.props.mProjectListMap.viewMap ? 'm-projectlistmap__toolbar--map-view' : '';
    let mobileFilteringClass = this.props.mProjectListMap.isFilteringOpen ? 'm-projectlistmap__toolbar--fixed' : '';

    let programs = [{title: 'All', value: ''}].concat(
      this.props.mProjectListMap.programs
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
        .map(program => {
          return {title: program.title, value: program.id};
        })
    );
    let projectStatuses = [{title: 'All', value: ''}].concat(
      this.props.mProjectListMap.projectStatuses
        .sort((a, b) => {
          return a.title.localeCompare(b.title);
        })
        .map(projectStatus => {
          return {title: projectStatus.title, value: projectStatus.id};
        })
    );
    let urlParams = helpers.urlParams();

    return (
      <div className={`m-projectlistmap__toolbar ${mapViewClass} ${mobileFilteringClass}`}>
        <div className="coupling coupling--full-centered">
          <div className=" text-right">
            <button className="m-projectlistmap__toolbar-close" onClick={this.filteringClose_onClick}>Close</button>
          </div>
          <div className="container container--coupling">
            <div className="m-projectlistmap__toolbar-container">
              <div className="row">
                <div className="m-projectlistmap__toolbar-col-filter-title col">
                  Filter projects
                  <button className="m-projectlistmap__toolbar-clear-all" onClick={this.filteringClear_onClick}>Clear
                    All</button>
                </div>
                <div className="m-projectlistmap__toolbar-col-types col">
                  <ProjectTypeList defaultValue={urlParams.ProjectTypes_in}
                                   navListId='5bff0e7b6d09534d2922e69c'
                                   onFilter={this.projectTypeList_onFilter}/>
                </div>
                {/* <AvForm> */}


                <div className="m-projectlistmap__toolbar-col-programs col">
                  <label className="d-block">
                    {/* <AvField type="select" name="program" label="Program" onChange={this.programsSelect_onChange} value={this.props.mProjectListMap.programSelect}>
                        {programs}
                      </AvField> */}
                    <div className="m-projectlistmap__toolbar-select-title">Program</div>

                    <Select className="m-projectlistmap__toolbar-select"
                            defaultValue={urlParams.Program_in}
                            value={this.props.mProjectListMap.programSelect}
                            onSelectedChange={this.programsSelect_onChange}
                            options={programs}/>
                  </label>
                </div>
                <div className="m-projectlistmap__toolbar-col-statuses col">
                  <label className="d-block">
                    {/* <AvField type="select" name="status" label="Project Status" onChange={this.projectStatus_onChange} value={this.props.mProjectListMap.projectStatusSelect}>
                        {projectStatuses}
                      </AvField> */}
                    <div className="m-projectlistmap__toolbar-select-title">Project Status</div>

                    <Select className="m-projectlistmap__toolbar-select"
                            defaultValue={urlParams.projectstatus_in}
                            value={this.props.mProjectListMap.projectStatusSelect}
                            onSelectedChange={this.projectStatus_onChange}
                            options={projectStatuses}/>
                  </label>
                </div>
                {/* </AvForm> */}
                <div className="m-projectlistmap__toolbar-col-filter col align-self-center">
                  <div className="m-projectlistmap__toolbar-filter" onClick={this.filterProjectsButton_onClick}>Filter
                    Projects
                  </div>
                </div>
                <div className="m-projectlistmap__toolbar-col-toggle col text-right align-self-center">
                  {this.renderViewToggle()}
                </div>
              </div>
            </div>
          </div>
          <div className="m-projectlistmap__toolbar-buttons col">
            <button type="submit"
                    className="m-projectlistmap__toolbar-apply tg-button tg-button--pill tg-button--black flex-fill"
                    onClick={this.filteringApply_onClick}>
              <span className="tg-button__label">Apply</span>
            </button>
            <button type="submit"
                    className="m-projectlistmap__toolbar-cancel tg-button tg-button--pill tg-button--white flex-fill"
                    onClick={this.filteringCancel_onClick}>
              <span className="tg-button__label">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    );
  }
  renderViewProjectsNear(){
    if(!this.props.mProjectListMap.viewProjectsNear) return null;

    const nearProjects = this.props.mProjectListMap.viewProjectsNear.filter(item => {
      if(!item.projectId) return false;
      return this.state.mapNearByProjects.includes(item.projectId);
    });

    let mListNav = {
      title: 'View Projects Near',
      navigationLinks: nearProjects.slice(0, 4).map(project => {
        return {
          key: project.id,
          title: project.title,
          url: project.slug
        };
      }),
      highlight: this.props.mProjectListMap.searchQuery
    };

    return (
      <div className="col-12">
        <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/>
        {/* <SuperLink className="m-projectlistmap__results-link" to="projects">Use my current location</SuperLink> */}
      </div>
    );
  }
  renderViewToggle(){
    let listViewClass = !this.props.mProjectListMap.viewMap ? 'tg-button--orange' : '';
    let mapViewClass = this.props.mProjectListMap.viewMap ? 'tg-button--orange' : '';

    return (
      <div className="m-projectlistmap__view-toggle">
        <span className={`m-projectlistmap__view-toggle-button tg-button tg-button--pill ${listViewClass}`}
              onClick={this.viewToggle_listView_onClick}>
          <img alt="List view icon" className="tg-button__icon" src={iconViewGrid}/>
          <span className="tg-button__label">List View</span>
        </span>
        <span className={`m-projectlistmap__view-toggle-button tg-button tg-button--pill ${mapViewClass}`}
              onClick={this.viewToggle_mapView_onClick}>
          <img alt="Map view icon" className="tg-button__icon" src={iconViewMap}/>
          <span className="tg-button__label">Map View</span>
        </span>
      </div>
    );
  }
  render(){
    // console.log(`MProjectListMap render`, this.props);
    if(!this.props.mProjectListMap ||
       !this.props.mProjectListMap.programs ||
       !this.props.mProjectListMap.projects ||
       !this.props.mProjectListMap.projectStatuses) return null;

    return (
      <div className={`m-projectlistmap ${this.props.className || ''}`}>
        {this.renderSearch()}
        {this.renderToolbar()}
        {this.renderMap()}
        {this.renderProjects()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mProjectListMap: state.mProjectListMap,
    project: state.project,
    projectTypeList: state.projectTypeList
  }),
  (dispatch) => ({
    actions: {
      mProjectListMap: bindActionCreators(mProjectListMapActions, dispatch),
      projectTypeList: bindActionCreators(projectTypeListActions, dispatch)
    }
  })
)(MProjectListMap));


MProjectListMap.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  history: PropTypes.object,
  mProjectListMap: PropTypes.object,
  project: PropTypes.array,
  projectTypeList: PropTypes.object
};
