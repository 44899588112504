import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mHeaderActions from '../actions/mHeaderActions';
import iconShareYourFeedback from '../img/icons/icon-share-your-feedback.svg';
import MHeaderTab from './MHeaderTab';
import MListNav from './MListNav';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import ButtonInput from './ButtonInput';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MHeader extends React.Component{
  state = {
    suggestedProjects: {
      title: 'Suggested Projects',
      navigationLinks: [],
    },
    suggestedPages: {
      title: 'Suggested Pages',
      navigationLinks: [],
    },
    keyword: null,
  };


  constructor(){
    super();
    this.state = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  UNSAFE_componentWillMount(){
    this.handleSearchInputChange = helpers.debounceEvent((event) => {
      console.log(`debounced event`);
      this.handleSearch(event.target.value);
    });
  }
  componentDidMount(){
    //console.log(`MHeader.componentDidMount()`, this.props);
    this.props.actions.initMHeader(this.props.cmsId);
    this.props.actions.expandedTab({
      expandedTabBarVisible: false,
      expandedTabBarOffset: 0,
      expandedTabBarWidth: 0,
    });
    window.addEventListener('resize', this.updateDimensions);
    this.props.actions.toggleSearch(false);
    this.setState({
      suggestedProjects: {
        title: 'Suggested Projects',
        navigationLinks: [],
      },
      suggestedPages: {
        title: 'Suggested Pages',
        navigationLinks: [],
      },
      keyword: null,
    });
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }


  mListNav_NavigationItem_onClick = () => {
    console.log(`mListNav_NavigationItem_onClick`);
  };
  onSearchClearClick = () => {
    this.toggleMenu(false);
  };
  onSearchInputChange = event => {
    event.persist();
    console.log('persisted event');
    let isSearchOpen = event && event.target.value.length > 0;
    this.props.actions.toggleSearch(isSearchOpen);
    this.setState({
      keyword: event.target.value === '' ? null : event.target.value,
    });
    this.handleSearchInputChange(event);
  };
  onSearchTabClick = () => {
    this.toggleMenu(true);
    this.props.actions.tabToggle('search', true);
    setTimeout(() => {
      document.getElementById('search-main').focus();
    });
  };
  search_onSubmit = () => {
    if(!this.state.keyword) return;
    this.tab_onMouseLeave();
    this.props.history.push(`/search?q=${this.state.keyword}`);
    return true;//clear results
  };
  tab_onMouseEnter = (tabName, e) => {
    if(tabName){
      this.props.actions.expandedTab({
        expandedTabBarVisible: true,
        expandedTabBarOffset: e.currentTarget.offsetLeft,
        expandedTabBarWidth: e.currentTarget.clientWidth,
        expandedTab: tabName,
      });
    }
  };
  tab_onMouseLeave = () => {
    //this.setState({
    //  expandedTabBarVisible: false,
    //  expandedTabBarWidth: 0,
    //  expandedTab: ''
    //});
    this.props.actions.expandedTab({
      expandedTabBarVisible: false,
      expandedTabBarWidth: 0,
      expandedTab: '',
    });
  };


  bgPath(){
    return this.props.mHeader.imageBackground ? this.props.mHeader.imageBackground.url : '';
  }
  toggleMenu = (isOpen) => {
    this.props.actions.menuToggle(typeof isOpen != 'undefined' ? isOpen : !this.props.mHeader.isOpen);
    this.props.actions.toggleAllTabs(false);
  };
  handleSearch = query => {
    console.log(query);
    mHeaderActions.headerSearch(query).then(results => {
      console.log(`doSearch`, query, results);

      this.setState({
        keyword: query,
        suggestedProjects: {
          ...this.state.suggestedProjects,
          navigationLinks: results.projects.slice(0, 4).map(project => {
            return {
              id: project.id,
              key: project.id,
              title: `${project.title || ''}`,
              url: `/projects/${project.slug}`,
            };
          }),
          highlight: query,
        },
        suggestedPages: {
          ...this.state.suggestedPages,
          navigationLinks: results.navigations.slice(0, 4).map(navigation => {
            return {
              id: navigation.id,
              key: navigation.id,
              title: `${navigation.title || ''}`,
              url: navigation.url,
            };
          }),
          highlight: query,
        },
      });
    });
  };
  updateDimensions(){
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }


  renderBetaFormLink(){
    return (
      <SuperLink to='https://forms.gle/TUXPnQk38DqVHcd4A' className="tg-button tg-button--pill tg-button--beta-link">
        <img className="tg-button__icon" src={iconShareYourFeedback} alt='Share your Feedback icon'/>
        <span className="tg-button__label d-none d-sm-inline-block">
          <span className="d-none d-xl-inline-block">Share your</span> Feedback
        </span>
      </SuperLink>
    );
  }
  renderExpandedTabBar(){
    const leftVal = this.props.mHeader.expandedTabBarOffset + this.props.mHeader.expandedTabBarWidth * .5;
    const widthVal = this.props.mHeader.expandedTabBarWidth;
    let expandedTabBarStyle = {
      left: leftVal || null,
      width: widthVal || null,
    };
    let visibleClass = this.props.mHeader.expandedTabBarVisible ? 'm-header__expanded-tab-bar--is-visible' : '';

    return (
      <div className={`m-header__expanded-tab-bar ${visibleClass}`} style={expandedTabBarStyle}></div>
    );
  }
  renderGetInvolved(){
    if(!this.props.getInvolved) return null;

    let isExpanded = this.props.mHeader.expandedTab == 'get-involved';
    let selectedClass = isExpanded ? 'm-header__tab--is-open' : '';

    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab m-header__tab-listed m-header__tab--get-involved ${selectedClass} coupling--full-centered`}
                  id='get-involved'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('get-involved', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  title={this.props.getInvolved.title}
                  url={this.props.getInvolved.url}>
        <div className="container container--coupling m-header__tab-menu-container">
          <div className="row">
            <div className="col-12 col-lg-4 transition transition-1">
              <MListNav cmsId={apiValues.HEADER_GETINVOLVED_TAKEACTION_ID}/>
            </div>
            <div className="col-12 col-lg-4 transition transition-2">
              <MListNav cmsId={apiValues.HEADER_GETINVOLVED_FROMYOU_ID}/>
            </div>
          </div>
        </div>
      </MHeaderTab>
    );
  }
  renderLogo(){
    if(!this.props.mHeader.imageLogo) return null;

    return (
      <SuperLink to="/" className="m-header__logo">
        <img alt="LADOT Streets Logo" className="m-header__logo-img"
             src={apiValues.imgPath(this.props.mHeader.imageLogo.url)}/>
      </SuperLink>
    );
  }
  renderMapsData(){
    if(!this.props.mapsAndData) return null;

    let isExpanded = this.props.mHeader.expandedTab == 'maps-and-data';
    let selectedClass = isExpanded ? 'm-header__tab--is-open' : '';

    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab m-header__tab-listed m-header__tab--maps-and-data ${selectedClass} coupling--full-centered`}
                  id='maps-and-data'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('maps-and-data', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  title={this.props.mapsAndData.title}
                  url={this.props.mapsAndData.url}>
        <div className="container container--coupling m-header__tab-menu-container">
          <div className="row">
            <div className="col-lg-4 d-none d-lg-block"></div>
            <div className="col-12 col-lg-4 transition transition-1">
              <MListNav cmsId={apiValues.HEADER_MAPSANDDATA_LIST_ID}/>
            </div>
          </div>
        </div>
      </MHeaderTab>
    );
  }
  renderMenuToggle(){
    if(!this.props.whoWeAre) return null;

    return (
      <button className={`m-header__menu-toggle`} onClick={() => this.toggleMenu()}>&nbsp;</button>
    );
  }
  renderOurProjects(){
    if(!this.props.ourProjects) return null;

    let isExpanded = this.props.mHeader.expandedTab == 'our-projects';
    let selectedClass = isExpanded ? 'm-header__tab--is-open' : '';

    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab m-header__tab-listed m-header__tab--our-projects ${selectedClass} coupling--full-centered`}
                  id='our-projects'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('our-projects', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  title={this.props.ourProjects.title}
                  url={this.props.ourProjects.url}>
        <div className="container container--coupling m-header__tab-menu-container">
          <div className="row">
            <div className="col-12 col-lg-4 transition transition-1">
              <MListNav className="m-header__project-types" cmsId={apiValues.HEADER_OURPROJECTS_FEATURED_ID}/>
            </div>
            <div className="col-12 col-lg-4 transition transition-2">
              <MListNav className="m-header__project-types m-listnav--blue-outline-pills"
                        cmsId={apiValues.HEADER_OURPROJECTS_TYPES_ID}/>
            </div>
          </div>
        </div>
      </MHeaderTab>
    );
  }
  renderSearch(){
    if(!this.props.search) return null;

    let searchingClass = this.props.mHeader.isSearchOpen ? 'is-searching' : '';
    let isExpanded = this.props.mHeader.expandedTab == 'search';

    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab--search m-header__tab coupling--full-centered ${searchingClass}`}
                  id='search'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('search', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  onTabClick={this.onSearchTabClick}
                  title={this.props.search.title}
                  url={this.props.search.url}>
        <div className="m-header__search-content">
          <label htmlFor="search-main" className="m-header__search-title">Search our projects</label>
          <ButtonInput className="m-header__search-input"
                       inputID="search-main"
                       onClearClick={this.onSearchClearClick}
                       onInputChange={this.onSearchInputChange}
                       onSubmit={this.search_onSubmit}
                       showX={true}/>

          <div className="m-header__search-preview">
            <MListNav className="m-listnav--hide-buttons transition transition-1"
                      cmsId={apiValues.HEADER_OURPROJECTS_TYPES_ID}/>
            <div className="row">
              <div className="col-12 col-lg-6 transition transition-2">
                <MListNav cmsId={apiValues.HEADER_OURPROJECTS_FEATURED_ID}/>
                <div className="navigationlink m-listnav__navigationlink navigationlink--blue-outline">
                  <SuperLink to="/projects" className="navigationlink__link">
                    <div className="navigationlink__title">View All Projects</div>
                  </SuperLink>
                </div>
              </div>
              <div className="col-12 col-lg-6 transition transition-3">
                <MListNav cmsId={apiValues.HEADER_SEARCHLINKS_ID}/>
              </div>
            </div>
          </div>
          <div className="m-header__search-results">
            {/* {this.state.suggestedPages.navigationLinks.length === 0 && this.state.suggestedProjects.navigationLinks.length === 0 && (
              <div className="row">
                <div className="col-12">
                  <div className="no-search-results">{`Sorry this search doesn't have any results.`}</div>
                  <MListNav mListNav={mListNav} className="m-listnav--page-search-results"/>
                </div>
              </div>
            )} */}
            {/* {(this.state.suggestedPages.navigationLinks.length !== 0 || this.state.suggestedProjects.navigationLinks.length !== 0) && ( */}
            <div className="row">
              <div className="col-12">
                <MListNav mListNav={this.state.suggestedPages}/>
              </div>
              <div className="col-12">
                <MListNav mListNav={this.state.suggestedProjects}/>
              </div>
            </div>
            {/* )} */}
            {/* {(this.state.suggestedPages.navigationLinks.length !== 0 || this.state.suggestedProjects.navigationLinks.length !== 0) && ( */}
            <SuperLink to={this.state.keyword ? `/search?q=${this.state.keyword}` : `/search`}
                       className="tg-button tg-button--pill tg-button--black m-header__view-all-results">
              <span className="tg-button__label">View All Search Results</span>
            </SuperLink>
            {/* )} */}
          </div>
        </div>
      </MHeaderTab>
    );
  }
  renderWhatWeDo(){
    if(!this.props.whatWeDo) return null;
    let isExpanded = this.props.mHeader.expandedTab == 'what-we-do';
    let selectedClass = isExpanded ? 'm-header__tab--is-open' : '';
    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab m-header__tab-listed m-header__tab--what-we-do ${selectedClass} coupling--full-centered`}
                  id='what-we-do'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('what-we-do', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  title={this.props.whatWeDo.title}
                  url={this.props.whatWeDo.url}>
        <div className="container container--coupling m-header__tab-menu-container">
          <div className="row">
            <div className="col-12 col-lg-4 transition transition-1">
              <MListNav cmsId={apiValues.HEADER_WHATWEDO_SAFETYPROGRAM_ID}/>
            </div>
            <div className="col-12 col-lg-4 transition transition-2">
              <MListNav cmsId={apiValues.HEADER_WHATWEDO_STREETSPROGRAM_ID}/>
            </div>
          </div>
        </div>
      </MHeaderTab>
    );
  }
  renderWhoWeAre(){
    if(!this.props.whoWeAre) return null;
    let isExpanded = this.props.mHeader.expandedTab == 'who-we-are';
    let selectedClass = '';

    return (
      <MHeaderTab ariaExpanded={isExpanded}
                  bgPath={this.bgPath()}
                  className={`m-header__tab m-header__tab-listed m-header__tab--who-we-are ${selectedClass} coupling--full-centered`}
                  id='who-we-are'
                  onMouseEnter={(e) => {
                    this.tab_onMouseEnter('who-we-are', e);
                  }}
                  onMouseLeave={this.tab_onMouseLeave}
                  title={this.props.whoWeAre.title}
                  url={this.props.whoWeAre.url}>
        <div className="m-header__who-we-are-row-container">
          <div className="container container--coupling m-header__tab-menu-container m-header__who-we-are-container">
            <div className="row">
              <div className="col-lg-4 d-none d-lg-block transition transition-1">
                <div className="m-header__vision-statement">{this.props.whoWeAre.description}</div>
              </div>
              <div className="col-12 col-lg-8 transition transition-2">
                <MListNav className="m-header__m-listnav"
                          cmsId={apiValues.HEADER_WHOWEARE_LIST_ID}
                          onNavigationItemClick={this.mListNav_NavigationItem_onClick}/>
              </div>
            </div>
          </div>
          {this.props.whoWeAre.descriptionOptional && this.props.whoWeAre.descriptionOptional.length > 0 ? (
            <div className="container container--coupling m-header__message-container">
              <div className="m-header__message transition transition-3">
                <div className="m-header__message-title">Message from Mayor Garcetti:</div>
                <div className="m-header__message-description">
                  {this.props.whoWeAre.descriptionOptional}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </MHeaderTab>
    );
  }
  render(){
    // console.log(`MHeader.render()`, this.props);
    if(!this.props.mHeader) return null;

    return (
      <div className={`m-header ${this.props.className || ''}`}>
        <div className="m-header__tab-bar">
          <div className="container container--coupling">
            <div className="row">
              <div className="m-header__tabs">
                {this.renderMenuToggle()}
                {this.renderLogo()}
                <div className="m-header__tabs-middle">
                  {this.renderWhoWeAre()}
                  {this.renderWhatWeDo()}
                  {this.renderOurProjects()}
                  {this.renderGetInvolved()}
                  {this.renderMapsData()}
                  {this.renderExpandedTabBar()}
                </div>
                {/*{this.renderBetaFormLink()}*/}
                {this.renderSearch()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    mHeader: state.mHeader,
    whoWeAre: helpers.getById(state.mListNav, apiValues.HEADER_WHOWEARE_ID),
    whatWeDo: helpers.getById(state.mListNav, apiValues.HEADER_WHATWEDO_ID),
    ourProjects: helpers.getById(state.mListNav, apiValues.HEADER_OURPROJECTS_ID),
    getInvolved: helpers.getById(state.mListNav, apiValues.HEADER_GETINVOLVED_ID),
    mapsAndData: helpers.getById(state.mListNav, apiValues.HEADER_MAPSANDDATA_ID),
    search: helpers.getById(state.mListNav, apiValues.HEADER_SEARCH_ID),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mHeaderActions, dispatch),
  }),
)(MHeader));


MHeader.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  getInvolved: PropTypes.object,
  history: PropTypes.object,
  mapsAndData: PropTypes.object,
  mHeader: PropTypes.object,
  ourProjects: PropTypes.object,
  search: PropTypes.object,
  whatWeDo: PropTypes.object,
  whoWeAre: PropTypes.object,
};
