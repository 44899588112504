import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import {filterResult} from '../actions/SearchActions';


//todo: redo all the redux stuff in the project
export default function SearchReducer(
  state = initialState.searchResult,
  action,
){
  switch(action.type){
    case actionTypes.SEARCH_SET_QUERY:
      return {
        ...state,
        ...filterResult({
          ...state,
          filterQuery: action.data,
        }),
        filterQuery: action.data,
      };

    case actionTypes.SEARCH_SET_TAG_QUERY:
      return {
        ...state,
        filterTag: action.data,
      };

    case actionTypes.SEARCH_SET_PROGRAMS:
      return {
        ...state,
        ...filterResult({
          ...state,
          filterProgram: Array.from(action.data),
        }),
        filterProgram: Array.from(action.data),
      };

    case actionTypes.SEARCH_SET_TYPES:
      return {
        ...state,
        ...filterResult({
          ...state,
          filterType: Array.from(action.data),
        }),
        filterType: Array.from(action.data),
      };

    case actionTypes.SEARCH_NEWS_LOAD_SUCCESS:
      return {
        ...state,
        ...filterResult({
          ...state,
          news: action.data,
        }),
        news: action.data,
      };

    case actionTypes.SEARCH_EVENTS_LOAD_SUCCESS:
      return {
        ...state,
        ...filterResult({
          ...state,
          events: action.data,
        }),
        events: action.data,
      };

    case actionTypes.SEARCH_PROJECTS_LOAD_SUCCESS:{
      const results5 = filterResult({
        ...state,
        projects: action.data,
      });
      return {
        ...state,
        ...results5,
        projects: action.data,
      };
    }

    case actionTypes.SEARCH_PROGRAMS_LOAD_SUCCESS:{
      const results6 = filterResult({
        ...state,
        programs: action.data,
      });
      return {
        ...state,
        ...results6,
        programs: action.data,
      };
    }

    case actionTypes.SEARCH_PAGECONTENTDETAILS_LOAD_SUCCESS:{
      const results7 = filterResult({
        ...state,
        pageContentDetails: action.data,
      });
      return {
        ...state,
        ...results7,
        pageContentDetails: action.data,
      };
    }

    default:
      return state;
  }
}
