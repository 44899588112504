import * as apiValues from '../constants/apiValues';
import PropTypes from 'prop-types';
import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import MarkdownField from './MarkdownField';
import BodyEnd from './BodyEnd';


export default class Lightbox extends React.Component{
  closeButton_onClick = () => {
    //console.log(`MListMedia.closeButton_onClick`, this);
    this.props.onCloseClick();
  };


  renderCarousel = () => {
    return (
      <div className="lightbox__carousel-container">
        <Carousel showStatus={false} showThumbs={false} infiniteLoop={true} selectedItem={this.props.selectedItem} showIndicators={false}>
          {this.renderSlides()}
        </Carousel>
      </div>
    );
  };
  renderSlides = () => {
    if(!this.props.mediaItems) return null;

    return this.props.mediaItems.map(mediaItem => {
      let mediaSpacer = <div className="lightbox__media-spacer"></div>;

      if(mediaItem.mediaEmbed){
        return (
          <div className="lightbox__media-slide" key={mediaItem.id}>
            <div className="lightbox__media-slide-imagery">
              <MarkdownField className="lightbox__media-imagery-container" markup={mediaItem.mediaEmbed}/>
              {mediaSpacer}
            </div>
            <div className="lightbox__media-caption">{mediaItem.caption}</div>
          </div>
        );
      }
      else if(mediaItem.mediaVideo){
        let mediaImage = mediaItem.mediaImage ?
                         <div className="lightbox__media-item lightbox__media-video-image" key={mediaItem.id} style={{backgroundImage: `url(${apiValues.imgPath(mediaItem.mediaImage.url)})`}}/> : '';

        return (
          <div className="lightbox__media-slide" key={mediaItem.id}>
            <div className="lightbox__media-slide-imagery">
              <div className="lightbox__media-imagery-container">
                <video className="lightbox__media-imagery"
                       src={apiValues.imgPath(mediaItem.mediaVideo.url)}
                       type="*"
                       autoPlay muted playsInline controls="true"></video>
                {mediaImage}
              </div>
              {mediaSpacer}
            </div>
            <div className="lightbox__media-caption">{mediaItem.caption}</div>
          </div>
        );
      }
      else if(mediaItem.mediaImage) return (
        <div className="lightbox__media-slide" key={mediaItem.id}>
          <div className="lightbox__media-slide-imagery">
            <div className="lightbox__media-imagery-container">
              <img alt={mediaItem.alt} className="lightbox__media-imagery" src={apiValues.imgPath(mediaItem.mediaImage.url)}/>
            </div>
            {mediaSpacer}
          </div>
          <div className="lightbox__media-caption">{mediaItem.caption}</div>
        </div>
      );
    });
  };
  render(){
    console.log(`Lightbox.render()`, this.props);

    let isOpenClass = this.props.isOpen ? 'lightbox--is-open' : '';

    return (
      <BodyEnd>
        <div className={`lightbox ${this.props.className || ''} ${isOpenClass}`}>
          <div className="lightbox__box">
            <button className="lightbox__close" onClick={this.closeButton_onClick}>Close</button>
            {this.renderCarousel()}
          </div>
        </div>
      </BodyEnd>
    );
  }
}


Lightbox.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  mediaItems: PropTypes.array,
  onCloseClick: PropTypes.func,
  selectedItem: PropTypes.number,
};
