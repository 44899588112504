import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMProjectsById} from "../reducers/index";


//Action Creators
export const loadMEventsStart = (id) => {
  return {
    type: actionTypes.MEVENTS_LOAD_START,
    id,
  };
};
export const loadMEventsFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MEVENTS_LOAD_FAIL,
    id,
    error,
  };
};
export const loadMEventsSuccess = (data) => {
  return {
    type: actionTypes.MEVENTS_LOAD_SUCCESS,
    data,
  };
};

export const initMEvents = () => dispatch => {
  dispatch(loadMEventsStart());


  const mEvents = {
    title: 'example mEventsActions title',
    description: 'example mEventsActions description',
  };

  return Promise.all([
      apiActions.loadEndpoint(apiValues.nref.event.endpoint, null),
      apiActions.loadEndpoint(apiValues.nref.program.endpoint, null),
      apiActions.loadEndpoint(apiValues.nref.category.endpoint, null),
    ])
    .then(([events, programs, categories]) => {
      let eventResults = events.filter(item => {
          let eventDate = new Date(item.EventDate);

          if(!eventDate.getDate()){
            return false;
          }
          const currentDate = new Date();
         // return eventDate > currentDate;
         return eventDate;
        })
          .sort((a, b) => new Date(a.EventDate) - new Date(b.EventDate));

        //        .sort((a, b) => new Date(a.EventDate) - new Date(b.EventDate));
      // console.log(events, eventResults);

      mEvents.events = eventResults ? apiActions.arraySelectCamelCaseKeys(eventResults, apiValues.nref.event.fieldsUsed) : undefined;
      mEvents.programs = programs ? apiActions.arraySelectCamelCaseKeys(programs, apiValues.nref.program.fieldsUsed) : undefined;
      mEvents.categories = categories ? apiActions.arraySelectCamelCaseKeys(categories, apiValues.nref.category.fieldsUsed) : undefined;

      dispatch(loadMEventsSuccess(mEvents));
    });

};


