import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMProjectsById} from "../reducers/index";


//Action Creators
export const loadNewsStart = () => {
  return {
    type: actionTypes.NEWS_LOAD_START,
  };
};
export const loadNewsFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.NEWS_LOAD_FAIL,
    error,
  };
};
export const loadNewsSuccess = (data) => {
  return {
    type: actionTypes.NEWS_LOAD_SUCCESS,
    data,
  };
};

export const initNews = () => dispatch => {
  dispatch(loadNewsStart());

  return Promise.all([
    apiActions.loadEndpoint(apiValues.nref.news.endpoint, null, {'_sort': 'NewsDate:DESC'}),
    apiActions.loadEndpoint(apiValues.nref.program.endpoint),
  ]).then(([news, programs]) => {
    dispatch(loadNewsSuccess({
      news: news ? apiActions.arraySelectCamelCaseKeys(news, apiValues.nref.news.fieldsUsed) : [],
      programs: programs ? apiActions.arraySelectCamelCaseKeys(programs, apiValues.nref.program.fieldsUsed) : [],
    }));
  });
};


