import * as helpers from '../helpers';
import * as mGetInvolvedActions from '../actions/mGetInvolvedActions';
import Card from '../components/Card';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MGetInvolved extends React.Component{
  componentDidMount(){
    //console.log(`MGetInvolved.componentDidMount()`, this.props);
    this.props.actions.initMGetInvolved(this.props.cmsId);
  }


  renderButton(){
    if(!this.props.mGetInvolved.buttonLabel) return null;

    return (
      <div className="m-getinvolved__button tg-button tg-button--pill tg-button--black">
        <SuperLink to="/get-involved" className="tg-button__label">{this.props.mGetInvolved.buttonLabel}</SuperLink>
      </div>
    );
  }
  renderCards(){
    if(!this.props.mGetInvolved.cards) return null;

    return (
      <div className="m-getinvolved__cards-container">
        {this.props.mGetInvolved.cards.map(cardId => (<Card className="list-card--get-involved"
                                                          isGetInvolved={true}
                                                          card={this.props.card.find(card=>card.id==cardId)}
                                                          cmsId={typeof this.props.card[cardId] == 'undefined' ? cardId : undefined}
                                                          key={cardId}/>))}
      </div>
    );
  }
  renderDescription(){
    if(!this.props.mGetInvolved.description) return null;

    return (<div className="m-getinvolved__description">{this.props.mGetInvolved.description}</div>);
  }
  renderTitle(){
    if(!this.props.mGetInvolved.title) return null;

    return (<div className="m-getinvolved__title">{this.props.mGetInvolved.title}</div>);
  }
  render(){
    //console.log(`MGetInvolved render`, this.props);
    if(!this.props.mGetInvolved ||
       !this.props.mGetInvolved.cards ||
       this.props.mGetInvolved.cards.length < 1) return null;

    return (
      <div className={`m-getinvolved ${this.props.className || ''}`}>
        <div className="container container--coupling">
          <div className="row">
            <div className="m-getinvolved__content">
              <div className="m-getinvolved__info">
                {this.renderTitle()}
                {this.renderDescription()}
                {this.renderButton()}
              </div>
              <div className="m-getinvolved__cards">
                {this.renderCards()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    card:state.card,
    mGetInvolved: helpers.getById(state.mGetInvolved, ownProps.cmsId),
  }),
  (dispatch) => ({
    actions: bindActionCreators(mGetInvolvedActions, dispatch),
  }),
)(MGetInvolved));


MGetInvolved.propTypes = {
  actions: PropTypes.object.isRequired,
  card: PropTypes.array,
  className: PropTypes.string,
  cmsId: PropTypes.string.isRequired,
  mGetInvolved: PropTypes.object,
};
