import ContactItem from './ContactItem';
import PropTypes from 'prop-types';
import React from 'react';


export default class MContacts extends React.Component{
  renderContacts(){
    return this.props.contacts.map(contact => (
      <ContactItem
        address={contact.address}
        description={contact.description}
        email={contact.email}
        feedbackFormLink={contact.feedbackFormLink}
        key={contact.id}
        name={contact.name}
        phoneNumber={contact.phoneNumber}
      />
    ));
  }
  renderSubtitle(){
    return (
      <div className="m-contacts__subtitle">{this.props.subtitle}</div>
    );
  }
  render(){
    // console.log(`MContacts.render()`, this.props);
    if(!this.props.contacts ||
       this.props.contacts.length < 1) return null;

    return (
      <div className={`m-contacts ${this.props.className}`}>
        <div className="container container--coupling">
          <div className="m-contacts__title">
            {this.props.title || 'Contact'}
          </div>
          {this.renderSubtitle()}
          <div>
            {this.renderContacts()}
          </div>
        </div>
      </div>
    );
  }
}


MContacts.propTypes = {
  className: PropTypes.string,
  contacts: PropTypes.array.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
