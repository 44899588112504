import * as apiValues from '../constants/apiValues';
import PropTypes from 'prop-types';
import React from 'react';
import {Markdown} from 'react-showdown';


export default class MarkdownField extends React.Component{
  render(){
    if(!this.props.markup) return null;

    // const markupStr = this.props.markup.replace(/(?:\r\n|\r|\n)/g, '<br>');
    const markupStr = this.props.markup
      .replace(/(?:\r\n|\r|\n)/g, '  \r\n') //fix for new lines
      .replace(/(?:\[cms]|\[CMS])/g, apiValues.CMS_PATH) //lets user use [cms], replaced by the environment's cms-app path at runtime
      .replace(/(?:([!\[]{2}|\[)(.*?)\]\((.*?)\))/gm, function(...args){
        // console.log(args);
        if(args[1] == '![' || //if it's an image
           args[3].indexOf(window.location.host) >= 0 || //if same host
           args[3].indexOf('/') == 0 //if relative link
        ) return args[0];
        else
          return `<a href='${args[3]}' target='_blank'>${args[2]}</a>`;
      });

    return (
      <div className={this.props.className}>
        <Markdown markup={markupStr}/>
      </div>
    );
  }
}


MarkdownField.propTypes = {
  className: PropTypes.string,
  markup: PropTypes.string,
};
