import iconMapLegendInjury from '../img/icons/map/legend/injury.svg';
import iconMapLegendMemorial from '../img/icons/map/legend/memorial.svg';
import iconMapLegendProjects from '../img/icons/map/legend/projects.svg';
import iconMapLegendSafety from '../img/icons/map/legend/safety.svg';
import iconMapLegendTransit from '../img/icons/map/legend/transit.svg';


export const programMapTypes = {
  memorialMap: {
    id: 'Memorial Map',
    icon: iconMapLegendMemorial,
  },
  neighborhoods: {
    id: 'Neighborhoods, Networks, and Zones',
    icon: iconMapLegendInjury,
  },
  projectFeatures: {
    id: 'Project Features',
    icon: iconMapLegendSafety,
  },
  projects: {
    id: 'Projects',
    icon: iconMapLegendProjects,
  },
  transit: {
    id: 'Transit',
    icon: iconMapLegendTransit,
  },
};
export const programsMapConf = {
  'active-transportation': {
    mode: 'active_transportation',
    tabs: [
      {
        name: 'Projects',
        id: 'active_transportation-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Neighborhoods, Networks, and Zones',
        id: 'active_transportation-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
      {
        name: 'Transit',
        id: 'active_transportation-transit',
        type: programMapTypes.transit,
      },
    ],
  },
  'great-streets': {
    mode: 'great_streets',
    tabs: [
      {
        name: 'Projects',
        id: 'great_streets-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Transit',
        id: 'great_streets-transit',
        type: programMapTypes.transit,
      },
      {
        name: 'Neighborhoods, Networks, and Zones',
        id: 'great_streets-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
    ],
  },
  'people-st': {
    mode: 'people_streets',
    tabs: [
      {
        name: 'Projects',
        id: 'people_streets-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Neighborhoods, Networks, and Zones',
        id: 'people_streets-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
    ],
  },
  'play-streets': {
    mode: 'play_streets',
    tabs: [
      {
        name: 'Projects',
        id: 'play_streets-projects',
        type: programMapTypes.projects,
      },
    ],
  },
  'safe-routes-for-seniors': {
    mode: 'safe_routes_for_seniors',
    tabs: [
      {
        name: 'Projects',
        id: 'safe_routes_for_seniors-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Safe Routes for Seniors Neighborhoods, Networks, and Zones',
        id: 'safe_routes_for_seniors-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
    ],
  },
  'safe-routes-to-school': {
    mode: 'safe_routes_to_schools',
    tabs: [
      {
        name: 'Projects',
        id: 'safe_routes_to_schools-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Neighborhoods, Networks, and Zones',
        id: 'safe_routes_to_schools-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
    ],
  },
  'vision-zero': {
    mode: 'vision_zero',
    tabs: [
      {
        name: 'Memorials and Traffic Fatalities',
        id: 'vision_zero-memorial_map',
        type: programMapTypes.memorialMap,
      },
      {
        name: 'Projects',
        id: 'vision_zero-projects',
        type: programMapTypes.projects,
      },
      {
        name: 'Project Features',
        id: 'vision_zero-project_features',
        type: programMapTypes.projectFeatures,
      },
      {
        name: 'Neighborhoods, Networks, and Zones',
        id: 'vision_zero-neighborhoods_zones',
        type: programMapTypes.neighborhoods,
      },
    ],
  },
};
