import * as apiValues from '../constants/apiValues';
import * as pageHomeActions from '../actions/pageHomeActions';
import Helmet from 'react-helmet';
import MCarousel from './MCarousel';
import MGetInvolved from './MGetInvolved';
import MProjectsCurrent from './MProjectsCurrent';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageHome extends React.Component{
  componentDidMount(){
    // console.log(`PageHome.componentDidMount()`, this.props);
    this.props.actions.initPageHome();
  }


  renderCurrentProjects(){
    if(!this.props.page.currentProjects) return null;

    return (
      <div className="coupling coupling--full-centered">
        <MProjectsCurrent cmsId={this.props.page.currentProjects.id}/>
      </div>
    );
  }
  renderGetInvolved(){
    if(!this.props.page.getInvolved) return null;

    return (
      <div className="coupling coupling--full-max">
        <MGetInvolved cmsId={this.props.page.getInvolved.id}/>
      </div>
    );
  }
  renderHeroCarousel(){
    if(!this.props.page.heroCarousel) return null;

    return (
      <div className="coupling coupling--full-max">
        <MCarousel cmsId={this.props.page.heroCarousel.id}/>
      </div>
    );
  }
  render(){
    // console.log(`PageHome.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>

        {this.renderHeroCarousel()}
        {this.renderGetInvolved()}
        {this.renderCurrentProjects()}
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageHomeActions, dispatch),
  }),
)(PageHome));


PageHome.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
