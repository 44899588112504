import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";


//Action Creators
export const searchFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.MAPPROGRAM_SEARCH_FAIL,
    error
  };
};
export const searchStart = (query) => {
  return {
    type: actionTypes.MAPPROGRAM_SEARCH_START,
    searchQuery: query
  };
};
export const searchSuccess = (data) => {
  return {
    type: actionTypes.MAPPROGRAM_SEARCH_SUCCESS,
    data
  };
};
export const toggleSearch = (isSearchOpen) => {
  console.log(isSearchOpen);
  return {
    type: actionTypes.MAPPROGRAM_TOGGLE_SEARCH,
    isSearchOpen
  };
};

export const fetchMemorialSuccess = memorials => {
  return {
    type: actionTypes.MAPPROGRAM_FETCH_MEMORIALS,
    memorials
  };
}

export const fetchProjectsSuccess = projects => {
  return {
    type: actionTypes.MAPPROGRAM_FETCH_PROJECTS,
    projects
  };
}

export const fetchProjectsByProgram = programID => dispatch => {
  apiActions.loadEndpoint(apiValues.nref.project.endpoint, null, {Program_in: programID}).then(projects => {
    const project = apiActions.arraySelectCamelCaseKeys(
      projects,
      apiValues.nref.project.fieldsUsed
    );

    dispatch(fetchProjectsSuccess(project));
  })
}

export const fetchMemorials = () => dispatch => {
  apiActions.loadEndpoint(apiValues.nref.memorial.endpoint, null).then(memorials => {
    const memorial = apiActions.arraySelectCamelCaseKeys(
      memorials,
      apiValues.nref.memorial.fieldsUsed
    );

    dispatch(fetchMemorialSuccess(memorial));
  })
}

export const doSearch = (query) => dispatch => {
  dispatch(searchStart(query));

  if (query && query.length > 0) {
    apiActions.loadProjectsByTitle(null, query)//needs to be null; we're searching for a list of results not a specific item
      .then((results) => {
        console.log(`success`, results);
        dispatch(searchSuccess(results));
      });
  }
  else {
    dispatch(searchSuccess({
      suggestedProjects: undefined,
      viewProjectsNear: undefined
    }));
  }
};
