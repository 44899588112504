import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageContentDetailActions from '../actions/pageContentDetailActions';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MContacts from './MContacts';
import MLatestEvents from './MLatestEvents';
import MLatestNews from './MLatestNews';
import MListCards from './MListCards';
import MListMedia from './MListMedia';
import MListShare from './MListShare';
import MListTag from './MListTag';
import MNewsletter from './MNewsletter';
import MProgramBanner from './MProgramBanner';
import MRelatedProjects from './MRelatedProjects';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Helmet from 'react-helmet';


class PageContentDetail extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageContentDetail.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageContentDetail(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: this.props.page.title, slug: '#'},
      ],
    };

    return (
      <MBreadcrumbs className="coupling coupling--full-centered"
                    mBreadcrumbs={mBreadcrumbs}/>
    );
  }
  renderContacts(){
    if(!this.props.page.program ||
       !this.props.page.contacts ||
       this.props.page.contacts.length < 1) return null;

    return (
      <MContacts title={`CONTACT ${this.props.page.program.title.toUpperCase()}`}
                 contacts={this.props.page.contacts}
                 className="m-contacts--sidebar"/>
    );
  }
  renderContent(){
    if(!this.props.page.shortDescription && !this.props.page.shortDescriptionSmall && !this.props.page.longDescription) return null;

    return (
      <div className="m-intro__contents">
        {this.props.page.shortDescription ? (
          <div className="m-intro__title">{helpers.nToP(this.props.page.shortDescription)}</div>
        ) : null}
        {this.props.page.shortDescriptionSmall ? (
          <div className="m-intro__description">{helpers.nToP(this.props.page.shortDescriptionSmall)}</div>
        ) : null}
        {this.props.page.longDescription ? (
          <div className="markdown-text">
            <MarkdownField markup={this.props.page.longDescription}/>
          </div>
        ) : null}
        {this.props.page.photosAndVideos && this.props.page.photosAndVideos.length > 0 ? (
          <MListMedia mListMedia={{
            mediaItems: this.props.page.photosAndVideos,
            id: this.props.match ? this.props.match.params.slug : 'pageContentDetail_mListMedia',
            title: 'Photos and Videos',
          }}/>
        ) : null}
      </div>
    );
  }
  renderLatestRelatedEvents(){
    if(!this.props.page.program) return null;

    return (<MLatestEvents program={this.props.page.program}/>);
  }
  renderRelatedProjects(){
    if(!this.props.page.program) return null;

    return (
      <MRelatedProjects featuredProjects={this.props.page.projects}
                        program={this.props.page.program}
                        programTitle={this.props.page.program.title}
                        className="coupling--full-centered"/>
    );
  }
  renderTags(){
    if(!this.props.page.tags) return null;

    return (<MListTag mListTag={{tags: this.props.page.tags}} className="list-tags"/>);
  }
  render(){
    // console.log(`render PageContentDetail`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <MProgramBanner/>
        <div className="coupling coupling---full-centered page-content-detail">
          <div className="columns">
            <div className="content-detail__content coupling--left">
              <div className="container container--coupling">
                {this.renderContent()}
                {this.renderTags()}
              </div>
              <MListShare title="Share this page"/>
            </div>
            <div className="content-detail__sidebar coupling--right">
              <MLatestNews program={this.props.page.program}/>
              {this.renderLatestRelatedEvents()}
              {this.renderContacts()}
              <MNewsletter/>
            </div>
          </div>
        </div>
        {this.renderRelatedProjects()}
        <MListCards cmsId={apiValues.PAGE_CONTENTDETAIL_RELATEDPROJECTS_ID} className="m-listcards--take-an-action coupling--full-centered"/>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageContentDetailActions, dispatch),
  }),
)(PageContentDetail));


PageContentDetail.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};
