import * as pageActions from './pageActions';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


export const initPagePrograms = slug => dispatch => {
  dispatch(pageActions.loadPageStart());
  return new Promise((resolve, reject) => {
    apiActions
      .loadEndpoint(apiValues.nref.program.endpoint, null, {Slug: slug})
      .then(results => {
        let program = apiActions.itemSelectCamelCaseKeys(
          results[0],
          apiValues.nref.program.fieldsUsed,
        );

        let page = {
          ...program,
          program: program,
          contacts: apiActions.arraySelectCamelCaseKeys(
            program.contacts,
            apiValues.nref.contact.fieldsUsed,
          ),
        };
        let promises = [];

        if(program.relatedTag && program.relatedTag.length > 0)
          promises.push(
            apiActions
              .loadEndpoint(apiValues.nref.projects.endpoint, null, {
                Tags_contains: program.relatedTag.id,
              })
              .then(projects => {
                page.program.project = apiActions.arraySelectCamelCaseKeys(
                  projects,
                  apiValues.nref.project.fieldsUsed,
                );

                return page;
              }),
          );

        promises.push(
          apiActions.loadEndpoint(apiValues.nref.memorial.endpoint, null).then(memorials => {
            page.memorial = apiActions.arraySelectCamelCaseKeys(
              memorials,
              apiValues.nref.memorial.fieldsUsed,
            );

            return page;
          }),
        );

        return Promise.all(promises).then(() => {
          page.program && page.program.events ? page.program.events = apiActions.arraySelectCamelCaseKeys(page.program.events, apiValues.nref.event.fieldsUsed) : [];
          page.program.featuredProjects ? page.program.featuredProjects = apiActions.arraySelectCamelCaseKeys(page.program.featuredProjects, apiValues.nref.project.fieldsUsed) : [];
          page.program.project ? page.program.project = apiActions.arraySelectCamelCaseKeys(page.program.project, apiValues.nref.project.fieldsUsed) : [];
          return page;
        });
      })
      .then(results => {
        resolve(dispatch(pageActions.loadPageSuccess(results)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
