import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mapParentActions from '../actions/mapParentActions';
import loadingIcon from '../img/loading-icon-small.gif';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


let currentDomain = '';  //validate iframe domain origin with map/child.html & map/dcr_mapping_child.js
if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1'){
  var portNumber = location.port ? ':' + location.port : '';
  currentDomain = window.location.protocol + '//' + window.location.hostname + portNumber;
}
else{
  currentDomain = window.location.protocol + '//' + window.location.hostname; //TODO TEST THIS ON BUILT VANITY URL LOCATION
}

// let dcrMappingData = {
//   project_id: '',
//   pif: '',
//   params: [],
//   functionName: '',
//   selectedEditLayer: '',
//   currentProgram: 'all',
//   currentProgramStatus: 'all',
//   startDate: '2012',
//   endDate: '2018'
// };


class MapParent extends React.Component{
  constructor(props){
    super(props);

    this.state = {
      collisions: 0,
      feedbackAddress: '',
      mapPluginReady: false,
    };
    this.isMapLoaded = false;

    this.componentDidMount = this.componentDidMount.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.sendMessageToChild = this.sendMessageToChild.bind(this);
    this.sendDirectlyToChild = this.sendDirectlyToChild.bind(this);
    this.recieveMessageFromChild = this.recieveMessageFromChild.bind(this);

    this.handleMessageFromChild = this.handleMessageFromChild.bind(this);

  }
  componentDidMount(){
    this.bindEvents();
  }
  UNSAFE_componentWillReceiveProps(prevProps){
    const {mProjectListMap: prevmProjectListMap} = prevProps;
    const {mProjectListMap} = this.props;
    if(prevmProjectListMap.isSearchOpen && !mProjectListMap.isSearchOpen){
      this.handleNearByProjects();
    }
    if(!this.isMapLoaded){
      return;
    }
    // if (prevmProjectListMap.filter_program != mProjectListMap.filter_program ||
    //   prevmProjectListMap.fitler_projectStatus != mProjectListMap.filter_projectStatus ||
    //   prevmProjectListMap.filter_projectType != mProjectListMap.filter_projectType ||
    //   prevmProjectListMap.projects != mProjectListMap.projects) {
    //   const allProjects = prevmProjectListMap.projects.filter(item => !!item.projectId);
    //   const projectIds = allProjects.map((item) => item.projectId);
    //   this.sendMessageToChild({
    //     functionName: "drawProjectsById",
    //     params: [projectIds]
    //   });
    // }
    if(prevmProjectListMap.projects != mProjectListMap.projects){
      // const allProjects = prevmProjectListMap.projects.filter(item => !!item.projectId);
      // console.log('---111', prevmProjectListMap);
      // console.log('--allprojects', allProjects);
      // const projectIds = mProjectListMap.projects
      const projectIds = prevmProjectListMap.projects
        .map(projectId => this.props.project.find(project => project.id == projectId))
        .filter(project => !!project && !!project.projectId)
        .map(project => project.projectId);
      console.log('---projectids', projectIds);
      if(projectIds.length === 0){
        this.sendMessageToChild({
          functionName: 'drawLayer',
          //params: [[]]  // empty array = draw All projects (i.e. no filter)
          params: ['cms_project_polygons', false],  // Roscoe Blvd, N Broadway
        });
      }
      else{
        this.sendMessageToChild({
          functionName: 'drawProjectsById',
          params: [projectIds],
        });
        this.sendMessageToChild({
          functionName: 'drawLayer',
          //params: [[]]  // empty array = draw All projects (i.e. no filter)
          params: ['cms_project_polygons', true],  // Roscoe Blvd, N Broadway
        });
      }
    }
  }


  /**
   * This function binds events from this javascript file to the browser
   */
  bindEvents(){
    // Assign handler to message event from the browser window.
    if(window.addEventListener){
      window.addEventListener('message', this.recieveMessageFromChild, false);
    }
    else if(window.attachEvent){ // supports ie8
      window.attachEvent('onmessage', this.recieveMessageFromChild);
    }
  }
  getNearbyProjects = data => {
    this.props.handleMapNearByProjects(data.projects);
  };
  handleGoToProjectDetail = data => {
    const selectedProject = this.props.project.find(item => item.projectId == data.project_id);
    if(selectedProject){
      const targetUrl = `/projects/${selectedProject.slug || ''}`;

      if(this.props.isPageProjectsExternal){
        var currentDomain = `${window.location.protocol}//${window.location.hostname}`;
        if(window.location.hostname == 'localhost' || window.location.hostname == '127.0.0.1')
          currentDomain += location.port ? ':' + location.port : '';

        window.parent.postMessage(JSON.stringify({
          source:'page-projects-external',
          payload: currentDomain+targetUrl
        }), "*");
      }
      else{
        this.props.history.push(targetUrl);
      }
    }
  };
  handleMessageFromChild = (data) => {
    // DCR_Mapping test response
    // =============================

    //console.group("parent.html > parent.js > handleMessageFromChild");
    //console.log('data:', data);
    //console.log(typeof data);

    console.log(data, data.functionName);
    if(data && data.functionName){

      switch(data.functionName){
        case 'mapPluginReady':
          this.mapLoaded();
          break;
        case 'projectPopupClicked':
          this.handleGoToProjectDetail(data);
          break;
        case 'updateFeatureCount':
          this.updateFeatureCount(data);

          break;

        case 'displayFeedbackAddressPoint':
          this.displayFeedbackAddressPoint(data);

          break;
        case 'getNearbyProjects':
          this.getNearbyProjects(data);
          break;
        case 'test':
          //console.log('testData from Child:', data);

          break;

        default:
        //console.warn("Error with payload data", data);
      }
    }
    //console.groupEnd();
  };
  handleNearByProjects = () => {
    this.sendMessageToChild({
      functionName: 'getNearbyProjects',
      params: [1],
    });
  };
  handleProjectRedirect = () => {
    //handleProjectRedirect = data => {
    // const { project_id } = data;
    // this.props.actions.initMapParent(project_id);
  };
  mapLoaded = () => {
    const {mProjectListMap} = this.props;
    if(!mProjectListMap.filter_program && !mProjectListMap.fitler_projectStatus && !mProjectListMap.filter_projectType){
      this.sendMessageToChild({
        functionName: 'setMapMode',
        params: ['projects'],
      });
    }
    else{
      if(mProjectListMap.projects.length > 0){
        const allProjects = mProjectListMap.projects.filter(item => !!item.projectId);
        const projectIds = allProjects.map((item) => item.projectId);
        this.sendMessageToChild({
          functionName: 'drawProjectsById',
          params: [projectIds],
        });
      }
    }
    // this.sendMessageToChild({
    //   functionName: "zoomToLoc",
    //   params: ["city_la"]
    // });
    // this.sendMessageToChild({
    //   functionName: "drawProjectsById",
    //   params: [[]]
    // });
    this.isMapLoaded = true;
    setTimeout(() => this.setState({
      mapPluginReady: true,
    }), 5000);
    // this.handleNearByProjects();
  };
  /**
   * This function listens for an event from the browser message event and returns a JSON
   * @param {*} event
   */
  recieveMessageFromChild(event){
    //console.group("MAPPARENT parent.html > parent.js > recieveMessageFromChild");
    //console.log(event);

    console.log(event, event.origin, currentDomain);
    if(event.origin === currentDomain){ //WARNING this is used to verify the source of the message, aka whitelist
      // Retrieve data sent in postMessage

      //console.log("MAPPARENT event.origin", event.origin);
      //console.log("MAPPARENT currentDomain", currentDomain);
      //console.log("MAPPARENT event.data", event.data);

      if(event.data){
        //console.log("Message received from child:", JSON.parse(event.data));
        //console.groupEnd();

        let data = typeof event.data != 'object' ? JSON.parse(event.data) : event.data;
        this.handleMessageFromChild(data);

        return data;
      }
      else{
        //console.warn("Check message payload on event.data", event.data);
      }
    }
    else{
      //console.warn("Error with validating origin: " + event.origin + " !== " + currentDomain);
    }
  }
  /**
   * // TODO: Talk to Roland about strictly defining the params as a JSON Object,
   * that way you can just use this function and pass parameters directly,
   * with a strictly defined JSON Object he would have easier time validating the parameters too
   *
   * Currently this will not work unless his parameters are properly defined.
   *
   * @param {*} event
   */
  sendDirectlyToChild(event){
    //console.group("parent.html > parent.js > sendDirectlyToChild");
    //console.log(event);


    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [],
    });
    //console.groupEnd();
  }
  /**
   * This function sents a message from the parent to the child.
   *
   * It can be called by passing a JSON as an example
   *
   * sentMessageToChild({ //payload is being defined at the moment w/ DCR Maps Team.
        mapMode: programs-projects, // can also be program-projects, project-details, feedback, memorial
        program: [vision_zero, active_transporation, ...]
        projects: [data_collection_feedback, plannding_design, construction, ...] , // can also be data_collection_feedback, planning_design, construction, completed, post_project_evaluation, active
        ui_ZoomLevel: "lab-zoom", // defines HTML element showing current zoomlevel,
   * })
   *
   */
  sendMessageToChild(payload){
    //console.group("parent.html > parent.js > sendMessageToChild");
    //console.log(payload);
    if(payload && this.iframe){
      this.iframe.contentWindow.postMessage(JSON.stringify(payload), currentDomain);
    }

    //console.log("MAPPARENT -> dcrMappingData: ", dcrMappingData);
    //console.groupEnd();
  }


  renderMapLoadingIconDesktop = () => {
    if(this.state.mapPluginReady) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon"/>);
  };
  renderMapLoadingIconMobile = () => {
    if(this.state.mapPluginReady) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon-mobile"/>);
  };
  render(){
    return (
      <div className={`m-projectlistmap ${this.props.className || ''}`}>
        <div className="m-projectlistmap__map-iframe-container">
          {this.renderMapLoadingIconDesktop()}
          {this.renderMapLoadingIconMobile()}
          <iframe className="m-projectlistmap__map-iframe"
            src={`/maps/child.html?cms_path=${apiValues.CMS_PATH}`}
                  // src={`/maps/child.html?cms_path=https://ladot-dev-cms.getsomeglue.com`}
                  ref={(event) => this.iframe = event}/>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => ({
    MapParent: helpers.getById(state.mapParent, ownProps.cmsId),
    mProjectListMap: state.mProjectListMap,
    project: state.project,
  }),
  (dispatch) => ({
    actions: bindActionCreators(mapParentActions, dispatch),
  }),
)(MapParent));


MapParent.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  handleMapNearByProjects: PropTypes.func,
  history: PropTypes.object,
  isPageProjectsExternal:PropTypes.bool,
  MapParent: PropTypes.object,
  mProjectListMap: PropTypes.object,
  project: PropTypes.array,
};
