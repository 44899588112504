import * as helpers from '../helpers';
import * as mIntroActions from '../actions/mIntroActions';
import MarkdownField from './MarkdownField';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MIntro extends React.Component{
  componentDidMount(){
    //console.log(`MIntro.componentDidMount()`, this.props);
    if(this.props.cmsId){
      this.props.actions.initMIntro(this.props.cmsId);
    }
    else{
      // Additional setup
      //console.log(this.props);
    }
  }


  renderDescription(){
    if(!this.props.mIntro.description) return null;

    return (<MarkdownField markup={this.props.mIntro.description} className="m-intro__description"/>);
  }
  renderTitle(){
    if(!this.props.mIntro.title) return null;

    return (<div className="m-intro__title">{this.props.mIntro.title}</div>);
  }
  render(){
    // console.log(`MIntro render`, this.props);
    if(!this.props.mIntro ||
       (!this.props.mIntro.title && !this.props.mIntro.description)) return null;

    return (
      <div className={`m-intro ${this.props.className || ''}`}>
        <div className="container container--coupling">
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {mIntro: helpers.getById(state.mIntro, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: bindActionCreators(mIntroActions, dispatch),
  }),
)(MIntro));


MIntro.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mIntro: PropTypes.object,
};
