import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";

export default function mProjectsReducer(state = initialState.mProjects, action) {
  switch (action.type) {
    case actionTypes.MPROJECTS_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });

    case actionTypes.MPROJECTS_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });

    case actionTypes.MPROJECTS_LOAD_SUCCESS: {
      return helpers.updateOrAdd(state, {
        ...action.data,
        isLoading: false,
        error: undefined
      });
    }

    case actionTypes.MPROJECTS_SET_LISTLENGTH: {
      return helpers.updateOrAdd(state, {
        id:action.id,
        listLength: action.listLength
      });
    }

    case actionTypes.MPROJECTS_SET_FILTER: {
      return helpers.updateOrAdd(state, {
        id:action.id,
        filter: action.filter
      });
    }

    default:
      return state;
  }
}
