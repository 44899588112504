import 'rc-slider/assets/index.css';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as mapProgramActions from '../actions/mapProgramActions';
import iconArrowRight from '../img/icon-arrow-right-black.png';
import iconClose from '../img/icons/icon-close.svg';
import iconMapLegendInjury from '../img/icons/map/legend/injury.svg';
import iconMapLegendMemorial from '../img/icons/map/legend/memorial.svg';
import iconMapLegendProjects from '../img/icons/map/legend/projects.svg';
import iconMapLegendSafety from '../img/icons/map/legend/safety.svg';
import iconMapLegendTransit from '../img/icons/map/legend/transit.svg';
import loadingIcon from '../img/loading-icon-small.gif';
import ProgramBanner from './ProgramBanner';
import PropTypes from 'prop-types';
import React from 'react';
import ShareMemorial from './ShareMemorial';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Range } from 'rc-slider';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import MarkdownField from './MarkdownField';


let currentDomain = ''; //validate iframe domain origin with map/child.html & map/dcr_mapping_child.js

if(
  window.location.hostname == 'localhost' ||
  window.location.hostname == '127.0.0.1'
){
  var portNumber = location.port ? ':' + location.port : '';
  currentDomain =
    window.location.protocol + '//' + window.location.hostname + portNumber;
}
else{
  currentDomain = window.location.protocol + '//' + window.location.hostname; //TODO TEST THIS ON BUILT VANITY URL LOCATION
}

// let initMapPayload = {
//   testMsg: "Hello World!!!",
//   debug: true,
//   isCMS: false,  // flag for CMS or Public version
//   ui_ZoomLevel: "lab-zoom",  // defines HTML element showing current zoomlevel
//   map_html_class: "map-render-area", // this would be the "class" name of the div for example <div class="map-render-area"></div> whether in the CMS or in the PUB site
//   map_div_id: "map-primary-render-area", // since you can only have one "id" on a html page, you would only be able to use this once. Examples: <div id="map-primary"></div>, <div class="map-render-area"></div>, <div id="somethingelse" class="map-render-area"></div>
//   memorial_csv: "./test_memorial.csv"
// };

//TODO you may not need the project_id, pif if passed directly however if you do
//you can use this to temporarily store data for reference locally.
let dcrMappingData = {
  project_id: '',
  pif: '',
  params: [],
  functionName: '',
  selectedEditLayer: '',
  currentProgram: 'all',
  currentProgramStatus: 'all',
  startDate: '2017',
  endDate: '2018',
};
let layer_names = {};
let features_types_name = [];
let default_layers = [];
const overallProgram = {
  title: 'Overall Map & Data',
  color: '#3D5741',
  mapsIntroText: '',
};


class OverallMap extends React.Component{
  constructor(props){
    super(props);

    let slug = props.location.search;
    let memorialPopUpName = null;
    if(slug && slug.includes('sharememorial')){
      slug = slug.split('-');
      memorialPopUpName = slug[1].replace('%20', ' ');
      slug = slug[0].replace('sharememorial=', '').replace('?', '');
    }
    else{
      slug = null;
    }

    this.state = {
      showMemorial: slug,
      memorialPopUpName,
      collisions: 0,
      collisionDetail: {},
      feedbackAddress: '',
      currentLayers: [],
      currentTab: props.tabs[0].id,
      height: window.innerHeight,
      width: window.innerWidth,
      selectedObject: null,
      memorialDateFilter: [2017, 2018],
      mapPluginReady: false,
      tabContentClose: false,
    };

    //this.componentDidMount = this.componentDidMount.bind(this);
    this.bindEvents = this.bindEvents.bind(this);
    this.sendMessageToChild = this.sendMessageToChild.bind(this);
    this.sendDirectlyToChild = this.sendDirectlyToChild.bind(this);
    this.recieveMessageFromChild = this.recieveMessageFromChild.bind(this);
    this.testBtn = this.testBtn.bind(this);
    this.zoomToOpt = this.zoomToOpt.bind(this);
    this.getMapLayersBtn = this.getMapLayersBtn.bind(this);
    this.mapmodeOpt = this.mapmodeOpt.bind(this);
    this.programOpt = this.programOpt.bind(this);
    this.projectOpt = this.projectOpt.bind(this);
    this.drawLayer = this.drawLayer.bind(this);
    this.setDateStart = this.setDateStart.bind(this);
    this.setDateEnd = this.setDateEnd.bind(this);
    this.statusOpt = this.statusOpt.bind(this);
    this.startEditingOpt = this.startEditingOpt.bind(this);
    this.stopEditingBtn = this.stopEditingBtn.bind(this);
    this.handleMessageFromChild = this.handleMessageFromChild.bind(this);
    this.updateFeatureCount = this.updateFeatureCount.bind(this);
    this.enableFeedbackBtn = this.enableFeedbackBtn.bind(this);
    this.displayFeedbackAddressPoint = this.displayFeedbackAddressPoint.bind(
      this,
    );
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  componentDidMount(){
    this.props.actions.fetchMemorials();
    this.bindEvents();
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount(){
    window.removeEventListener('resize', this.updateDimensions);
  }


  filteringApply_onClick = () => {
    this.setState({filtersOpen: false});
  };
  filteringCancel_onClick = () => {
    this.setState({
      filtersOpen: false,
      currentLayers: this.state.previousLayers,
    });
  };
  filteringOpen_onClick = () => {
    let newState = {filtersOpen: !this.state.filtersOpen};
    if(!this.state.filtersOpen)
      newState.previousLayers = this.state.currentLayers;
    this.setState(newState);
  };
  mapClose_onClick = () => {
    this.setState({mapOpen: false});
  };
  mapOpen_onClick = () => {
    this.setState({mapOpen: !this.state.mapOpen});
  };


  /**
   * This function binds events from this javascript file to the browser
   */
  bindEvents(){
    // Assign handler to message event from the browser window.
    if(window.addEventListener){
      window.addEventListener('message', this.recieveMessageFromChild, false);
    }
    else if(window.attachEvent){
      // supports ie8
      window.attachEvent('onmessage', this.recieveMessageFromChild);
    }
  }
  changeValue = value => {
    this.setState({
      memorialDateFilter: value,
    });
    this.sendMessageToChild({
      functionName: 'setDateFilter',
      params: ['collisions', value[0], value[1]],
    });
  };
  displayFeedbackAddressPoint(data){
    //console.group("parent.html > parent.js > displayFeedbackAddressPoint");
    //console.log("data:", data);
    //console.log("MapProgram before setState this.state", this.state);
    this.setState({feedbackAddress: data.address});
    //console.log("MapProgram after setState this.state", this.state);
    //console.groupEnd();
  }
  getMapLayersBtn(){
    this.sendMessageToChild({
      functionName: 'getMapLayer',
      params: ['srts_top50'],
    });
  }
  drawLayer(event){
    //console.log("MapProgram -> drawLayer event: ", event);
    //console.log("MapProgram -> drawLayer event.target.value: ", event.target.value);
    //console.log("MapProgram -> drawLayer event.target.name: ", event.target.name);
    //console.log("MapProgram -> drawLayer event.target.getAttribute(map-layer): ", event.target.getAttribute("map-layer"));

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.getAttribute('map-layer'), event.target.value],
    });
  }
  enableFeedbackBtn(event){
    //console.log("MapProgram -> enableFeedbackBtn event: ", event);
    //TODO event.target.getAttribute('service-task')
    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [], // project_id is optional to test if point inside projecta area
    });
  }
  handleGoToProjectDetail = data => {
    // const program = this.props.page.program.find(item => item.slug === layer_id);
    let selectedProject;
    this.props.page.program.forEach(program => {
      program.project.forEach(project => {
        // console.log(`project id = ${project.projectId} == ? ${project.projectId == data.project_id}`);
        if(project.projectId == data.project_id) selectedProject = project;
      });
    });
    if(selectedProject) this.props.history.push(`/projects/${selectedProject.slug || ''}`);
    else console.error(`couldn't find project with id ${data.project_id}`);
  };
  handleMessageFromChild(data){
    // console.log(`handleMessageFromChild(data)`, data);
    // DCR_Mapping test response
    // =============================

    //console.group("parent.html > parent.js > handleMessageFromChild");
    //console.log('data:', data);
    //console.log(typeof data);
    // console.log("--handlemsssage---", data);
    if(data && data.functionName){
      let memorialMapTab = this.props.tabs.find(tab => tab.id.indexOf('memorial_map') >= 0);
      switch(data.functionName){
        case 'mapPluginReady':
          this.mapLoaded();
          break;
        case 'projectPopupClicked':
          this.handleGoToProjectDetail(data);
          break;
        case 'updateFeatureCount':
          this.updateFeatureCount(data);

          break;

        case 'displayFeedbackAddressPoint':
          this.displayFeedbackAddressPoint(data);

          break;
        case 'getMapLayerGroup':{
          const {mode} = this.props;
          const layers = data.layers.layers;
          const group_id = data.layers.id;
          let layer_data = {};
          layers.forEach(layer => {
            this.sendMessageToChild({
              functionName: 'getMapLayer',
              params: [layer], // no arg
            });
            if(group_id == `${mode}-default`){
              default_layers.push(layer);
            }
            layer_data[layer] = default_layers.includes(layer);
            // if (layer === 'coll_ped_fatal') {
            //   layer_data[layer] = false;
            // }
          });
          this.setState({
            ...this.state,
            layers: {
              ...this.state.layers,
              [group_id]: layer_data,
            },
          });
        }
          break;
        case 'getFeatureTypes':{
          if(data.types.length === 0){
            setTimeout(() => {
              this.sendMessageToChild({
                functionName: 'getFeatureTypes',
                params: ['point'],
              });
            }, 3000);
          }
          else{
            features_types_name = data.types;
            let layer_data = {};
            features_types_name.map(item => {
              if(item && item.value && item.count){
                layer_data[item.value] = false;
              }
            });
            this.setState({
              layers: {
                ...this.state.layers,
                'overall-project_features': layer_data,
              },
            });
          }
        }
          break;
        case 'getMapLayer':{
          const layer = data.layer;
          layer_names[layer.id] = layer.name;
        }
          break;
        case 'selectedFeature':
          if(memorialMapTab){
            this.selectTab(memorialMapTab.id);
            setTimeout(() => {
              this.setState({
                selectedObject: data.values.caseid,
                memorialPopUpName: data.values.name,
              });
            });
          }
          break;
        case 'memorialPopupMore':
          console.log('----memorialPopupMore', data);
          break;
        case 'cmsFeaturePopupMore':
          console.log('----cmsFeaturePopupMore', data);
          break;

        case 'collisionPopupClicked':
          window.location = `mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${data.case_id})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`;
          break;

        default:
        //console.warn("Error with payload data", data);
      }
    }
    //console.groupEnd();
  }
  handleMemorialContent = () => {
    this.setState({
      selectedObject: null,
      showMemorial: null,
    });
  };
  handleTabContentClose = () => {
    // this.setState({
    //   tabContentClose: true,
    // });
    this.selectTab('');
  };
  mapLoaded(){
    this.sendMessageToChild({
      functionName: 'setMapMode',
      params: ['safe_routes_for_seniors'],
    });

    this.props.tabs.forEach(({id, layers}) => {
      let layer_data = {};
      layers.forEach(layer => {
        this.sendMessageToChild({
          functionName: 'getMapLayer',
          params: [layer], // no arg
        });
        layer_data[layer] = ['vz_hiinjnet'].includes(layer);
        // if (layer === 'coll_ped_fatal') {
        //   layer_data[layer] = false;
        // }
      });
      this.setState({
        ...this.state,
        layers: {
          ...this.state.layers,
          [id]: layer_data,
        },
      });
    });

    if(this.props.page.program && this.props.page.program.length > 0){
      let layer_data = {};
      this.props.page.program.map(item => {
        if(item.slug && item.title){
          layer_data[item.slug] = false;
          layer_names[item.slug] = item.title;
        }
      });

      this.setState({
        layers: {
          ...this.state.layers,
          ['overall-projects']: layer_data,
        },
      });
    }

    this.sendMessageToChild({
      functionName: 'zoomToLoc',
      params: ['city_la'],
    });
    // this.sendMessageToChild({
    //   functionName: "setDateFilter",
    //   params: ["collisions", this.state.memorialDateFilter[0], this.state.memorialDateFilter[1]]
    // });
    this.sendMessageToChild({
      functionName: 'getFeatureTypes',
      params: ['point'],
    });

    setTimeout(() => this.setState({
      mapPluginReady: true,
    }), 5000);
  }
  mapmodeOpt(event){
    //console.log("MapProgram -> mapmodeOpt event: ", event);
    //console.log("MapProgram -> mapmodeOpt event.target.value: ", event.target.value);

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.value],
    });
  }
  onToggleLayer(layer_id){
    const toggle = !this.state.layers[this.state.currentTab][layer_id];
    this.setState({
      ...this.state,
      layers: {
        ...this.state.layers,
        [this.state.currentTab]: {
          ...this.state.layers[this.state.currentTab],
          [layer_id]: toggle,
        },
      },
    });

    console.log(this.state.currentTab, layer_id);
    if(this.state.currentTab === 'overall-projects'){
      const program = this.props.page.program.find(item => item.slug === layer_id);
      console.log(program);
      if(toggle){
        if(program.project && program.project.length > 0){
          let projectIds = program.project.filter(item => item.projectId).map(item => item.projectId);
          Object.keys(this.state.layers['overall-projects']).forEach(itemLayer => {
            if(itemLayer !== layer_id && this.state.layers['overall-projects'][itemLayer]){
              const programTemp = this.props.page.program.find(itemProgram => itemProgram.slug === itemLayer);
              if(programTemp && programTemp.project && programTemp.project.length > 0){
                projectIds = projectIds.concat(programTemp.project.filter(project => project.projectId).map(project => project.projectId));
              }
            }
          });
          if(projectIds.length > 0){
            this.sendMessageToChild({
              functionName: 'drawProjectsById',
              //params: [[]]  // empty array = draw All projects (i.e. no filter)
              params: [projectIds],  // Roscoe Blvd, N Broadway
            });
          }
        }
      }
      else{
        let projectIds = [];
        Object.keys(this.state.layers['overall-projects']).forEach(item => {
          if(item !== layer_id && this.state.layers['overall-projects'][item]){
            const program = this.props.page.program.find(itemProgram => itemProgram.slug === item);
            if(program && program.project && program.project.length > 0){
              projectIds = projectIds.concat(program.project.filter(project => project.projectId).map(project => project.projectId));
            }
          }
        });
        if(projectIds.length > 0){
          this.sendMessageToChild({
            functionName: 'drawProjectsById',
            //params: [[]]  // empty array = draw All projects (i.e. no filter)
            params: [projectIds],  // Roscoe Blvd, N Broadway
          });
        }
        else{
          this.sendMessageToChild({
            functionName: 'drawLayer',
            //params: [[]]  // empty array = draw All projects (i.e. no filter)
            params: ['cms_project_polygons', false],  // Roscoe Blvd, N Broadway
          });
        }
      }
    }
    else{
      this.sendMessageToChild({
        functionName: 'drawLayer',
        params: [layer_id, toggle],
      });
    }
  }
  onToggleFeatureLayer(layer_id){
    const toggle = !this.state.layers[this.state.currentTab][layer_id];
    this.setState({
      ...this.state,
      layers: {
        ...this.state.layers,
        [this.state.currentTab]: {
          ...this.state.layers[this.state.currentTab],
          [layer_id]: toggle,
        },
      },
    });
    this.sendMessageToChild({
      functionName: 'drawLayerFeature',
      params: ['cms_feature_points', layer_id, toggle],
    });
  }
  programOpt(event){
    //console.log("MapProgram -> programOpt event: ", event);
    //console.log("MapProgram -> programOpt event.target.value: ", event.target.value);

    dcrMappingData.currentProgram = event.target.value;

    //console.log("MapProgram -> programOpt dcrMappingData.currentProgram: ", dcrMappingData.currentProgram);

    this.sendMessageToChild({
      functionName: 'drawProjects',
      params: [
        dcrMappingData.currentProgram,
        dcrMappingData.currentProgramStatus,
      ],
    });
  }
  projectOpt(event){
    this.sendMessageToChild({
      functionName: 'zoomToProject',
      params: [event.target.value],
    });

    // this.sendMessageToChild({
    //   functionName: "drawFeaturesByProject",
    //   params: [event.target.value]
    // });

    this.sendMessageToChild({
      functionName: 'drawFeaturesByProject',
      params: [event.target.value, 'vision_zero'],
    });
  }
  /**
   * This function listens for an event from the browser message event and returns a JSON
   * @param {*} event
   */
  recieveMessageFromChild(event){
    // console.log(`recieveMessageFromChild(event)`, event);
    //console.group("MapProgram parent.html > parent.js > recieveMessageFromChild");
    //console.log(event);
    if(event.origin === currentDomain){
      //WARNING this is used to verify the source of the message, aka whitelist
      // Retrieve data sent in postMessage

      //console.log("MapProgram event.origin", event.origin);
      //console.log("MapProgram currentDomain", currentDomain);
      //console.log("MapProgram event.data", event.data);

      if(event.data){
        // console.log("Message received from child:", JSON.parse(event.data));
        //console.groupEnd();

        let data = typeof event.data == 'object' ? event.data : JSON.parse(event.data);
        this.handleMessageFromChild(data);

        return data;
      }
      else{
        //console.warn("Check message payload on event.data", event.data);
      }
    }
    else{
      //console.warn("Error with validating origin: " + event.origin + " !== " + currentDomain);
    }
  }
  selectTab(tabName){
    switch(tabName){
      case 'overall-project_features':{
        this.sendMessageToChild({
          functionName: 'drawLayerGroup',
          params: ['street_improvements-default'],
        });
      }
        break;
      case 'overall-memorial_map':{
        this.sendMessageToChild({
          functionName: 'setDateFilter',
          params: ['collisions', this.state.memorialDateFilter[0], this.state.memorialDateFilter[1]],
        });
      }
        break;
    }
    this.setState({
      ...this.state,
      currentTab: tabName,
      showMemorial: null,
      tabContentClose: false,
    });
  }
  /**
   * // TODO: Talk to Roland about strictly defining the params as a JSON Object,
   * that way you can just use this function and pass parameters directly,
   * with a strictly defined JSON Object he would have easier time validating the parameters too
   *
   * Currently this will not work unless his parameters are properly defined.
   *
   * @param {*} event
   */
  sendDirectlyToChild(event){
    //console.group("parent.html > parent.js > sendDirectlyToChild");
    //console.log(event);

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [],
    });
    //console.groupEnd();
  }
  /**
   * This function sents a message from the parent to the child.
   *
   * It can be called by passing a JSON as an example
   *
   * sentMessageToChild({ //payload is being defined at the moment w/ DCR Maps Team.
        mapMode: programs-projects, // can also be program-projects, project-details, feedback, memorial
        program: [vision_zero, active_transporation, ...]
        projects: [data_collection_feedback, plannding_design, construction, ...] , // can also be data_collection_feedback, planning_design, construction, completed, post_project_evaluation, active
        ui_ZoomLevel: "lab-zoom", // defines HTML element showing current zoomlevel,
   * })
   *
   */
  sendMessageToChild(payload){
    //console.group("parent.html > parent.js > sendMessageToChild");
    //console.log(payload);
    if(payload){
      this.iframe.contentWindow.postMessage(
        JSON.stringify(payload),
        currentDomain,
      );
    }

    //console.log("MapProgram -> dcrMappingData: ", dcrMappingData);
    //console.groupEnd();
  }
  setDateEnd(event){
    dcrMappingData.endDate = event.target.value;

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: ['collisions', dcrMappingData.startDate, dcrMappingData.endDate],
    });
  }
  setDateStart(event){
    dcrMappingData.startDate = event.target.value;

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: ['collisions', dcrMappingData.startDate, dcrMappingData.endDate],
    });
  }
  startEditingOpt(event){
    //console.log("MapProgram -> startEditingOpt event: ", event);
    //console.log("MapProgram -> startEditingOpt event.target.value: ", event.target.value);

    //store selectedLayer for referenceing stopEditingBtn();
    dcrMappingData.selectedEditLayer = event.target.value;

    //console.log("MapProgram -> dcrMappingData: ", dcrMappingData);
    this.sendMessageToChild({
      functionName: 'startEditing',
      params: [event.target.value, {project_id: '0', pif: 'Test'}],
    });
  }
  statusOpt(event){
    //store currentProgramStatus
    dcrMappingData.currentProgramStatus = event.target.value;

    // this.sendMessageToChild({
    //   functionName: "drawProjects",
    //   params: [dcrMappingData.currentProgram, dcrMappingData.currentProgramStatus]
    // });
    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [
        dcrMappingData.currentProgram,
        dcrMappingData.currentProgramStatus,
      ],
    });
  }
  stopEditingBtn(){
    this.sendMessageToChild({
      functionName: 'stopEditing',
      params: [dcrMappingData.selectedEditLayer],
    });
  }
  testBtn(){
    //console.log("MapProgram -> testBtn this.state", this.state);
    //console.log("MapProgram -> testBtn this.props", this.props);
    //console.log("MapProgram -> testBtn this.actions", this.props.actions);

    this.sendMessageToChild({
      functionName: 'test',
      params: [], // no arg
    });
  }
  updateDimensions(){
    this.setState({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }
  updateFeatureCount(data){
    //console.group("parent.html > parent.js > updateFeatureCount");
    //console.log("data:", data);

    //console.log("MapProgram before setState this.state", this.state);
    //let dcrmap = {...this.state.dcrmap, collisions : data.count};

    const collisionDetail = this.state.collisionDetail;
    collisionDetail[data.layer] = data.count;

    this.setState({
      collisions: data.count,
      collisionDetail,
    });

    //console.log("MapProgram after setState this.state", this.state);
    //console.log(this.state.collisions);
    //console.groupEnd();
  }
  zoomToOpt(event){
    //console.log("MapProgram -> zoomtoOpt event: ", event);
    //console.log("MapProgram -> zoomtoOpt event.target.value: ", event.target.value);

    //TODO: Since the params array doesn't need to be stored, you can reuse it but it must be cleared first.
    dcrMappingData.params.splice(0, dcrMappingData.params.length); //empties dcrMappingData.params array

    this.sendMessageToChild({
      functionName: event.target.getAttribute('service-task'),
      params: [event.target.value],
    });
  }


  renderFilterControls(){
    if(!this.state.mapOpen) return null;

    return (
      <div className="map-program__tab-menu-buttons col">
        <button className="tg-button tg-button--pill tg-button--black flex-fill"
                onClick={this.filteringApply_onClick}>
          <span className="tg-button__label">Apply</span>
        </button>
        <button className="tg-button tg-button--pill tg-button--white-ghost flex-fill"
                onClick={this.filteringCancel_onClick}>
          <span className="tg-button__label">Cancel</span>
        </button>
      </div>
    );
  }
  renderIntroSection(){
    const buttonDisplayClass = this.state.mapOpen ? 'd-none' : '';
    return (
      <div className="map-program__intro coupling coupling--centered">
        <div className="container container--coupling">
          <div className="map-program__intro-title-container">
            <div>
              <ProgramBanner program={overallProgram}/>
            </div>
            <div className="map-program__intro-title">Maps</div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="map-program__intro-description">
                {overallProgram.mapsIntroText}
              </div>

              <button className={`map-program__view-maps-button tg-button tg-button--pill tg-button--black ${buttonDisplayClass}`}
                      onClick={this.mapOpen_onClick}>
                <span className="tg-button__label">View Maps</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderMapControls = () => {
    if(!this.state.mapOpen) return null;

    return (
      <>
        <button className="map-program__map-button map-program__map-button--filtering"
                onClick={this.filteringOpen_onClick}>
          Open Filtering
        </button>
        <button className="map-program__map-button map-program__map-button--close"
                onClick={this.mapClose_onClick}>
          Close
        </button>
      </>
    );
  };
  renderMapLoadingIconDesktop = () => {
    if(this.state.mapPluginReady) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon"/>);
  };
  renderMapLoadingIconMobile = () => {
    if(this.state.mapPluginReady || !this.state.mapOpen) return null;

    return (<img alt="Loading" src={loadingIcon} className="map-loading-icon-mobile"/>);
  };
  renderMapSection(){
    const displayClass = this.state.mapOpen ? 'map-program__map--is-open' : '';
    return (
      <div className={`map-program__map ${displayClass}`}>
        {this.renderTabs()}
        <div className="map-program__map-iframe-container">
          {this.renderMapLoadingIconDesktop()}
          {this.renderMapLoadingIconMobile()}
          <iframe className="map-program__map-iframe"
                  src={`/maps/child.html?cms_path=${apiValues.CMS_PATH}`}
                  ref={event => (this.iframe = event)}/>
        </div>
        {this.renderMapControls()}
      </div>
    );
  }
  renderTab(id, name){
    const isActive = this.state.currentTab === id;
    let className = 'map-program__tab' + (isActive ? ' active' : '');
    let iconSrc;
    switch(name){
      case 'Projects':
        iconSrc = iconMapLegendProjects;
        break;
      case 'Neighborhoods, Networks, and Zones':
        iconSrc = iconMapLegendInjury;
        break;
      case 'Transit':
        iconSrc = iconMapLegendTransit;
        break;
      case 'Project Features':
        iconSrc = iconMapLegendSafety;
        break;
      case 'Memorial Map':
        iconSrc = iconMapLegendMemorial;
        break;
    }
    return (
      <div key={id} className='map-program__tab-container'>
        <div className={className} key={id} onClick={(e) => {
          e.preventDefault();
          this.selectTab(id);
        }}>
          <img alt={`${name} icon`} className="map-program__tab-icon" src={iconSrc}/>
          <div className="map-program__tab-title">{name}</div>
        </div>
        {isActive && helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) ? this.renderTabContent() : null}
      </div>
    );
  }
  renderTabContent(){
    if(this.state.tabContentClose === true) return null;
    if(this.state.showMemorial){
      const memorial = this.props.mapProgram.memorials.find(
        memorial => (memorial.caseId ? memorial.caseId.toString() : '') === this.state.showMemorial.toString() && memorial.title.toLowerCase() === this.state.memorialPopUpName.toLowerCase(),
      );
      if(memorial){
        //todo: why is this duplicated below??
        return (
          <div className="map-program__tab-content-container" style={{width: 'calc(100vw - 214px)'}}>
            <button aria-label="Clear"
                    className="button-input__button tab-content-close-icon"
                    type="button" onClick={this.handleTabContentClose}>
              <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
            </button>
            <div className="map-program__tab-content"
                 key={this.state.currentTab}>
              <span onClick={this.handleMemorialContent} className="memorial-close-button">
                <img alt='Close' className="button__icon" src={iconArrowRight}/>
              </span>
              {memorial.title && (<div className="map-program__memorial-title">{`${memorial.title}, ${memorial.age || ''}`}</div>)}
              {memorial.eventDate && (<div className="map-program__memorial-event">{`Died on ${moment(memorial.eventDate).format('MMMM D, YYYY')}`}</div>)}
              {memorial.address && (<div className="map-program__memorial-address">{memorial.address}</div>)}
              {memorial.media && (<div className="map-program__memorial-content">
                <img alt={memorial.title}
                     className="map-program__memorial-media-imagery"
                     src={apiValues.imgPath(memorial.media.url)}/>
              </div>)}
              {memorial.description && (<MarkdownField className="map-program__memorial-description" markup={memorial.description}/>)}
              <a className='map-program__memorial-know' href={`mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${this.state.selectedObject.toString()})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`}>Did you know this person?</a>
              {/* <div className="map-program__memorial-read-more">
                READ MORE
                <MListNav
                  mListNav={mListNav}
                  className="m-listnav--page-content"
                />
              </div> */}
              <ShareMemorial shareUrl={document.URL}
                             pageTitle={`${this.props.page.program.title} Memorial Map - ${memorial.title || ''}`}/>
            </div>
          </div>
        );
      }
    }

    if(!this.state.currentTab ||
       !this.state.layers ||
       !this.state.layers[this.state.currentTab]) return null;

    let layerOptions = Object.keys(
      this.state.layers[this.state.currentTab],
    ).map(key => {
      return (
        <div key={key}>
          {this.renderTabChild(key)}
        </div>
      );
    });

    if(this.state.currentTab === 'overall-memorial_map'){
      if(this.state.selectedObject){
        const memorial = this.props.mapProgram.memorials.find(
          memorial => (memorial.caseId ? memorial.caseId.toString() : '') === this.state.selectedObject.toString() && memorial.title.toLowerCase() === this.state.memorialPopUpName.toLowerCase(),
        );

        if(memorial){
          console.log(memorial.eventDate, moment(memorial.eventDate));
          return (
            <div className="map-program__tab-content-container">
              <button aria-label="Clear"
                      className="button-input__button tab-content-close-icon"
                      type="button" onClick={this.handleTabContentClose}>
                <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
              </button>
              <div className="map-program__tab-content"
                   key={this.state.currentTab}>
                <span onClick={this.handleMemorialContent} className="memorial-close-button">
                  <img alt='Close' className="button__icon" src={iconArrowRight}/>
                </span>
                {memorial.title && (<div className="map-program__memorial-title">{`${memorial.title}, ${memorial.age || ''}`}</div>)}
                {memorial.eventDate && (<div className="map-program__memorial-event">{`Died on ${moment(memorial.eventDate).format('MMMM D, YYYY')}`}</div>)}
                {memorial.address && (<div className="map-program__memorial-address">{memorial.address}</div>)}
                {memorial.media && (<div className="map-program__memorial-content">
                  <img alt={memorial.title}
                       className="map-program__memorial-media-imagery"
                       src={apiValues.imgPath(memorial.media.url)}/>
                </div>)}
                {memorial.description && (<MarkdownField className="map-program__memorial-description" markup={memorial.description}/>)}
                <a className='map-program__memorial-know' href={`mailto:visionzero@lacity.org?subject=Vision Zero Memorial Unidentified person (ID: ${this.state.selectedObject.toString()})&body=Hello, I am visiting the Vision Zero Memorial of someone I know and would like to create a memorial for them.`}>Did you know this person?</a>
                {/* <div className="map-program__memorial-read-more">
                  READ MORE
                  <MListNav
                    mListNav={mListNav}
                    className="m-listnav--page-content"
                  />
                </div> */}
                <ShareMemorial shareUrl={`https://${window.location.hostname}/overall-map/maps?sharememorial=${this.state.selectedObject.toString()}-${this.state.memorialPopUpName}`}
                               pageTitle={`${this.props.page.program.title} Memorial Map - ${memorial.title || ''}`}/>
              </div>
            </div>
          );
        }
      }

      return (
        <div className="map-program__tab-content-container">
          <button
            aria-label="Clear"
            className="button-input__button tab-content-close-icon"
            type="button" onClick={this.handleTabContentClose}
          >
            <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
          </button>
          <div className="map-program__tab-content" key={this.state.currentTab}>
            <div className="map-program__tab-content-title">
              Memorial Map
            </div>
            <div className="map-program__memorial-slider">
              <Range min={2010}
                     max={2021}
                     marks={{
                       2010: 2010,
                       2011: 2011,
                       2012: 2012,
                       2013: 2013,
                       2014: 2014,
                       2015: 2015,
                       2016: 2016,
                       2017: 2017,
                       2018: 2018,
                       2019: 2019,
                       2020: 2020,
                       2021: 2021,
                     }}
                     onChange={this.changeValue}
                     defaultValue={this.state.memorialDateFilter}
                     tipFormatter={value => `${value}%`}
                     trackStyle={[
                       {
                         backgroundColor: '#ff8563',
                       },
                     ]}
                     dotStyle={{
                       width: '2px',
                       backgroundColor: 'rgba(0, 0, 0, 0.54)',
                       border: 'none',
                     }}
                     activeDotStyle={{}}
                     handleStyle={[{
                       width: '27px',
                       height: '24px',
                       border: 'none',
                       borderRadius: '12px',
                       backgroundColor: 'rgba(0, 0, 0, 0.78)',
                       marginTop: '-10px',
                       marginLeft: '-16px',
                     },
                       {
                         width: '27px',
                         height: '24px',
                         border: 'none',
                         borderRadius: '12px',
                         backgroundColor: 'rgba(0, 0, 0, 0.78)',
                         marginTop: '-10px',
                         marginLeft: '-16px',
                       }]}/>
            </div>
            <div className="map-program__tab-content-description">
              Every dot on this map represents a person who passed away in a
              traffic crash and a person whose family and friends were deeply
              affected by their tragedy. The City of Los Angeles is committed to
              working with loved ones.
            </div>
            <div className="map-program__memorial-posts">LIVES LOST</div>
            {layerOptions}
          </div>
        </div>
      );
    }

    if(this.state.currentTab === 'overall-project_features'){
      layerOptions = features_types_name.filter(item => item && item.value && item.count).map(item => {
        const layer = this.state.layers[this.state.currentTab][item.value];
        return (
          <div key={item.value}>
            <div className="checkbox-item-container"
                 key={item.value}>
              <input checked={layer}
                     id={`input-${item.value}`}
                     onChange={() => this.onToggleFeatureLayer(item.value)}
                     type="checkbox"/>
              <label htmlFor={`input-${item.value}`}>{`${item.value} ${item.count}`}</label>
            </div>
          </div>
        );
      });
    }

    layerOptions = layerOptions.filter(item => !!item);
    if(layerOptions.length === 0) return null;

    if(this.state.currentTab === 'overall-projects'){
      return (
        <div className="map-program__tab-content-container">
          <button aria-label="Clear"
                  className="button-input__button tab-content-close-icon"
                  type="button" onClick={this.handleTabContentClose}>
            <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
          </button>
          <div className="map-program__tab-content" key={this.state.currentTab}>
            <div className="map-program__tab-content-title">
              Our Projects
            </div>
            {layerOptions}
          </div>
        </div>
      );
    }

    return (
      <div className="map-program__tab-content-container">
        <button aria-label="Clear"
                className="button-input__button tab-content-close-icon"
                type="button" onClick={this.handleTabContentClose}>
          <img alt='Clear' className="button-input__button-icon" src={iconClose}/>
        </button>
        <div className="map-program__tab-content" key={this.state.currentTab}>
          <div className="map-program__tab-content-title">
            {this.state.currentTab === 'overall-neighborhoods_zones' ? 'Neighborhoods, Networks, and Zones' : 'Street Improvements'}
          </div>
          {layerOptions}
        </div>
      </div>
    );
  }
  renderTabChild(key){
    const layer = this.state.layers[this.state.currentTab][key];
    let layerTitle = layer_names[key];
    if(key === 'coll_bik_fatal'){
      layerTitle = `Biking`;
    }
    else if(key === 'coll_car_fatal'){
      layerTitle = `Driving`;
    }
    else if(key === 'coll_ped_fatal'){
      layerTitle = `Walking`;
    }
    else if(key === 'memorial'){
      layerTitle = 'Memorials';
    }
    if(key === 'coll_bik_fatal' || key === 'coll_car_fatal' || key === 'coll_ped_fatal' || key === 'memorial'){
      return (
        <div className="checkbox-item-container"
             key={key}>
          <input checked={layer}
                 id={`input-${key}`}
                 onChange={() => this.onToggleLayer(key)}
                 type="checkbox"/>
          <label htmlFor={`input-${key}`}>
            <span className="memorial-map-layer-title">
              <span className="memorial-map-number-count"><b>{`${this.state.collisionDetail[key] || ''}`}</b></span>
              <span> {layerTitle}</span>
            </span>
          </label>
        </div>
      );
    }
    if(!layerTitle) return null;
    return (
      <div className="checkbox-item-container"
           key={key}>
        <input checked={layer}
               id={`input-${key}`}
               onChange={() => this.onToggleLayer(key)}
               type="checkbox"/>
        <label htmlFor={`input-${key}`}>{layerTitle}</label>
      </div>
    );
  }
  renderTabs(){
    if(!this.props.tabs ||
       !this.state.mapPluginReady) return null;

    let tabs = this.props.tabs.map(({id, name}) => this.renderTab(id, name));
    let openClass = this.state.filtersOpen ? 'map-program__tab-menu--filters-open' : '';
    let selectedClass = this.state.currentTab != '' ? 'map-program__tab-menu--is-open' : '';

    return (
      <div className={`map-program__tab-menu ${openClass} ${selectedClass}`}>
        <div className="map-program__tab-menu-tabs">{tabs}</div>
        {helpers.isScreenSmallerThan(helpers.screenSizes.lgMin) ? null : this.renderTabContent()}
        {this.renderFilterControls()}
      </div>
    );
  }
  render(){
    // console.log(`MapProgram.render()`, this.props);
    if(!this.props.mapProgram ||
       !this.props.page ||
       !this.props.page.program ||
       !this.props.tabs) return null;

    return (
      <div className="map-program">
        {this.renderIntroSection()}
        {this.renderMapSection()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
    mapProgram: state.mapProgram,
  }),
  (dispatch) => ({
    actions: bindActionCreators(mapProgramActions, dispatch),
  }),
)(OverallMap));


OverallMap.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
  mapProgram: PropTypes.object,
  mode: PropTypes.string.isRequired,
  page: PropTypes.object,
  tabs: PropTypes.array,
};
