import * as actionTypes from '../constants/actionType';
import initialState from './initialState';

export default function mNewsDetailReducer(state = initialState.mNewsDetail, action) {
  switch (action.type) {
    case actionTypes.MNEWSDETAIL_LOAD_START:
      return {
        ...state,
        isLoading: true
      };

    case actionTypes.MNEWSDETAIL_LOAD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.error
      };

    case actionTypes.MNEWSDETAIL_LOAD_SUCCESS: {
      return {
        ...state,
        ...action.data,
        isLoading: false,
        error: undefined
      };
    }

    default:
      return state;
  }
}
