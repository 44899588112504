import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageContentLandingActions from '../actions/pageContentLandingActions';
import Helmet from 'react-helmet';
import MBreadcrumbs from './MBreadcrumbs';
import MIntro from './MIntro';
import MLatestEvents from './MLatestEvents';
import MLatestNews from './MLatestNews';
import MListCards from './MListCards';
import MRichText from './MRichText';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageContentLanding extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageContentLanding.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageContentLanding(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: this.props.page.browserTitle, slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderBody(){
    if(!this.props.page.body) return null;

    const mRichText = {contents: this.props.page.body};
    return (<MRichText mRichText={mRichText} className="coupling coupling--centered-thin"/>);
  }
  renderCardList(){
    if(!this.props.page.cardList) return null;

    return (<MListCards cmsId={this.props.page.cardList.id} className="coupling coupling--full-centered"/>);
  }
  renderIntro(){
    if(!this.props.page.title && !this.props.page.subtitle) return null;

    const mIntro = {
      title: this.props.page.title,
      description: this.props.page.subtitle,
    };
    return (<MIntro mIntro={mIntro} className="coupling coupling--centered-thin"/>);
  }
  renderLatestEvents(){
    return (
      <div className="coupling coupling--full-centered">
        <MLatestEvents className="m-latest-events--border-top m-latest-events--horizontal"
                       horizontal
                       title="Events and Meetings"/>
      </div>
    );
  }
  renderLatestNews(){
    return (
      <div className="coupling coupling--full-centered">
        <MLatestNews className="m-latest-news--green m-latest-news--horizontal"
                     horizontal
                     relatedProgram={this.props.page.newsRelatedProgram}
                     relatedProject={this.props.page.newsRelatedProject}
                     title="News and Updates"/>
      </div>
    );
  }
  render(){
    //console.log(`PageContentLanding.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.browserTitle)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        {this.renderIntro()}
        {this.renderBody()}
        {this.renderCardList()}
        {this.renderLatestEvents()}
        {this.renderLatestNews()}
      </PageBase>
    );
  }
}

export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageContentLandingActions, dispatch),
  }),
)(PageContentLanding));


PageContentLanding.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
