import * as apiValues from '../constants/apiValues';
import Helmet from 'react-helmet';
import img404 from '../img/404.gif';
import img404mobile from '../img/404-mobile.gif';
import React from 'react';


export default class Page500 extends React.Component{
  render(){
    console.log(`NotFoundPage.render()`, this.props);

    return (
      <>
        <Helmet>
          <title>{apiValues.buildTitle('500 Internal Server Error')}</title>
        </Helmet>

        <div className="page-content__content notfoundpage coupling coupling--centered-thin">
          <div className="container container--coupling">
            <img className="notfoundpage__img" src={img404} alt="404"/>
            <img className="notfoundpage__img notfoundpage__img--mobile" src={img404mobile} alt="404"/>
            <div className="notfoundpage__text">Sorry the page you were looking for could not be found. Try returning to our <a href="/">home page</a> or <a href="mailto:visionzero@lacity.org">contact us</a> if you can&apos;t find what you are looking for.</div>
          </div>
        </div>
      </>
    );
  }
}
