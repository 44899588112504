import cardReducer from './cardReducer';
import carouselSlideReducer from './carouselSlideReducer';
import mapParentReducer from './mapsParentReducer';
import mapProgramReducer from './mapProgramReducer';
import mBrandBarReducer from './mBrandBarReducer';
import mBreadcrumbsReducer from './mBreadcrumbsReducer';
import mCarouselReducer from './mCarouselReducer';
import mediaItemReducer from './mediaItemReducer';
import mEventDetailReducer from './mEventDetailReducer';
import mEventsReducer from './mEventsReducer';
import mFooterReducer from './mFooterReducer';
import mGetInvolvedReducer from './mGetInvolvedReducer';
import mHeaderReducer from './mHeaderReducer';
import mHeaderTabReducer from './mHeaderTabReducer';
import mIntroReducer from './mIntroReducer';
import mLatestEventsReducer from './mLatestEventsReducer';
import mLatestNewsReducer from './mLatestNewsReducer';
import mListCardsReducer from './mListCardsReducer';
import mListFollowReducer from './mListFollowReducer';
import mListMediaReducer from './mListMediaReducer';
import mListNavReducer from './mListNavReducer';
import mListShareReducer from './mListShareReducer';
import mListTagReducer from './mListTagReducer';
import mNewsDetailReducer from './mNewsDetailReducer';
import mNewsletterReducer from './mNewsletterReducer';
import mProgramBannerReducer from './mProgramBannerReducer';
import mProgramMapAdReducer from './mProgramMapAdReducer';
import mProjectListMapReducer from './mProjectListMapReducer';
import mProjectsCurrentReducer from './mProjectsCurrentReducer';
import mProjectsReducer from './mProjectsReducer';
import mRelatedProjectsReducer from './mRelatedProjectsReducer';
import mRichTextReducer from './mRichTextReducer';
import navigationLinkReducer from './navigationLinkReducer';
import NewsReducer from './NewsReducer';
import pageContentLandingReducer from './pageContentLandingReducer';
import pageEventDetailsReducer from './pageEventDetailsReducer';
import pageEventsRedcuer from './pageEventsReducer';
import pageGetInvolvedReducer from './pageGetInvolvedReducer';
import pageHomeReducer from './pageHomeReducer';
import pageProjectDetailsReducer from './pageProjectsDetailsReducer';
import pageProjectsReducer from './pageProjectsReducer';
import pageReducer from './pageReducer';
import projectReducer from './projectReducer';
import projectTypeListReducer from './projectTypeListReducer';
import searchReducer from './searchReducer';
import tagReducer from './tagReducer';
import {combineReducers} from 'redux';


const rootReducer = combineReducers({
  card: cardReducer,
  carouselSlide: carouselSlideReducer,
  mapParent: mapParentReducer,
  mapProgram: mapProgramReducer,
  mBrandBar: mBrandBarReducer,
  mBreadcrumbs: mBreadcrumbsReducer,
  mCarousel: mCarouselReducer,
  mediaItem: mediaItemReducer,
  mEventDetail: mEventDetailReducer,
  mEvents: mEventsReducer,
  mFooter: mFooterReducer,
  mGetInvolved: mGetInvolvedReducer,
  mHeader: mHeaderReducer,
  mHeaderTab: mHeaderTabReducer,
  mIntro: mIntroReducer,
  mLatestEvents: mLatestEventsReducer,
  mLatestNews: mLatestNewsReducer,
  mListCards: mListCardsReducer,
  mListFollow: mListFollowReducer,
  mListMedia: mListMediaReducer,
  mListNav: mListNavReducer,
  mListShare: mListShareReducer,
  mListTag: mListTagReducer,
  mNewsDetail: mNewsDetailReducer,
  mNewsletter: mNewsletterReducer,
  mProgramBanner: mProgramBannerReducer,
  mProgramMapAd: mProgramMapAdReducer,
  mProjectListMap: mProjectListMapReducer,
  mProjects: mProjectsReducer,
  mProjectsCurrent: mProjectsCurrentReducer,
  mRelatedProjects: mRelatedProjectsReducer,
  mRichText: mRichTextReducer,
  navigationLink: navigationLinkReducer,
  news: NewsReducer,
  page: pageReducer,
  pageContentLanding: pageContentLandingReducer,
  pageEventDetails: pageEventDetailsReducer,
  pageEvents: pageEventsRedcuer,
  pageGetInvolved: pageGetInvolvedReducer,
  pageHome: pageHomeReducer,
  pageProjectDetails: pageProjectDetailsReducer,
  pageProjects: pageProjectsReducer,
  project: projectReducer,
  projectTypeList: projectTypeListReducer,
  searchResult: searchReducer,
  tag: tagReducer,
});

export default rootReducer;
