import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageGetInvolvedApplyActions from '../actions/pageGetInvolvedApplyActions';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import MContacts from './MContacts';
import MListNav from './MListNav';
import MProgramBanner from './MProgramBanner';
import NavigationLink from './NavigationLink';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class PageGetInvolvedApply extends React.Component{
  lastUrl = '';


  componentDidMount(){
    // console.log(`PageGetInvolvedApply.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }


  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageGetInvolvedApply(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    if(!this.props.page.program) return null;

    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: 'Get Involved', slug: 'get-involved'},
        {browserTitle: `Apply for ${this.props.page.program.title}`, slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  renderContacts(){
    if(!(this.props.page.contacts && this.props.page.program)) return null;

    return (<MContacts className="m-contacts--sidebar"
                       contacts={this.props.page.contacts}
                       title={`Contact ${this.props.page.program.title}`}/>);
  }
  renderContent(){
    if(!this.props.page.longDescription && !this.props.page.shortDescription && !this.props.page.getInvolvedContent) return null;

    return (
      <div className="m-intro__contents">
        <div className="m-intro__title">
          {helpers.nToP(this.props.page.shortDescription)}
        </div>
        <div className="m-intro__description">
          <MarkdownField markup={this.props.page.getInvolvedSubhead}/>
        </div>
        <div className="markdown-text">
          <MarkdownField markup={this.props.page.getInvolvedContent}/>
        </div>
      </div>
    );
  }
  renderOnlineApplication(){
    if(!this.props.page.program ||
       !this.props.page.getInvolvedOnlineApplicationUrl) return null;

    return (
      <div className="page-getinvolvedapply__online">
        <div className="page-getinvolvedapply__online-title">Online Application</div>
        <div className="page-getinvolvedapply__online-text">Complete online form and bring a {this.props.page.program.title} to your doorstep!</div>
        <SuperLink to={this.props.page.getInvolvedOnlineApplicationUrl} className="page-getinvolvedapply__online-button tg-button tg-button--pill tg-button--black">
          <span className="tg-button__label">Start Your Online Application</span>
        </SuperLink>
      </div>
    );
  }
  renderPaperForm(){
    if(!this.props.page.program) return null;

    //return (
    //  <MListNav cmsId={this.props.page.documents.id} className="m-listnav--paper-form"/>
    //);

    return (
      <div className="page-getinvolvedapply__paper">
        <div className="page-getinvolvedapply__paper-title">Paper Form</div>
        <div className="page-getinvolvedapply__paper-description">What to submit a printed application? Download here!
        </div>
        <div className="page-getinvolvedapply__paper-list">
          {this.props.page.program.paperForms.map(form => {
            return (<NavigationLink navigationLink={form} className='navigationlink--link' key={form.url}/>);
          })}
        </div>
        <div className="page-getinvolvedapply__paper-instructions">
          You can email, mail in, or drop off applications in person Monday—Friday, 9:00 a.m. to 6:00 p.m. at Attention:
          <br/><span
          className="page-getinvolvedapply__paper-address">{this.props.page.program.getInvolvedApplicationAddress}</span>
          <br/>Email:&nbsp;
          <a href={`mailto:${this.props.page.program.getInvolvedEmail}`}>{this.props.page.program.getInvolvedEmail}</a>
        </div>
      </div>
    );
  }
  renderProcess(){
    if(!this.props.page.program ||
       !this.props.page.program.applicationSteps) return null;

    return (
      <div className="page-getinvolvedapply__process">
        <ol className="page-getinvolvedapply__process-list">
          {this.props.page.program.applicationSteps.map((processItem, processItemIndex) => {
            return this.renderProcessItem(processItemIndex + 1, processItem.title, processItem.description);
          })}
        </ol>
      </div>
    );
  }
  renderProcessItem(number, title, description){
    if(!this.props.page.program) return null;

    let numberColorStyle = {backgroundColor: this.props.page.program.color};
    let textColorStyle = {color: this.props.page.program.color};

    return (
      <li className="page-getinvolvedapply__process-item" key={number}>
        <div className="page-getinvolvedapply__process-item-col-number">
          <div className="page-getinvolvedapply__process-number" style={textColorStyle}>Step {number}</div>
          <div className="page-getinvolvedapply__process-number-md">
            <div className="page-getinvolvedapply__process-number-bg" style={numberColorStyle}></div>
            <div className="page-getinvolvedapply__process-number-text">{number}</div>
          </div>
        </div>
        <div className="page-getinvolvedapply__process-item-col-content">
          <div className="page-getinvolvedapply__process-item-title" style={textColorStyle}>{title}</div>
          <div className="page-getinvolvedapply__process-item-description">
            <div className="markdown-text">
              <MarkdownField markup={description}/>
            </div>
          </div>
        </div>
      </li>
    );
  }
  renderRelatedLinks(){
    if(!this.props.page || !this.props.page.documents) return null;

    return (
      <div className="container container--coupling">
        <MListNav cmsId={this.props.page.documents.id} className="m-listnav--related-links"/>
      </div>
    );
  }
  render(){
    // console.log(`render PageGetInvolvedApply`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase className="page-getinvolvedapply">
        <Helmet>
          <title>Apply For {apiValues.buildTitle(this.props.page.title)}</title>
        </Helmet>
        {this.renderBreadcrumbs()}
        <MProgramBanner/>
        <div className="coupling coupling---full-centered">
          <div className="columns">
            <div className="coupling--left">
              <div className="container container--coupling">
                {this.renderContent()}
                {this.renderProcess()}
                {/*{this.renderOnlineApplication()}*/}
                {/*{this.renderPaperForm()}*/}
              </div>
            </div>
            <div className="coupling--right">
              {this.renderRelatedLinks()}
              {this.renderContacts()}
            </div>
          </div>
        </div>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageGetInvolvedApplyActions, dispatch),
  }),
)(PageGetInvolvedApply));


PageGetInvolvedApply.propTypes = {
  actions: PropTypes.object.isRequired,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};
