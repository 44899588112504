import * as SearchActions from '../actions/SearchActions';
import ListFilter from './ListFilter';
import Pagination from './Pagination';
import PropTypes from 'prop-types';
import QueryString from 'query-string';
import React from 'react';
import SearchItem from './SearchItem';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


const ITEMS_PER_PAGE = 10;
const filterTypes = [
  {id: 'project', title: 'Project'},
  {id: 'news', title: 'News'},
  {id: 'event', title: 'Event'},
  {id: 'document', title: 'Document'},
  {id: 'program', title: 'Program'},
  {id: 'map', title: 'Map'},
  {id: 'other', title: 'Other'}
];


class TagSearch extends React.Component{
  state = {
    currentPage: 1,
    value: '',
    selectedProgram: [],
    selectedType: [],
    modalOpen: false
  };


  constructor(props){
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClearInput = this.handleClearInput.bind(this);
  }
  componentDidMount(){
    const query = QueryString.parse(this.props.location.search);
    console.log(`query`, query);
    this.props.actions.setQuery('');
    this.props.actions.setTagQuery(query.tag);
    // this.props.actions.initSearchWithTags(query.tag);
  }


  handleCategoryFilter = (value, type) => {
    if(type === 'type'){
      const {selectedType} = this.state;
      if(selectedType.includes(value)){
        selectedType.splice(selectedType.indexOf(value), 1);
        this.setState({
          selectedType
        });
        this.props.actions.setTypes(selectedType);
      }
      else{
        selectedType.push(value);
        this.setState({
          selectedType
        });
        this.props.actions.setTypes(selectedType);
      }
    }
    else if(type === 'program'){
      const {selectedProgram} = this.state;

      if(selectedProgram.includes(value)){
        selectedProgram.splice(selectedProgram.indexOf(value), 1);
        this.setState({
          selectedProgram
        });
        this.props.actions.setProgram(selectedProgram);
      }
      else{
        selectedProgram.push(value);
        this.setState({
          selectedProgram
        });
        this.props.actions.setProgram(selectedProgram);
      }
    }
  };
  handleClearFilter = () => {
    this.setState({
      selectedCategory: [],
      selectedProgram: [],
      startDate: null,
      endDate: null
    });
  };
  handleClearInput = () => {
    this.setState({
      value: ''
    });

    this.props.actions.setQuery('');
  };
  handleInputChange = evt => {
    this.setState({
      value: evt.target.value
    });

    this.props.actions.setQuery(evt.target.value);
  };
  handleModalHide = () => {
    this.setState({
      modalOpen: false
    });
  };
  handleModalShow = () => {
    this.setState({
      modalOpen: true
    });
  };
  handlePagination = page => {
    this.setState({
      currentPage: page
    });
  };


  renderItems = () => {
    const {currentPage} = this.state;
    const {searchResult} = this.props;
    const {
      total,
      newsResult,
      eventResult,
      programResult,
      projectResult,
      pageContentDetailResult
    } = searchResult;

    if(total === 0) return null;

    const newResult = newsResult
      .concat(eventResult)
      .concat(programResult)
      .concat(projectResult)
      .concat(pageContentDetailResult);

    return newResult
      .slice((currentPage - 1) * ITEMS_PER_PAGE, ITEMS_PER_PAGE * currentPage)
      .map((item, index) => (
        <div key={item.id} className="col-12">
          <SearchItem
            title={item.title}
            url={item.slug || ''}
            type={item.type}
            className={index === ITEMS_PER_PAGE - 1 ? 'remove--border' : ''}
          />
        </div>
      ));
  };
  renderResultStatus = () => {
    const {searchResult} = this.props;
    const {total, filterQuery} = searchResult;
    const {currentPage} = this.state;

    const startFrom = (currentPage - 1) * ITEMS_PER_PAGE + 1;
    const lastTo =
      currentPage * ITEMS_PER_PAGE > total
      ? total
      : currentPage * ITEMS_PER_PAGE;

    return (
      <div className="search__results">
        {`Results ${startFrom}-${lastTo} of ${total}`}
        {filterQuery !== '' && ` for ${filterQuery}`}
      </div>
    );
  };
  render(){
    //console.log(`MEvents render`, this.props);
    const {selectedType, modalOpen} = this.state;
    const {searchResult} = this.props;
    const totalPage = searchResult.total / ITEMS_PER_PAGE;

    return (
      <div className="columns">
        <div className="coupling coupling--parent coupling--left">
          <div className={`search-page ${this.props.className || ''}`}>
            <div className="container container--coupling">
              <h1 className="tag-search__title">
                {searchResult.filterTag ? searchResult.filterTag.title : ''}
              </h1>
              {/* {this.renderResultStatus()} */}
              <div className="news__listing">
                <div className="row">{this.renderItems()}</div>
              </div>
              <Pagination totalPage={totalPage}
                          currentPage={this.state.currentPage}
                          handlePagination={this.handlePagination}/>
            </div>
          </div>
        </div>
        <div className="coupling coupling--parent coupling--right">
          <ListFilter types={filterTypes}
                      selectedType={selectedType}
                      title="Filter Results"
                      handleCategoryFilter={this.handleCategoryFilter}
                      disableDate={true}
                      handleClearFilter={this.handleClearFilter}
                      modalOpen={modalOpen}
                      handleModalHide={this.handleModalHide}/>
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  state => ({
    searchResult: state.searchResult
  }),
  dispatch => ({
    actions: bindActionCreators(SearchActions, dispatch)
  })
)(TagSearch));


TagSearch.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  location: PropTypes.object,
  searchResult: PropTypes.object,
  selectedProgram: PropTypes.object,
  value: PropTypes.object
};
