import * as helpers from '../helpers';
import * as mListNavActions from '../actions/mListNavActions';
import NavigationLink from './NavigationLink';
import ProjectTypeList from './ProjectTypeList';
import SuperLink from './SuperLink';
import PropTypes from 'prop-types';
import React from 'react';
import {withRouter} from 'react-router-dom';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';


class MListNav extends React.Component{
  componentDidMount(){
    //console.log(`MListNav.componentDidMount()`, this.props);

    if(this.props.cmsId){
      this.props.actions.initMListNav(this.props.cmsId);
    }
    else if(this.props.mListNav){
      // console.log(`passed in `, this.props.mListNav);
    }
  }


  renderDescription(){
    if(!this.props.mListNav.description) return null;

    return (<div className="m-listnav__description">{helpers.nToP(this.props.mListNav.description)}</div>);
  }
  renderDescriptionOptional(){
    if(!this.props.mListNav.descriptionOptional) return null;

    return (<div className="m-listnav__description m-listnav__description--optional">{helpers.nToP(this.props.mListNav.descriptionOptional)}</div>);
  }
  renderNavigationLinks(){
    if(!this.props.mListNav.navigationLinks) return null;

    return (<div className="m-listnav__list">
        {this.props.mListNav.navigationLinks.map(navigationLink => {
          //if (navigationLink.onClick) console.log(`navigationLink`, navigationLink.onClick);
          if(navigationLink.title){
            //console.log("mlistnav a", navigationLink.title);
            return (
              <NavigationLink className={`m-listnav__navigationlink ${navigationLink.className}`}
                              highlight={this.props.mListNav.highlight}
                              key={`${navigationLink.id || navigationLink.key}-${navigationLink.title || ''}`}
                              navigationLink={navigationLink}
                              onClick={navigationLink.onClick}/>
            );
          }
          else if(navigationLink.id){
            //console.error("mlistnav b", navigationLink);

            return (
              <NavigationLink className={`m-listnav__navigationlink ${navigationLink.className}`}
                              cmsId={navigationLink.id}
                              highlight={this.props.mListNav.highlight}
                              key={navigationLink.id}
                              onClick={navigationLink.onClick}/>
            );
          }
          else{
            //console.log("mlistnav c", navigationLink.key);
            return (
              <NavigationLink className={`m-listnav__navigationlink ${navigationLink.className}`}
                              highlight={this.props.mListNav.highlight}
                              key={navigationLink.key}
                              navigationLink={navigationLink}
                              onClick={navigationLink.onClick}/>
            );
          }
        })}
      </div>
    );
  }
  renderProjectTypes(){
    if(!this.props.mListNav.projectTypes || this.props.mListNav.projectTypes.length < 1) return null;

    return (<ProjectTypeList navListId={this.props.cmsId}/>);
  }
  renderTitle(){
    if(!this.props.mListNav.title) return null;

    if(this.props.mListNav.url)
      return (<SuperLink to={this.props.mListNav.url} className="m-listnav__title">{this.props.mListNav.title}</SuperLink>);
    else
      return (<div className="m-listnav__title">{this.props.mListNav.title}</div>);
  }
  render(){
    //console.log(`MListNav render`, this.state, this.props);
    if(!this.props.mListNav ||
       (!this.props.mListNav.title && !this.props.mListNav.navigationLinks)) return null;

    let displayModeClass = this.props.mListNav.displayMode ? `m-listnav--${this.props.mListNav.displayMode}` : ``;

    return (
      <div className={`m-listnav ${this.props.className || ''} ${displayModeClass}`}>
        {this.renderTitle()}
        {this.renderProjectTypes()}
        {this.renderDescription()}
        {this.renderDescriptionOptional()}
        {this.renderNavigationLinks()}
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {mListNav: helpers.getById(state.mListNav, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: bindActionCreators(mListNavActions, dispatch),
  }),
)(MListNav));


MListNav.propTypes = {
  actions: PropTypes.object,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mListNav: PropTypes.object,
};
