import * as helpers from '../helpers';
import * as mRichTextActions from '../actions/mRichTextActions';
import MarkdownField from './MarkdownField';
import PropTypes from 'prop-types';
import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


class MRichText extends React.Component{
  componentDidMount(){
    //console.log(`MRichText.componentDidMount()`, this.props);
    if(this.props.cmsId){
      this.props.actions.initMRichText(this.props.cmsId);
    }
    else{
      // Additional setup
      //console.log(this.props);
    }
  }


  renderDescription(){
    if(!this.props.mRichText.contents) return null;

    return (<MarkdownField markup={this.props.mRichText.contents} className="m-richtext__contents"/>);
  }
  renderTitle(){
    if(!this.props.mRichText.title) return null;

    return (<div className="m-richtext__title">{this.props.mRichText.title}</div>);
  }
  render(){
    console.log(`MRichText render`, this.props);
    if(!this.props.mRichText ||
       (!this.props.mRichText.contents && !this.props.mRichText.title)) return null;

    return (
      <div className={`m-richtext ${this.props.className || ''}`}>
        <div className="container container--coupling">
          {this.renderTitle()}
          {this.renderDescription()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  (state, ownProps) => (
    ownProps.cmsId ? {mRichText: helpers.getById(state.mRichText, ownProps.cmsId)} : {}
  ),
  (dispatch) => ({
    actions: bindActionCreators(mRichTextActions, dispatch),
  }),
)(MRichText));


MRichText.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  cmsId: PropTypes.string,
  mRichText: PropTypes.object,
};
