import * as mLatestEventsActions from '../actions/mLatestEventsActions';
import EventItem from './EventItem';
// import moment from './MLatestNews';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import SuperLink from './SuperLink';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';


const PRODUCTS_PER_PAGE = 3;


class MLatestEvents extends React.Component{
  state = {
    currentPage: 1,
  };


  componentDidMount(){
    //console.log(`MEvents.componentDidMount()`, this.props);
    this.props.actions.initMLatestEvents({
      program: this.props.program,
      relatedProgram: this.props.relatedProgram,
      relatedProject: this.props.relatedProject,
      relatedTag: this.props.relatedTag,
    }).then(results => {
      this.props.onLoad ? this.props.onLoad(results) : null;
    });
  }


  renderEvents(){
    const events = this.props.mLatestEvents.events.sort((b, a) =>
    
    
    {
      return moment(b.eventDate).valueOf() - moment(a.eventDate).valueOf();
    }).slice(0, PRODUCTS_PER_PAGE);

    return events.map(event => {
      return (
        <div key={event.id} className="col-12 events__listing-item">
          <EventItem event={event}
                     disableDate
                     disableLocation
                     disableDescription={!this.props.horizontal}/>
        </div>
      );
    });
  }
  renderLink(){
    let url = '/events';
    if(this.props.program)
      url = `/events?program=${this.props.program.id}`;
    if(this.props.relatedProject && this.props.relatedProject.program)
      url = `/events?program=${this.props.relatedProject.program._id}`;

    if(this.props.horizontal) return (
      <center>
        <SuperLink to={url} className="m-latest-events__view-all tg-button tg-button--pill tg-button--black">
          <span className="tg-button__label">View All Events</span>
        </SuperLink>
      </center>
    );
    else return (
      <div className="navigationlink navigationlink--blue-outline">
        <SuperLink to={url} className="navigationlink__link">
          <div className="navigationlink__title">View All Events</div>
        </SuperLink>
      </div>
    );
  }
  renderTitle(){
    if(this.props.program && (this.props.program.Title || this.props.program.title))
      return (
        <div className="m-latest-events__title">{`LATEST ${this.props.program.Title || this.props.program.title} EVENTS And Meetings`}</div>
      );
    else return (
      <div className="m-latest-events__title">
        {this.props.title || `LATEST EVENTS`}
      </div>
    );
  }
  render(){
    // console.log(`MLatestEvents render`, this.props);
    if(!this.props.mLatestEvents ||
       !this.props.mLatestEvents.events ||
       this.props.mLatestEvents.events.length < 1) return null;

    return (
      <div className={`m-latest-events ${this.props.className || ''}`}>
        <div className="container container--coupling">
          {this.renderTitle()}
          <div className="events__listing">
            <div className="row">{this.renderEvents()}</div>
          </div>
          {this.renderLink()}
        </div>
      </div>
    );
  }
}


export default withRouter(connect(
  state => ({
    mLatestEvents: state.mLatestEvents,
  }),
  dispatch => ({
    actions: bindActionCreators(mLatestEventsActions, dispatch),
  }),
)(MLatestEvents));


MLatestEvents.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  mLatestEvents: PropTypes.object,
  onLoad: PropTypes.func,
  program: PropTypes.object,
  relatedProgram: PropTypes.object,
  relatedProject: PropTypes.object,
  relatedTag: PropTypes.object,
  title: PropTypes.string,
};
