import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";
import update from 'immutability-helper';

export default function mediaItemReducer(state = initialState.mediaItem, action) {
  switch (action.type) {
    case actionTypes.MEDIAITEM_LOAD_START:
      return update(state, state[action.id] ? {
        [action.id]: {
          isLoading: {$set: true},
        },
      } : {
        [action.id]: {$set: {isLoading: true}},
      });

    case actionTypes.MEDIAITEM_LOAD_FAIL:
      return update(state, {
        [action.id]: {
          isLoading: {$set: false},
          error: {$set: action.error},
        },
      });

    case actionTypes.MEDIAITEM_LOAD_SUCCESS: {
      return update(state, {
        [action.data.id]: {$set: action.data},
      });
    }

    case actionTypes.MEDIAITEM_LOAD_MANY_SUCCESS:{
      console.error("MEDIAITEM_LOAD_MANY_SUCCESS not set up yet");
      return state;
    }

    default:
      return state;
  }
}
