import * as pageActions from './pageActions';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';


export const initPageGetInvolvedApply = (slug) => (dispatch) => {
  // console.log(slug);
  dispatch(pageActions.loadPageStart());

  return new Promise((resolve, reject) => {
    apiActions.loadEndpoint(apiValues.nref.program.endpoint, null, {Slug: slug})
      .then(results => {
        let program = apiActions.itemSelectCamelCaseKeys(results[0], apiValues.nref.program.fieldsUsed);

        program.paperForms = apiActions.arraySelectCamelCaseKeys(program.paperForms, apiValues.nref.navigationLink.fieldsUsed);
        program.applicationSteps = apiActions.arraySelectCamelCaseKeys(program.applicationSteps, apiValues.nref.programApplicationStep.fieldsUsed);

        let page = {
          ...program,
          program: program,
          contacts: apiActions.arraySelectCamelCaseKeys(program.contacts, apiValues.nref.contact.fieldsUsed),
        };

        return page;
      })
      .then(results => {
        // console.log(results);
        resolve(dispatch(pageActions.loadPageSuccess(results)));
      })
      .catch(error => {
        console.error(error);
        reject(error);
      });
  });
};
