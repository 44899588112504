import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
//import {getMListMediaById} from "../reducers/index";


//Action Creators
export const loadStart = (id) => {
  return {
    type: actionTypes.MLISTMEDIA_LOAD_START,
    id,
  };
};
export const loadFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MLISTMEDIA_LOAD_FAIL,
    id,
    error,
  };
};
export const loadSuccess = (data) => {
  return {
    type: actionTypes.MLISTMEDIA_LOAD_SUCCESS,
    data,
  };
};
export const toggleLightboxActionCreator = (id, showLightbox) => {
  return {
    type: actionTypes.MLISTMEDIA_TOGGLE_LIGHTBOX,
    id,
    showLightbox,
  };
};
export const setSelectedItemActionCreator = (id, selectedItem) => {
  console.log(id, selectedItem);
  return {
    type: actionTypes.MLISTMEDIA_SET_SELECTEDITEM,
    id,
    selectedItem,
  };
};

export const initMListMedia = data => dispatch => {
  if(!data) return null;

  data.mediaItems = data.mediaItems ? apiActions.arraySelectCamelCaseKeys(data.mediaItems, apiValues.nref.mediaItem.fieldsUsed) : undefined;

  dispatch(loadStart(data.id));
  dispatch(loadSuccess(data));
};

export const initMListMediaWithEndpoint = id => dispatch => {
  dispatch(loadStart(id));

  apiActions.loadEndpoint(apiValues.nref.mListMedia.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mListMedia.fieldsUsed);
    normalized.mediaItems = apiActions.arraySelectCamelCaseKeys(normalized.mediaItems, apiValues.nref.mediaItem.fieldsUsed);

    dispatch(loadSuccess(normalized));
  });
};

export const toggleLightbox = (id, showLightbox) => dispatch => {
  dispatch(toggleLightboxActionCreator(id, showLightbox));
};
export const setSelectedItem = (id, selectedItem) => dispatch => {
  dispatch(setSelectedItemActionCreator(id, selectedItem));
};


