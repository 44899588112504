import PropTypes from 'prop-types';
import React, {Component} from 'react';


export default class ShareMemorial extends Component{
  constructor(props){
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      dropdownOpen: false,
    };
  }


  onFacebookShare = () => {
    let shareUrl = this.props.shareUrl ? this.props.shareUrl : document.URL;
    window.open(
      `https://www.facebook.com/dialog/feed?app_id=2777389088941915&link=${shareUrl}&smid=fb-share&name=${this.props.pageTitle}&redirect_uri=https://www.facebook.com`,
      'Facebook-Popup',
      'height=350, width=600',
    );
  };
  onTwitterShare = () => {
    let shareUrl = this.props.shareUrl ? this.props.shareUrl : document.URL;
    window.open(
      `https://twitter.com/intent/tweet?url=${shareUrl}&text=${this.props.pageTitle}`,
      'Twitter-Popup',
      'height=350, width=500',
    );
  };
  toggle(){
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }


  render(){
    return (
      <div className={`dropdown ${this.state.dropdownOpen ? 'show' : ''}`}>
        <button onClick={this.toggle}
                className="map-program__memorial__button-share tg-button tg-button--pill tg-button--black"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false">
          <span className="tg-button__label">Share Memorial</span>
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <a style={{cursor: 'pointer'}} className="dropdown-item" onClick={this.onFacebookShare}>Facebook</a>
          <a style={{cursor: 'pointer'}} className="dropdown-item" onClick={this.onTwitterShare}>Twitter</a>
          <a className="dropdown-item" href={`mailto:?subject=${this.props.pageTitle}&body=Check out this page ${this.props.shareUrl || document.URL}`}>eMail</a>
        </div>
      </div>
    );
  }
}


ShareMemorial.propTypes = {
  shareUrl: PropTypes.string,
  pageTitle: PropTypes.string,
};
