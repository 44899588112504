import * as actionTypes from '../constants/actionType';
import initialState from './initialState';
import * as helpers from "../helpers";

export default function mListFollowReducer(state = initialState.mListFollow, action) {
  switch (action.type) {
    case actionTypes.MLISTFOLLOW_LOAD_START:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: true
      });

    case actionTypes.MLISTFOLLOW_LOAD_FAIL:
      return helpers.updateOrAdd(state, {
        id: action.id,
        isLoading: false,
        error: action.error
      });

    case actionTypes.MLISTFOLLOW_LOAD_SUCCESS: {
      return helpers.updateOrAdd(state, {
        ...action.data,
        isLoading: false,
        error: undefined
      });
    }

    default:
      return state;
  }
}
