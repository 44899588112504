import * as actionTypes from '../constants/actionType';
import * as apiActions from "./apiActions";
import * as apiValues from "../constants/apiValues";
//import {getMBrandBarById} from "../reducers/index";


//Action Creators
export const loadMBrandBarStart = (id) => {
  return {
    type: actionTypes.MBRANDBAR_LOAD_START,
    id
  };
};
export const loadMBrandBarFail = (id, error) => {
  console.error(error);
  return {
    type: actionTypes.MBRANDBAR_LOAD_FAIL,
    id,
    error
  };
};
export const loadMBrandBarSuccess = (data) => {
  return {
    type: actionTypes.MBRANDBAR_LOAD_SUCCESS,
    data
  };
};

export const initMBrandBar = id => dispatch => {
  dispatch(loadMBrandBarStart(id));

  apiActions.loadEndpoint(apiValues.nref.mBrandBar.endpoint, id).then(result => {
    let normalized = apiActions.itemSelectCamelCaseKeys(result, apiValues.nref.mBrandBar.fieldsUsed);

    dispatch(loadMBrandBarSuccess(normalized));
  });
};


