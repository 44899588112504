import * as actionTypes from '../constants/actionType';
import * as apiActions from './apiActions';
import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as projectActions from './projectActions';


//Action Creators
export const loadFail = (error) => {
  console.error(error);
  return {
    type: actionTypes.PAGE_PROJECTS_EXTERNAL_LOAD_FAIL,
    error
  };
};
export const loadStart = () => {
  return {
    type: actionTypes.PAGE_PROJECTS_EXTERNAL_LOAD_START
  };
};
export const loadSuccess = (data) => {
  return {
    type: actionTypes.PAGE_PROJECTS_EXTERNAL_LOAD_SUCCESS,
    data
  };
};

export const init = () => dispatch => {
  console.log(`pageProjectsExternalActions.init()`);
  dispatch(loadStart());

  //get projects
  return apiActions.loadEndpoint(apiValues.nref.project.endpoint).then(projects=> {
    let loadedProjects = apiActions.arraySelectCamelCaseKeys(projects, apiValues.nref.project.fieldsUsed);
    dispatch(projectActions.loadProjectManySuccess(loadedProjects));
    dispatch(loadSuccess());
  }).catch(console.error);
};
