import * as apiValues from '../constants/apiValues';
import * as helpers from '../helpers';
import * as pageContentActions from '../actions/pageContentActions';
import Helmet from 'react-helmet';
import MarkdownField from './MarkdownField';
import MBreadcrumbs from './MBreadcrumbs';
import PageBase from './PageBase';
import PropTypes from 'prop-types';
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


class PageContent extends React.Component{
  componentDidMount(){
    // console.log(`PageContent.componentDidMount()`, this.props);
    this.fetchContent();
  }
  componentDidUpdate(){
    if(this.props.match.url != this.lastUrl) this.fetchContent();
  }
  lastUrl = '';
  fetchContent(){
    this.lastUrl = this.props.match.url;
    this.props.actions.initPageContent(this.props.match.params.slug).then(null, () => helpers.handle404(this.props.history));
  }


  renderBreadcrumbs(){
    let mBreadcrumbs = {
      pageChain: [
        {browserTitle: 'Home', slug: ''},
        {browserTitle: this.props.page.browserTitle, slug: '#'},
      ],
    };

    return (<MBreadcrumbs className="coupling coupling--full-centered" mBreadcrumbs={mBreadcrumbs}/>);
  }
  render(){
    // console.log(`PageContent.render()`, this.props);
    if(!this.props.page ||
       !this.props.page.id) return null;

    return (
      <PageBase>
        <Helmet>
          <title>{apiValues.buildTitle(this.props.page.browserTitle)}</title>
          <meta property="og:title" content={this.props.page.browserTitle}/>
          <meta property="og:description" content={this.props.page.content}/>
        </Helmet>

        {this.renderBreadcrumbs()}
        <div className="page-content__content coupling coupling--centered-thin">
          <div className="container container--coupling">
            <div className="markdown-text">
              <MarkdownField markup={this.props.page.content}/>
            </div>
          </div>
        </div>
      </PageBase>
    );
  }
}


export default withRouter(connect(
  (state) => ({
    page: state.page,
  }),
  (dispatch) => ({
    actions: bindActionCreators(pageContentActions, dispatch),
  }),
)(PageContent));


PageContent.propTypes = {
  actions: PropTypes.object.isRequired,
  className: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  page: PropTypes.object,
};
